import React, { useState, useEffect, useRef } from "react";
import { getEnergyConsumption } from "../../buildingFunctions";
import { Bar } from "react-chartjs-2";
import ModalView from "./ModalView";
import EnergySwitchesModal from './EnergySwitchesModal'
import VanillaCalendar from './Calendar'
import MenuCard from './MenuCard';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
import logger from '../Functions/logger';

function Energy(props) {
  const [energySwitches, setEnergySwitches] = useState([]);
  const [energyConsumption, setEnergyConsumption] = useState({});
  const [consumptionIds, setConsumptionIds] = useState([]);
  const [data, setData] = useState([]);
  const [switchData, setSwitchData] = useState([]);
  const [currentSwitch, setCurrentSwitch] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [chartData, setChartData] = useState({});
  const [startDate, setStartDate] = useState(getPreviousDate(7));
  const [endDate, setEndDate] = useState(getPreviousDate(1));
  const [isOpen, setIsOpen] = useState(false);

  const calendarContainerRef = useRef(null);

  useEffect(() => {
    setIsOpen(false);
    return () =>{
      setIsOpen(false);
    }
  }, [])

  useEffect(() => {
    consumption(startDate, endDate);
  }, [consumptionIds]);

  useEffect(() => {
    const dataa = {
      labels: data.map((d) => d.date),
      datasets: [
        {
          label: 'KW',
          data: data.map((d) => d.kW / 1000),
          backgroundColor: 'rgb(246, 146, 32)'
        },
      ],
    };
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: props.translations.energyConsumption,
        },
      },
    };
    setChartData({
      options: options,
      data: dataa,
    })
    setIsOpen(false);
  }, [data, props.translations])



  useEffect(() => {
    const fetchData = async () => {
      try {
        props.getEnergy()
        
        setEnergySwitches(props.energySwitches);
        setEnergyConsumption(props.energyConsumption);
      } catch (error) {
        logger.error("Error fetching energy consumption:", error);
      }
    };
    fetchData();
    setIsOpen(false);
  }, [props.buildingID]);

  useEffect(() => {
    phaseClampCheck();
  }, [energyConsumption]);

  function consumption(start, end, id) {
    setStartDate(new Date(start));
    setEndDate(new Date(end));

    if (id) {
      deviceConsumption(start, end, id);
    } else {
      generalConsumption(start, end);
    }
  }

  function deviceConsumption(start, end, id) {
    let deviceCurrentConsumption = [];
    let currentDate = new Date(start);
    let switchConsumption = 0;
    while (currentDate <= end) {
      let formatedCurrentDate = formateDate(currentDate);
      const sw = energyConsumption[formatedCurrentDate];
      if (sw) {
        switchConsumption = sw[id];
        if (switchConsumption) {
          deviceCurrentConsumption.push({
            date: formatedCurrentDate,
            kW: switchConsumption,
          })
        }
      } else {
        deviceCurrentConsumption.push({
          date: formatedCurrentDate,
          kW: 0,
        })
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setSwitchData(deviceCurrentConsumption);
  }

  function deviceYearConsumption(id) {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 1, d.getMonth());
    const currentD = new Date();
    let data = {};
    let dataArray = [];

    while (d <= currentD) {
      d.setDate(d.getDate() + 1);
      let month = new Date(d).toLocaleString("default", {
        month: "short",
        year: "numeric",
      });
      let currDate = formateDate(d);
      if (energyConsumption[currDate]) {
        const sw = energyConsumption[currDate];
        const switchConsumption = sw[id];
        if (switchConsumption) {
          if (!data[month]) {
            data[month] = 0;
          }
          data[month] += +switchConsumption;
        }
      }
    }
    const keys = Object.keys(data);
    keys.forEach(key => {
      dataArray.push({
        date: key,
        kW: data[key],
      })
    });
    setSwitchData(dataArray)
  }

  function generalConsumption(start, end) {
    let dataArray = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      let totalConsumption = 0;
      let formattedDate = formateDate(currentDate);
      consumptionIds.forEach((id) => {
        if (energyConsumption[formattedDate]) {
          const sw = energyConsumption[formattedDate];
          const switchConsumption = sw[id];
          if (switchConsumption) {
            totalConsumption += switchConsumption;
          }
        }
      });
      dataArray.push({
        date: formattedDate,
        kW: totalConsumption,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setData(dataArray);
  }

  function yearConsumption() {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 1, d.getMonth());
    const endD = new Date();
    let data = {};
    let dataArray = [];

    while (d <= endD) {
      d.setDate(d.getDate() + 1);
      let month = new Date(d).toLocaleString("default", {
        month: "short",
        year: "numeric",
      });
      consumptionIds.forEach((id) => {
        let testDate = formateDate(d);
        if (energyConsumption[testDate]) {
          const sw = energyConsumption[testDate];
          const switchConsumption = sw[id];
          if (switchConsumption) {
            if (!data[month]) {
              data[month] = 0;
            }
            data[month] += +switchConsumption;
          }
        }
      });
    }
    const keys = Object.keys(data);
    keys.forEach(key => {
      dataArray.push({
        date: key,
        kW: data[key],
      })
    });
    setData(dataArray)
  }

  function phaseClampCheck() {
    let phaseclamp = energySwitches
      .filter((sw) => sw.type === "phase_clamp")
      .map((sw) => {
        return sw.id;
      });
    if (phaseclamp.length > 0) {
      setConsumptionIds(phaseclamp);
    } else {
      let sw = energySwitches.map((s) => {
        return s.id;
      });
      setConsumptionIds(sw);
    }
  }

  function formateDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  function currentDate() {
    const d = new Date();
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  function getPreviousDate(num) {
    const d = new Date();
    d.setDate(d.getDate() - num);
    return d;
  }

  function showModal() {
    return (
      <ModalView open={openModal} close={() => {
        setIsOpen(false)
        setOpenModal(false)
      }} sizeStyle={{width: "33%", height: "72%"}}  padding={false}>
        <EnergySwitchesModal
          consumption={(start, end) => consumption(start, end, currentSwitch.id)}
          switchData={switchData}
          currentSwitch={currentSwitch}
          formateDate={formateDate}
          deviceYearConsumption={() => deviceYearConsumption(currentSwitch.id)}
          translations={props.translations}
        ></EnergySwitchesModal>
      </ModalView>)
  }

  function energySwitchesMenu(sw, event) {
    event.stopPropagation();
    setCurrentSwitch(sw);
    setOpenModal(true);
    setIsOpen(false);
  }

  function energySwitchesView() {
    return (
      energySwitches.length > 0 ? 
        energySwitches.filter((sw) => sw.type !== "phase_clamp").map((sw) => (
          <div className="energyDivSwitches" key={sw.id} onClick={(event) => energySwitchesMenu(sw, event)}>
            {sw.name}
          </div>
        )) 
        : 
        <div className="zeroEnergySwitches">Δεν έχετε κανένα energy metering διακόπτη</div>
      );
  }
  

  function week() {
    consumption(getPreviousDate(7), getPreviousDate(1));
    setIsOpen(false);
  }
  function month() {
    consumption(getPreviousDate(30), getPreviousDate(1));
    setIsOpen(false);
  }
  function year() {
    yearConsumption();
    setIsOpen(false);
  }
  function handleConsumptionChange(start, end) {
    consumption(start, end)
    setIsOpen(false)
  }

  const containerHeader = (
    <div className="buttonsEnergy">
      <div className="insideButtonEnergy" onClick={() => setIsOpen(!isOpen)}>
        <img className="imageEnergyCalendar" src={require("./images/calendarEnergy.png")}></img>
      </div>
      <div className="insideButtonEnergy" onClick={week}>{props.translations.week}</div>
      <div className="insideButtonEnergy" onClick={month}>{props.translations.month}</div>
      <div className="insideButtonEnergy" onClick={year}>{props.translations.year}</div>
    </div>
  )

  return (
    <MenuCard 
      url={require("./images/energy_metering.png")}
      title={props.translations.energy}
      // menuCardCss={"energyMeteringDisplay"}
      containerHeader={containerHeader}
      menuCard={"menuCard energyMeteringDisplay"}
      cardHeaderBackColor={"cardHeader "}
      cardTitle={"cardTitle"}
      >
      <div className="energyView">
        <div style={{marginRight: "86%"}} ref={calendarContainerRef}>
          <VanillaCalendar isOpen={isOpen} setOpen={(open)=>{
            logger.log("===========set open===========")
            setIsOpen(open)}
          }
             consumption={(start, end) => handleConsumptionChange(start, end)} currentLanguage={props.currentLanguage} />
        </div>
        <div className="chartEnergy">
          {chartData.data && <Bar options={chartData.options} data={chartData.data} />}
        </div> 
        <div className="energySwitchesView scrollable">
          {energySwitchesView()}
        </div>
        {showModal()}
      </div>
    </MenuCard>
  );
}

export default Energy;
