import React, { useState, useEffect, useRef } from "react";
import './NewMain.css';
import { homenameRegexCheck } from '../Validation/accountValidation';
import { usernameRegexCheck } from '../Validation/accountValidation';
import { renameGuest } from '../Functions/userFunctions';
import { addNicknames, renameBuilding, deleteEmail } from '../../buildingFunctions';
import logger from '../Functions/logger';

function NewAccount(props) {
    const { openSnackbar } = props;
    let building = JSON.parse(localStorage.getItem("building"));
    let user = JSON.parse(localStorage.getItem("user"));

    const email = localStorage.getItem("email");
    const homeName = building.name
    const [homeNameInput, sethomeNameInput] = useState(homeName);
    const [userName, setUserName] = useState("");
    const [currentInput, setCurrentInput] = useState('');
    const [isOpen, setIsOpen] = useState(props.isOpen);
    logger.log("ACCOUNT PROPS:",user);
    let currentUsername = "";
    let userNicknames = [];

    function findNames() {
        currentUsername = user?.name;
        userNicknames = user?.nicknames;
        if (!userNicknames) {
            userNicknames = [];
        }
        if (!currentUsername) {
            currentUsername = null
        }
    }

    const [nickNames, setNickNames] = useState(userNicknames);
    findNames();
    //logger.log(currentUsername);
    //logger.log(userNicknames);
    //logger.log(nickNames)
    //logger.log((localStorage.getItem("building")))

    useEffect(() => {
        sethomeNameInput(homeName);
        findNames();
        setNickNames(userNicknames)
        setUserName(currentUsername)
    }, [homeName]);

    function changeHomeName() {
        if (homenameRegexCheck(homeNameInput)) {
            renameBuilding(homeNameInput)
                .then(() => {
                    openSnackbar(props.translations.successfulRegistration);
                    building.name = homeNameInput
                    localStorage.setItem("building", JSON.stringify(building));
                    props.setUpBuilding(homeNameInput);
                })
                .catch(() => {
                });
        } else {
            openSnackbar(props.translations.unsuccessfulRegistration);
        }
    }

    function changeUserName() {
        if (usernameRegexCheck(userName)) {
            logger.log(userName + email);
            renameGuest(userName, email)
                .then(() => {
                    openSnackbar(props.translations.successfulRegistration);
                    user.name = userName;
                    localStorage.setItem("user", JSON.stringify(user));
                })
                .catch(() => {
                });
        } else {
            openSnackbar(props.translations.unsuccessfulRegistration);
        }
    }

    function deleteAccount() {
        if (confirm(props.translations.deleteAccountQuestion)) {
            deleteEmail(userName, "el").then(() => { openSnackbar(props.translations.sendEmailSuccess) }).catch(() => { openSnackbar(props.translations.sendEmailFailure) })
        } else {
            openSnackbar(props.translations.successfulCancelation);
        }

    }



    const handleAddNickname = () => {
        const trimmedInput = currentInput.trim();
        if (nickNames.includes(trimmedInput)) {
            openSnackbar(props.translations.nicknameExists);
            setCurrentInput('');
            return;
        } else {
            if (usernameRegexCheck(trimmedInput)) {
                logger.log(trimmedInput)
                nickNames.push(trimmedInput)
                addNicknames(nickNames, email)
                    .then(() => {
                        openSnackbar(props.translations.nicknameSavedSuccessfuly);
                        user.nicknames = nickNames;
                        localStorage.setItem("user", JSON.stringify(user));
                    })
                    .catch(() => {
                    });
            } else {
                openSnackbar(props.translations.unsuccessfulRegistration);
                setCurrentInput('');
                return;
            }
            setNickNames([...nickNames]);
            setCurrentInput('');
        };
    }

    const handleRemoveNickname = (indexToRemove) => {
        //const filteredNickNames = nickNames.filter((_, index) => index !== indexToRemove);
        //setNickNames(filteredNickNames);
        nickNames.splice(indexToRemove, 1)
        setNickNames([...nickNames]);
        addNicknames(nickNames, email).then(() => {
            openSnackbar(props.translations.nicknameDelete);
            user.nicknames = nickNames;
            localStorage.setItem("user", JSON.stringify(user));
        })
            .catch(() => {
            });
    };

    const renderNicknameInputs = () => {
        return nickNames.map((nickname, index) => (
            <div key={index} className="titleArea sceneTitleLabel">
                <input
                    type="text"
                    value={nickname}
                    onChange={(event) => {
                        const updatedNickNames = [...nickNames];
                        updatedNickNames[index] = event.target.value;
                        setNickNames(updatedNickNames);
                    }}
                    className="textBoxAccount"
                />
                <img className='deleteInputImg' src={require("./images/bin_black.png")} onClick={() => handleRemoveNickname(index)} alt="delete" />
            </div>
        ));
    };

    const handleSubmit = () => {
        logger.log(nickNames);
        openSnackbar(props.translations.nicknamesSavedSuccessfuly);
    };

 


    return (
        <div className="container column onCreate scrollable">
            <img className="imgAccount" src={require("./images/orange_account.png")}></img>
            <div className="account" style={{ marginBottom: '15px' }}>{props.translations.myAccount}</div>
            <div className="textAccount">{props.translations.homeName}</div>
            <div className="titleArea sceneTitleLabel">
               { user.permission === "admin" ? <input
                    placeholder={"Εισάγετε όνομα σπιτιού"}
                    value={homeNameInput}
                    type="text"
                    onChange={(event) => sethomeNameInput(event.target.value)}
                    className="textBoxAccount"
                /> : <span  className="textBoxAccount"> {homeNameInput} </span>}
            </div>
           { user.permission === "admin" ? <div className="accountButton" onClick={() => changeHomeName()}>
                {props.translations.save2}
            </div> : <></>
}
            <div className="textAccount">{props.translations.username}</div>
            <div className="titleArea sceneTitleLabel">
                { user.permission === "admin" ? <input
                    placeholder={props.translations.insertUserName}
                    value={userName}
                    type="text"
                    onChange={(e) => { setUserName(e.target.value) }}
                    className="textBoxAccount"
                /> : <span  className="textBoxAccount"> {userName} </span>}
            </div>
            {user.permission === "admin" ?  <div className="accountButton" onClick={() => changeUserName()}>
                {props.translations.save2}
            </div> : <></>}

            <div className="textAccount2">{props.translations.addExtraNickname}</div>
            {renderNicknameInputs()}
            {nickNames.length < 3 && (
                <div className="titleArea sceneTitleLabel">
                    <input
                        placeholder={props.translations.newNameMult}
                        value={currentInput}
                        type="text"
                        onChange={(e) => setCurrentInput(e.target.value)}
                        className="textBoxAccount"
                    />
                </div>
            )}

            <div className="accountButton" onClick={()=>{handleSubmit(), handleAddNickname()}}>
                {props.translations.save2}
            </div>
            <div className="outsideAccountDeleteButton">
                <div className="accountButtonDelete" onClick={() => deleteAccount()}>
                    {props.translations.deleteAccount}
                    <img className="deleteAccount" src={require("./images/bin_black.png")} alt="delete" />
                </div>
            </div>
        </div>
    );

}

export default NewAccount;
