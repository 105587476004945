import React, { useEffect, useState } from 'react';
import { deleteMsg } from '../../MessagesFunctions';
import { ReactComponent as ReceptionMessageSvg } from '../NewInterface/images/reception_message.svg';
import ModalView from "./ModalView";
import ModalDouble from "./ModalDouble";
import MenuCard from './MenuCard';
import logger from '../Functions/logger';

export default function MessageArchive(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [button, setButton] = useState(false);
    const [isOpen, setIsOpen] = useState(props.isOpen);

    useEffect(() => {
        if (props.messages.length == 0) {
            setButton(false);
        }
        else {
            setButton(true);
        }
    }, [props.messages]);

    function deleteMessage(id) {
        deleteMsg(id).then(response => {
            logger.log(response.data);
            props.removeFunction(id, "messageArchive");
            props.openSnackbar(props.translations.deleteConfirmation);


        }).catch((error) => {
            props.openSnackbar(props.translations.deleteFailure);
        });
    }

    function confirmationMessage(id) {
        setIsModalOpen(id);
    };

    function messagesView() {
        if (button) {
            logger.log(props.messages)
            return (
                props.messages.map(message => (
                    <div className="messagesContainer" key={message.id}>
                        <div style={{
                            display: 'flex',
                            width: '100%'
                        }}>
                            <div style={{ margin: "0px 15px 0px 0px", width: "70px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ReceptionMessageSvg width={"25px"} height={"25px"} fill={"rgb(246, 146, 32)"} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <div className="messageArea">{message.text}</div>
                                <div className='messageDetailsArea'>{props.translations.messageFrom} {message.name}  {message.time}</div>
                            </div>
                        </div>
                        <img className="newImageArea" style={{ margin: "0px 15px 0px 0px", cursor: "pointer", alignItems: 'center', justifyContent: 'center' }} onClick={() => confirmationMessage(message.id)} src={require("./images/bin_black.png")} />
                    </div>
                ))
            )


        }
    }

    function emptyMessagesView() {

        <MenuCard
            menuCard={"menuCardMessage"}
            cardHeaderBackColor={"cardHeader menuCardMessageHeader "}
            // cardContainer={"cardContainerMessage"}
            title={props.translations.scenarios}
            cardTitle={"cardTitle"}
        >
            <div style={{ paddingBlock: '18%' }}>
                {props.translations.thereAreNoText1 + " " + props.translations.scenarios.toLowerCase() + " " + props.translations.thereAreNoText2}
            </div>
        </MenuCard>
    }

    return (
        props.messages.length > 0 ? <ModalView open={isOpen} close={() => {
            setIsOpen(false);
            props.currentButtonSelected(0);
        }} sizeStyle={{ width: "35%", height: "60%" }}  padding={true}>
            <div style={{ flex: "1", height: "100%", overflow: "hidden", position: "relative" }}>
                <div>
                    <div className="messagesTitleContainer">
                        <div style={{
                            display: 'flex',
                            width: '100%'
                        }}>
                            <div style={{ margin: "5px 0px 0px 0px", width: "70px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ReceptionMessageSvg width={"25px"} height={"25px"} fill={"rgb(246, 146, 32)"} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <div >{props.translations.incomingMessages}</div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="scrollable" style={{ width: "100%", height: '80%' }}>
                    {messagesView()}
                </div>
                {props.messages.length ? <div className="outsideSceneCreateButton">
                    <div className="modalButton" onClick={() => confirmationMessage('all')} >{props.translations.deleteAll}
                        <img style={{ cursor: "pointer" }} src={require("./images/bin_black.png")} />
                    </div>
                </div> : <></>}
            </div>
            <ModalDouble translations={props.translations} isOpen={isModalOpen} ok={() => deleteMessage(isModalOpen)}
                closeModal={() => setIsModalOpen(false)} title={isModalOpen === "all" ? props.translations.deleteAllQuestion : props.translations.deleteMessageQuestion}
                rightButton={isModalOpen === "all" ? props.translations.deleteAll : props.translations.delete} />
        </ModalView> : <ModalView open={isOpen} close={() => {
            setIsOpen(false);
            props.currentButtonSelected(0);

        }} sizeStyle={{ width: "28%", height: "25%" }} ><MenuCard
            menuCard={" modalMenuCardMessage"}
            cardHeaderBackColor={"cardHeader menuCardMessageHeader menuCardMessageHeaderPadding"}
            cardContainer={"cardContainerMessage"}
            title={props.translations.messages.toUpperCase()}
            cardTitle={"cardTitle"}
        >
                <div style={{ paddingBlock: '18%' }}>
                    {props.translations.thereAreNoText1 + " " + props.translations.messages.toLowerCase() + " " + props.translations.thereAreNoText2}
                </div>
            </MenuCard></ModalView>


    );
}
