import React from 'react';
import NewOnOff from './NewOnOff';
//import '../css/Clock.css';
import logger from '../Functions/logger';

class NewSceneNames extends React.Component {
    state = {
        selected: this.props.scenes?.[0]?.name ?? "",
    }
    constructor(props) {
        super(props);
        this.onChangeValue = this.onChangeValue.bind(this)
        this.renderSc = this.renderSc.bind(this)

    }
    componentDidMount() {
        this.props.enableScene(this.state.selected);
        logger.log("mount");
        logger.log(this.props.scene);
    }
    deviceOnOff = (on, deviceID) => {
        this.props.deviceOnOff(on, deviceID);
    }

    deviceExists = (deviceID) => {
        let device = this.props.devices.find(device => device == deviceID);
        return typeof device == "undefined";
    }

    enableScene(scene) {
        let index = this.props.scenes.findIndex(ss => scene.name == ss.name);

        if (index > -1) {
            this.props.scenes[index].selected = this.props.scenes[index].selected ? false : true;
            this.setState({ scenes: this.props.scenes });
        }
        this.props.enableScene(this.props.scenes[index]);
    }

    onChangeValue(event) {
        logger.log(event.target.value);
        this.setState({
            selected: event.target.value,
        })
        this.props.enableScene(event.target.value);

    }

    renderSc(scene) {
        const { selected } = this.state;
        return <div className="container space-between devicePaddingContainer">
            <div>{scene.name}</div>
            <div>
                <label className="radioButtonContainer">
                    <input type="radio" id={scene.name} checked={selected === scene.name} onChange={this.onChangeValue} value={scene.name} name="scene" />
                    <span className="checkmark"></span>
                </label>
            </div>
            
        </div>
    }

    renderSceneNames = (scenes) => {
        return <div className="container column">
            {scenes.map(this.renderSc)}
        </div>;
    }

    render() {
        return (
            this.renderSceneNames(this.props.scenes)
        );
    }
}

export default NewSceneNames;