import React, { useState } from 'react';
import ModalView from './ModalView';
import './NewMain.css';
import {hideSwitch, insertAllerRetourSwitches, getAllerRetourDevices} from '../../buildingFunctions'

function AddAllerRetour(props) {

    const [checkedValues, setCheckedValues] = useState([]);

    function handleCheckboxChange(switchId) {
        const index = checkedValues.indexOf(switchId);
        if (index === -1) {
            setCheckedValues([...checkedValues, switchId]);
        }else {
            const newCheckedValues = checkedValues.filter(id => id !== switchId);
            setCheckedValues(newCheckedValues);
        }
    };

    function saveChecked() {
        props.openSnackbar("Επιτυχής αποθήκευση.");
        let matchedSwitches = [];
        checkedValues.forEach(id => {
            const matchedSwitch = props.switches.find(obj => obj.id === id);
            if (matchedSwitch) matchedSwitches.push(matchedSwitch)
        });

        matchedSwitches.push(props.selectedSw)
        matchedSwitches = matchedSwitches.map((sw) => {
            return sw.id
        })

        insertAllerRetourSwitches(props.selectedSw.id, matchedSwitches).then(() => {
            props.openSnackbar("Επιτυχής καταχώρηση Aller-Retour.");
        }).catch(() => {
            props.openSnackbar("Αποτυχία καταχώρησης Aller-Retour.");
        }).finally(() => {
            props.close();
            props.closePrevious();
        })
    }

    return (
        <ModalView open={props.openModal} close={() => props.close()} sizeStyle={{width: "29%", height: "70%"}} padding={true}>
            <div style={{height: "100%"}}>
                <div style={{height: "80%"}}>
                    <div className="allerRetourText">{props.translations.allerRetour}</div>
                    <div style={{marginBlock: "20px", fontSize: "14px auto"}}>{props.translations.allerRetourWith}</div>
                    <div className="scrollable" style={{height: "80%"}}>
                        {props.switches.filter(s => ["switch", "dimmer"].includes(s.type) && s.id !== props.selectedSw.id).map(sw => (
                            <div className="switchAllerRetour" key={sw.id}>
                                <input
                                    className="checkboxAllerRetour"
                                    type="checkbox"
                                    id={`checkbox-${sw.name}`}
                                    onChange={() => handleCheckboxChange(sw.id)}
                                    checked={checkedValues.includes(sw.id) || false}
                                />
                                <label htmlFor={`checkbox-${sw.name}`} className="checkbox-label">{sw.name}</label>
                                
                            </div>
                        ))}
                    </div>
                </div>
                
                <div style={{height: "20%", overflow: "hidden"}}>
                    <div className="saveNameBtn" style={{marginTop: "40px"}} onClick={saveChecked}>{props.translations.save2}</div>
                </div>
            </div>
        </ModalView>
    );
}

export default AddAllerRetour;
