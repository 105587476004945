import storage from "./Storage";
import { getBuildingDevicesUrl, renameHomeBuilding, guestUrl, deleteEmailUrl, leaveHome , renameSwitch, switchUrl, allerRetourDevicesUrl, energyUrl, changeKeyword } from "./urls";
import logger from './Components/Functions/logger';

export const getBuildingDevices = () => {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'GET',
        };
        return storage.FetchData(getBuildingDevicesUrl + "/" + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });

}

export const removeUserHome = (buildingID, userEmail) => {
    const DATA = {
        method: 'DELETE',
        body: JSON.stringify({
            buildingID: buildingID,
            userEmail: userEmail,
        }),
    };
    return storage.FetchData(leaveHome, DATA);
}

export const renameBuilding = (name) => {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = { 
            method: 'PUT',
            body: JSON.stringify({
                name: name,
                buildingID: buildingID
            }),
        };
        return storage.FetchData(renameHomeBuilding + "/", DATA );
    }).catch((error) => {
        logger.warn(error);
    })
}

export const addNicknames = (nickname, email) => {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'POST',
            body: JSON.stringify({
                buildingID: buildingID,
                nicknames: nickname
            }),
        };
        return storage.FetchData(guestUrl + "/nicknames/" + buildingID + "/", DATA);
    }).catch((error) => {
        logger.warn(error);
    })
}

export const renameCurrentSwitch = (settedName, mSwitch) => {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                switch: {
                    name: settedName,
                },
            }),
        };
        return storage.FetchData(renameSwitch + "/" + mSwitch.id + "/" + buildingID, DATA)
    }).catch((error) => {
        logger.warn(error);
    });
}

export function hideSwitch(sw) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                switch: {
                    hidden: true
                }
            }),
        };
        return storage.FetchData(switchUrl + '/hidden/' + sw.id + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function unhideSwitch(sw) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                switch: {
                    hidden: false
                }
            }),
        };
        return storage.FetchData(switchUrl + '/hidden/' + sw.id + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function deleteSwitches(selectedSwitchID) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'DELETE'
        };
        return storage.FetchData(switchUrl + '/' + selectedSwitchID + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function updateSwitchNames(sw) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                switch: sw
            }),
        };
        return storage.FetchData(switchUrl + '/names/' + sw.id + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function deleteEmail(email, language) {
    const DATA = {
        method: 'POST',
        body: JSON.stringify({
            email: email,
            language:language
        }),
    };
    return storage.FetchData(deleteEmailUrl, DATA);
}

export function insertAllerRetourSwitches(id, switches) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'POST',
            body: JSON.stringify({
                buildingID: buildingID,                
                mainSwitchID: id,
                switches: switches
            }),
        };
        return storage.FetchData(allerRetourDevicesUrl + "/", DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function deleteAllerRetourSwitches(id) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'DELETE',
            body: JSON.stringify({
                buildingID: buildingID,
                oneSwitchID: id,
            }),
        };
        return storage.FetchData(allerRetourDevicesUrl, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export const getAllerRetourDevices = () => {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'GET',
        };
        return storage.FetchData(allerRetourDevicesUrl + "/" + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export const getEnergyConsumption = () => {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'GET',
        };
        return storage.FetchData(energyUrl + "/" + buildingID ,DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function changeKeywords(Keyword, device) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: "PUT",
            body: JSON.stringify({
                keyword : Keyword,
                device: device,
            })
        };
        return storage.FetchData(changeKeyword + "/" + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}