import storage from "../../Storage";
import { getBuildingDevicesUrl, settingsUrl, newsUrl, changeTabletLanguage } from "../../urls";
import logger from './logger';

export const getBuildingDevices = () => {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'GET',
        };
        return storage.FetchData(getBuildingDevicesUrl + "/" + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export  function setRegion(text) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                region: text,
            }),
        };
        return storage.FetchData(settingsUrl + '/region/' + buildingID, DATA);
    });
}

export function setNews(news) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                numberOfNews: news,
                origin: 4,
            }),
        };
        return storage.FetchData(newsUrl + '/' + buildingID, DATA);
    });
}

export function setLanguageTablet(language) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'POST',
            body: JSON.stringify({
                buildingID: buildingID,
                language: language
            }),
        };
        return storage.FetchData(changeTabletLanguage, DATA);
    });
}

export function setCity(place, country) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                city: place,
                country: country,
            }),
        };
        return storage.FetchData(settingsUrl + '/city/' + buildingID, DATA);
    });
}