import React, { useState } from 'react';
import { acceptInvites, deleteInvites, deleteAllInvites } from '../../InviteFunctions';
import ModalView from "./ModalView";
import ModalDouble from "./ModalDouble";
import MenuCard from './MenuCard';

export default function NewInvites(props) {

    const [isOpen, setIsOpen] = useState(props.isOpen);
    const [isModalOpen, setIsModalOpen] = useState(false);


    function deleteInvite(id) {
        if (id == "all") {
            deleteAllInvites(id)
                .then((res) => {
                    props.removeFunction(id, "invite");
                    props.openSnackbar(props.translations.deleteInviteSuccess);
                })
                .catch((err) => {
                    props.openSnackbar(props.translations.deleteInviteFailure);
                })
        } else {
            deleteInvites(id)
                .then((res) => {
                    props.removeFunction(id, "invite");
                    props.openSnackbar(props.translations.deleteInviteSuccess)
                })
                .catch((err) => {
                    props.openSnackbar(props.translations.deleteInviteFailure)
                })
        }
    }

    function acceptInvite(id) {
        acceptInvites(id)
            .then((res) => {
                props.removeFunction(id, "invite");
                props.openSnackbar(props.translations.acceptInviteSuccess)
            })
            .catch((err) => {
                props.openSnackbar(props.translations.acceptInviteFailure)
            })
    }

    function confirmation(id) {
        setIsModalOpen(id);
    }

    return (
        props.invites.length > 0 ? <ModalView open={isOpen} close={() => {
            setIsOpen(false);
            props.currentButtonSelected(0);
        }} sizeStyle={{width: "35%", height: "60%"}}  padding={true}>
            <div style={{width: "100%", height:'10%'}}>
                <span className="titleInvites">{props.translations.invites}</span>
            </div>
            <div className="invitesScrollMenu">
                <div className="scrollable sceneSizeButton">
                    {
                        props.invites.map((anInvite) => {
                            return <div className="inviteArea inviteButton space-between" key={anInvite.id}> {props.translations.inviteFrom}
                                {anInvite.name}
                                <div className="buttonInvites" onClick={() => { acceptInvite(anInvite.id) }}>
                                    {props.translations.acceptInvite}
                                </div>
                                <div style={{ marginLeft: "35px" }} onClick={() => { confirmation(anInvite.id) }}>
                                    <img style={{ cursor: 'pointer' }} src={require("./images/bin_black.png")} />
                                </div>
                            </div>
                        })
                    }
                </div>
                {props.invites.length > 0 && <div className="outsideSceneCreateButton">
                    <div className="modalButton" onClick={() => { confirmation("all") }}>
                        <div className="buttonArea">{props.translations.deleteAll}</div>
                        <img className="imgBin" src={require("./images/bin_black.png")} />
                    </div>
                </div>}
            </div>
            <ModalDouble translations={props.translations} isOpen={isModalOpen} ok={() => deleteInvite(isModalOpen)}
                closeModal={() => setIsModalOpen(false)} title={isModalOpen === "all" ? props.translations.deleteAllInvitesQuestion : props.translations.deleteInviteQuestion}
                rightButton={isModalOpen === "all" ? props.translations.deleteAll : props.translations.delete} />
        </ModalView> : <ModalView open={isOpen} close={() => {
            setIsOpen(false);
            props.currentButtonSelected(0);

        }} sizeStyle={{ width: "28%", height: "25%" }} ><MenuCard
            menuCard={" modalMenuCardMessage"}
            cardHeaderBackColor={"cardHeader menuCardMessageHeader menuCardMessageHeaderPadding"}
            cardContainer={"cardContainerMessage"}
            title={props.translations.invitesText.toUpperCase()}
            cardTitle={"cardTitle"}
        >
                <div style={{ paddingBlock: '18%' }}>
                    {props.translations.thereAreNoText1 + " " + props.translations.invitesText.toLowerCase() + " " + props.translations.thereAreNoText2}
                </div>
            </MenuCard></ModalView>
    )
}