import React from 'react';
import "./NewMain.css";
import "../../App.css"
import { ReactComponent as AccountSvg } from './images/account.svg'

function UserMenu(props) {


    return (
        <div className="dropdown">
            <div className="dropbtn profileContainer">
                <div className="topBarImgAccount"><AccountSvg /></div>
                <span >
                    {props.selectedBuilding.toUpperCase()}
                </span>
                {/* <img className="imgMenu" src={require("./images/orange_account.png")} />
                <div className="currentBuildingName">{props.selectedBuilding}</div> */}
            </div>
            <div className="dropdown-content-menu">
                <div className="menuButtons" id="account" onClick={() => props.currentButtonSelected("account")}>{props.translations.myAccount}</div>
                <div className="menuButtons" id="general_info" onClick={() => props.currentButtonSelected("general_info")}>{props.translations.generalInformation}</div>
                <div className="menuButtons" id="messages" onClick={() => props.currentButtonSelected("messages")}>{props.translations.messageArchiveText}</div>
                <div className="menuButtons" id="invites" onClick={() => props.currentButtonSelected("invites")}>{props.translations.invites}</div>
                <div className="menuButtons" id="change_building" onClick={() => props.currentButtonSelected("change_building")}>{props.translations.changeHome}</div>
                <div className="menuButtons" id="remove_building" onClick={() => props.currentButtonSelected("remove_building")}>{props.translations.deleteHome}</div>
                <div className="menuButtons" id="disconnect" onClick={() => props.currentButtonSelected("disconnect")}>{props.translations.logout}</div>
            </div>
        </div>
    )
}

export default UserMenu;