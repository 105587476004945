import React, { createRef } from "react";
import NewOnOff from "./NewOnOff";
//import '../css/Clock.css';
import NewThermostat from "./NewThermostat";
import EditSceneThermostat from "./EditSceneThermostat";
import axios from "axios";
import {
	sceneInsert,
	sceneUpdate,
	sceneDelete,
} from "../../ScenariosFunctions";
import { deleteAction } from "../../ActionFunctions";
import { withSnackbar } from "react-simple-snackbar";
import { checkScene } from "../../validation";
import MenuCard from "./MenuCard";
import NewButtons from './NewButtons';
import logger from '../Functions/logger';

class EditScene extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allThermostats: [],
			allSwitches: [],

			scene: {
				name: "",
				switches: [],
				thermostats: [],
			},
			sceneToBeEdited: {
				id: '',
				name: '',
				names: {},
				switches: [],
				dimmers: [],
				thermostats: [],
			},
			new: false,
			sceneName: '',
			selectUnselect: false,
			buttonArray: []
		};

		this.inputRef = createRef();

		this.fillSwitches = this.fillSwitches.bind(this);
		this.FillDevices = this.FillDevices.bind(this);
	}

	componentDidMount() {
		logger.log("All Switches:", this.state.allSwitches);
		logger.log("Edit Scene");
		logger.log("props.action:", this.props);
		this.fillSwitches();
		logger.log("name", this.state.scene.name);
		logger.log(this.state.scene.name);
		// this.setState({ new: this.state.sceneName == "" ? true : false });

		const isNew = this.state.scene.name == "" ? true : false;
		logger.log("ISNEW:", isNew);

		const newButtonArray = isNew ? [
			{ text: this.props.translations.cancel, onClickFunction: () => { this.props.changeMenu(1) } },
			{ text: this.props.translations.save2, onClickFunction: this.save }
		] :
			[
				{ text: this.props.translations.cancel, onClickFunction: ()=>{this.props.changeMenu(1)} },
				{ text: this.props.translations.delete, onClickFunction: this.deleteScene },
				{ text: this.props.translations.save2, onClickFunction: this.save }
			]

		this.setState({
			new: this.state.sceneName == "" ? true : false,
			buttonArray: newButtonArray
		})

		logger.log("buttonArray:", this.state.buttonArray);

		logger.log("this.state.sceneName:", this.state.sceneName);
	}

	fillSwitches = () => {
		// this.state.scene = this.props.scene;
		//this.props.building.switches.filter(sw => sw.type == "switch").map(this.swExists);
		this.props.thermostats.map(this.thExists);
		this.props.switches.map(this.swExists);



		let index = this.props.scenes.findIndex(
			(sc) => this.props.scene.id == sc.id
		);

		if (index > -1) {
			this.state.scene = this.props.scene;
			this.state.scene.thermostats.map(this.ExistedSceneTh);
			this.ExistedSceneSw();
			this.setState({ scene: this.state.scene });
		}
		else {
			this.enableSwitches();
		}
	};




	/*filterSwitches = (ss) => {
		/*let index = this.props.building.switches.findIndex(sw => ss.id == (sw.id));
		logger.log(this.props.building.switches);
		return index > -1 ? this.props.building.switches[index].type == "switch" ? true : false : false;
		let index = this.props.switches.findIndex(sw => ss.id == (sw.id));
		return index > -1 ? this.props.switches[index].type == "switch" ? true : false : false;
	}*/

	/*getThermostat = (building) => {
		let potentialThermostats = building.switches
			.filter((aSwitch) => {
				return aSwitch.type == 'thermostat';
			});
		if (potentialThermostats.length > 0)
			return potentialThermostats[0];
		else return {
			"hidden": false,
			"id": "00000",
			"name": "thermostat-1",
			"paired": false,
			"state": 0,
			"type": "thermostat"
		};
	}*/

	/*getThermostatArray = () => {
		let potentialThermostats = this.props.building.switches
		.filter((aSwitch) => {
			if(aSwitch !== undefined){
				return aSwitch.type == 'thermostat';
			}
	
		});
		logger.log(potentialThermostats);
		return potentialThermostats;
	}*/

	getThermostatScene = () => {
		let thermostat = this.getThermostat(this.props.building);
		let index = this.state.scene.thermostats.findIndex(sw => thermostat.id == (sw.id));
		if (index > -1) {
			return this.state.scene.thermostats[index];
		} else {
			return {
				state: 0,
				selected: false,
				name: "thermostat-0"
			}
		}
	}

	newScene = () => {
		const { openSnackbar } = this.props;
		/*const data = {
			token: this.props.token,
			buildingID: this.props.building.buildingID,
			origin: 2,
			name: this.state.scene.name,
			switches: this.state.switches
		};*/
		logger.log("scenes: ", this.props.scenes);
		logger.log(this.state.scene.name);
		let index = this.props.scenes.findIndex(
			(scene) => scene.name === this.state.scene.name
		);
		if (index > -1) {
			logger.log("index here: ", index);
			openSnackbar(this.props.translations.scenarioNameExists);
		} else if (this.state.scene.name.length < 3) {
			openSnackbar(this.props.translations.shortName);
		} else if (
			this.state.scene.switches.length == 0 &&
			this.state.scene.thermostats.length == 0
		) {
			openSnackbar(this.props.translations.selectASwitch);
		} else {
			sceneInsert(
				this.state.scene.name,
				this.state.scene.switches,
				this.state.scene.thermostats
			)
				.then((responseData) => {
					this.props.addFunction(this.state.scene, "scenario");
					this.props.changeMenu(1);
					this.props.getScenes();
					if (responseData.state == 0) {
						this.props.open_Snackbar();
					}

					//alert("Executed Everything");
				})
				.catch((error) => { });
		}

		/*axios
			.post(this.newSceneLink, data).then((res) => {
				if (res.data.state === 0) {
					this.props.changeMenu(1)
					//this.setState({ action: this.state.action });
					alert("Executed Everything");
				}
	
			}).catch(err => {
			})*/
	};

	/*updateScene = () => {
		const data = {
			token: this.props.token,
			buildingID: this.props.building.buildingID,
			origin: 2,
			name: this.state.scene.name,
			switches: this.state.scene.switches
		};
		axios
			.post(this.updateSceneLink, data).then((res) => {
				if (res.data.state === 0) {
					this.props.changeMenu(1)
					//this.setState({ action: this.state.action });
				}
			}).catch(err => {
			})
	}*/

	updateScene = () => {
		const { openSnackbar } = this.props;
		logger.log("==UPDATE==");
		logger.log(this.state.scene.switches.length);
		if (
			this.state.scene.switches.length > 0 ||
			this.state.scene.thermostats.length > 0
		) {
			sceneUpdate(
				this.state.scene.name,
				this.state.scene.switches,
				this.state.scene.thermostats,
				this.state.scene.id
			)
				.then((responseData) => {
					//this.props.building.scene = this.state.scene;
					/*let index = this.props.building.scenes.findIndex(scene => scene.id == this.state.scene.id);
				if (index > -1) {
					this.props.building.scenes[index] = this.state.scene;
				}*/
					let index = this.props.scenes.findIndex(
						(scene) => scene.id == this.state.scene.id
					);
					if (index > -1) {
						this.props.scenes[index] = this.state.scene;
					}
					this.props.changeMenu(1);
				})
				.catch((error) => { });
		} else {
			openSnackbar(this.props.translations.selectASwitch);
		}
	};

	/*deleteScene = () => {
		const data = {
			token: this.props.token,
			buildingID: this.props.building.buildingID,
			origin: 2,
			name: this.state.scene.name,
		};
		axios
			.post(this.deleteSceneLink, data).then((res) => {
				if (res.data.state === 0) {
					this.props.changeMenu(1)
					//this.setState({ action: this.state.action });
				}
			}).catch(err => {
			})
	}*/

	deleteScene = () => {
		let index = this.props.actions.findIndex(
			(action) =>
				action.text === this.state.scene.name && action.type === "scene"
		);

		sceneDelete(this.state.scene.id)
			.then((responseData) => {
				this.props.removeFunction(this.state.scene.id, "scenario");
				this.props.changeMenu(1);
			})
			.catch((error) => { });

		if (index > -1) {
			deleteAction(this.props.actions[index].id)
				.then((responseData) => {
					this.props.removeFunction(
						this.props.actions[index].id,
						"action"
					);
					this.changeMenu();
				})
				.catch((error) => { });
		}
	};

	save = () => {
		logger.log(this.state.scene);
		const { openSnackbar, closeSnackbar } = this.props;
		logger.log("Scene name");
		if (this.state.sceneName !== '') {
			this.state.scene.name = this.state.sceneName;
		}
		logger.log(this.state.scene.name);
		if (checkScene(this.state.scene.name)) {
			this.state.scene.thermostats = [];
			this.state.scene.switches = [];
			logger.log("=======");
			this.state.allSwitches.map(x => this.FillDevices(x, "switches"));
			logger.log("============");
			this.state.allThermostats.map(x => this.FillDevices(x, "thermostats"));

			if (typeof this.state.scene.id === "undefined") {
				this.newScene();
			} else {
				this.updateScene();
			}
		} else {
			openSnackbar(this.props.translations.wrongNameType);
		}
	};


	handleChange = (event) => {

		logger.log("this.state.sceneName:", this.state.sceneName);
		logger.log("scene:", this.state.scene);

		if (document.activeElement === this.inputRef.current) {
			logger.log("Input is focused");
			this.setState({ sceneName: event.target.value })
		} else {
			this.setState({ sceneName: this.state.scene.name })
			logger.log("Input is not focused");
		}
	};

	renderScene = (scene) => {
		logger.log("sceneSTATE:", this.state.scene);
		logger.log("buttonArray:", this.state.buttonArray);
		const containerHeader = (

			<div>
				<div className="titleArea sceneTitleLabel">
					<input
						type="text"
						name="name"
						style={{ border: 'none' }}
						placeholder={this.state.scene.name === '' ? this.props.translations.newNameMult : this.state.scene.name}
						ref={this.inputRef}
						onChange={this.handleChange}
					/>

				</div>
				<div className="selectUnselect" onClick={() => this.enableSwitches()}>{this.state.selectUnselect ? this.props.translations.unselectAll : this.props.translations.selectAll}</div>

			</div>

		)
		logger.log("SCENE:", this.state.new);
		const containerFooter = (
			<div>
				{this.renderTh()}
					<NewButtons
						buttonArray={this.state.buttonArray}
					>
					</NewButtons>
			</div>
		)
		// {this.state.allSwitches.map(this.renderSw)};
		logger.log("renderScene");
		return (
			<MenuCard
				url={require("./images/senaria_orange_02.png")}
				title={this.state.scene.name == '' ? this.props.translations.addNewScene : this.props.translations.editScene}
				containerHeader={containerHeader}
				containerFooter={containerFooter}
				cardTitle={"cardTitle cardTitleColor"}
				menuCard={"menuCard scenarioCard"}
				cardContainer={"tableCssForNewScenario"}>
				<div>
					{this.state.allSwitches.map((swIndex, index) => {
						return this.renderSw(swIndex, index)
					})}
				</div>
			</MenuCard>

		);
	};

	swExists = (ss) => {
		let index = this.state.allSwitches.findIndex((sw) => ss.id == sw.id);
		logger.log("swExists");

		if (index < 0) {
			logger.log("here");
			logger.log(typeof this.state.allSwitches);
			this.state.allSwitches.push({
				id: ss.id,
				selected: ss.selected,
				state: 0,
			});
			logger.log(this.state.allSwitches);
		}

		return ss;
	};


	FillDevices = (sw, deviceArray) => {

		if (sw.selected) {
			let index = this.state.scene[deviceArray].findIndex(
				(ss) => sw.id == ss.id
			);
			if (index > -1) {
				this.state.scene[deviceArray][index] = sw;
			} else {
				this.state.scene[deviceArray].push(sw);
			}
		}
	}

	thExists = (therm) => {
		let index = this.state.allThermostats.findIndex(
			(stateTherm) => therm.id == stateTherm.id
		);
		if (index < 0) {
			this.state.allThermostats.push({
				id: therm.id,
				selected: false,
				state: therm.state,
			});
			//this.state.scene.thermostats.push(therm);
		}
		return therm;
	};

	ExistedSceneTh = (therm) => {
		let index = this.state.allThermostats.findIndex(
			(stateTherm) => therm.selected == stateTherm.selected
		);
		if (index < 0) {
			let position = this.state.allThermostats.findIndex(
				(stateTherm) => therm.id == stateTherm.id
			);
			if (position > -1) {
				this.state.allThermostats[position] = therm;
			}
		}
	};

	ExistedSceneSw = () => {
		//let index = this.state.allSwitches.findIndex(ss => sw.selected == ss.selected);
		//if(index < 0){
		logger.log("existed");
		for (let i = 0; i < this.state.allSwitches.length; i++) {
			let position = this.state.scene.switches.findIndex(
				(ss) => this.state.allSwitches[i].id == ss.id
			);
			logger.log(position);
			if (position > -1) {
				this.state.allSwitches[i] = this.state.scene.switches[position];
			} else {
				this.state.allSwitches[i].selected = false;
			}
		}

		//}
	};

	changeSceneThermostat = (thermostatToChange) => {
		//change state
		let index = this.state.allThermostats.findIndex(
			(stateTherm) => thermostatToChange.id == stateTherm.id
		);
		if (index > -1) {
			if (!this.state.allThermostats[index].selected) {
				this.state.allThermostats[index].selected = true;
			}
			this.state.allThermostats[index].state = thermostatToChange.state;
		}
	};

	getSwitchName = (ss) => {
		let index = this.props.switches.findIndex((sw) => ss.id == sw.id);
		if (index > -1) return this.props.switches[index].name;
		else return "no name for switch";
	};

	selectedColor = (theSwitch) => {

		let allDevices = this.state.allSwitches;
		let selected = allDevices.find(item => item.id === theSwitch.id);

		if (selected?.selected === true) {
			logger.log("selected ID:", theSwitch.id);
			return true;
		}
		return false;

	}

	renderSw = (sw, index) => {
		// logger.log("sw:", this.state.scene);
		const isSelected = this.selectedColor(sw);
		logger.log("SELECTED COLOR:", this.selectedColor(sw));
		return (
			<div className="editScenarioRowColor">
				<div className="editScenarioRow" onClick={() => { this.enableSwitch(sw) }} key={sw.id}>
					<div className="selectUnselectName">
						<div className={isSelected ? "selectedDiv" : "unselectedDiv"} ></div>
						<span className='switchNames' >
							{this.getSwitchName(sw)}
						</span>
					</div>

					<div className="scenarioOnOff" >
						<NewOnOff
							repeat={sw.state}
							OnOff={(on) => this.onSelected(on, sw)}
							isEnabled={sw.state}
						></NewOnOff>
					</div>
				</div>
			</div>


		);
	};

	enableSwitches = () => {



		const { allSwitches, selectUnselect } = this.state;
		logger.log("selectUnselect:", selectUnselect);
		const updatedSwitches = allSwitches.map(item => ({
			...item,
			selected: !selectUnselect
		}));
		logger.log("allSwitches:", updatedSwitches);
		this.setState({
			allSwitches: updatedSwitches,
			selectUnselect: !selectUnselect
		});
	};



	enableSwitch(sw) {
		let index = this.state.allSwitches.findIndex((ss) => sw.id == ss.id);
		if (index > -1) {
			this.state.allSwitches[index].selected = this.state.allSwitches[
				index
			].selected
				? false
				: true;
			this.setState({ allSwitches: this.state.allSwitches });
		}

		logger.log("switch:", sw);
	}

	onSelected = (state, ss) => {
		let index = this.state.allSwitches.findIndex((sw) => ss.id == sw.id);

		if (index > -1) {
			this.state.allSwitches[index].state = state ? 1 : 0;
			if (state == 1) {
				this.state.allSwitches[index].selected = true;
			}
			this.setState({ allSwitches: this.state.allSwitches });
		}
	};

	renderTh = () => {
		if (this.state.allThermostats.length !== 0) {
			return (
				<div className="thermoscene">
					<EditSceneThermostat
						changeSceneThermostat={this.changeSceneThermostat}
						thermostatArray={this.state.allThermostats}
						thermostats={this.props.thermostats}
					></EditSceneThermostat>
				</div>
			);
		}
	};

	render() {
		return this.state.scene != null ? (
			this.renderScene(this.state.scene)
		) : (
			<div></div>
		);
	}
}

const options = {
	position: "bottom-left",
	style: {
		marginBottom: "30px",
	},
};

export default withSnackbar(EditScene, options);
