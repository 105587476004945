import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ActionForm from './Components/ActionForm';
import registerServiceWorker from './registerServiceWorker';
import Home from './Stateless/Home';
import './fonts/Manrope/Manrope-Bold.ttf'
import './fonts/Manrope/Manrope-ExtraBold.ttf'
import './fonts/Manrope/Manrope-ExtraLight.ttf'
import './fonts/Manrope/Manrope-Light.ttf'
import './fonts/Manrope/Manrope-Medium.ttf'
import './fonts/Manrope/Manrope-Regular.ttf'
import './fonts/Manrope/Manrope-SemiBold.ttf'

ReactDOM.render(<Home />, document.getElementById('root'));
registerServiceWorker();

/*if(window.innerWidth>600) {
   // ReactDOM.render(<App />, document.getElementById('root'));

}
else {
    window.location.assign('https://m.housemate.online');
}*/