
import { React, useState, useEffect } from "react";
import '../../css/GeneralInformation.css'
import {setNews} from '../Functions/buildingFunctions'
import logger from '../Functions/logger';

function NumberOfNews(props) {

    const [radioButton, setRadioButton] = useState(parseInt(localStorage.getItem('selectedOption')) || 1);

    useEffect(() => {
        localStorage.setItem('selectedOption', radioButton);
    }, [radioButton]);

    const handleChange = (value) => {
        
        setNews(value)
        .then(()=>{
            setRadioButton(value);
        })
        .catch(error =>{
            logger.log("error:",error);
            props.setCurrentNumberOfNews(radioButton);
        })
        .finally("value:",value)
    }


    const generateRadioButtons = () => {
        const radioButtons = [];
    
        for (let i = 1; i <= 5; i++) {
            radioButtons.push(
                <div key={`editList-${i}`} >
                    <label key={i} className="radioButtonContainer">
                        <input type="radio" checked={radioButton === i} onChange={() => handleChange(i)} name="news" />
                        <span className="label-number">{i}</span>
                        <span className="checkmark"></span>
                    </label>
                </div>
            );
        }
        return radioButtons;
    }
    
    return (
        <div className="container">
            {generateRadioButtons()}
        </div>
    );
    
}

export default NumberOfNews;