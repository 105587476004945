import React, { Component } from 'react'
import axios from 'axios'
import FetchLeafletMap from './FetchLeafletMap'

class FetchUpload extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            EngineerFirstName: "",
            EngineerLastName: "",
            EngineerPhoneNumber: "",
            EngineerCountry: "",
            EngineerCity: "",
            EngineerStreetAddress: "",
            EngineerStreetNumber: "",
            EngineerAFM: 12312312312,
            EngineerDOY: "",
            EngineerPROFESSION: "Engineer",
            fetched: false,
            posts: [],
            loginEmail: this.props.email,
            loginPassword: this.props.password
        }

        this.handleChange = this.handleChange.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    fetchData = () => {
        let recordExists = false;
        let all_engineers = [];

        axios.get('https://hm-api.entranet.us:7656/main/electrician').then(response => {
            all_engineers = response.data.data;

            all_engineers.map(currRec => {
            if (currRec.afm === this.state.EngineerAFM)
                recordExists = true;
            });

            if (recordExists) {
                alert("there is already an engineer with the AFM you entered. Make sure you have entered it correctly.");
            }
            else {
                axios.get('https://nominatim.openstreetmap.org/search?q=' + this.state.EngineerStreetNumber + '+' 
                + this.state.EngineerStreetAddress + '+' + this.state.EngineerCity + '+' 
                + this.state.EngineerCountry + '&format=geojson').then(response => {
                    if (response.data.features.length) {
                        this.setState({
                            posts: response.data.features,
                            fetched: true
                        });
                    }
                    else {
                        alert("Could not retrieve any results! There might be an error in the data you entered!");
                    }
                }).catch(error => {
                    alert(error);
                })
            }
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            failedLogin: false
        });
    }

    render() {
        if (!this.state.fetched) {
            return (
                <div id="login">
                    <div className="false">
                        <div>
                            <h1 id="selLogin" style={{color:"orange", cursor:"auto!important"}}>Add New Engineer Data</h1> <span id = "selMid"></span>
                            <ul>
                                <li><input name="EngineerFirstName" className="lgInputFetch" type="text" placeholder="Frist name" onChange={this.handleChange} value={this.state.EngineerFirstName}></input></li>
                                <li><input name="EngineerLastName" className="lgInputFetch" type="text" placeholder="Last name" onChange={this.handleChange} value={this.state.EngineerLastName}></input></li>
                                <li><input name="EngineerPhoneNumber" className="lgInputFetch" type="text" placeholder="Phone number" onChange={this.handleChange} value = {this.state.EngineerPhoneNumber}></input></li>
                                <li><input name="EngineerCountry" className="lgInputFetch" type="text" placeholder="Country" onChange={this.handleChange} value={this.state.EngineerCountry}></input></li>
                                <li><input name="EngineerCity" className="lgInputFetch" type="text" placeholder="City" onChange={this.handleChange} value={this.state.EngineerCity}></input></li>
                                <li><input name="EngineerStreetAddress" className="lgInputFetch" type="text" placeholder="Adress" onChange={this.handleChange} value={this.state.EngineerStreetAddress}></input></li>
                                <li><input name="EngineerStreetNumber" className="lgInputFetch" type="text" placeholder="Street number" onChange={this.handleChange} value={this.state.EngineerStreetNumber}></input></li>
                                <li><input name="EngineerAFM" className="lgInputFetch" type="text" placeholder="AFM" onChange={this.handleChange} value={this.state.EngineerAFM}></input></li>
                                <li><input name="EngineerDOY" className="lgInputFetch" type="text" placeholder="DOY" onChange={this.handleChange} value={this.state.EngineerDOY}></input></li>
                                <li><input name="EngineerPROFESSION" className="lgInputFetch" type="text" placeholder="Professtion" onChange={this.handleChange} value={this.state.EngineerPROFESSION}></input></li>
                                <li><input name="UploadButton" type="button" value="Upload" onClick={this.fetchData}></input></li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return(
                <FetchLeafletMap results={this.state.posts} firstname={this.state.EngineerFirstName} lastname={this.state.EngineerLastName} phone={this.state.EngineerPhoneNumber} country={this.state.EngineerCountry} city={this.state.EngineerCity} addrName={this.state.EngineerStreetAddress} addrNum={this.state.EngineerStreetNumber} afm={this.state.EngineerAFM} doy={this.state.EngineerDOY} profession={this.state.EngineerPROFESSION} email={this.state.loginEmail} password={this.state.loginPassword} />
            )
        }
    }
}

export default FetchUpload