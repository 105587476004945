import React, { Component } from 'react'
import FetchUpload from './FetchUpload'
import md5 from 'md5'
import axios from 'axios'

class FetchLogin extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            failedLogin: false,
            loginEmail: "",
            loginPassword: "",
            config: false
        }
        
        this.handleChange = this.handleChange.bind(this);
        this.clickedLogin = this.clickedLogin.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            failedLogin: false
        });
    }

    clickedLogin = () => {
        axios.post('https://hm-api.entranet.us:7656/main/electrician/login', {
            email: this.state.loginEmail,
            password: md5(this.state.loginPassword)
        }).then(response => {
            this.setState({
                failedLogin: false,
                config: true
            })

            if (response.data.state === 0) {
                this.setState({
                    failedLogin: false,
                    config: true
                });
            }
            else {
                this.setState({
                    failedLogin: true
                })
                alert('Some error occured. Could not proceed...');
            }
        })
    }

    render() {
        if (!this.state.config) {
            return (
                <div id="platform">
                    <div className = {this.state.failedLogin ? "shake" : ""} id = "login">
                        <div id = "selBar">
                            <h1 id = "selLogin" style = {{color: "orange"}}>Login</h1> <span id = "selMid"></span>
                        </div>
                        <div>
                            <input name = "loginEmail" className = "lgInput" type = "text" placeholder = "email" onChange = {
                                    this.handleChange
                                }
                                value = {
                                    this.state.loginEmail
                                }>
                            </input>
                        </div>
                        <div>
                            <input name = "loginPassword" className = "lgInput" type = "password" placeholder = "password" onChange = {
                                    this.handleChange
                                }
                                value = {
                                    this.state.loginPassword
                                }>
                            </input> </div> <span onClick = {
                                this.clickedLogin
                            }
                        id = "lgButton" > → </span> {
                        this.state.failedLogin && <span id = "lgError" > Please check your info </span>} 
                    </div>
                </div>
            )
        } else {
            return (<FetchUpload email={this.state.loginEmail} password={this.state.loginPassword} />)
        }
    }
}

export default FetchLogin

