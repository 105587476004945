
import React, { useState, useEffect } from 'react';
import availableLanguages from "../../tabletLanguage/tabletLanguages.json"
import {setLanguageTablet} from '../Functions/buildingFunctions'
import logger from '../Functions/logger';

function ChangeTabletLanguage(props) {
    var tabletLanguagesParsed = availableLanguages?.data;



    return (
        <div className="languageSelectionGeneralInfo" style={{height: "100%"}}>
            <div>
                <div style={{height: "35%"}} className="bullet-checkbox">
                    <div className="changeTimeZoneTitle2 selectLanguage" style={{marginLeft: "10.5%"}}>{props.translations.changeLanguage}</div>
                    <div className="selectLanguage" style={{marginBlock: "10px", marginLeft: "8.5%", fontSize: "13px"}}>{props.translations.selectedLang} {props.currentTabletLanguage}</div>
                    <div style={{fontSize: "14px", marginBlock: "20px", marginLeft: "8%"}} className="selectLanguage">
                        <img className="imgAddSwitchName" src={require("./images/language.png")}/>
                        <div style={{fontSize: "14px", marginLeft: "10px"}}>{props.translations.selectLang}</div>
                    </div>
                    <div className="scrollable" style={{height: "60%", paddingLeft: "8%"}}>
                        {tabletLanguagesParsed.map((language) => {
                            return( 
                                <div className="checkbox-lang noselect" key={language.key}>
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${language.text}`}
                                        checked={language.text === props.currentTabletLanguage || false}
                                        onChange={() => {
                                            setLanguageTablet(language.key)
                                                .then((response) => {
                                                    props.setCurrentTabletLanguage(language.text);
                                                    logger.log("tablet response:",response);
                                                    
                                                })
                                                .catch((error) => {
                                                    logger.log("error:", error);
                                                    alert("Σφάλμα!");
                                                })
                                                .finally(() => {
                                                    props.setTabletLanguagesModal(false);
                                                })
                                        }}
                                    />
                                    <label htmlFor={`checkbox-${language.text}`} className="checkbox-label">{language.text}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangeTabletLanguage;
