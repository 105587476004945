import { useEffect, useRef, useState } from "react";
import VC from "vanilla-calendar-pro";
import "vanilla-calendar-pro/build/vanilla-calendar.min.css";
import logger from '../Functions/logger';

function VanillaCalendar(props) {
    const [start, setStart] = useState(null);
    const [end , setEnd] = useState(null);
    const ref = useRef(null);
    const [calendar, setCalendar] = useState(null);
    const [vc, setVC] = useState(null);


    function currentDate() {
        const d = new Date();
        const day = String(d.getDate()).padStart(2, "0");
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const year = d.getFullYear();

        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    }

    const options = {
        input: true,
        type: 'multiple',
        date: {
            min: '2022-01-01',
            max: currentDate(),
        },
        settings: {
        selection: {
            day: 'multiple-ranged',
        },
        visibility: {
            weekend: false,
            daysOutside: false,
        },
        },
        actions: {
            changeToInput(e, self) {
                e.stopPropagation()
                if (!self.HTMLInputElement) return;
                if (self.selectedDates[1]) {
                    self.selectedDates.sort((a, b) => +new Date(a) - +new Date(b));
                    self.HTMLInputElement.value = `${self.selectedDates[0]} — ${self.selectedDates[self.selectedDates.length - 1]}`;
                } else if (self.selectedDates[0]) {
                    self.HTMLInputElement.value = self.selectedDates[0];
                } else {
                    self.HTMLInputElement.value = '';
                }
            },
            clickDay(event, self) {
                event.stopPropagation()
                if(self.selectedDates){
                    const start = new Date(self.selectedDates[0]);
                    const end = new Date(self.selectedDates[self.selectedDates.length - 1]);
                    setStart(start);
                    if(self.selectedDates.length > 1){
                        setEnd(end);
                    }
                }else{ 
                    logger.log("error at selected dates.");
                }
            },
            clickArrow(event, self) {
                event.stopPropagation();
            },
        },
    }; 

    useEffect(() => {
        props.consumption(start,end);
    }, [end]);

    useEffect(() => {
        if(props.isOpen){
            vc?.show?.()
        } else {
            vc?.hide?.()
        }
    }, [props.isOpen])

    useEffect(()=>{
        if(ref == null || !ref.current) return;
        let vc = new VC(ref.current, options)
        vc?.init?.();
        setVC(vc);
        document.addEventListener("click", (event) => {
        if (vc && !vc.HTMLElement.innerHTML.includes(event.target.innerHTML)) {
            vc?.hide?.()
            props.setOpen(false);
        }    
        })
    }, [ref])

    function handleClickOutside(event) {
        if (vc && !vc.HTMLElement.innerHTML.contains(event.target)) {
            vc?.hide?.()
        }
    }
    
    useEffect(() => {
        return () =>{
            vc?.hide?.()
            vc?.destroy?.()
            document.removeEventListener("click", handleClickOutside)
          }
    }, []);
   
    return (
        <div ref={ref}></div>
    );
}

export default VanillaCalendar;
