import React from 'react';
//import '../css/Clock.css';
import axios from 'axios';
import NewThermostat from './NewThermostat';
import CustomTable from './CustomTable';
import { updateSwitches } from '../../SwitchFunctions';
import { updateThermostat } from '../../ThermostatFunctions';
import ModalView from './ModalView';
import SwitchesEditMenu from './SwitchesEditMenu';

import '../../App.css'
import { checkArrayLength } from '../Functions/helperFunctions';
import '../../css/emptyMessage.css'
import MenuCard from './MenuCard'; './MenuCard'
import { ReactComponent as EditSvg } from './images/edit_ic.svg'
import { ReactComponent as TickSvg } from './images/tick.svg'
import { ReactComponent as NoTickSvg } from './images/no_tick.svg'
import logger from '../Functions/logger';


class NewSwitches extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSwitch: {},
            thermPosition: 0,
            action: 0,
            switches: [],
            thermostats: [],
            openModal: false,
            selectedSw: this.props.switches[0] || null,
            isHidden: false,

        };
        this.changeSwitchState = this.changeSwitchState.bind(this);
        this.getName = this.getName.bind(this);
        this.showModal = this.showModal.bind(this);
        this.onClickSwitch = this.onClickSwitch.bind(this);
        this.handleButtonRelease = this.handleButtonRelease.bind(this);
        this.handleButtonPress = this.handleButtonPress.bind(this);
        let timeoutId;
    }

    UNSAFE_componentWillMount() {
        this.props.getSwitches();
        logger.log(this.width);
        logger.log("PROOPS:", this.props.permission);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.permission !== this.props.permission) {
            this.setState({ permission: this.props.permission });
        }
    }

    changeSwitchState = (aSwitch) => {
        aSwitch.state = aSwitch.state == 1 ? 0 : 1;
        this.setState({ selectedSwitch: aSwitch });
        let switchToBeUpdated = {
            id: aSwitch.id,
            state: aSwitch.state
        }
        updateSwitches(switchToBeUpdated).then((responseData) => {
            logger.log("Update switch then");
        }).catch((error) => { });
    }

    changeThermostat = (thermostat) => {
        logger.log("Sending Thermostat value to server");
        logger.log(thermostat);
        updateThermostat(thermostat).then(() => {
            logger.log("Update thermostat");
        }).catch((error) => { logger.warn(error) });
    }

    convertToUpperGreek = (text) => {
        if (this.props.currentLanguage.name == "gr") {
            text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            //logger.log("text in if:" + text);
        }
        text = text.toUpperCase();
        return text;
    }

    getName = (mSwitch) => {
        let currentLanguage = this.props.currentLanguage.name == "gr" ? "el" : this.props.currentLanguage.name;
        if (mSwitch?.names?.[currentLanguage] !== undefined) {
            return mSwitch.names[currentLanguage]
        }
        return mSwitch.name;
    }

    renderSwitch = (mSwitch) => {
        logger.log("==SWITCH==", mSwitch);
        // let iconName = require("./images/bulp_new.webp")
        let filter = 'brightness(0) saturate(100%) invert(71%) sepia(38%) saturate(3317%) hue-rotate(343deg) brightness(97%) contrast(99%)'
        if (mSwitch.state == 0) {
            filter = 'brightness(0) saturate(100%) invert(23%) sepia(4%) saturate(2810%) hue-rotate(168deg) brightness(95%) contrast(88%)'
        }
        if (mSwitch.hidden == false) {
            return <div className={mSwitch.state == 1 ? "mainMenuContent switchButton menuOn" : "mainMenuContent switchButton"} key={mSwitch.id} onClick={() => this.changeSwitchState(mSwitch)}>
                {/* <img src={mSwitch.state == 1 ? require("./images/bulp_orange_02.png") : require("./images/bulp_dark_blue_01.png")} ></img> */}
                <span>{this.convertToUpperGreek(this.getName(mSwitch))}</span>
            </div>
        }
        //mSwitch.state == 1 ? iconName ="./images/bulp_orange_02.png" : iconName ="./images/bulp_dark_blue_01.png"
        switch (mSwitch.type) {
            case "switch":
                iconName = require("./images/bulp_new.webp")
                break
            case "dimmer":
                iconName = require("./images/dimmer_01.png")
                break
            case "curtain":
                iconName = require("./images/blinds_01.png")
                break
            case "boiler":
                iconName = require("./images/boiler_01.png")
                break
            case "socket":
                iconName = require("./images/socket_white.webp")
                break
        }

        if (mSwitch.hidden == false) {
            return <div className={mSwitch.state == 1 ? "mainMenuContent switchButton menuOn" : "mainMenuContent switchButton"} key={mSwitch.id} onClick={() => this.changeSwitchState(mSwitch)}>
                <img style={{ filter: filter }} src={iconName} ></img>
                <span>{this.convertToUpperGreek(this.getName(mSwitch))}</span>
            </div>
        }

    }

    handleClose = () => {
        this.setState({ openModal: false });
    };

    showModal = () => {
        return (
            <ModalView open={this.state.openModal} close={() => this.handleClose()}>
                <SwitchesEditMenu switches={this.props.switches} selectedSw={this.state.selectedSw} handleClose={this.handleClose}
                    openSnackbar={this.props.openSnackbar} ARgroups={this.props.ARgroups}
                    translations={this.props.translations}></SwitchesEditMenu>
            </ModalView>)
    }

    onClickSwitch = (mSwitch) => {
        this.props.changeMenu(19, mSwitch)

    }

    handleButtonPress = (mSwitch) => {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => { this.onClickSwitch(mSwitch) }, 1000);
    }

    handleButtonRelease = () => {
        clearTimeout(this.timeoutId);
    }

    handleClick = (e, sw) => {
        if (e.target === path) {
            logger.log("pencil");
        }
        else {
            logger.log("on/off");
        }
    }

    onoff = () => {
        logger.log('SW clicked');
    };

    edit = (e, sw) => {
        e.stopPropagation();
        this.onClickSwitch(sw);
        logger.log('edit');
    };

    renderSwitch = (mSwitch) => {
        // let btn_class =
        if (this.state.isHidden == mSwitch.hidden) {
            return (
                // <div key={mSwitch.id} className='grid-item'>
                <div className="mainMenuContent switchButton" key={mSwitch.id}
                    onClick={() => this.changeSwitchState(mSwitch)}>
                    {/* <img src={mSwitch.state == 1 ? require("./images/bulp_orange_02.png") : require("./images/bulp_dark_blue_01.png")} ></img> */}
                    <div className='switchesContainerHeader'>
                        {this.props.permission === "admin" ? <div className='switchesEditIcon'>
                            <EditSvg className='editSvg' onClick={(e) => this.edit(e, mSwitch)} />
                        </div> : <></>}
                    </div>
                    <div className='switchesContainerFooter'>
                        <span>{this.convertToUpperGreek(this.getName(mSwitch))}</span>
                        <span className={mSwitch.state ? "menuOnSwitch" : "menuOffSwitch"}>
                            {mSwitch.state === 0 ? "OFF" : "ON"}
                        </span>
                    </div>
                </div>
                // </div>
            )
        }
    }

    iconChange() {
        return this.state.isHidden ? <TickSvg style={{ width: "50px" }} /> : <NoTickSvg style={{ width: "50px" }} />;
    }



    render() {
        const containerHeaderSwitches = (
            <div className="hiddenDevices">
                <div className="checkbox">
                    <input
                        type="checkbox"
                        name="scales"
                        id="input-confidencial"
                        checked={this.state.isHidden}
                        onChange={() => {
                            this.setState({ isHidden: !this.state.isHidden });
                        }}
                    />
                    {this.props.permission === "admin" ? <label htmlFor="input-confidencial" style={{ cursor: 'pointer' }}>
                        <span className="icon">{this.iconChange()}</span>
                        {this.props.translations.showHiddenDevices}
                    </label> : <></>}
                </div>
            </div>
        );


        const checkIfThermostartIsAvailable = (
            this.props.thermostats.length !== 0 &&
            <div className='cardThermostatContainer'>
                <div className='cardThermostat'>
                    <div className="Mythermostat">
                        <NewThermostat changeThermostat={this.changeThermostat} thermostatArray={this.props.thermostats} css={"thermDataSwitchContainer"} />
                    </div>
                </div>
            </div>
        )






        return (
            this.props.switches?.length > 0 ? (
                <MenuCard
                    url={require("./images/senaria_orange_02.png")}
                    title={this.props.translations.switches}
                    cardHeaderBackColor={"cardHeader "}
                    containerHeader={containerHeaderSwitches}
                    containerFooter={checkIfThermostartIsAvailable}
                    cardContainer={"tableCss"}
                    cardTitle={"cardTitle"}
                    menuCard={"menuCard"}
                >
                    <div className="switchGrid">
                        {this.props.switches.map(this.renderSwitch)}
                        {this.showModal()}
                    </div>
                </MenuCard>
            ) : (
                <MenuCard
                    containerFooter={checkIfThermostartIsAvailable}
                    // cardContainer={"tableCss"}
                    menuCard={"menuCardMessage"}
                    cardHeaderBackColor={"cardHeader menuCardMessageHeader"}
                    cardContainer={"cardContainerMessage"}
                    cardTitle={"cardTitle"}
                    title={this.props.translations.switches}
                >
                    <div style={{ paddingBlock: '25%' }}>
                        {this.props.translations.thereAreNoText1 + " " + this.props.translations.switches.toLowerCase() + " " + this.props.translations.thereAreNoText2}
                    </div>
                </MenuCard>
            )
        )

    }
}

export default NewSwitches;