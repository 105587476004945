import React from 'react';
//import '../css/Clock.css';
import { ReactComponent as ThermostatLeftArrow } from './images/thermostatLeftArrow.svg'
import { ReactComponent as ThermostatRightArrow } from './images/thermostatRightArrow.svg'
import logger from '../Functions/logger';

class NewThermostat extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            thermPosition: 0,
            defaultThermostat: this.props.thermostatArray[this.thermPosition],
            action: 0,
        }


        this.initTimer;
        this.initThermostat = this.initThermostat.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.initThermostat();
    }


    componentDidUpdate(prevProps) {
        if (prevProps.thermostatArray[this.state.thermPosition] != this.props.thermostatArray[this.state.thermPosition]) {
            this.state.defaultThermostat = this.props.thermostatArray[this.state.thermPosition];
            this.setState({ defaultThermostat: this.state.defaultThermostat });
        }
    }

    //Initialize thermostat on screen
    initThermostat() {
        this.state.defaultThermostat = this.props.thermostatArray[this.state.thermPosition];
        this.setState({ defaultThermostat: this.state.defaultThermostat });
    }

    //Change room current temperature on screen
    ChangeCurrentTemp(NewCurrentTemp) {

        this.state.defaultThermostat.currentState = NewCurrentTemp;
        this.setState({ defaultThermostat: this.state.defaultThermostat });
    }

    //Change set temperature on default thermostat
    changeSetTemp(number) {
        let value = parseInt(this.state.defaultThermostat.state);
        let newValue = value + number;
        if (newValue >= 5 && newValue <= 35) {
            this.state.defaultThermostat.state = newValue;
            this.setState({ defaultThermostat: this.state.defaultThermostat });
            this.countdown();
        }

    }

    countdown() {
        if (this.initTimer !== undefined) {
            clearTimeout(this.initTimer);
        }

        this.initTimer = setTimeout(() => { this.props.changeThermostat(this.state.defaultThermostat) }, 1000);
    }

    //Change appearing thermostat if we have multiple thermostats
    swipeThermostat(action) {
        let found = this.props.thermostatArray.findIndex(thermostat => thermostat === this.state.defaultThermostat);
        if (action === 1) {
            if (found === this.props.thermostatArray.length - 1) {
                this.state.thermPosition = 0;
            }
            else {
                this.state.thermPosition = found + 1;
            }
        } else if (action === -1) {
            if (found === 0) {
                this.state.thermPosition = this.props.thermostatArray.length - 1;
            }
            else {
                this.state.thermPosition = found - 1;
            }

        }
        else {
        }
        this.initThermostat();
    }



    render() {
        logger.log("THERMOSTAT", this.props.thermostatArray);
        return (

            <div className="thermContainer">

                {this.props.thermostatArray.length > 1 ? <div className="thermChangeLeft">
                    <ThermostatLeftArrow
                        style={{ width: "35px", height: "35px", cursor: 'pointer' }}
                        onClick={() => this.swipeThermostat(-1)}
                    />

                </div> : <div></div>}
                <div className={`thermDataContainer ${this.props.css}`}>
                    <table className={`ThermostatLayout ${this.props.css}`}>
                        <tr className='thermNameLine'>
                            <td colSpan="5"><span className="thermName">{this.state.defaultThermostat ? this.state.defaultThermostat.name : ""}</span></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="thermostatLeft">
                                    <img src={require("./images/snow.png")} onClick={() => this.changeSetTemp(-1)}></img>
                                </div>
                            </td>
                            <td>
                                <div className="thermostatMid">{this.state.defaultThermostat ? this.state.defaultThermostat.state + "°C" : "-"}</div>
                            </td>
                            <td>
                                <span className="thermSeperator">|</span>
                            </td>
                            <td>
                                <span className="thCurrent">{this.state.defaultThermostat ? Math.floor(this.state.defaultThermostat.currentState) + "°C" : "-"}</span>
                            </td>
                            <td>
                                <div className="thermostatRight">
                                    <img src={require("./images/sun.png")} onClick={() => this.changeSetTemp(1)}></img>
                                </div>
                            </td>
                        </tr>
                    </table>

                </div>
                {this.props.thermostatArray.length > 1 ? <div className="thermChangeRight">
                    <ThermostatRightArrow
                        style={{ width: "35px", height: "35px", cursor: 'pointer' }}
                        onClick={() => this.swipeThermostat(1)}
                    />
                </div> : <div></div>}
            </div>

        );
    }
}

export default NewThermostat;