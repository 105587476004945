export default {
    translations:{
        userLogin: "BENUTZERANMELDUNG",//1
        login: "VERBINDUNG ", //2
        logout: "ABMELDEN",//3
        switches: "SCHALTER",//4
        scenarios: "SCENARIOS",//5
        timers: "SZENARIEN EINSTELLEN",
        commands: "BEFEHLSPROGRAMMIERUNG",
        camera: "KAMERA",
        alarm: "ALARM",
        reminder: "ERINNERUNG",
        notes: "NOTIZEN",
        energy: "ENERGY METERING",
        scenarioProgCreation: "SZENARIO-PLANUNG / -ERSTELLUNG",
        scenarioCreation: "SZENARIEN / ERSTELLUNG",
        commandsCreation: "BEFEHLE PLANUNG / ERSTELLUNG",
        alarmCreation: "ALARM / ERSTELLUNG",
        reminderCreation: "ERINNERUNG / ERSTELLUNG",
        titleNameError: "FEHLER IM TITELNAMEN",
        addNewScene: "NEUES SZENARIO",
        multiplePlan: "MEHRFACHE SZENARIEN",
        newCommand: "NEUER BEFEHL",
        newAlarm: "NEUER ALARM",
        newReminder: "NEUE ERINNERUNG",
        dateOrTimeOld: "Das Datum oder die Uhrzeit ist veraltet.",
        successfulSave: "Die Rettung war erfolgreich.",
        cancel: "ABBRECHEN",
        delete: "KÜNDIGEN",
        hideCaps: "DISCLOSURE",
        show: "ANZEIGE",
        save2: "SPEICHERN",
        scenarioNameExists: "Dieser Szenarioname existiert bereits.",
        shortName: "Der Name muss mindestens 3 Zeichen beinhalten",//
        scenarioName: "Szenario Name",
        selectASwitch: "Wählen Sie mindestens einen Schalter aus.",
        wrongNameType: "Falscher Namenstyp",
        noDeviceChosen: "Sie haben kein Gerät ausgewählt.",
        devices: "GERÄTE",
        smallName: "Name muss über vier Zeichen haben",
        newNameMult: "Name einfügen",
        time: "Uhrzeit",
        date: "Datum",
        repeat: "Wiederholung",
        videoCall: "VIDEOANRUF",
        display: "ANZEIGE",
        selectCamera: "Kamera auswählen",
        noteLengthRestriction: "Die Notiz muss aus mindestens 4 Zeichen bestehen",
        sendNote: "ANMERKUNG SENDEN",
        days: {
            Monday: "Montag",
            Tuesday: "Dienstag",
            Wednesday: "Mittwochs",
            Thursday: "Donnerstag",
            Friday: "Freitag",
            Saturday: "Samstag",
            Sunday: "Sonntag"
        },
        days_locale: {
            Monday: "Mon",
            Tuesday: "Die",
            Wednesday: "Mit",
            Thursday: "Don",
            Friday: "Fre",
            Saturday: "Sam",
            Sunday: "Son"
        },
        changeHome: "UMZIEHEN",
        addNewName: "Einen neuen Namen eingeben",
        selectedLang: "Ausgewählte Sprache",
        selectLang: "Auswahl der Sprache",
        english: "Englisch",
        german: "Deutsch",
        spanish: "Spanisch",
        romanian: "Rumänisch",
        bulgarian: "bulgarisch",
        greek: "Griechisch",
        allerRetourWith: "Aller - Retour mit",
        allerRetour: "Aller - Retour",
        changeBuidling: "Gebäude ändern",
        error: "Fehler 404! Seite nicht gefunden!",
        buildingNotValid: "Gebäude nicht gültig",
        newBuilding: "Neues Gebäude hinzufügen",
        checkInfo: "Bitte überprüfen Sie Ihre Angaben",
        passwordMissing: "Das Passwort fehlt",
        emailMissing: "E-Mail fehlt",
        missMatchPass: "Passwörter stimmen nicht überein",
        switch_on: "auf",
        switch_off: "aus",
        thereAreNo: "ES GIBT KEINE",
        command: "BEFEHLE",
        cameras: "KAMERAS",
        alarmsStr: "ALARME",
        reminders: "MAHNUNGEN",
        hmLanguage: "Standartsprache",
        name: "Deutsch",
        deleteConfirmation: "Die Löschung war erfolgreich.", //56
        deleteFailure: "Die Löschung ist fehlgeschlagen.", //57
        deleteAllQuestion: "Sind Sie sicher, dass Sie alle Nachrichten löschen möchten?", //58
        deleteMessageQuestion: "Sind Sie sicher, dass Sie die Nachricht löschen wollen?", //59
        incomingMessages: "EINGEHENDE NACHRICHTEN", //60
        messageFrom: "Nachricht von", //61
        deleteAll: "ALLE LÖSCHEN", //62
        messageArchiveText: "NACHRICHTENARCHIV", //63
        deleteInviteSuccess: "Die Einladung wurde gelöscht",
        deleteInviteFailure: "Die Löschung der Einladung hat nicht stattgefunden",
        acceptInviteSuccess: "Die Einladung ist angenommen worden",
        acceptInviteFailure: "Die Einladung ist nicht angenommen worden",
        deleteAllInvitesQuestion: "Sind Sie sicher, dass Sie alle Einladungen löschen wollen?",
        deleteInviteQuestion : "Sind Sie sicher, dass Sie diese Einladung löschen möchten?",
        inviteFrom: "Einladung von ",
        acceptInvite: "AKZEPTIEREN",
        invites: "EINLADUNGEN",
        newAccessScenario: ": Szenarien",
        newAccessScenarioProgramming: ": Szenarioplanung",
        newAccessCommandsProgramming: ": Befehle Zeitplanung",
        newAccessCamera: ": Kamera",
        newAccessAlert: ": Alarme",
        newAccessReminder: ": Mahnungen",
        newAccessPostIt: ": Post It",
        newAccessDot: ".",
        newAccessNoAccess: "Sie haben keine Erlaubnis",
        deleteQuestion: "Sind Sie sicher, dass Sie sie löschen wollen?",
        account: "KONTO",
        successfulRegistration: "Erfolgreiche Registrierung",
        unsuccessfulRegistration: "Erfolglose Registrierung",
        deleteAccountQuestion: "Sind Sie sicher, dass Sie Ihr Konto löschen möchten?\nWenn JA, prüfen Sie Ihre E-Mail auf die Löschbestätigung.",
        sendEmailSuccess: "E-Mail erfolgreich gesendet.",
        sendEmailFailure: "Die E-Mail konnte nicht gesendet werden.",
        successfulCancelation: "Erfolgreiche Stornierung.",
        nicknameExists: "Dieser Name existiert bereits.",
        nicknameSavedSuccessfuly: "Der Spitzname ist erfolgreich gerettet worden.",
        nicknameDelete: "Der Name wurde erfolgreich gelöscht.",
        nicknamesSavedSuccessfuly: "Die Spitznamen sind erfolgreich gespeichert worden.",
        myAccount: "MEIN KONTO",
        homeName: "Name des Hauses",
        username: "Benutzername",
        addExtraNickname: "Zusätzliche Benutzernamen hinzufügen",
        deleteAccount: "KONTO LÖSCHEN",
        deleteHome: "HAUS LÖSCHEN",
        deleteHomeQuestion: "Sind Sie sicher, dass Sie dieses Haus löschen wollen?",
        unhideSwitch: "Weet je zeker dat je de schakelaar wilt weergeven ",
        hideSwitch: "Weet je zeker dat je de schakelaar wilt verbergen ",
        deleteSwitch: "Weet je zeker dat je de schakelaar wilt verwijderen ",
        insertUserName: "Benutzernamen einfügen",
        weather: "WETTER",
        weatherText_1: "Sie wird in Befehlen wie dem folgenden verwendet:",
        weatherText_2: "\"Wie wird das Wetter morgen sein?\",\"Sag mir das Wetter\"",
        timeZone: "Zeitzone",
        timeZoneText_1: "Sie wird in Befehlen wie dem folgenden verwendet:",
        timeZoneText_2: "\"Wie spät ist es?\",\"Sagen Sie mir, wie spät es ist\"",
        news: "NACHRICHT",
        newsText: "Wie viele Schlagzeilen wollen Sie sich anhören?",
        tabletLanguage: "Tablet-Sprache",
        changeKeyword: "Schlüsselwort ändern",
        generalInformation: "ALLGEMEINE INFORMATIONEN",
        theUser: "Der Benutzer:  ",
        hasPermission: " hat bereits die Erlaubnis!",
        members: "Mitglieder",
        changeUsername: "Möchten Sie den Benutzernamen des Benutzers ändern:",
        noUsersAvailabe: "Es sind keine Benutzer zum Löschen vorhanden!",
        deleteMember: "Sind Sie sicher, dass Sie den Benutzer löschen möchten: ",
        invalidEmail: "Sie haben eine ungültige E-Mail eingegeben!",
        insertNewUsername: "Neuen Benutzernamen einfügen",
        save: "Speichern Sie",
        cancel_2: "Abbrechen",
        insertNewEmail: "E-Mail einfügen",
        availablePermissions: "Verfügbare Berechtigungen",
        memberUsername: "Benutzername des Mitglieds:",
        currentUserPermission: "Derzeitige Erlaubnis des Mitglieds",
        renameUser: "BENUTZER UMBENENNEN",
        deleteUsers: "BENUTZER LÖSCHEN",
        addNewUser: "NEUEN BENUTZER HINZUFÜGEN",
        permissions: "ZUGANGSREGELN",
        week: "WOCHE",
        month: "MONAT",
        year: "JAHR",
        energyConsumption: "Verbrauch in KW",
        energyMetering: "Energiemessung",
        energyMeteringCapital: "ENERGIEMESSUNG",
        insertCommand: "Befehl einfügen",
        selectCamera: "KAMERAAUSWAHL",
        edit: "BEARBEITEN",
        showHiddenDevices: "Versteckte Geräte anzeigen",
        editScene: "SCENARIO BEARBEITEN",
        unselectAll: "Alle abwählen",
        selectAll: "Alle auswählen",
        cancelAllerRetour: "Stornierung Aller - Retour",
        showHiddenDevices: "Versteckte Geräte anzeigen",
        rename: "Umbenennen",
        addName: "Hinzufügen eines Namens",
        showLow: "Anzeigen",
        hide: "Ausblenden",
        deleteLow: "Löschen",
        currentDeviceName: "Aktueller Name des Schalters",
        newDeviceName: "Neuer Name des Schalters",
        successfullRename: "Erfolgreiche Umbenennung des Schalters.",
        failRename: 'Umbenennung des Schalters fehlgeschlagen.',
        successfullHide: 'Erfolgreiches Ausblenden des Schalters.',
        failHide: 'Fehlerhaftes Ausblenden des Schalters.',
        cancelHide: "Sie haben das Ausblenden des Schalters abgebrochen.",
        successfullShow: "Erfolgreiches Anzeigen des Schalters.",
        failShow: "Schalter konnte nicht gezeigt werden.",
        cancelShow: "Sie haben das Anzeigen des Schalters abgebrochen.",
        successfullDelete: "Erfolgreiche Löschung des Schalters.",
        failDelete: 'Schalter konnte nicht gelöscht werden.',
        cancelDelete: 'Sie haben das Löschen des Schalters abgebrochen.',
        successfullCancelAllerRetour: 'Erfolgreiche Stornierung der Aller-Retour.',
        failCancelAllerRetour: 'Die Stornierung der Aller-Retour ist fehlgeschlagen.',
        successfullAddName: 'Erfolgreiche Eingabe eines zusätzlichen Namens.',
        failAddName: 'Fehlgeschlagene Namenseingabe.',
        renameCaps: "TEILNAHME",
        noInvites: "Sie haben keine Einladung",
        noMessages: "Sie haben keine Nachrichten",
        oneTime: "Einmal",
        postItMessage: 'Ihre Notiz einfügen',
        thereAreNoText1:"Es gibt keine",
        thereAreNoText2:"auf Ihrer Liste.",
        messages:"Nachrichten",
        ivnites:"lädt ein",
        noPermissionToService:"Leider haben Sie derzeit keine Berechtigung, auf diesen Dienst zuzugreifen.",
        location: "STANDORT",
        changeTimeZone: "Wechsel der Zeitzone",
        selectedZone: "ausgewählte Zone: ",
        selectZone: "Zonenauswahl",
        timeZoneCaps: "ZEITZONE",
        tabletLanguageCaps: "SPRACHTABLETT",
        changeKeyword: "SCHLÜSSELWORT ÄNDERUNG",
        changeLanguage: "Sprache ändern",
        invitesText : "EINLADUNGEN"
    }
}