import React, { useEffect, useState } from 'react';
import '../../css/Permissions.css';
import { addGuest, deleteGuest, fetchGuests, renameGuest, changePermission } from '../../userFunctions';
import RadioButtonPermissions from './radioButtonPermissions';
import logger from '../Functions/logger';

function Permissions(props) {

    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const [username, setUsername] = useState("");
    const [modal, setModal] = useState(false);
    const [modal2, setmodal2] = useState(false);
    const [newUser, setnewUser] = useState({ email: "", permission: "guest" });


    useEffect(() => {
        fetchGuests()
            .then((response) => {
                setUsers(response.data);
                setCurrentUser(response.data[0])
            })
            .catch((error) => {
            })
    }, []);

    useEffect(() => {
        setUsers(prevUsers => prevUsers.map(user => {
            if (currentUser !== undefined) {
                if (user.email === currentUser.email) {
                    return currentUser;
                }
                return user;
            } else {
                if (user.email === newUser.email) {
                    return newUser;
                }
                return user;
            }

        }))
    }, [currentUser, newUser]);

    useEffect(() => {
        logger.log(username);
    }, [username]);

    useEffect(() => {
        if (!modal) {
            setUsername("")
        }
    }, [modal]);

    useEffect(() => {
        if (!modal2) {
            setnewUser({ email: "", permission: "guest" });
        }
    }, [modal2]);

    const handleChange = (event) => {
        setUsername(event.target.value);
    };

    const handleChange2 = (event) => {
        setnewUser({ ...newUser, email: event.target.value });
        logger.log(event.target.value);
    };

    function getColor(user) {
        if (currentUser && currentUser.email === user.email) {
            return 'orange';
        }
        return '';
    }

    function selectPerm(permission) {
        if (currentUser.permission === permission) {
            window.alert(props.translations.theUser + " " + usernameOrEmail() + props.translations.hasPermission);
            return;
        } else {
            changePermission(permission, currentUser.email)
                .then(() => {
                    setCurrentUser({ ...currentUser, permission: permission });
                })
                .catch((e) => {
                    logger.error("error:", e);
                })
        }
    }

    function selectNewPerm(permission) {
        setnewUser({ ...newUser, permission: permission });
    }


    function nameOfCurrentUser() {
        if (currentUser !== undefined) {
            if (currentUser.name && currentUser.name !== "") {
                return currentUser.name;
            }
            return currentUser.email;
        } else {
            if (newUser.name && currentUser.name !== "") {
                return newUser.name;
            }
            return newUser.email;
        }
    }

    function renameUser() {
        if (currentUser.name !== undefined) {
            if (window.confirm(props.translations.changeUsername + currentUser.name)) {
                renameGuest(username, currentUser.email)
                    .then(() => {
                        setCurrentUser({ ...currentUser, name: username });
                        setModal(false);
                        return;
                    })
                    .catch((error) => {
                        logger.log("error:", error);
                    })
                    .finally(() => {
                        setModal(false);
                        return;
                    })
            }
            setModal(false);
        }
        else {
            renameGuest(username, currentUser.email)
                .then(() => {
                    setCurrentUser({ ...currentUser, name: username });
                    setModal(false);
                    return;
                })
                .catch((error) => {
                    logger.log("error:", error);
                })
                .finally(() => {
                    setModal(false);
                    return;
                })
        }
        setModal(false);
    }

    function usernameOrEmail() {
        let emailOrName;
        if (currentUser.name !== undefined) {
            return emailOrName = currentUser.name;
        }
        else {
            return emailOrName = currentUser.email;
        }
    }

    function deleteUser() {
        logger.log("clicked");
        if (users.length < 1) {
            return window.alert(props.translations.noUsersAvailabe);
        }
        if (window.confirm(props.translations.deleteMember + usernameOrEmail())) {
            if (users.includes(currentUser)) {
                logger.log("users:", users);
                logger.log("currentUser:", currentUser);
                let index = users.findIndex((user) => {
                    logger.log("user:", user);
                    return user.email == currentUser.email;
                });
                deleteGuest(currentUser.email).then(() => {
                    users.splice(index, 1);
                    setCurrentUser(users[0]);
                }).catch(() => {
                    logger.log("error");
                })
            }
        }
    }

    function validateEmail(email) {
        if (users.some(item => item.email === email)) {
            return false;
        }
        logger.log("email validation: " + email);
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email)) {
            logger.log("email validation:OK ");
            return (email);
        }
        alert(props.translations.invalidEmail);
        emailField = document.getElementById("emailField");
        emailField.value = "";
        return false;
    }

    function addNewUser() {
        logger.log("Users:", users);
        logger.log("Current user:", currentUser);
        logger.log("New user:", newUser);
        let validatedEmail = validateEmail(newUser.email);
        if (validatedEmail) {
            addGuest(validatedEmail, "gr", newUser.permission)
                .then(() => {
                    setUsers(prevUsers => [...prevUsers, newUser]);
                })
                .catch(error => { logger.log(error); })
                .finally(setmodal2(false));
        }
    }

    function choosePermission() {
        if (currentUser !== undefined) {
            return currentUser.permission;
        } else {
            return newUser.permission;
        }
    }

    return (
        <div style={{
            position: 'relative',
            width: '100%'
        }}>
            {modal &&
                <div className='modal_container'>
                    <div className='modal'>
                        <h3>{props.translations.insertNewUsername}</h3>
                        <input type="text" onChange={handleChange}></input>
                        <span>{username}</span>
                        <button onClick={renameUser}>{props.translations.save}</button>
                        <button onClick={() => { setModal(false) }}>{props.translations.cancel_2}</button>
                    </div>
                </div>}
            {modal2 && <div className='modal_container'>
                <div className='modal'>
                    <label>{props.translations.insertNewEmail}</label><br />
                    <input type="text" id='emailField' onChange={handleChange2}></input><br />
                    {logger.log(handleChange2)}
                    <h4>{props.translations.availablePermissions}</h4>
                    <RadioButtonPermissions permission={newUser.permission} onClick={selectNewPerm} />
                    <button onClick={addNewUser}>{props.translations.save}</button>
                    <button onClick={() => { setmodal2(false) }}>{props.translations.cancel_2}</button>
                    {logger.log("The new user:", newUser)}
                </div>
            </div>}

            <div>
                <h2>{props.translations.members}</h2>
            </div>
            <div></div>
            {users.length > 0 &&
                <>
                    <div>
                        <h3>{props.translations.memberUsername + nameOfCurrentUser()}</h3>
                    </div>
                    <div>
                        {users.map(user => {
                            return (
                                <div key={user.email} style={{ backgroundColor: getColor(user) }} onClick={() => {
                                    setCurrentUser(user)
                                }}> {user.email} </div>
                            )
                        })}
                    </div>

                    <div>
                        <h5>{props.translations.currentUserPermission}</h5>
                        <RadioButtonPermissions permission={choosePermission()} onClick={selectPerm} />
                    </div>
                    <div>

                        <div className="container sceneCreateButton space-between" style={{ cursor: 'pointer' }}>
                            <div className="buttonArea2" onClick={() => { setModal(true); logger.log(users); }}>{props.translations.renameUser}</div>
                            <div className="buttonArea2" onClick={deleteUser}>{props.translations.deleteUsers}</div>
                        </div>
                    </div>
                </>}
            <div>

                <div className="container sceneCreateButton space-between" style={{ cursor: 'pointer' }}>
                    <div className="buttonArea2" onClick={() => { setmodal2(true) }}>{props.translations.addNewUser}</div>
                </div>
            </div>
        </div>
    )
}


export default Permissions;
