import React, {Component} from 'react';
import '../css/Home.css';
/*import Welcome from './Welcome';
import SmartHome from './SmartHome';
import About from './About';
import HowDoesItWork from './HowDoesItWork';
import Features from './Features';
import Contains from './Contains';
import Contact from './Contact';*/
import App from '../App';
//import LeafletMapShower from './LeafletMapShower';

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: 0
        }
        this.changePage = this.changePage.bind(this);
    }

    changePage(page) {
        this.setState({selected: page});
    }

    render() {
        return (
            <div className='afterRoot'>{/** 
                <div id="headerDiv">
                    <img
                        onClick={() => this.setState({selected: 0})}
                        src={require('../images/site/logo-01.png')}/>
                    <span id="menu">
                        <span onClick={() => this.setState({selected: 2})}>ABOUT</span>
                        /
                        <span onClick={() => this.setState({selected: 3})}>HOW DOES IT WORK</span>
                        /
                        <span onClick={() => this.setState({selected: 4})}>FEATURES</span>
                        /
                        <span onClick={() => this.setState({selected: 5})} style={{marginRight: 0}}>CONTAINS</span>
                    </span>
                </div>
                {(this.state.selected === 0) && <Welcome changePage={this.changePage}/>}
                {(this.state.selected === 1) && <SmartHome changePage={this.changePage}/>}
                {(this.state.selected === 2) && <About changePage={this.changePage}/>}
                {(this.state.selected === 3) && <HowDoesItWork changePage={this.changePage}/>}
                {(this.state.selected === 4) && <Features changePage={this.changePage}/>}
                {(this.state.selected === 5) && <Contains changePage={this.changePage}/>}
                {(this.state.selected === 6) && <Contact changePage={this.changePage}/>}
                {(this.state.selected === 7) && <LeafletMapShower changePage={this.changePage}/>}
                {(this.state.selected === 9) && <App />}
                <span id="contact">
                    <span id="spL"><img onClick={() => {window.open("https://www.entranetinc.com")}} src={require('../images/site/powered by entranet-01.png')}/></span>
                    <span id="spM"><img onClick = { () => { this.setState({ selected: 7 }) } } src = { require('../images/site/certified partners-01.png') }/></span>
                    <span onClick={() => this.setState({selected: 6})} id="spR">CONTACT US</span>
                </span>
                <img width="100%" src={require('../images/site/awards-01.png')}/>
                <span id="social">
                    <img onClick={() => {window.open("https://www.facebook.com/Entranet-Ltd-395048340614051")}} src={require('../images/site/facebook 35-01.png')}/>
                    <img onClick={() => {window.open("https://www.linkedin.com/company/entranet")}} src={require('../images/site/linkedin 35-01.png')}/>
                    <img onClick={() => {window.open("https://twitter.com/Entranet_Inc")}} src={require('../images/site/twitter 35-01.png')}/>
                    <img onClick={() => {window.open("https://www.youtube.com/user/EntranetLtd")}} src={require('../images/site/youtube 35-01.png')}/>
                </span>*/}
                {<App />}
                <div className='footer'><img className="footerIcon" src={require('../Components/NewInterface/images/footer_ic.png')} /></div>
            </div>
        );
    }
}

export default Home;