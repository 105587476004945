export default {
    translations: {
        userLogin: "USER LOGIN",//1
        login: "LOGIN", //2
        logout: "LOGOUT",//3
        switches: "SWITCHES",//4
        scenarios: "SCENARIOS",//5
        timers: "SCENARIO SCHEDULING",//6
        commands: "COMMANDS SCHEDULING",//7
        camera: "CAMERA",//8
        alarm: "ALARM",//9
        reminder: "REMINDER",//10
        notes: "POST IT",//11
        energy: "ENERGY METERING",
        scenarioProgCreation: "SCENARIO SCHEDULING ",//12
        scenarioCreation: "SCENARIOS / CREATION",//13
        commandsCreation: "COMMANDS SCHEDULING / CREATION",//14
        alarmCreation: "ALARM / CREATION",//15
        reminderCreation: "REMINDER / CREATION",//16
        titleNameError: "TITLE NAME ERROR",//17
        addNewScene: "NEW SCENARIO",//18
        multiplePlan: "MULTIPLE SCHEDULE",//19
        newCommand: "NEW COMMAND",//20
        newAlarm: "NEW ALARM",//21
        newReminder: "NEW REMINDER",//22
        dateOrTimeOld: "The date or time is old.",//23
        successfulSave: "The save was succesful.",//24
        cancel: "CANCEL",//25
        delete: "DELETE",//26
        hideCaps: "HIDE",
        show: "DISPLAY",
        save2: "SAVE",//27
        scenarioNameExists: "This scenario name already exists.",//28
        shortName: "The name of the scenario must consist of at least 3 characters.",//29
        scenarioName: "Scenario name",//30
        selectASwitch: "Select at least one switch.",//31
        wrongNameType: "Wrong name type.",//32
        noDeviceChosen: "You have not selected a device",//33
        devices: "DEVICES",//34
        smallName: "The name must consist of at least 4 characters",//35
        newNameMult: "Insert name",//36
        time: "TIME",//37
        date: "DATE",//38
        repeat: "REPEAT",//39
        videoCall: "VIDEOCALL",//40
        display: "DISPLAY",//41
        selectCamera: "Select Camera",//42
        noteLengthRestriction: "The note must consist of at least 4 characters",//43
        sendNote: "SEND NOTE",//44
        days: {
            Monday: "Monday",
            Tuesday: "Tuesday",
            Wednesday: "Wednesday",
            Thursday: "Thursday",
            Friday: "Friday",
            Saturday: "Saturday",
            Sunday: "Sunday"
        },//45
        days_locale: {
            Monday: "Mon",
            Tuesday: "Tue",
            Wednesday: "Wed",
            Thursday: "Thu",
            Friday: "Fri",
            Saturday: "Sat",
            Sunday: "Sun"
        },
        changeHome: "CHANGE HOME",
        addNewName: "Enter a new name",
        selectedLang: "Selected language",
        selectLang: "Language selection",
        english: "English",
        german: "German",
        spanish: "Spanish",
        romanian: "Romanian",
        bulgarian: "Bulgarian",
        greek: "Greek",
        allerRetourWith: "Aller - Retour with",
        allerRetour: "Aller - Retour",
        changeBuidling: "Change Building",//46
        error: "Error 404! Page not Found!",//47
        buildingNotValid: "Building not valid",//48
        newBuilding: "Add new building",//49
        checkInfo: "Please verify your information.",//50
        passwordMissing: "Password is missing",//51
        emailMissing: "E-mail is missing",//52
        missMatchPass: "Passwords do not match",//53
        switch_on: "on",//54
        switch_off: "off",//55
        thereAreNoText1: "There are no",
        thereAreNoText2: "to your list yet.",
        command: "COMMANDS",
        cameras: "CAMERAS",
        alarmsStr: "ALARMS",
        reminders: "REMINDERS",
        hmLanguage: "Default language",
        name: "English",
        deleteConfirmation: "The deletion was successful.", //56
        deleteFailure: "The deletion failed.", //57
        deleteAllQuestion: "Are you sure you want to delete all messages?", //58
        deleteMessageQuestion: "Are you sure you want to delete this message?", //59
        incomingMessages: "INCOMING MESSAGES", //60
        messageFrom: "Message from", //61
        deleteAll: "DELETE ALL", //62
        messageArchiveText: "MESSAGES ARCHIVE", //63
        deleteInviteSuccess: "The invitation has been deleted",
        deleteInviteFailure: "The deletion of the invitation did not take place",
        acceptInviteSuccess: "The invitation has been accepted",
        acceptInviteFailure: "The invitation has not been accepted",
        deleteAllInvitesQuestion: "Are you sure you want to delete all of the invitations?",
        deleteInviteQuestion : "Are you sure you want to delete this invitation?",
        inviteFrom: "Invitation from ",
        acceptInvite: "ACCEPT",
        invites: "INVITATIONS",
        newAccessScenario: ": Scenarios",
        newAccessScenarioProgramming: ": Scenario Scheduling",
        newAccessCommandsProgramming: ": Commands Scheduling",
        newAccessCamera: ": Camera",
        newAccessAlert: ": Alarms",
        newAccessReminder: ": Reminders",
        newAccessPostIt: ": Post It",
        newAccessDot: ".",
        newAccessNoAccess: "You do not have permission",
        deleteQuestion: "Are you sure you want them deleted?",
        account: "ACCOUNT",
        successfulRegistration: "Successful registration",
        unsuccessfulRegistration: "Unsuccessful registration",
        deleteAccountQuestion: "Are you sure you want to delete your account?\nIf YES, check your email for the delete confirmation.",
        sendEmailSuccess: "Email successfuly sent.",
        sendEmailFailure: "Failed to send an email.",
        successfulCancelation: "Successful cancelation.",
        nicknameExists: "This name already exists.",
        nicknameSavedSuccessfuly: "The nickname has been succesfuly saved.",
        nicknameDelete: "The name has been successfully deleted.",
        nicknamesSavedSuccessfuly: "The nicknames have been succesfuly saved.",
        myAccount: "MY ACCOUNT",
        homeName: "House name",
        username: "Username",
        addExtraNickname: "Add extra usernames",
        deleteAccount: "DELETE ACCOUNT",
        deleteHome: "DELETE HOUSE",
        deleteHomeQuestion: "Are you sure you want to delete this house?",
        unhideSwitch: "Are you sure you want to display the switch ",
        hideSwitch: "Are you sure you want to hide the switch ",
        deleteSwitch: "Are you sure you want to delete the switch ",
        insertUserName: "Insert username",
        weather: "WEATHER",
        weatherText_1: "It will be used in commands such as:",
        weatherText_2: "\"What will be the weather tommorow?\",\"Tell me the weather\"",
        timeZone: "Time zone",
        timeZoneText_1: "It will be used in commands such as:",
        timeZoneText_2: "\"What time is it?\",\"Tell me the time\"",
        news: "NEWS",
        newsText: "How many news headlines do you want to listen to?",
        tabletLanguage: "Tablet language",
        changeKeyword: "Change keyword",
        generalInformation: "GENERAL INFORMATION",
        theUser: "The user:  ",
        hasPermission: " already has the permission!",
        members: "Members",
        changeUsername: "Do you want to change the username of the user: ",
        noUsersAvailabe: "There aren't any users available to delete!",
        deleteMember: "Are you sure yo want to delete the user: ",
        invalidEmail: "You have entered an invalid email!",
        insertNewUsername: "Insert new username",
        save: "Save",
        cancel_2: "Cancel",
        insertNewEmail: "Insert email",
        availablePermissions: "Available permissions",
        memberUsername: "Member Username: ",
        currentUserPermission: "Current member permission",
        renameUser: "RENAME USER",
        deleteUsers: "DELETE USER",
        addNewUser: "ADD NEW USER",
        permissions: "ACCESS RULES",
        week: "WEEK",
        month: "MONTH",
        year: "YEAR",
        energyConsumption: "Consumption to KW",
        energyMetering: "Energy metering",
        energyMeteringCapital: "ENERGY METERING",
        insertCommand: "Insert Command",
        selectCamera: "CAMERA SELECTION",
        edit: "EDIT",
        showHiddenDevices: "Show Hidden Devices",
        editScene: "EDIT SCENARIO",
        unselectAll: "Unselect All",
        selectAll: "Select All",
        cancelAllerRetour: "Cancel Aller - Retour",
        showHiddenDevices: "Show Hidden Devices",
        rename: "Rename",
        addName: "Add Name",
        showLow: "Display",
        hide: "Hide",
        deleteLow: "Delete",
        currentDeviceName: "Current switch name",
        newDeviceName: "New switch name",
        successfullRename: "Successful switch renaming.",
        failRename: 'Switch renaming failure.',
        successfullHide: 'Successful switch hiding.',
        failHide: 'Failure to hide switch.',
        cancelHide: "You have cancelled the switch hide.",
        successfullShow: "Successful switch show.",
        failShow: "Failed to show switch.",
        cancelShow: 'You have cancelled the show of the switch.',
        successfullDelete: "Successful deletion of switch.",
        failDelete: 'Failure to delete switch.',
        cancelDelete: 'You have cancelled the deletion of the switch.',
        successfullCancelAllerRetour: 'Successful cancellation of Aller-Retour.',
        failCancelAllerRetour: 'Failure to cancel Aller-Retour.',
        successfullAddName: 'Successful entry of an additional name.',
        failAddName: 'Failed name entry.',
        renameCaps: "RENAME",
        noInvites: "You have no invites",
        noMessages: "You have no messages",
        oneTime: "Once",
        postItMessage: 'Insert your note',
        messages:'messages',
        invitesText:"invites",
        noPermissionToService:"Sorry you currently don't have permission to access this service.",
        location: "LOCATION",
        changeTimeZone: "Time zone change",
        selectedZone: "selected zone: ",
        selectZone: "zone selection",
        timeZoneCaps: "TIME ZONE",
        tabletLanguageCaps: "LANGUAGE TABLET",
        changeKeyword: "KEYWORD CHANGE",
        changeLanguage: "Language Change",
        invitesText : "INVITATIONS"
    }
}