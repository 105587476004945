import React, {useState, useEffect} from 'react'
import ModalView from './ModalView';
import {updateSwitchNames} from '../../buildingFunctions'
import './NewMain.css'

function AddSwitchNames(props) {

    let translation = { 
        en: props.translations.english,
         de: props.translations.german,
          es: props.translations.spanish,
           ro: props.translations.romanian,
            bg: props.translations.bulgarian,
             gr: props.translations.greek
    }
    const [languages, setLanguages] = useState(translation);

    const [checkedLanguages, setCheckedLanguages] = useState({});
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [newName, setNewName] = useState();
    const [names, setNames] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const languageArray = Object.keys(languages);

    const handleCheckboxChange = (lang) => {
        return () => {
          setCheckedLanguages({
            [lang]: !checkedLanguages[lang]
          });
          setSelectedLanguage(lang === selectedLanguage ? null : lang);
        };
    };

    useEffect(() => {
        setLanguages(translation)
    }, [props.translations])

    function addSwitchNames() {
        if(!props.selectedSw.names) props.selectedSw.names = {};
        props.selectedSw.names[selectedLanguage] = newName;
        updateSwitchNames(props.selectedSw).then(() => {
            props.openSnackbar(props.translations.successfullAddName)
        }).catch(() => {
            props.openSnackbar(props.translations.failAddName)
        })
        props.close(); //Close this Modal
        props.closePrevious(); // Close Previous (SwitchesEditMenu) modal
    }

    return (
        <ModalView open={props.openModal} close={() => props.close()} sizeStyle={{width: "25%", height: "70%"}} padding={true}>
            <div className="scrollable">
                <div style={{height: "22%"}}>
                    <div className="allerRetourText">{props.translations.addNewName}</div>
                    <input className="inputEditSwitches" placeholder={props.selectedSw?.names?.[selectedLanguage]?props.selectedSw.names[selectedLanguage] : props.translations.addNewName} onChange={(event) => {
                        {setNewName(event.target.value)}
                    }}></input>
                    {selectedLanguage && (
                        <div className="selectedLanguage">
                            {props.translations.selectedLang}: {languages[selectedLanguage]}
                        </div>
                    )}
                </div>
                
                <div style={{height: "78%"}}>
                    <div className="bullet-checkbox">
                        <div className="selectLanguage">
                            <img className="imgAddSwitchName" src={require("./images/language.png")}/>
                            <div style={{fontSize: "14px", marginLeft: "10px"}}>{props.translations.selectLang}</div>
                        </div>
                        {languageArray.map((lang, index) => {
                            return( 
                                <div className="checkbox-lang noselect">
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${lang}`}
                                        checked={checkedLanguages[lang] || false}
                                        onChange={handleCheckboxChange(lang)}
                                    />
                                    <label htmlFor={`checkbox-${lang}`} className="checkbox-label">{languages[lang]}</label>
                                </div>
                            )
                        })}
                    </div>
                    <div className="selectLangOutDiv"> 
                        <div className="saveNameBtn" onClick={addSwitchNames}>{props.translations.save2}</div>
                    </div>
                </div>
            </div>
        </ModalView>
    )
}

export default AddSwitchNames;