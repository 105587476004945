import { React, useState, useEffect } from 'react';
import moment from 'moment';
import { createAction as createActionFunction } from '../../ActionFunctions';
import { withSnackbar } from 'react-simple-snackbar';
import NewAccessMessage from './NewAccessMessage';
import MenuCard from './MenuCard';
import logger from '../Functions/logger';

function NewNote(props) {


    const [text, setText] = useState('');
    const [selectedDevice, setSelectedDevice] = useState(null);


    useEffect(() => {
        if (props.devices.length > 0) {
            setSelectedDevice(props.devices[0].id)
        }
    }, [props.devices]);




    const timeFormat = (date) => {
        return moment(date).format("HH:mm");
    }

    const dateFormat = (date) => {
        return moment(date).format("DD/MM/YYYY");
    }

    const createAction = () => {
        const { openSnackbar } = props;
        if (text.length < 5 || text === props.translations.postItMessage) {
            openSnackbar(props.translations.noteLengthRestriction);
        } else {
            let date = new Date();
            const data = {
                confirmed: false,
                type: "note",
                text: text,
                time: timeFormat(date),
                enabled: true,
                repeat: false,
                day: {
                    Monday: false,
                    Tuesday: false,
                    Wednesday: false,
                    Thursday: false,
                    Friday: false,
                    Saturday: false,
                    Sunday: false,
                    date: dateFormat(date)
                },
                devices: [selectedDevice]
            };

            createActionFunction(data).then((responseData) => {
                setText(props.translations.postItMessage);
            }).catch((error) => { logger.log("Note error:", error) });
        }
    }

    const handleChange = (event) => {
        setText(event.target.value);
    }

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedDevice(newValue);
    };


    return (
        <>
            {props.permission === "admin" ?
                <div className="textNote cardContainerExtraPadding">
                    <>
                        <textarea
                            placeholder={props.translations.postItMessage}
                            value={text}
                            onChange={handleChange}
                        />
                        <div className='noteButtonsContainer'>
                            <div onClick={createAction}><span>{props.translations.sendNote}</span></div>
                            <div className='dropDownDevices'>
                                <select
                                    value={selectedDevice}
                                    onChange={handleSelectChange}
                                    className="containerCSS"
                                    style={{ background: 'linear-gradient(180deg, #f7941d, #f25100)' }}
                                >
                                    {props.devices.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                    </>
                </div>
                : <MenuCard
                    menuCard={"menuCardMessage"}
                    cardHeaderBackColor={"cardHeader menuCardMessageHeader"}
                    cardContainer={"cardContainerMessage"}
                    cardTitle={"cardTitle"}

                    title={props.translations.notes}
                >
                    <NewAccessMessage type={props.type} openSnackbar={props.openSnackbar} translations={props.translations}></NewAccessMessage>
                </MenuCard>
            }</>
    );
}

const options = {
    position: 'bottom-left',
    style: {
        marginBottom: '30px'
    }
}

export default withSnackbar(NewNote, options);
