import { strings } from "./Localization";
import logger from './Components/Functions/logger';

export const buildingIDValidation = (buildingID) => {
	const re = /^\w+$/;
	return re.test(buildingID);
};

export function passwordRules(password, confirmPassword) {
	// ^	The password string will start this way
	// (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
	// (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
	// (?=.*[0-9])	The string must contain at least 1 numeric character
	// (?=.{8,})	The string must be eight characters or longer
	// ("\\s")      The string must not contain space characters
	var error = [];
	var passwdRules1 = new RegExp("(?=.*[a-z-ά-ώ-α-ω])");
	var passwdRules2 = new RegExp("(?=.*[A-Z-Ά-Ώ-Α-Ω])");
	var passwdRules3 = new RegExp("(?=.*[0-9])");
	var passwdRules4 = new RegExp("(?=.{8,16}$)");
	var passwdRules5 = new RegExp("\\s");
	var passwdRules6 = password === confirmPassword;

	if (!passwdRules1.test(password)) {
		error.push(strings.passwordLowercase);
	}
	if (!passwdRules2.test(password)) {
		error.push(strings.passwordUppercase);
	}
	if (!passwdRules3.test(password)) {
		error.push(strings.passwordNumber);
	}
	if (!passwdRules4.test(password)) {
		error.push(strings.shortPass);
	}
	if (passwdRules5.test(password)) {
		error.push(strings.whitespaceError);
	}
	if (!passwdRules6) {
		error.push(strings.missMatchPass);
	}
	if (error.length > 0) {
		return error;
	} else {
		return true;
	}
}

export function nameRules(name) {
	var nameRule1 = new RegExp(/[~`!@#$%\^&*()+=_\-\[\]\\';,/{}|\\":<>\?]/);
	var nameRule2 = new RegExp(/^([ά-ώΆ-Ώ-α-ωΑ-Ω-a-zA-Z0-9]{4,20}(\s)?){1,2}$/);

	if (nameRule1.test(name)) {
		return (error = strings.specialCharacter);
	} else if (name.length > 22) {
		return (error = strings.lengthError);
	} else if (!nameRule2.test(name)) {
		return (error = strings.generalNameError);
	} else {
		return true;
	}
}

export function ActionNameRules(name, type) {
	var timeName = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/); //accepts only time -> hh:mm
	var actionName = new RegExp(/^[ά-ώΆ-Ώ-α-ωΑ-Ω-a-zA-Z0-9_ ]*/);

	logger.log("name: " + name);
	logger.log("type: " + type);
	if (type === "alarm") {
		if (!timeName.test(name)) {
			return false;
		}
	} else {
		if (!actionName.test(name)) {
			return false;
		}
	}
	return true;
}

export const emailValidation = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

export const passwordValidation = (password) => {
	// Check if there is at least one lowercase letter
	// α-ω might not be needed because ά-ώ might be adequate for it
	const lowercaseRegex = /(.*[a-z-ά-ώ-α-ω].*)/;
	// Check if there is at least one uppercase letter
	const uppercaseRegex = /(.*[A-Z-Ά-Ώ-Α-Ω].*)/;

	if (password.length < 6) {
		return strings.shortPass;
	} else if (!lowercaseRegex.test(password)) {
		return strings.passwordLowercase;
	} else if (!uppercaseRegex.test(password)) {
		return strings.passwordUppercase;
	} else {
		return true;
	}
};

export const checkScene = (value) => {
	// /^([ά-ώΆ-Ώ-α-ωΑ-Ω-a-zA-Z0-9_]{1,12}(\s)?){1,5}$/ 1 to 5 words (with spaces) TOCHANGE
	const re = /^([ά-ώΆ-Ώ-α-ωΑ-Ω-a-zA-Z0-9_]{3,20}(\s)?){1,3}$/;
	if (!re.test(value)) {
		return false;
	}

	if (!isNaN(value)) {
		return false;
	}

	if (value.length > 20) {
		return false;
	}
	return true;
};

export const checkSwitches = (value) => {
	const re = /^([ά-ώΆ-Ώ-α-ωΑ-Ω-a-zA-Z0-9_]{2,20}(\s)?){1,3}$/;
	if (!re.test(value)) {
		return false;
	}
	if (value.length > 20) {
		return false;
	}

	return true;
};
