import GeneralInformation from "../../Components/NewInterface/GeneralInformation";

export default {
    translations: {
        userLogin: "ВЛИЗАНЕ В СИСТЕМАТА НА ПОТРЕБИТЕЛЯ",//1 //toDo
        login: "Вход", //2 //ok
        logout: "Излизане от системата",//3 //ok
        switches: "ПРЕВКЛЮЧВАТЕЛИ",//4 //ok
        scenarios: "СЦЕНАРИИ",//5 //toDo
        timers: "ПЛАНИРАНЕ НА СЦЕНАРИЙ",//6 //ok
        commands: "ПЛАНИРАНЕ НА КОМАНДИ",//7 //toDo ??
        camera: "КАМЕРА",//8 //ok
        alarm: "АЛАРМА",//9 //ok
        reminder: "НАПОМНЯНЕ",//10 //toDo
        notes: "ПУБЛИКУВАЙТЕ ГО",//11 //ok
        energy: "ENERGY METERING",
        scenarioProgCreation: "СЪЗДАВАНЕ НА СЦЕНАРИИ / ПЛАНИРАНЕ",//12 //toDo
        scenarioCreation: "СЦЕНАРИИ / СЪЗДАВАНЕ",//13 //toDo
        commandsCreation: "СЪЗДАВАНЕ НА КОМАНДИ / ПЛАНИРАНЕ",//14 //toDo
        alarmCreation: "АЛАРМА / СЪЗДАВАНЕ",//15 //toDo
        reminderCreation: "НАПОМНЯНЕ / СЪЗДАВАНЕ",//16 //toDo
        titleNameError: "ЗАГЛАВИЕ ИМЕ ГРЕШКА",//17 //toDo
        addNewScene: "НОВ СЦЕНАРИЙ",//18 //toDo
        multiplePlan: "МНОГОКРАТЕН ГРАФИК",//19 //ok
        newCommand: "НОВА КОМАНДА",//20 //ok
        newAlarm: "НОВА АЛАРМА",//21 //tpDo
        newReminder: "НОВО НАПОМНЕНИЕ",//22 //toDo
        dateOrTimeOld: "Датата или часът са стари.",//23 //toDo
        successfulSave: "Спасяването беше успешно.",//24 //toDo
        cancel: "ОТМЕНЯМ",//25 //ok
        delete: "ИЗТРИВАНЕ",//26 //toDo
        hideCaps: "РАЗКРИВАНЕ НА ИНФОРМАЦИЯ",
        show: "РЕКЛАМА",
        save2: "СПАСИ",//27 //toDo
        scenarioNameExists: "Това име на сценарий вече съществува.",//28 //toDo
        shortName: "Името на сценария трябва да се състои от поне 3 символа.",//29 //toDo
        scenarioName: "Име на сценария",//30 //toDo
        selectASwitch: "Изберете поне един превключвател.",//31 //toDo
        wrongNameType: "Неправилен тип име",//32 //ok
        noDeviceChosen: "Не сте избрали устройство",//33 //toDo
        devices: "УСТРОЙСТВА",//34 //ok
        smallName: "Името трябва да се състои от поне 4 символа.",//35 //toDo
        newNameMult: "Въведи име",//36 //toDo
        time: "ВРЕМЕ",//37 //ok
        date: "Дата",//38 //ok
        repeat: "Повторете",//39 //ok
        videoCall: "ВИДЕОРАЗГОВОР",//40 //ok
        display: "ДИСПЛЕЙ",//41 //toDo
        selectCamera: "Изберете камера",//42 //toDo
        noteLengthRestriction: "Бележката трябва да се състои от поне 4 символа.",//43 //toDo
        sendNote: "ИЗПРАТИ БЕЛЕЖКА",//44 //toDo
        days: {
            Monday: "Понеделник",
            Tuesday: "Вторник",
            Wednesday: "Сряда",
            Thursday: "Четвъртък",
            Friday: "Петък",
            Saturday: "Събота",
            Sunday: "Неделя",
        },//45 //ok
        days_locale: {
            Monday: "Понеделник",
            Tuesday: "Вторник",
            Wednesday: "Сряда",
            Thursday: "Четвъртък",
            Friday: "Петък",
            Saturday: "Събота",
            Sunday: "Неделя"
        },
        changeHome: "СМЯНА НА ДОМА",
        addNewName: "Въведете ново име",
        selectedLang: "Избран език",
        selectLang: "Избор на език",
        english: "Английски",
        german: "Немски",
        spanish: "испански",
        romanian: "румънски",
        bulgarian: "български",
        greek: "гръцки",
        allerRetourWith: "Aller - Retour с",
        allerRetour: "Aller - Retour",
        changeBuidling: "Смени сградата",//46 //toDo
        error: "Грешка 404! Страницата не е намерена!",//47 //toDo
        buildingNotValid: "Сградата не е валидна",//48 //toDo
        newBuilding: "Добавяне на нова сграда",//49 //toDo
        checkInfo: "Моля, проверете информацията си.",//50 //toDo
        passwordMissing: "Липсва парола",//51 //toDo
        emailMissing: "Липсва електронна поща",//52 //toDo
        missMatchPass: "Паролите не съвпадат",//53
        switch_on: "на",//54
        switch_off: "от",//55
        thereAreNo: "НЯМА",
        command: "КОМАНДИ",
        cameras: "КАМЕРИ",
        alarmsStr: "АЛАРМИ",
        reminders: "НАПОМНЯНИЯ",
        hmLanguage: "Език по подразбиране",
        name: "български",
        deleteConfirmation: "Изтриването е успешно.", //56
        deleteFailure: "Изтриването е неуспешно.", //57
        deleteAllQuestion: "Сигурни ли сте, че искате да изтриете всички съобщения?", //58
        deleteMessageQuestion: "Сигурни ли сте, че искате да изтриете това съобщение?", //59
        incomingMessages: "ВХОДЯЩИ СЪОБЩЕНИЯ", //60
        messageFrom: "Съобщение от", //61
        deleteAll: "ИЗТРИВАНЕ НА ВСИЧКИ", //62
        messageArchiveText: "АРХИВ НА СЪОБЩЕНИЯТА", //63
        deleteInviteSuccess: "Поканата е изтрита",
        deleteInviteFailure: "Изтриването на поканата не се е състоял",
        acceptInviteSuccess: "Поканата е приета",
        acceptInviteFailure: "Поканата не е била приета.",
        deleteAllInvitesQuestion: "Сигурни ли сте, че искате да изтриете всички покани?",
        deleteInviteQuestion: "Сигурни ли сте, че искате да изтриете тази покана?",
        inviteFrom: "Покана от ",
        acceptInvite: "ПРИЕМАМ",
        invites: "ПОКАНИ", //?
        newAccessScenario: ": Сценарии",
        newAccessScenarioProgramming: ": Планиране на сценарии",
        newAccessCommandsProgramming: ": Команди Планиране",
        newAccessCamera: ": Камера",
        newAccessAlert: ": Аларми",
        newAccessReminder: ": Напомняния",
        newAccessPostIt: ": ПОСТНЕТЕ ГО",
        newAccessDot: ".",
        newAccessNoAccess: "Нямате разрешение",
        deleteQuestion: "Сигурни ли сте, че искате да ги изтриете?",
        account: "СМЕТКА",
        successfulRegistration: "Успешна регистрация",
        unsuccessfulRegistration: "Неуспешна регистрация",
        deleteAccountQuestion: "Сигурни ли сте, че искате да изтриете профила си?\nАко отговорът е ДА, проверете електронната си поща за потвърждение за изтриване.",
        sendEmailSuccess: "Имейлът е изпратен успешно.",
        sendEmailFailure: "Не успяхте да изпратите имейл.",
        successfulCancelation: "Успешно анулиране.",
        nicknameExists: "Това име вече съществува.",
        nicknameSavedSuccessfuly: "Прякорът е успешно запазен.",
        nicknameDelete: "Имя было успешно удалено.",
        nicknamesSavedSuccessfuly: "Прозвищата са успешно запазени.",
        myAccount: "МОЯТ АКАУНТ",
        homeName: "Име на къщата",
        username: "Потребителско име",
        addExtraNickname: "Добавяне на допълнителни потребителски имена",
        deleteAccount: "ИЗТРИВАНЕ НА АКАУНТА",
        deleteHome: "ИЗТРИВАНЕ НА КЪЩАТА",
        deleteHomeQuestion: "Сигурни ли сте, че искате да изтриете тази къща?",
        unhideSwitch: "Сигурни ли сте, че искате да покажете превключвателя ",
        hideSwitch: "Сигурни ли сте, че искате да скриете превключвателя ",
        deleteSwitch: "Сигурни ли сте, че искате да изтриете превключвателя ",
        insertUserName: "Въведете потребителско име",
        weather: "ВРЕМЕ",
        weatherText_1: "Той ще се използва в команди като:",
        weatherText_2: "\"Какво ще бъде времето утре?\",\"Кажи ми за времето\"",
        timeZone: "Часова зона",
        timeZoneText_1: "Той ще се използва в команди като:",
        timeZoneText_2: "\"Колко е часът?\",\"Кажи ми часа\"",
        news: "НОВИНИ",
        newsText: "Колко новинарски заглавия искате да слушате?",
        tabletLanguage: "Език на таблета",
        changeKeyword: "Промяна на ключовата дума",
        generalInformation: "ОБЩА ИНФОРМАЦИЯ",
        theUser: "Потребителят:  ",
        hasPermission: " вече има разрешение!",
        members: "Членове",
        changeUsername: "Искате ли да промените потребителското име на потребителя: ",
        noUsersAvailabe: "Няма налични потребители за изтриване!",
        deleteMember: "Сигурни ли сте, че искате да изтриете потребителя: ",
        invalidEmail: "Въвели сте невалиден имейл!",
        insertNewUsername: "Вмъкване на ново потребителско име",
        save: "Запазете",
        cancel_2: "Отменя",
        insertNewEmail: "Въведете имейл",
        availablePermissions: "Налични разрешения",
        memberUsername: "Потребителско име на члена: ",
        currentUserPermission: "Разрешение за настоящ член",
        renameUser: "ПРЕИМЕНУВАНЕ НА ПОТРЕБИТЕЛ",
        deleteUsers: "ДЕЛЕТИРАНЕ НА ПОТРЕБИТЕЛ",
        addNewUser: "ДОБАВЯНЕ НА НОВ ПОТРЕБИТЕЛ",
        permissions: "ПРАВИЛА ЗА ДОСТЪП",
        week: "СЕДМИЦА",
        month: "МЕСЕЦ",
        year: "ГОДИНА",
        energyConsumption: "Консумация в KW",
        energyMetering: "Измерване на енергията",
        energyMeteringCapital: "ИЗМЕРВАНЕ НА ЕНЕРГИЯТА",
        insertCommand: "Команда за вмъкване",
        selectCamera: "ИЗБОР НА КАМЕРА",
        edit: "РЕДАКТИРАНЕ",
        showHiddenDevices: "Показване на скрити устройства",
        editScene: "СЦЕНАРИЙ ЗА РЕДАКТИРАНЕ",
        unselectAll: "Премахване на избора на всички",
        selectAll: "Изберете всички",
        cancelAllerRetour: "Анулиране Aller - Retour",
        showHiddenDevices: "Показване на скрити устройства",
        rename: "Преименуване на",
        addName: "Добавяне на име",
        showLow: "Покажи",
        hide: "Скрий",
        deleteLow: "Изтриване на",
        currentDeviceName: "Име на текущия превключвател",
        newDeviceName: "Ново име на превключвателя",
        successfullRename: "Успешно преименуване на превключвател.",
        failRename: "Неуспешно преименуване на превключвател.",
        successfullHide: "Успешно скриване на превключвател.",
        failHide: "Неуспешно скриване на превключвателя.",
        cancelHide: "Отменихте скриването на превключвателя.",
        successfullShow: "Успешно показване на превключвателя.",
        failShow: "Неуспешно показан превключвател.",
        cancelShow: "Отменили сте показването на превключвателя.",
        successfullDelete: "Успешно изтриване на превключвател.",
        failDelete: "Неуспешно изтриване на превключвател.",
        cancelDelete: "Отменили сте изтриването на превключвателя.",
        successfullCancelAllerRetour: "Успешно анулиране на Aller-Retour.",
        failCancelAllerRetour: "Неуспешна отмяна на Aller-Retour.",
        successfullAddName: "Успешно въвеждане на допълнително име.",
        failAddName: "Неуспешно въвеждане на име.",
        renameCaps: "УЧАСТИЕ",
        noInvites: "Нямате покани",
        noMessages: "Нямате съобщения",
        oneTime: "Веднъж",
        postItMessage: 'Поставете бележката си',
        thereAreNoText1: "Няма такъв",
        thereAreNoText2: "към вашия списък.",
        messages:"съобщения",
        ivnites:"кани",
        noPermissionToService:"Съжаляваме, че в момента нямате разрешение за достъп до тази услуга.",
        location: "МЕСТОПОЛОЖЕНИЕ",
        changeTimeZone: "Промяна на часовата зона",
        selectedZone: "избрана зона: ",
        selectZone: "избор на зона",
        timeZoneCaps: "ВРЕМЕВА ЗОНА",
        tabletLanguageCaps: "ЕЗИКОВ ТАБЛЕТ",
        changeKeyword: "ПРОМЯНА НА КЛЮЧОВИТЕ СЛОВА",
        changeLanguage: "Промяна на езика",
        invitesText : "ПОКАНИ"
    }
}