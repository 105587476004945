export default {
    translations:{
        userLogin: "ΣΥΝΔΕΣΗ ΧΡΗΣΤΗ",
        login: "ΣΥΝΔΕΣΗ",
        logout: "ΑΠΟΣΥΝΔΕΣΗ",
        switches: "ΔΙΑΚΟΠΤΕΣ",
        scenarios: "ΣΕΝΑΡΙΑ",
        timers: "ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΣ ΣΕΝΑΡΙΩΝ",
        commands: "ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΣ ΕΝΤΟΛΩΝ",
        camera: "ΚΑΜΕΡΑ",
        alarm: "ΞΥΠΝΗΤΗΡΙ",
        reminder: "ΥΠΕΝΘΥΜΙΣΗ",
        notes: "POST IT",
        energy: "ENERGY METERING",
        scenarioProgCreation: "ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΣ ΣΕΝΑΡΙΩΝ",
        scenarioCreation: "ΣΕΝΑΡΙΑ / ΔΗΜΙΟΥΡΓΙΑ",
        commandsCreation: "ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΣ ΕΝΤΟΛΩΝ",
        alarmCreation: "ΞΥΠΝΗΤΗΡΙ / ΔΗΜΙΟΥΡΓΙΑ",
        reminderCreation: "ΥΠΕΝΘΥΜΙΣΗ / ΔΗΜΙΟΥΡΓΙΑ",
        titleNameError: "TITLE NAME ERROR",
        addNewScene: "ΝΕΟ ΣΕΝΑΡΙΟ",
        multiplePlan: "ΠΟΛΛΑΠΛΟΣ ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΣ",
        newCommand: "ΝΕΑ ΕΝΤΟΛΗ",
        newAlarm: "ΝΕΟ ΞΥΠΝΗΤΗΡΙ",
        newReminder: "ΝΕΑ ΥΠΕΝΘΥΜΙΣΗ",
        dateOrTimeOld: "Η ημερομηνία ή η ώρα είναι παλιά",
        successfulSave: "Η αποθήκευση έγινε επιτυχώς.",
        cancel: "ΑΚΥΡΩΣΗ",
        hideCaps: "ΑΠΟΚΡΥΨΗ",
        show: "ΕΜΦΑΝΙΣΗ",
        delete: "ΔΙΑΓΡΑΦΗ",
        save2: "ΑΠΟΘHΚΕΥΣΗ",
        scenarioNameExists: "Αυτό το όνομα σεναρίου υπάρχει ήδη.",
        shortName: "Το όνομα του σεναρίου πρέπει να περιέχει τουλάχιστον 3 χαρακτήρες.",
        scenarioName: "Όνομα σεναρίου",
        selectASwitch: "Επιλέξτε τουλάχιστον έναν διακόπτη.",
        wrongNameType: "Λάθος τύπος ονόματος",
        noDeviceChosen: "Δεν έχετε επιλέξει κάποια συσκευή",
        noInvites: "ΔΕΝ ΕΧΕΤΕ ΚΑΜΙΑ ΠΡΟΣΚΛΗΣΗ",
        noMessages: "ΔΕΝ ΕΧΕΤΕ ΚΑΝΕΝΑ ΜΗΝΥΜΑ",
        devices: "ΣΥΣΚΕΥΕΣ",
        smallName: "Το όνομα πρέπει να περιέχει πάνω από 4 χαρακτήρες",
        newNameMult: "Εισάγετε όνομα",
        time: "ΩΡΑ",
        date: "ΗΜΕΡΟΜΗΝΙΑ",
        repeat: "ΕΠΑΝΑΛΗΨΗ",
        videoCall: "ΒΙΝΤΕΟΚΛΗΣΗ",
        display: "ΠΡΟΒΟΛΗ",
        selectCamera: "Επιλογή Κάμερας",
        noteLengthRestriction: "Το note πρέπει να περιέχει πάνω από 4 χαρακτήρες",
        sendNote: "ΑΠΟΣΤΟΛΗ NOTE",
        days: {
            Monday: "Δευτέρα",
            Tuesday: "Τρίτη",
            Wednesday: "Τετάρτη",
            Thursday: "Πέμπτη",
            Friday: "Παρασκευή",
            Saturday: "Σάββατο",
            Sunday: "Κυριακή"
        },
        days_locale: {
            Monday: "Δευ",
            Tuesday: "Τρί",
            Wednesday: "Τετ",
            Thursday: "Πέμ",
            Friday: "Παρ",
            Saturday: "Σάβ",
            Sunday: "Κυρ"
        },
        changeHome: "ΑΛΛΑΓΗ ΣΠΙΤΙΟΥ",
        addNewName: "Εισάγετε νέο όνομα",
        selectedLang: "Επιλεγμένη γλώσσα",
        selectLang: "Επιλογή Γλώσσας",
        english: "Αγγλικά",
        german: "Γερμανικά",
        spanish: "Ισπανικά",
        romanian: "Ρουμάνικα",
        bulgarian: "Βουλγάρικα",
        greek: "Ελληνικά",
        allerRetourWith: "Aller - Retour με",
        allerRetour: "Aller - Retour",
        cancelAllerRetour: "Ακύρωση Aller - Retour",
        changeBuidling: "Άλλαξε κτήριο",
        error: "Error 404! Page not Found!",
        buildingNotValid: "Κτήριο μη διαθέσιμο",
        newBuilding: "Πρόσθεσε νέο κτήριο",
        checkInfo: "Παρακαλώ ελέγξτε τα στοιχεία σας",
        passwordMissing: "Ο κωδικός λείπει",
        emailMissing: "Το e-mail λείπει",
        missMatchPass: "Οι κωδικοί δεν ταιριάζουν",
        switch_on: "on",
        switch_off: "off",
        thereAreNoText1: "Δεν υπάρχουν",
        thereAreNoText2: "στην λίστα σας.",
        command: "ΕΝΤΟΛΕΣ",
        cameras: "ΚΑΜΕΡΕΣ",
        alarmsStr: "ΞΥΠΝΗΤΗΡΙΑ",
        reminders: "ΥΠΕΝΘΥΜΙΣΕΙΣ",
        generalInformation: "ΓΕΝΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ",
        hmLanguage: "Προκαθορισμένη γλώσσα",
        name: "Ελληνικά",
        deleteConfirmation: "Η διαγραφή έγινε επιτυχώς.",
        deleteFailure: "Η διαγραφή απέτυχε.",
        deleteAllQuestion: "Είστε σίγουροι ότι θέλετε να διαγράψετε όλα τα μηνύματα;",
        deleteMessageQuestion: "Είστε σίγουροι ότι θέλετε να διαγράψετε το μήνυμα;",
        incomingMessages: "ΕΙΣΕΡΧΟΜΕΝΑ ΜΥΝΗΜΑΤΑ",
        messageFrom: "Μήνυμα από",
        deleteAll: "ΔΙΑΓΡΑΦΗ ΟΛΩΝ",
        messageArchiveText: "ΑΡΧΕΙΟ ΜΗΝΥΜΑΤΩΝ",
        deleteInviteSuccess: "Έγινε η διαγραφή της πρόσκλησης",
        deleteInviteFailure: "Δεν έγινε η διαγραφή της πρόσκλησης",
        acceptInviteSuccess: "Έγινε η αποδοχή της πρόσκλησης",
        acceptInviteFailure: "Δεν έγινε η αποδοχή της πρόσκλησης",
        deleteAllInvitesQuestion: "Είστε σίγουροι ότι θέλετε να διαγράψετε όλες τις προσκλήσεις;",
        deleteInviteQuestion : "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την πρόσκληση;",
        inviteFrom: "Πρόσκληση από ",
        acceptInvite: "ΑΠΟΔΟΧΗ",
        invites: "ΠΡΟΣΚΛΗΣΕΙΣ",
        newAccessScenario: ": Σενάρια",
        newAccessScenarioProgramming: ": Προγραμματισμός Σεναρίων",
        newAccessCommandsProgramming: ": Προγραμματισμός Εντολών",
        newAccessCamera: ": Κάμερα",
        newAccessAlert: ": Ξυπνητήρια",
        newAccessReminder: ": Υπενθυμίσεις",
        newAccessPostIt: ": Post It",
        newAccessDot: ".",
        newAccessNoAccess: "Δεν έχετε πρόσβαση",
        deleteQuestion: "Είστε σίγουροι ότι θέλετε να διαγραφούν;",
        account: "ΛΟΓΑΡΙΑΣΜΟΣ",
        successfulRegistration: "Επιτυχής καταχώρηση",
        unsuccessfulRegistration: "Ανεπιτυχής καταχώρηση",
        deleteAccountQuestion: "Είστε σίγουροι ότι θέλετε να διαγράψετε τον λογαριασμό σας;\nΑν NAI, ελέγξτε το email σας για επιβεβαίωση της διαγραφής.",
        sendEmailSuccess: "Επιτυχής αποστολή email.",
        sendEmailFailure: "Αποτυχία αποστολής email.",
        successfulCancelation: "Επιτυχής ακύρωση.",
        nicknameExists: "Αυτό το όνομα υπάρχει ήδη.",
        nicknameSavedSuccessfuly: "Το όνομα αποθηκεύτηκε επιτυχώς.",
        nicknameDelete: "Το όνομα έχει διαγραφεί επιτυχώς",
        nicknamesSavedSuccessfuly: "Αποθηκεύτηκαν τα ονόματα χρηστών.",
        myAccount: "ΛΟΓΑΡΙΑΣΜΟΣ",
        homeName: "Όνομα σπιτιού",
        username: "Όνομα χρήστη",
        addExtraNickname: "Προσθήκη ονομάτων",
        deleteAccount: "ΔΙΑΓΡΑΦΗ ΛΟΓΑΡΙΑΣΜΟΥ",
        deleteHome: "ΑΦΑΙΡΕΣΗ ΣΠΙΤΙΟΥ",
        deleteHomeQuestion: "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το σπίτι;",
        unhideSwitch: "Είστε σίγουροι ότι θέλετε να εμφανίσετε το διακόπτη ",
        hideSwitch: "Είστε σίγουροι ότι θέλετε να αποκρύψετε το διακόπτη ",
        deleteSwitch: "Είστε σίγουροι ότι θέλετε να διαγράψετε το διακόπτη ",
        insertUserName: "Εισάγετε όνομα χρήστη",
        weather: "ΚΑΙΡΟΣ",
        weatherText_1: "Θα χρησιμοποιηθεί σε εντολές όπως:",
        weatherText_2: "\"Τι καιρό θα κάνει αύριο\",\"Πες μου τον καιρό\"",
        timeZone: "Ζώνη ώρας",
        timeZoneText_1: "Θα χρησιμοποιηθεί σε εντολές όπως:",
        timeZoneText_2: "\"Τι ώρα είναι\",\"Πες μου την ώρα\"",
        news: "NEA",
        newsText: "Πόσες επικεφαλίδες νέων θέλετε να ακούτε;",
        tabletLanguage: "Γλώσσα Tablet",
        timeZoneCaps: "ΖΩΝΗ ΩΡΑΣ",
        tabletLanguageCaps: "ΓΛΩΣΣΑ TABLET",
        changeKeyword: "ΑΛΛΑΓΗ KEYWORD",
        theUser: "Ο χρήστης:  ",
        hasPermission: " έχει ήδη το δικαίωμα!",
        members: "Μέλη",
        changeUsername: "Θέλετε να αλλάξετε το username του χρήστη: ",
        noUsersAvailabe: "Δεν υπάρχουν διαθέσιμοι χρήστες για διαγραφή!",
        deleteMember: "Θέλετε να διαγράψετε τον χρήστη: ",
        invalidEmail: "Έχετε εισάγει μη έγκυρο email!",
        insertNewUsername: "Εισάγετε το νέο username",
        save: "Αποθήκευση",
        cancel_2: "Ακύρωση",
        insertNewEmail: "Εισάγετε το email",
        availablePermissions: "Διαθέσιμα δικαιώματα",
        memberUsername: "Username μέλους: ",
        currentUserPermission: "Τρέχον δικαίωμα χρήστη",
        renameUser: "ΜΕΤΟΝΟΜΑΣΙΑ ΧΡΗΣΤΗ",
        deleteUsers: "ΔΙΑΓΡΑΦΗ ΧΡΗΣΤΗ",
        addNewUser: "ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΧΡΗΣΤΗ",
        permissions: "ΚΑΝΟΝΕΣ ΠΡΟΣΒΑΣΗΣ",
        week: "ΕΒΔΟΜΑΔΑ",
        month: "ΜΗΝΑΣ",
        year: "ΕΤΟΣ",
        energyConsumption: "Κατανάλωση σε KW",
        energyMetering: "Μέτρηση ενέργειας",
        energyMeteringCapital: "ΜΕΤΡΗΣΗ ΕΝΕΡΓΕΙΑΣ",
        insertCommand: "Εισάγετε εντολή",
        selectCamera: "ΕΠΙΛΟΓΗ ΚΑΜΕΡΑΣ",
        edit: "ΕΠΕΞΕΡΓΑΣΙΑ",
        showHiddenDevices: "Εμφάνιση Κρυφών Συσκευών",
        editScene: "ΕΠΕΞΕΡΓΑΣΙΑ ΣΕΝΑΡΙΟΥ",
        unselectAll: "Αποεπιλογή Όλων",
        selectAll: "Έπιλογή Όλων",

        rename: "Μετονομασία",
        renameCaps: "ΜΕΤΟΝΟΜΑΣΙΑ",
        addName: "Προσθήκη ονόματος",
        showLow: "Εμφάνιση",
        hide: "Απόκρυψη",
        deleteLow: "Διαγραφή",
        currentDeviceName: "Τρέχον όνομα διακόπτη",
        newDeviceName: "Νέο όνομα διακόπτη",
        successfullRename: "Επιτυχής μετονομασία διακόπτη.",
        failRename: "Αποτυχία μετονομασίας διακόπτη.",
        successfullHide: "Επιτυχής απόκρυψη διακόπτη.",
        failHide: "Αποτυχία απόκρυψη διακόπτη.",
        cancelHide: "Έχετε ακυρώσει την απόκρυψη του διακόπτη.",
        successfullShow: "Επιτυχής εμφάνιση διακόπτη.",
        failShow: "Αποτυχής εμφάνιση διακόπτη.",
        cancelShow: "Έχετε ακυρώσει την εμφάνιση του διακόπτη.",
        successfullDelete: "Επιτυχής διαγραφή διακόπτη.",
        failDelete: "Αποτυχία διαγραφής διακόπτη.",
        cancelDelete: "Έχετε ακυρώσει την διαγραφή του διακόπτη.",
        successfullCancelAllerRetour: "Επιτυχής ακύρωση Aller-Retour.",
        failCancelAllerRetour: "Αποτυχία ακύρωσης Aller-Retour.",
        successfullAddName: "Επιτυχής καταχώρηση επιπλέον ονόματος.",
        failAddName: "Αποτυχία καταχώρησης ονόματος.",
        oneTime: "Μία φορά",
        postItMessage: 'Πληκτρολογήστε το μήνυμά σας',
        messages: 'μηνύματα',
        noPermissionToService:"Λυπούμαστε, αυτή την χρονική στιγμή δεν έχετε τα απαραίτητα δικαιώματα για αυτή τη λειτουργία.",
        location: "ΤΟΠΟΘΕΣΙΑ",
        
        changeTimeZone: "Αλλαγή Ζώνης Ώρας",
        changeLanguage: "Αλλαγή Γλώσσας",
        selectedZone: "επιλεγμένη ζώνη: ",
        selectZone: "επιλογή ζώνης",
        invitesText : "ΠΡΟΣΚΛΗΣΕΙΣ"
    }
}