import React from 'react';
import { render } from 'react-dom';
import { ReactComponent as ThermostatLeftArrow } from './images/thermostatLeftArrow.svg';
import { ReactComponent as ThermostatRightArrow } from './images/thermostatRightArrow.svg';
import logger from '../Functions/logger';

class EditSceneThermostat extends React.Component {
    constructor(props) {
        super(props);
        logger.log(this.props);
        this.state = {
            thermPosition: 0,
            defaultThermostat: this.props.thermostatArray[0],
            action: 0
        }
        this.initThermostat = this.initThermostat.bind(this);
    }

    /*componentDidMount(){
        this.initThermostat();
    }*/

    initThermostat() {
        logger.log("Init thermostat");
        this.state.defaultThermostat = this.props.thermostatArray[this.state.thermPosition];
        this.setState({ defaultThermostat: this.state.defaultThermostat });
    }

    changeSetTemp(number) {
        let value = parseInt(this.state.defaultThermostat.state);
        let newValue = value + number;
        this.state.defaultThermostat.state = newValue;
        this.setState({ defaultThermostat: this.state.defaultThermostat });

        //Call props to change thermostat in scene
        this.props.changeSceneThermostat(this.state.defaultThermostat);
    }
    searchThermostatByName() {
        let index = this.props.thermostats.findIndex(thermostat => thermostat.id === this.state.defaultThermostat.id);
        if (index > -1) {
            return (<div className="thermNameContainer" onClick={() => this.enableThermostat()}><div>{this.state.defaultThermostat.selected ? <div className='roundIndicatorOn'></div> : <div className="roundIndicatorOff"></div>}</div>
                <span className='thermName'>{this.props.thermostats[index].name}</span></div>)
        }
        return (<span>We got to go bold</span>)
    }

    searchThermostatByName_2() {
        let index = this.props.thermostats.findIndex(thermostat => thermostat.id === this.state.defaultThermostat.id);
        if (index > -1) {
            return (
                <tr className='thermNameLine' >
                    <td colSpan={4}><div className="thermNameContainer" onClick={() => this.enableThermostat()}><div>{this.state.defaultThermostat.selected ? <div className='roundIndicatorOnThermCreateScene'></div> : <div className="roundIndicatorOffThermCreateScene"></div>}</div>
                        <span className='thermName'>{this.props.thermostats[index].name}</span></div></td>
                    {/*<td colSpan="1"><div>{this.state.defaultThermostat.selected ? <div className='roundIndicatorOnThermCreateScene'></div> : <div className="roundIndicatorOffThermCreateScene"></div>}</div></td>
                <td colSpan="3"><span  className='thermName'>{this.props.thermostats[index].name}</span></td>*/}
                </tr>
            );
        }
    }

    enableThermostat() {
        this.state.defaultThermostat.selected = this.state.defaultThermostat.selected ? false : true;
        this.setState({ defaultThermostat: this.state.defaultThermostat });
    }

    swipeThermostat(action) {
        let found = this.props.thermostatArray.findIndex(thermostat => thermostat === this.state.defaultThermostat);
        if (action === 1) {
            if (found === this.props.thermostatArray.length - 1) {
                this.state.thermPosition = 0;
            }
            else {
                this.state.thermPosition = found + 1;
            }
        }
        else if (action === -1) {
            if (found === 0) {
                this.state.thermPosition = this.props.thermostatArray.length - 1;
            }
            else {
                this.state.thermPosition = found - 1;
            }
        }
        else {
            logger.log("That's some nice action you got there");
        }

        this.initThermostat();
    }

    /*render() {
        return (
            <div className="thermContainer">
                <span className="sceneCreateButton">
                    <div className="thermChange">
                        <span onClick={() => this.swipeThermostat(-1)}>Previous</span>
                    </div>
                    <div className="thermostatLeft">
                        <img src={require("./images/snow_dark_blue_01.png")} onClick={() => this.changeSetTemp(-1)}></img>
                    </div>
                    <div className="thermostatMid">{this.state.defaultThermostat ? this.state.defaultThermostat.state: "-"}°C</div>
                    <div className="thermostatRight">
                        <img src={require("./images/sun_dark_blue_01.png")} onClick={() => this.changeSetTemp(1)}></img>
                    </div>
                    <div className="thermChange">
                        <span onClick={() => this.swipeThermostat(1)}>Next</span>
                    </div>
                </span>
                {/*<span className="thermName">{this.state.defaultThermostat ? this.state.defaultThermostat.name: ""}</span>}*/
    /*{this.searchThermostatByName()}
</div>
 
);
}*/

    render() {
        return (
            <div className="thermEditContainer">

                {this.props.thermostatArray.length > 1 ? <div className="thermEditChangeLeft">
                    {/*<span onClick={() => this.swipeThermostat(-1)}>Previous</span>*/}
                    <ThermostatLeftArrow
                        style={{ width: "35px", height: "35px" }}
                        onClick={() => this.swipeThermostat(-1)}
                    />
                </div> : <div></div>}
                <div className="thermDataContainer">
                    <table className="ThermostatLayout">
                        <tr>
                            <td>
                                <div></div>
                            </td>
                            <td>
                                <div className="thermostatLeft">
                                    <img src={require("./images/snow.png")} onClick={() => this.changeSetTemp(-1)}></img>
                                </div>
                            </td>
                            <td>
                                <div className="thermostatMid">{this.state.defaultThermostat ? this.state.defaultThermostat.state + "°C" : "-"}</div>
                            </td>
                            <td>
                                <div className="thermostatRight">
                                    <img src={require("./images/sun.png")} onClick={() => this.changeSetTemp(1)}></img>
                                </div>
                            </td>
                            <td>
                                <div></div>
                            </td>
                        </tr>
                        {this.searchThermostatByName_2()}
                        {/*<tr className='thermNameLine'>
                            <td colSpan="5"><span className="thermName">{this.searchThermostatByName()}</span></td>
                        </tr>*/}
                    </table>

                </div>
                {this.props.thermostatArray.length > 1 ? <div className="thermEditChangeRight">
                    <ThermostatRightArrow
                        style={{ width: "35px", height: "35px" }}
                        onClick={() => this.swipeThermostat(1)}
                    />
                </div> : <div></div>}
            </div>

        );
    }

}

export default EditSceneThermostat;