import { useState, useEffect } from "react";
import '../../css/GeneralInformation.css';
import { changeKeywords } from "../../buildingFunctions";
import logger from '../Functions/logger';

function ChangeKeyword(props) {
    const [radioButton, setRadioButton] = useState(0);
    const [devices, setDevices] = useState([]);
    const [keywordSelected, setKeywordSelected] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);

    useEffect(() => {
        setDevices(props.selectedBuilding?.devices || []);
    }, [props.selectedBuilding]);

    useEffect(() => {
        if (devices.length > 0) {
            setSelectedDevice(devices[radioButton]);
        }
    }, [devices]);

    useEffect(() => {
        if (selectedDevice) {
            let keyword = selectedDevice.settings?.keyword || 'hey_eleeza';
            setKeywordSelected(keyword);
        }
    }, [selectedDevice, radioButton]);

    function changeKeywordToDevice(keyword) {
        changeKeywords(keyword, selectedDevice?.id)
            .then(() => {
            })
            .catch((error) => {
                logger.warn(error);
            });
    }

    function onChange2(keyword) {
        setKeywordSelected(keyword);
        if (selectedDevice?.settings) {
            selectedDevice.settings.keyword = keyword;
            props.newDevices(devices);
            changeKeywordToDevice(keyword);
        }
    }

    return (
        <div className="keywordGeneralDiv">
            <div className="changeKeyword">{props.translations.changeKeyword}</div>
            <div className="elizaRadioButtonDiv">
                <div key={"elaiza"}>
                    <label className="radioButtonContainer keywordEliza">
                        <input 
                            type="radio" 
                            id="elaiza" 
                            name="keywords" 
                            value="hey_eleeza" 
                            checked={keywordSelected === "hey_eleeza"} 
                            onChange={() => { onChange2("hey_eleeza") }} 
                        />
                        <span className="checkmark" style={{ marginRight: "10px" }}></span>
                        <span htmlFor="elaiza">Hey Ελίζα</span>
                    </label>
                </div>
                <div key={"eliza"}>
                    <label className="radioButtonContainer keywordEliza" style={{ marginTop: "20px" }}>
                        <input 
                            type="radio" 
                            id="eliza" 
                            name="keywords" 
                            value="eleeza" 
                            checked={keywordSelected === "eleeza"} 
                            onChange={() => { onChange2("eleeza") }} 
                        />
                        <span className="checkmark" style={{ marginRight: "10px" }}></span>
                        <span htmlFor="eliza">Ελάϊζα</span>
                    </label>
                </div>
            </div>
            <div className="buildingRadioButtonDiv">
                <div className='dropDownDevices'>
                    <select
                        className="containerCSS"
                        style={{ background: 'linear-gradient(180deg, #f7941d, #f25100)' }}
                        onChange={(event) => {
                            setSelectedDevice(devices[event.target.value]);
                            setRadioButton(event.target.value);
                        }}
                    >
                        {devices.map((device, index) => (
                            <option key={index} value={index}>
                                {device.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default ChangeKeyword;
