import React from 'react';
import './DatePicker.css';
import NewActionDays from './NewActionDays';
import NewOnOff from './NewOnOff';
import Datetime from 'react-datetime';
import NewDevices from './NewDevices';
import NewSceneNames from './NewCreateProgramScene';
import axios from 'axios';
import moment from 'moment';
import { createAction, updateActions, toogleAction, deleteAction } from '../../ActionFunctions';
import { withSnackbar } from 'react-simple-snackbar'
import MenuCard from './MenuCard';
import NewButtons from './NewButtons';
import logger from '../Functions/logger';

class NewPorgramScene extends React.Component {
  constructor(props) {
    super(props);
    this.insertLink = "https://hm-api.entranet.us:7656/housemate/action/createAction";
    this.updateLink = "https://hm-api.entranet.us:7656/housemate/action/updateAction";
    this.deleteLink = "https://hm-api.entranet.us:7656/housemate/action/deleteAction";
    this.state = {
      action: null,
      date: null,
      noDate: "--/--/--",
      repeat: false,
      noDeviceSelected: false,
      DaysSelected: false,
      buttonArray: []
    }
    this.name = "";

  }

  componentDidMount() {
    this.setState({ action: this.props.action });
    logger.log("----------")
    logger.log(this.props.action.id);
    logger.log("MY ACTION:", this.props.action);
    const isNew = this.props.action.id == "" ? true : false;
    logger.log("ISNEW:", isNew);

    const newButtonArray = isNew ? [
      { text: this.props.translations.cancel, onClickFunction: () => { this.props.changeMenu(2) } },
      { text: this.props.translations.save2, onClickFunction: () => { this.save() } }
    ] :
      [
        { text: this.props.translations.cancel, onClickFunction: () => { this.props.changeMenu(2) } },
        { text: this.props.translations.delete, onClickFunction: () => { this.deleteAction() } },
        { text: this.props.translations.save2, onClickFunction: () => { this.save() } }
      ]

    this.setState({ buttonArray: newButtonArray })

  }

  componentDidUpdate(nextProps) {
    if (nextProps.action !== this.props.action) {
      this.setState({ action: this.props.action });
    }
  }

  componentWillUnmount() {

  }

  dayClicked = (day) => {
    logger.log("day clicked");
    this.state.action.repeat = true;
    this.state.action.day[day] = !this.state.action.day[day]

    //check if any day is clicked and set DaysSelected -> true so that the switch is on 
    Object.keys(this.state.action.day).forEach(x => {
      if (this.state.action.day[x] == true) {
        this.state.action.DaysSelected = true;
      }
    })

    //if no days are selected -> the switch is off and the action has the date that is selected
    if (!this.state.action.DaysSelected) {
      this.repeatOnOff(false)
      this.state.action.repeat = false;
      let date = new Date();
      this.state.action.day.date = this.dateFormat(date)
    }
    else {
      this.state.action.day.date = this.state.noDate;
    }

    this.state.action.DaysSelected = false;
    this.setState({ action: this.state.action });
  }

  repeatOnOff = (on) => {

    if (on === false)
      this.unselectDays();

    this.state.action.repeat = on;
    this.setState({ action: this.state.action });
  }

  timeFormat = (date) => {
    return moment(date).format("HH:mm");
  }

  dateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY");
  }

  saveTime = (date) => {
    this.state.action.time = this.timeFormat(date);
    this.setState({ action: this.state.action });
  }

  saveDate = (date) => {
    this.state.action.repeat = false;
    this.unselectDays();
    this.state.action.day.date = this.dateFormat(date);
    this.setState({ action: this.state.action });
  }

  unselectDays = () => {

    Object.keys(this.state.action.day).forEach(x => {
      if (this.state.action.day[x] == true) {
        this.state.action.day[x] = false;
      }
    })
    let date = new Date();
    this.state.action.day.date = this.dateFormat(date)
    this.setState({ action: this.state.action });
  }

  deviceOnOff = (on, deviceID) => {
    logger.log("deviceOnOff", this.state.action.devices);

    let propsDeviceIndex = this.props.devices.findIndex(device => deviceID === device.id);
    if (propsDeviceIndex > -1) {
      let stateDeviceIndex = this.state.action.devices.findIndex(device => deviceID === device)
      if (!on) {
        this.state.action.devices.splice(stateDeviceIndex, 1);
        this.setState({ action: this.state.action })

      } else if (on && stateDeviceIndex == -1) {
        this.state.action.devices.push(deviceID);
        this.setState({ action: this.state.action })

      }
    }
    this.setState({ action: this.state.action })

  }

  enableScene = (name) => {

    logger.log(name);
    this.name = name;
  }
  deleteAction = () => {
    logger.log("delete");

    deleteAction(this.state.action.id).then((responseData) => {

      this.props.removeFunction(this.state.action.id, "action");
      this.changeMenu();
    }).catch((error) => { });
  }

  createAction = () => {
    logger.log("createAction");
    let newDate = new Date();
    const actionDate = (this.state.action.day.date).split('/'); // DD/MM/YYYY
    const time = (this.state.action.time).split(':');
    let flag = false;

    if (this.state.action.devices.length < 1) {
      flag = true;
      this.props.openSnackbar(this.props.translations.noDeviceChosen)
    }
    else if ((this.state.action.day.date !== '--/--/--')) {

      if ((this.state.action.day.date !== '--/--/--')) {
        if (parseInt(actionDate[2]) < newDate.getFullYear()) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
        if (parseInt(actionDate[2]) == newDate.getFullYear()) {
          logger.log("year");
          if (parseInt(actionDate[1]) < (newDate.getMonth() + 1)) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
          if (parseInt(actionDate[1]) == (newDate.getMonth() + 1)) {
            logger.log("month");
            if (parseInt(actionDate[0]) < newDate.getDate()) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
            if (parseInt(actionDate[0]) == newDate.getDate()) {
              logger.log("day");
              if (parseInt(time[0]) < newDate.getHours()) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
              if (parseInt(time[0]) == newDate.getHours()) {
                logger.log("hours");
                if (parseInt(time[1]) <= (newDate.getMinutes() - 3)) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) }
              }
            }
          }
        }
      }
    }
    if (flag == false) {
      //if a scenario is programmed by multiple programming then create the action with the name of the scenario that was selected
      if (this.state.action.text === "") {

        this.props.enableScene(this.name)
        logger.log("empty: ", this.state.action);
        createAction(this.state.action).then((responseData) => {

          this.state.action.id = responseData.data.actionID
          this.props.addFunction(this.state.action, "action");
          this.changeMenu();
          this.props.getActions();
          if (responseData.state == 0) {
            this.props.open_Snackbar();
          }

          // logger.log("==============");
          // logger.log(this.props.actions);

        }).catch((error) => { });

      }
      else {
        //if a scenario is programmed by the editing image then create the action with the name that the scenario already has
        logger.log("not empty: ", this.state.action);
        createAction(this.state.action).then((responseData) => {

          this.props.addFunction(this.state.action, "action");
          this.changeMenu();
          this.props.getActions();
          if (responseData.state == 0) {
            this.props.open_Snackbar();
          }

        }).catch((error) => { });
      }
    }

    /*  createAction(this.state.action).then((responseData) => {
      
       this.props.addFunction(this.state.action, "action");
          this.changeMenu();
          this.props.getActions();
      
        }).catch((error) => {}); */

  }

  updateAction = () => {
    let newDate = new Date();

    const actionDate = (this.state.action.day.date).split('/'); // DD/MM/YYYY
    const time = (this.state.action.time).split(':');
    let flag = false;

    if ((this.state.action.day.date !== '--/--/--')) {
      if (parseInt(actionDate[2]) < newDate.getFullYear()) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
      if (parseInt(actionDate[2]) == newDate.getFullYear()) {
        logger.log("year");
        if (parseInt(actionDate[1]) < (newDate.getMonth() + 1)) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
        if (parseInt(actionDate[1]) == (newDate.getMonth() + 1)) {
          logger.log("month");
          if (parseInt(actionDate[0]) < newDate.getDate()) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
          if (parseInt(actionDate[0]) == newDate.getDate()) {
            logger.log("day");
            if (parseInt(time[0]) < newDate.getHours()) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
            if (parseInt(time[0]) == newDate.getHours()) {
              logger.log("hours");
              if (parseInt(time[1]) <= (newDate.getMinutes() - 3)) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) }
            }
          }
        }
      }
    }
    // if the date and hour are right
    if (flag == false) {
      if (this.state.action.enabled) {
        this.state.action.confirmed = false;
      }

      this.setState({ action: this.state.action });

      updateActions(this.state.action).then((responseData) => {
        logger.log("update");
        // logger.log(this.state.action);
        let index = this.props.actions.findIndex(action => action.id === this.state.action.id);
        if (index > -1) {
          this.props.actions[index] = this.state.action;
        }
        this.changeMenu();

      }).catch((error) => { });
    }


  }

  save = () => {
    logger.log("save:", this.state.action.id);
    if (this.state.action.id == "") {
      this.createAction();
    } else {
      this.updateAction();
    }

  }

  changeMenu = () => {
    let menu = 0;
    logger.log("menu: ", this.state.action.type);
    switch (this.state.action.type) {
      case "scene":
        menu = 2;
        break;
      case "command":
        menu = 3;
        break;
      case "alarm":
        menu = 5;
        break;
      case "alert":
        menu = 6;
        break;
      case "postit":
        menu = 7;
        break;
    }
    this.props.changeMenu(menu);
  }

  render() {

    const containerHeader = (
      <></>
    )

    // const buttonSaveFunction = () => {
    //   this.save();
    // }

    // const buttonCancelFunction = () => {
    //   this.props.changeMenu(2);
    // }

    // const buttonDeleteFunction = () => {
    //   this.props.deleteAction();
    // }

    // const buttonArray = [
    //   { text: this.props.translations.cancel, onClickFunction: buttonCancelFunction },
    //   { text: this.props.translations.delete, onClickFunction: buttonDeleteFunction },
    //   { text: this.props.translations.save2, onClickFunction: buttonSaveFunction }
    // ];

    const containerFooter = (
      this.state.action !== null &&
      <NewButtons
        buttonArray={this.state.buttonArray}
      >
      </NewButtons>
    )

    return (
      <MenuCard
        url={require("./images/senaria_orange_02.png")}
        title={this.props.translations.multiplePlan}
        containerFooter={containerFooter}
        menuCard={"menuCard scenarioCard"}
        cardTitle={"cardTitle cardTitleColor"}

      >
        {this.state.action !== null &&
          <div className="  onCreate">
            <div className='timeDiv'></div>
            <div className=" actionLabelContainer">
              <div></div>
              <div className="actionLabel">{this.props.translations.time}</div><div className="container end time"><Datetime
                id="time"
                timeFormat="HH:mm"
                dateFormat={false}
                onChange={(date) => { this.saveTime(date._d) }}
                value={this.state.action.time} /></div></div>
            <div className="container actionLabelContainer">
              <div className="actionLabel">{this.props.translations.date}</div><div className="container end date"><Datetime
                id="date"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                onChange={(date) => { this.saveDate(date._d) }}
                closeOnSelect={true}
                value={!this.state.action.repeat ? this.state.action.day.date : this.state.noDate} /></div></div>

            <div className="repeatLabelContainer">{this.props.translations.repeat}</div>
            <div className="daysArea actionLabelContainer"><NewActionDays day={this.state.action.day} dayClicked={this.dayClicked} translations={this.props.translations}></NewActionDays></div>
            <div className="actionLabel">{this.props.translations.devices}</div>
            <div className="devicesArea "><NewDevices buildingDevices={this.props.devices} devices={this.state.action.devices} deviceOnOff={this.deviceOnOff}></NewDevices></div>
            <div className="container actionLabelContainer"></div>
            {this.state.noDeviceSelected && <span id="nameError">{this.props.translations.noDeviceChosen}</span>}

            {this.state.action.id == "" && this.state.action.text == "" ? <div><div className="container actionLabelContainer"> <div className="actionLabel">{this.props.translations.scenarios}</div>
            </div>
              <div className="devicesArea">
                <NewSceneNames scenes={this.props.scenes} scene={this.state.scene} enableScene={this.enableScene}></NewSceneNames>
              </div>
            </div> :

              <div></div>}


          </div>}
      </MenuCard>

    )
  }
}
const options = {
  position: 'bottom-left',
  style: {
    marginBottom: '30px'
  }
}

export default withSnackbar(NewPorgramScene, options);