import { useEffect, useState } from "react";

function NewAccessMessage(props) {

    const [message, setMessage] = useState('');

    useEffect(()=>{
        switch (props.type) {
            case "scene":
                return setMessage(props.translations.newAccessScenario);
            case "proscene":
                return setMessage(props.translations.newAccessScenarioProgramming);
            case "command":
                return setMessage(props.translations.newAccessCommandsProgramming);
            case "camera":
                return setMessage(props.translations.newAccessCamera);
            case "alarm":
                return setMessage(props.translations.newAccessAlert);
            case "alert":
                return setMessage(props.translations.newAccessReminder);
            case "postit":
                return setMessage(props.translations.newAccessPostIt);
            default:
                return setMessage(props.translations.newAccessDot);
        }
    }, [props.type])



    return (
        <span className="accessMessage">{props.translations.newAccessNoAccess}{message}</span>
    )
}

export default NewAccessMessage;