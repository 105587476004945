import storage from "./Storage";
import { switchUrl, accessUrl, guestInvitesUrl } from "./urls";
import logger from './Components/Functions/logger';

export function updateSwitches(sw) {
	//Use to be like that
	//const buildingID = storage.getBuildingID();
	return storage
		.getBuildingID()
		.then((buildingID) => {
			const DATA = {
				method: "PUT",
				body: JSON.stringify({
					buildingID: buildingID,
					origin: 4,
					switch: sw,
				}),
			};
			return storage.FetchData(switchUrl + "/" + sw.id, DATA);
		})
		.catch((error) => {
			logger.warn(error);
		});
}

export function fetchSwitches() {
	return storage
		.getBuildingID()
		.then((buildingID) => {
			if (!buildingID) return;
			const DATA = {
				method: "GET",
			};
			return storage.FetchData(switchUrl + "/" + buildingID, DATA);
		})
		.catch((error) => {
			logger.warn(error);
			return Promise.reject(error);
		});
}

export function deleteSwitches(selectedSwitchID) {
	return storage
		.getBuildingID()
		.then((buildingID) => {
			const DATA = {
				method: "DELETE",
				body: JSON.stringify({
					buildingID: buildingID,
				}),
			};
			return storage.FetchData(switchUrl + "/" + selectedSwitchID, DATA);
		})
		.catch((error) => {
			logger.warn(error);
		});
}

export function insertSwitches(id, type, name) {
	return storage
		.getBuildingID()
		.then((buildingID) => {
			const DATA = {
				method: "POST",
				body: JSON.stringify({
					buildingID: buildingID,
					id: id,
					type: type,
					name: name,
					origin: 4,
				}),
			};
			return storage.FetchData(switchUrl + "/" + id, DATA);
		})
		.catch((error) => {
			logger.warn(error);
		});
}

//Send a post to server when accessing a page that requires login
/*export function acceptInvite(id, userEmail) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                buildingID: buildingID,
                email: userEmail,
                invite: id,
            })
        };
        return storage.FetchData(accessUrl, DATA);
    })
}*/

export function acceptGuestInvite(inviteID) {
	const DATA = {
		method: "PUT",
		body: JSON.stringify({
			invite: inviteID,
		}),
	};
	return storage.FetchData(guestInvitesUrl, DATA);
}

export default { updateSwitches, fetchSwitches };
