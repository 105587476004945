import {React,useState} from 'react';
import { ReactComponent as MaximizeWindow } from './images/maximizeWindow.svg'
import MenuCard from './MenuCard';
import { ReactComponent as VideoCall } from './images/closeButton.svg'
import logger from '../Functions/logger';

function CameraWindow(props) {


    const [menuCardCSS, setMenuCardCSS] = useState("cameraMenuCardMax")
    const [flag, setFlag] = useState(true);

    logger.log("myprops:", props);
    const toggleClasses = () => {
        setFlag(!flag);
        let cameraWindowContainer = document.getElementById("cameraWindowContainer");
        cameraWindowContainer.classList.toggle("cameraCardMin");
        flag ? setMenuCardCSS("cameraMenuCardMin") : setMenuCardCSS("cameraMenuCardMax")
        
    }

    const containerHeader = (

        <div className='functionalityButtons'>
            <div  style={{ paddingRight: '2vw' }}>
                <MaximizeWindow onClick={toggleClasses} style={{ width: '22px', height: '22px', cursor: 'pointer' }} />
            </div>
            <div onClick={props.closeModal} style={{ paddingRight: '.5vw', paddingTop: '0.3vh', cursor: 'pointer' }}>
                <VideoCall style={{ width: '15px', height: '15px' }} />
            </div>
        </div>
    );
    return (
        <div>
            <MenuCard
                url={require("./images/camera_orange_02.png")}
                title={props.title}
                buttons={containerHeader}
                menuCard={menuCardCSS}
                cardTitle={"cameraCardTitle"}
                cardHeaderBackColor={"cameraCardHeaderBackColor"}
            >
                <div>
                    <iframe
                        className="cameraPreview"
                        src={props.src}
                        frameBorder="0"
                        allowFullScreen
                        allow="microphone; camera"
                        scrolling='no'
                    />
                </div>
            </MenuCard>
        </div>

    )
}

export default CameraWindow;
