import React from 'react';
import { sceneActivate, sceneDeleteAll } from '../../ScenariosFunctions';
import { checkArrayLength } from '../Functions/helperFunctions';
import MenuCard from './MenuCard';
import { lightBlue } from '@material-ui/core/colors';
import NewButtons from './NewButtons';
import logger from '../Functions/logger';

class NewScenes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //newScenes: this.props.actions,
            scenes: this.props.scenes,
            activatedScenes: [],
        }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.scenes !== this.props.scenes) {
            this.setState({ scenes: this.props.scenes });
        }
    }
    renderScene = (scene) => {
        if (!(this.state.scenes.length > 0)) {
            logger.log("empty array:" + this.state.scenes.length);
        }
        //change scene to action
        if (this.props.id === "name") {
            /*if (typeof this.props.building !== "undefined") {
                let index = this.props.building.scenes.findIndex(scene => scene.text === scene.name);
                if (index > -1)
                    scene.enabled = this.props.building.scenes[index].enabled;
            }*/
            if (typeof this.props.scenes !== "undefined") {
                let index = this.props.scenes.findIndex(scene => scene.text === scene.name);
                if (index > -1)
                    scene.enabled = this.props.scenes[index].enabled;
            }
            logger.log("========");
            logger.log(scene[this.props.id]);
            // if the scene was clicked enabled gets added as true (orange so we know it was activated) and after 2 seconds it is set to false
            if (scene.enabled) {
                setTimeout(() => {
                    scene.enabled = false;
                    this.setState({ scene: scene });
                }, 3000);
            }



        }
        return <div className={scene.enabled ? "container sceneButton On" : "container sceneButton"} key={scene[this.props.label]} >
            {
                this.props.permission == "admin" &&
                <img onClick={() => { this.props.sceneClicked(scene.id) }} src={require("./images/edit_dark_blue_01.png")} ></img>
            }
            {/* <div className="textContainer" onClick={() => { this.props.sceneActivate(scene[this.props.id], scene.id) }}>
                {scene[this.props.label]}</div> */}
        </div>;
    }

    renderScenes = (scenes) => {
        return <div className="column scrollable sceneSizeButton">{
            scenes.map((aScene) => {
                return this.renderScene(aScene);
                //return <li key={aScene.name} onClick={() => this.activateScene(aScene)}>{aScene.name}</li>;
            })}</div>
    }

    deleteAllScns = () => {

        sceneDeleteAll()
            .then(
                this.props.removeFunction('all', 'scenario')
            ).then(
                this.props.openSnackbar(this.props.translations.deleteConfirmation)
            ).catch((err) => {
                logger.error(err);
                this.props.openSnackbar(this.props.translations.deleteFailure)
            });

    }

    confirmation = () => {
        if (confirm(this.props.translations.deleteQuestion)) {
            this.deleteAllScns()
        } else {
            logger.log("cancelled")
        }
    }

    sceneActivation = (sceneID, index) => {
        return sceneActivate(sceneID)
    }


    render() {

        const containerHeader = (
            <></>
        )

        // const containerFooter = (
        //     this.props.permission == 'admin' && <>
        //         <div className="sceneCreateButton sceneCreateButtonMulProgr" style={{ cursor: 'pointer' }} onClick={() => { this.props.sceneClicked("",20) }}>
        //             <div className="buttonArea">{this.props.new}</div>
        //             {/* <img src={this.state.menu == 1 ? require("./images/senaria_orange_02.png") : require("./images/senaria_dark_blue_01.png")} ></img> */}
        //         </div>
        //         {/* <div className="container sceneCreateButton space-between" onClick={this.confirmation} style={{ cursor: "pointer" }}>
        // //     </div> */}
        //     </>
        // )

        const buttonFunction = () => {
            this.props.sceneClicked("", 20)
        }

        const buttonArray = [{ text: this.props.new, onClickFunction: buttonFunction }]

        const containerFooter = (
         
                this.props.permission == 'admin' &&
                <NewButtons
                    buttonArray={buttonArray}
                    buttonScenarioCss={"buttonScenarioCss"}
                >
                </NewButtons>
        )
            

        


        return (
            this.props.scenes.length > 0 ? (<MenuCard
                url={require("./images/senaria_orange_02.png")}
                menuCard={"menuCard scenarioCard"}
                title={this.props.translations.scenarios}
                cardTitle={"cardTitle cardTitleColor"}
                containerHeader={containerHeader}
                containerFooter={containerFooter}
                cardHeaderBackColor={"cardHeader"}
            >
                <div className="column scrollable sceneSizeButton">
                    {this.props.scenes.map((scene, index) => (
                        <div className={`newScenesTable ${index % 2 === 1 ? 'oddRow' : 'evenRow'} ${this.state.activatedScenes.includes(scene.id) ? "activeScenario" : ""}`} onClick={() => {
                            this.sceneActivation(scene.id).then(() => {
                                this.state.activatedScenes.push(scene.id)
                                this.setState({ activatedScenes: this.state.activatedScenes })
                                setTimeout(() => {
                                    const index = this.state.activatedScenes.indexOf(scene.id);
                                    if (index > -1) { // only splice array when item is found
                                        this.state.activatedScenes.splice(index, 1); // 2nd parameter means remove one item only
                                        this.setState({ activatedScenes: this.state.activatedScenes })
                                    }
                                }, 3000)
                            })
                        }}
                            key={index}>
                            <div className='scenarioTableImageResize'>
                                {this.props.permission === "admin" ? <img onClick={() => { this.props.sceneClicked(scene.id, 20) }} src={require("./images/edit_dark_blue_01.png")} ></img> : <></>}
                            </div>
                            <div>
                                <span className="sceneName">{scene.name}</span>
                            </div>
                        </div>
                    ))}
                </div>

            </MenuCard >) : (
                <MenuCard

                    // cardContainer={"tableCss"}
                    menuCard={"menuCardMessage"}
                    cardHeaderBackColor={"cardHeader menuCardMessageHeader"}
                    cardContainer={"cardContainerMessage"}
                    title={this.props.translations.scenarios}
                    cardTitle={"cardTitle"}
                    containerFooter={containerFooter}
                >
                    <div style={{paddingBlock:'18%'}}>
                        {this.props.translations.thereAreNoText1 + " " + this.props.translations.scenarios.toLowerCase() + " " + this.props.translations.thereAreNoText2}
                    </div>
                </MenuCard>
            )
        )

    }
}

export default NewScenes;