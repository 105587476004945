import React from 'react';
import './InfoWindowsLayout.css';
import {confirmEmail} from '../Functions/userFunctions';

const successImg = require('../../images/success_access.png');
const unsuccessImg = require('../../images/unsuccess_access.png');

class ConfirmEmail extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            confirm: "Not confirmed",
            title: "",
            resultText: "",
            image: null
        }
    }

    componentDidMount(){
        let params = {}
        params = this.props.confirmParams();
        if(params.confirm != null){
            this.ConfirmEmail(params.confirm);
        }else{
            this.setState({title: "ΛΑΘΟΣ ΣΥΝΔΕΣΜΟΣ", resultText:"Ο σύνδεσμος που καταχωρήθηκε είναι λάθος", image: unsuccessImg});
        }

    }

    ConfirmEmail = (id) => {
        confirmEmail(id).then((responseData) =>{
            this.setState({title:"ΟΛΟΚΛΗΡΩΣΗ ΕΠΙΒΕΒΑΙΩΣΗΣ!", resultText: "Το email σας επιβεβαιώθηκε με επιτυχία.", image: successImg});
        }).catch((error) => {
            this.setState({title:"ΚΑΤΙ ΠΗΓΕ ΣΤΡΑΒΑ!", resultText: "Η επιβεβαίωση του emails σας απέτυχε.", image: unsuccessImg});
        });
    }

    ChangePage = () => {
        window.location.assign("/guide");
    }

    render(){
        return(
            <div className="inviteElementContainer">
                <div className="header">
                    <div className="logoContainer">
                        <img src={require("./images/housemate_logo_01.png")}></img>
                    </div>
                    
                </div>
                <div className='mainBody'>
                    <div className="infoWindow">
                        <div className='statusImage'>
                            <img src={this.state.image}></img>
                        </div>
                        <div className="resultContainer">
                            <h1>{this.state.title}</h1>
                            <div>{this.state.resultText}</div>
                        </div>
                        <div className="returnBtn" onClick={()=> this.ChangePage()}>
                            <span>ΣΥΝΕΧΕΙΑ</span>
                        </div>
                    </div>
                </div>
            
            </div>
        )
    }
}

export default ConfirmEmail;