import React, { useEffect } from 'react';
//import '../css/Clock.css';
import QRCode from 'qrcode';
import '../css/QRCode.css';
import logger from './Functions/logger';


function QRcode(props) {

  useEffect(() => {
    let canvas = document.getElementById('canvas');
    QRCode.toCanvas(canvas, props.code, function (error) {
      if (error) logger.error(error)
    })
  }, [props.code])

  return (
    <div className='QrContainer'
    >
      <canvas id="canvas"></canvas>
      {/* <qr-code
        id="qr1"
        contents={props.code}
        // module-color="#1c7d43"
        // position-ring-color="#13532d"
        // position-center-color="#70c559"
        mask-x-to-y-ratio="1.2"
        style={{ borderRadius: '10px', width: '170px', height: '170px', backgroundColor:'white' }}
      >
        <img src={''} slot="Housemate" />
      </qr-code> */}
    </div>
  );
}
export default QRcode;
