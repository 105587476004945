
import { React, useState, useEffect, useRef } from "react";
import { ReactComponent as WeatherIcon } from './images/main_ic_settings_weather.svg'
import { ReactComponent as TimeZoneIcon } from './images/main_ic_settings_timezone.svg'
import { ReactComponent as NewsIcon } from './images/main_ic_settings_news.svg'
import { ReactComponent as LanguageIcon } from './images/main_ic_settings_languange.svg'
import { ReactComponent as KeyowrdIcon } from './images/keyword.svg'
import '../../css/GeneralInformation.css'
import SetLocationForWeather from "./SetLocationForWeather.js"
import NumberOfNews from "./NumberOfNews.js"
import ChangeTabletLanguage from "./ChangeTabletLanguage.js"
import GooglePlacesInput from "./GooglePlacesInput.js"
import GooglePlacesAutocomplete from "./GooglePlacesAutocomplete.js";
import availableLanguages from "../../tabletLanguage/tabletLanguages.json"
import ChangeKeyword from "./ChangeKeyword.js";
import ModalView from "./ModalView";
import MenuCard from './MenuCard';
import logger from '../Functions/logger';



function GeneralInformation(props) {

    const [renderCurrentTimeZone, setRenderCurrentTimeZone] = useState(props.selectedBuilding?.settings?.region);
    const [timeZoneModal, setTimeZoneModal] = useState(false);
    const [locationModal, setLocationModal] = useState(false);
    const [tabletLanguagesModal, setTabletLanguagesModal] = useState(false);
    const [currentTabletLanguage, setCurrentTabletLanguage] = useState('');
    const [currentCityModal, setCurrentCityModal] = useState(false);
    const [insertedLocation, setInsertedLocation] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(props.selectedBuilding?.settings?.city);
    const [currentNumberOfNews, setCurrentNumberOfNews] = useState(1);
    const [changeKeywordModal, setChangeKeywordModal] = useState(false);
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const wrapperRefLocation = useRef(null);
    // useOutsideAlerterLocation(wrapperRefLocation);
    const wrapperRefTimeZone = useRef(null);
    useOutsideAlerterTimeZone(wrapperRefTimeZone);
    const wrapperRefLanguage = useRef(null);
    useOutsideAlerterLanguages(wrapperRefLanguage);
    const wrapperRefKeyword = useRef(null);
    useOutsideAlerterKeyword(wrapperRefKeyword);
    const wrapperRefLoc = useRef(null);
    useOutsideAlerterLocation(wrapperRefLoc);



    function useOutsideAlerterLoc(ref) {

        useEffect(() => {

            function handleClickOutsideDiv(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setLocationModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutsideDiv);

            return () => {
                document.removeEventListener("mousedown", handleClickOutsideDiv);
            }
        }, [ref])

    }

    function useOutsideAlerterTimeZone(ref) {

        useEffect(() => {

            function handleClickOutsideDiv(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setTimeZoneModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutsideDiv);

            return () => {
                document.removeEventListener("mousedown", handleClickOutsideDiv);
            }
        }, [ref])

    }

    function useOutsideAlerterLocation(ref) {
        useEffect(() => {

            function handleClickOutsideDiv(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setCurrentCityModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutsideDiv);

            return () => {
                document.removeEventListener("mousedown", handleClickOutsideDiv);
            }
        }, [ref])

    }

    function useOutsideAlerterLanguages(ref) {
        useEffect(() => {

            function handleClickOutsideDiv(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setTabletLanguagesModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutsideDiv);

            return () => {
                document.removeEventListener("mousedown", handleClickOutsideDiv);
            }
        }, [ref])

    }

    function useOutsideAlerterKeyword(ref) {
        useEffect(() => {

            function handleClickOutsideDiv(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setChangeKeywordModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutsideDiv);

            return () => {
                document.removeEventListener("mousedown", handleClickOutsideDiv);
            }
        }, [ref])

    }

    useEffect(() => {
        var tabletLanguagesParsed = availableLanguages?.data;
        // logger.log("selectedBuilding:", props.selectedBuilding);
        // logger.log("the language:", tabletLanguagesParsed);
        let language = tabletLanguagesParsed.find(lang => {
            return lang.key == props.selectedBuilding?.settings?.language
        })

        if (language) {
            setCurrentTabletLanguage(language.text)
            // logger.log("the language:", language);
        }
        setRenderCurrentTimeZone(props.selectedBuilding?.settings?.region);
        setCurrentLocation(props.selectedBuilding?.settings?.city);
    }, [props.selectedBuilding])

    useEffect(() => {
        setInsertedLocation(insertedLocation);
    }, [insertedLocation])

    // logger.log("Location:", insertedLocation);


    return (
        <ModalView open={isOpen} close={() => {
            setIsOpen(false);
            props.currentButtonSelected(0);
        }} sizeStyle={{ width: "40%", height: "68%" }} padding={false}>
            <MenuCard
                url={require("./images/general_info.png")}
                title={props.translations.generalInformation}
                menuCard={"generalInfoCard"}
                cardTitle={"cardTitle"}
                cardHeaderBackColor={"cardHeader energyModalBold"}
            >
                <div className="generalContainer scrollable">

                    {
                        currentCityModal &&
                        <div className="generalOverlay">
                            <div className="locationDiv" style={{ marginTop: "20%" }} >
                                <GooglePlacesAutocomplete translations={props.translations} insertedLocation={setCurrentLocation} setCurrentCityModal={setCurrentCityModal} />
                            </div>
                        </div>
                    }
                    {
                        timeZoneModal &&
                        <div className="generalOverlay">
                            <div className="timeZoneDiv" ref={wrapperRefTimeZone}>
                                <SetLocationForWeather setTimeZoneModal={setTimeZoneModal} setRenderCurrentTimeZone={setRenderCurrentTimeZone} timezone={renderCurrentTimeZone} translations={props.translations} />
                            </div>
                        </div>
                    }
                    {
                        tabletLanguagesModal &&
                        <div className="generalOverlay">
                            <div className="languageDiv" ref={wrapperRefLanguage}>
                                <ChangeTabletLanguage setTabletLanguagesModal={setTabletLanguagesModal} setCurrentTabletLanguage={setCurrentTabletLanguage} translations={props.translations} currentTabletLanguage={currentTabletLanguage} />
                            </div>
                        </div>
                    }
                    {
                        changeKeywordModal &&
                        <div className="generalOverlay">
                            <div className="changeKeyw" ref={wrapperRefKeyword}>
                                <ChangeKeyword selectedBuilding={props.selectedBuilding} newDevices={props.newDevices} translations={props.translations} />
                            </div>
                        </div>
                    }
                    <div className="gridContainer">
                        <div className="gridItem" onClick={() => { setTabletLanguagesModal(true) }} style={{ cursor: "pointer" }}>
                            <div className="grid-icon">
                                <LanguageIcon style={{ height: '40px', width: '40px' }} />
                                <div>{props.translations.tabletLanguageCaps}</div>
                            </div>
                            <div className="grid-textDiv">
                                <span className="grid-textDiv selectedValueGeneralInfo" style={{ cursor: 'pointer ', paddingLeft: "15px" }}>{currentTabletLanguage.toString()}</span>
                            </div>
                        </div>
                        <div className="gridItem" onClick={() => { setChangeKeywordModal(true) }} style={{ cursor: "pointer" }}>
                            <div className="grid-icon">
                                <KeyowrdIcon style={{ height: '40px', width: '40px' }} />
                                <div style={{ cursor: 'pointer', paddingLeft: "15px", paddingBottom: "40px" }}>{props.translations.changeKeyword}</div>
                            </div>
                        </div>
                        <div className="gridItem" onClick={() => { setCurrentCityModal(true) }} style={{ cursor: "pointer" }}>
                            <div className="grid-icon">
                                <WeatherIcon style={{ height: '40px', width: '40px' }} />
                                <div>{props.translations.weather}</div>
                            </div>
                            <div className="grid-textDiv">
                                <div className="grid-title  selectedValueGeneralInfo">{currentLocation}</div>
                                <span style={{ fontSize: "12px", textAlign: "left", paddingLeft: "15px", width: "85%" }}>{props.translations.weatherText_1}{props.translations.weatherText_2}</span>
                            </div>
                        </div>
                        <div className="gridItem" onClick={() => { setTimeZoneModal(true) }} style={{ cursor: "pointer" }}>
                            <div className="grid-icon">
                                <TimeZoneIcon style={{ height: '40px', width: '40px' }} />
                                <div>{props.translations.timeZoneCaps}</div>
                            </div>
                            <div className="grid-textDiv">
                                <div className="grid-title selectedValueGeneralInfo">{renderCurrentTimeZone}</div>
                                <span style={{ fontSize: "12px", textAlign: "left", paddingLeft: "15px", width: "85%" }}>{props.translations.timeZoneText_1}{props.translations.timeZoneText_2}</span>
                            </div>
                        </div>
                        <div className="gridItem">
                            <div className="grid-icon">
                                <NewsIcon style={{ height: '40px', width: '40px' }} />
                                <div>{props.translations.news}</div>
                            </div>
                            <div className="grid-textDiv">
                                <span style={{ fontSize: "12px", textAlign: "left", paddingLeft: "15px", width: "85%" }}>{props.translations.newsText}</span>
                                <NumberOfNews currentNumberOfNews={currentNumberOfNews} setCurrentNumberOfNews={setCurrentNumberOfNews} />
                            </div>
                        </div>

                    </div>

                </div>
            </MenuCard>
        </ModalView>
    )
}

export default GeneralInformation;