import storage from './Storage';
import { sceneUrl } from './urls'; 
import logger from './Components/Functions/logger';


export function fetchScenes() {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'GET',
        };
        return storage.FetchData(sceneUrl + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function sceneInsert(name, switches, thermostats) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                switches: switches,
                thermostats: thermostats
            }),
        };
        return storage.FetchData(sceneUrl + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function sceneUpdate(name, switches, thermostats, scenarioID) {
    
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                buildingID: buildingID,
                name: name,
                switches: switches,
                thermostats: thermostats,
                origin: 4,
            }),
        };
        return storage.FetchData(sceneUrl + '/' + scenarioID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function sceneActivate(scenarioID) {
    logger.log("This is scenario ID");
    logger.log(scenarioID);
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                buildingID: buildingID,
                origin: 4,
            }),
        };
        return storage.FetchData(sceneUrl + '/activate/' + scenarioID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function sceneDelete(scenarioID) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'DELETE',
            body: JSON.stringify({
                buildingID: buildingID,
            }),
        };
        return storage.FetchData(sceneUrl + '/' + scenarioID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function sceneDeleteAll() {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'DELETE',
            body: JSON.stringify({
                buildingID: buildingID,
            }),
        };
        return storage.FetchData(sceneUrl, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

//export default {fetchScenes}