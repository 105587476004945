import React from 'react';
import NewOnOff from './NewOnOff';
import RadioButtons from './RadioButtons';
import logger from '../Functions/logger';

class NewDevices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDeviceID: null,
            selectedDeviceName: ''
        };
    }

    componentDidMount() {
        const { buildingDevices } = this.props;
        if (buildingDevices.length > 0) {
            const reversedDevices = buildingDevices.slice().reverse();
            const initialDeviceID = reversedDevices[0].id;
            const initialDeviceName = reversedDevices[0].name;
            this.setState({ 
                selectedDeviceID: initialDeviceID,
                selectedDeviceName: initialDeviceName 
            });
            this.props.deviceOnOff(true, initialDeviceID);
        }
    }

    deviceOnOff = (on, deviceID) => {
        logger.log("device id for off: ", deviceID);
        this.props.deviceOnOff(on, deviceID);
    }

    deviceExists = (deviceID) => {
        logger.log("props devices: ", this.props.devices);
        logger.log("id: ", deviceID);
        let device = this.props.devices.findIndex(device => device === deviceID);
        logger.log("device: ", device);
        return device > -1;
    }

    handleRadioChange = (device) => {
        logger.log("device:", device);
        this.setState({
            selectedDeviceID: device.id,
            selectedDeviceName: device.name
        });
        this.deviceOnOff(true, device.id);
    }

    renderDevices = (devices) => {
        return (
            <div className="container column">
                {devices.slice().reverse().map(device => (
                    <div key={device.id} style={{ color: this.state.selectedDeviceName === device.name ? '#F69322' : 'black' }}>
                        <RadioButtons
                            name={device.name}
                            generalRadioContainer={true}
                            checked={this.state.selectedDeviceID === device.id}
                            handleRadioChange={() => { this.handleRadioChange(device) }}
                        />
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            this.renderDevices(this.props.buildingDevices)
        );
    }
}

export default NewDevices;
