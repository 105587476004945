import canPromise from "qrcode/lib/can-promise";
import React from "react";
import "./InfoWindowsLayout.css";
import { passwordRules } from "../../validation";
import { resetPassword } from "../Functions/userFunctions";
import md5 from "md5";

const resetPassMain = require("../../images/reset_password.png");
class ResetPass extends React.Component {
	state = {
		title: "",
		result: "",
		image: resetPassMain,
		resetPassword: "",
		resetPasswordConfirm: "",
		showPass: false,
		showPassConfirm: false,
	};

	constructor(props) {
		super(props);
		this.onFocus = this.onFocus.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showPassword = this.showPassword.bind(this);
		this.showPasswordConfirm = this.showPasswordConfirm.bind(this);
		this.resetPasswordRef = this.updateRef.bind(this, "resetPassword");
		this.resetPasswordConfirmRef = this.updateRef.bind(
			this,
			"resetPasswordConfirm"
		);
	}

	// When you press button on password go to confirm password.
	onSubmitNewPassword() {
		this.confirmPassword.focus();
	}

	// When you press button on confirm password close keyboard.
	onSubmitConfirmPassword() {
		this.confirmPassword.blur();
	}

	// Set reference to name.
	updateRef(name, ref) {
		this[name] = ref;
	}

	//
	onFocus() {
		let { errors = {} } = this.state;

		for (let name in errors) {
			let ref = this[name];

			if (ref) {
				delete errors[name];
			}
		}
		this.setState({ errors });
	}

	//Check password for errors and submit new password
	onSubmit = async () => {
		var errors = [];
		let value = this.state.resetPassword;
		let valueConfirm = this.state.resetPasswordConfirm;

		if (passwordRules(value, valueConfirm)) {
			let params = {};
			params = this.props.resetParams();
			if (params.t != null) {
				let resetPasswordHashed = md5(this.state.resetPassword);
				resetPassword(params.t, resetPasswordHashed)
					.then((responseData) => {
						this.state.resetPassword = "";
						this.state.resetPasswordConfirm = "";

						this.ChangePage();
					})
					.catch((error) => {
						if (error === "Invalid Token") {
							errors.push("Λανθασμένο token");
							this.setState({ errors });
						}
					});
			} else {
				errors.push("Άδειο token");
			}
		} else {
			errors = passwordRules(value, valueConfirm);
			this.setState({ errors });
		}
	};

	/*Return to login or home after reseting password*/
	ChangePage = () => {
		this.props.setSelected(0);
		window.location.assign("/");
	};

	//Handle changes on texts
	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	//Show password characters for password field
	showPassword() {
		let newToogle = !this.state.showPass;
		this.setState({ showPass: newToogle });
	}

	//Show confirm password characters for confirm password field
	showPasswordConfirm() {
		let newToogle = !this.state.showPassConfirm;
		this.setState({ showPassConfirm: newToogle });
	}

	render() {
		const {
			errors = [],
			resetPassword,
			resetPasswordConfirm,
			...data
		} = this.state;

		return (
			<div className="inviteElementContainer scrollable">
				<div className="headerResetPass">
					<div className="logoContainer">
						<img
							src={require("./images/housemate_logo_01.png")}
						></img>
					</div>
				</div>
				<div className="mainBody">
					<div className="resetInfoWindows">
						<div className="statusImage">
							<img src={this.state.image}></img>
						</div>
						<div className="resetResultContainer">
							<h2>ΕΠΑΝΑΦΟΡΑ ΚΩΔΙΚΟΥ</h2>
							<div className="passContainer">
								<input
									name="resetPassword"
									className="repass"
									type={
										this.state.showPass
											? "text"
											: "password"
									}
									placeholder="Νέος κωδικός"
									onChange={this.handleChange}
									value={data.resetPassword}
									maxLength={18}
									ref={this.resetPasswordRef}
									onFocus={this.onFocus}
								></input>
								<button
									className="showPassBtn"
									onClick={this.showPassword}
								>
									<img
										className="showHideElement"
										src={
											this.state.showPass
												? require("../../images/eye_on.png")
												: require("../../images/eye_off.png")
										}
									></img>
								</button>
							</div>
							<div className="passContainer">
								<input
									name="resetPasswordConfirm"
									className="repass"
									type={
										this.state.showPassConfirm
											? "text"
											: "password"
									}
									placeholder="Επιβεβαίωση κωδικού"
									onChange={this.handleChange}
									value={data.resetPasswordConfirm}
									maxLength={18}
									ref={this.resetPasswordConfirmRef}
									onFocus={this.onFocus}
								></input>
								<button
									className="showPassBtn"
									onClick={this.showPasswordConfirm}
								>
									<img
										className="showHideElement"
										src={
											this.state.showPassConfirm
												? require("../../images/eye_on.png")
												: require("../../images/eye_off.png")
										}
									></img>
								</button>
							</div>
							{/*We need better errors layout in order to upload it*/}
							<ul className="errorContainer">
								{errors.length > 0
									? errors.map((error, index) => {
											return <li key={index}>{error}</li>;
									  })
									: null}
							</ul>
						</div>
						<div className="returnBtn" onClick={this.onSubmit}>
							<span>ΕΠΑΝΑΦΟΡΑ</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ResetPass;
