/**
 * API urls
 */
export const server_address = "https://hm-api-v2.entranet.us:7656";
// export const server_address = "http://192.168.1.70:8080";

export const login = `${server_address}/main/user/login`;
export const fcmUrl = `${server_address}/main/user/fcm`;
export const register = `${server_address}/main/user/register`;
export const messagesUrl = `${server_address}/main/user/message`;
export const changeKeyword = `${server_address}/main/settings/changeKeyword`;
export const confirmEmailUrl = `${server_address}/main/user/confirmation`;
export const deleteUserUrl = `${server_address}/main/user`;
export const resetPasswordUrl = `${server_address}/main/user/resetPassword`;

export const getBuildingsForUser = `${server_address}/main/building`;
export const activateBuilding = `${server_address}/main/building/activateBuilding`;

export const switchUrl = `${server_address}/housemate/home/switch`;
export const thermostatUrl = `${server_address}/housemate/home/thermostat`;

export const sceneUrl = `${server_address}/housemate/home/scene`;

export const requestVideoCall = `${server_address}/housemate/video/requestVideoCall`;
export const requestVideoFeed = `${server_address}/housemate/video/requestVideoFeed`;
export const revokeVideoFeed = `${server_address}/main/misc/revokeVideoFeed`;

export const actionsUrl = `${server_address}/housemate/action`;

export const guestUrl = `${server_address}/main/building/guest`;

export const settingsUrl = `${server_address}/housemate/settings`;

export const newsUrl = `${server_address}/housemate/news`;
export const buildingAddDevice = `${server_address}/main/building/device`;
export const getBuildingDevicesUrl = `${server_address}/main/building/devices`;
export const guestInvitesUrl = `${server_address}/main/building/invite`;

export const accessUrl = `${server_address}/main/building/invite`;
export const leaveHome = `${server_address}/main/building/userLeave`;
export const renameSwitch = `${server_address}/housemate/home/switch/name`;
export const insertSwitch = `${server_address}/housemate/home/devices/newSwitchTestInsert`

export const allerRetourDevicesUrl = `${server_address}/housemate/home/allerRetourDevices`

export const renameHomeBuilding = `${server_address}/main/building/name`;
export const deleteEmailUrl = `${server_address}/main/user/delete/email`;
export const changeTabletLanguage = `${server_address}/main/device/setDeviceLanguageFromMobile`;
export const energyUrl = `${server_address}/housemate/settings/energy`;

//export default {urls};
