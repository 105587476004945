import React from 'react';
import './GuideAfterConfirmEmail.css';

class GuideAfterConfirmEmail extends React.Component{

    ChangePage = () => {
        window.location.assign("/");
    }

    render() {
        return (
            <div className='guideElementContainer'>
                <div className="headerContainer">
                    <div className='resizeStyleCommon resizeStyleLogo' >
                        <img style={{objectFit: "contain", maxWidth: "60%"}} src={require("./images/housemate_logo_01.png")}></img>   
                    </div>
                    <div className='resizeStyleCommon guideLogoContainer'>
                        <p className='guideMobileDescriptionText guideMobileLogoText'>ΣΥΝΔΕΣΗ ΣΤΟΝ ΛΟΓΑΡΙΑΣΜΟ HOUSEMATE ΜΕΣΩ ΤΗΣ ΕΦΑΡΜΟΓΗΣ ΚΙΝΗΤΟΥ</p>
                    </div>
                </div>
                <div className="mainBodyGuide">
                    <div className='resizeStyleCommon resizeStyleMiddle' >
                        <img style={{objectFit: "contain", height:"105%"}} src={require('./images/guide_mobile_login_01.png')} />  
                    </div>
                    <div className='resizeStyleCommon resizeStyleMiddle rotate'>
                        <img style={{objectFit: "contain", maxWidth: "45%"}} src={require('./images/guide_mobile_arrow_01.png')} /> 
                    </div>
                    <div className='resizeStyleCommon resizeStyleMiddle resizeStyleDescription'>
                        <img style={{objectFit: "contain", maxWidth: "45%"}} src={require('./images/guide_mobile_login_02.png')} />
                        <h3 className='guideMobileDescriptionText'>
                            Στην εφαρμογή <b>housemate</b>
                            <br/>
                            επιλέξτε <b>ΣΥΝΔΕΣΗ</b> και στην συνέχεια
                            <br/>
                            <b>ΕΙΣΑΓΕΤΕ ΤΑ ΣΤΟΙΧΕΙΑ ΣΑΣ:</b>
                            <br/>
                            Ηλεκτρονική διεύθυνση και Κωδικό
                        </h3>
                        <img style={{objectFit: "contain", maxWidth: "50%"}} src={require('./images/guide_mobile_login_03.png')} />
                        <h3 className='guideMobileDescriptionText'>
                            Πατήστε <b>ΣΥΝΔΕΣΗ</b>
                            <br/>
                            για να ολοκληρωθεί η διαδικασία
                        </h3>
                    </div>
                </div>
            </div>
        )
    }
}

export default GuideAfterConfirmEmail;