import React, {useState} from "react";
import ModalView from "./ModalView";
import NewAccount from "./NewAccount";

function AccountModal(props) {
    const [openModal, setOpenModal] = useState(props.isOpen);

    return (
        <ModalView open={openModal} close={() => {
            setOpenModal(false);
            props.currentButtonSelected(0);
        }}sizeStyle={{width: "35%", height: "73%"}}  padding={true}>
            <NewAccount openSnackbar={props.openSnackbar} setUpBuilding={props.setUpBuilding} translations={props.translations}>
            </NewAccount>
        </ModalView>
    )

}

export default AccountModal;