import React from 'react';
import { deleteAllActions } from '../../ActionFunctions';
//import { withSnackbar } from 'react-simple-snackbar'
import NewAccessMessage from './NewAccessMessage';
import { checkArrayLength } from '../Functions/helperFunctions';
import MenuCard from './MenuCard';
import NewOnOff from './NewOnOff';
import NewButtons from './NewButtons';
import logger from '../Functions/logger';

class NewAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newActions: this.props.actions,
        }
        this.buttonNewActionFunction = this.buttonNewActionFunction.bind(this);
        this.buttonArray = [
            { text: this.props.new, onClickFunction: this.buttonNewActionFunction },
        ]
    }


    componentDidUpdate(nextProps) {
        if (nextProps.newActions !== this.props.newActions) {
            this.setState({ newActions: this.props.newActions });
        }
        logger.log("this.props.new:", this.props.new);
    }

    displayDays = (item) => {

        let text = ''
        let days = item.day;

        if (!days) {
            return;
        }
        let array = Object.keys(days).filter(key => {
            return days[key] === true;
        });
        array = this.sortArrayByDay(array);
        if (array.length > 0) {
            //TODO when days are continious show them like: Monday - Thursday
            array.forEach((day, index) =>
                index < array.length - 1
                    ? (text += day + ', ')
                    : (text += day),
            );
        } else {
            text = this.props.translations.oneTime;
        }
        return (
            <div>{text}</div>
        )
    }

    sortArrayByDay = (array) => {
        const sorter = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };
        array.sort(function sortByDay(a, b) {
            let day1 = a.toLowerCase().substring(0, 3);
            let day2 = b.toLowerCase().substring(0, 3);
            return sorter[day1] - sorter[day2];
        });
        let tempArray = [];
        array.forEach(x => {
            tempArray.push(this.props.translations.days_locale[x]);
        });
        return tempArray;
    };


    renderAction = (action, index) => {
        logger.log("hello alarm");
        return (
            <div
                className="commandProgrammingTable"
                key={action.id}
            >
                <div className='scenarioTableImageResize'>
                    {this.props.permission === "admin" && (
                        <img
                            onClick={() => this.props.actionTest(action.id)}
                            src={require("./images/edit_dark_blue_01.png")}
                            alt="Edit"
                        />
                    )}
                </div>
                <div
                    className='commandProgrammingRow'
                    onClick={() => this.props.sceneActivate(action[this.props.id], this.props.id)}
                >
                    <div>
                        <div className="sceneNameExtra">
                            {action[this.props.label]}
                            {action.repeat ?
                                <div className="actionRepeat">
                                    {this.displayDays(action)}
                                </div>
                                :
                                <div className="actionRepeat">{this.props.translations.oneTime}</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="toggleContainerExtra">
                    <NewOnOff
                        isEnabled={action.enabled}
                        OnOff={() => { this.props.sceneActivate(action[this.props.id], this.props.id) }}
                    ></NewOnOff>
                </div>
            </div>
        )


        // <div className={action.enabled ? " sceneButton On" : "container sceneButton"} key={action[this.props.id]} >
        //     {
        //         this.props.permission == "admin" &&
        //         <img onClick={() => { this.props.actionTest(action.id) }} src={require("./images/edit_dark_blue_01.png")} ></img>
        //     }
        //     <div className="textContainer" onClick={() => { this.props.sceneActivate(action[this.props.id], this.props.id) }}>
        //         {action[this.props.label]}</div>
        // </div>;
    }

    renderActions = (actions) => {
        return actions.map((aAction, index) => {
            return (<div className='proSceneDivColor'> {this.renderAction(aAction, index)} </div>)
        })

    }
    deleteAllActs = () => {

        deleteAllActions(this.props.type)
            .then(
                this.props.removeFunction('all', 'action')
            ).then(
                this.props.openSnackbar(this.props.translations.deleteConfirmation)
            ).catch((err) => {
                logger.error(err);
                this.props.openSnackbar(this.props.translations.deleteFailure)
            });

    };

    // typeOfAction = () => {
    //     switch (this.props.type) {
    //         case "command":

    //                 this.renderActions(this.props.actions);

    //         case "alarm":
    //            logger.log("You have alarms");
    //                 this.renderActions(this.props.actions);

    //         case "alert":

    //                 this.renderActions(this.props.actions);

    //         default:
    //             break;
    //     }
    // }

    buttonNewActionFunction = () => {
        this.props.actionTest("");
    }



    buttonArrayEmpty = [

        { text: this.props.new, onClickFunction: this.buttonNewActionFunction },

    ]

    containerFooterEmpty = (
        this.props.permission == "admin" && <>
            <NewButtons
                buttonArray={this.buttonArrayEmpty}
            >
            </NewButtons>
        </>
    )

    typeOfActionEmptyArray = () => {
        switch (this.props.type) {
            case "command":
                return (
                    <MenuCard
                        menuCard={"menuCardMessage"}
                        cardHeaderBackColor={"cardHeader menuCardMessageHeader"}
                        cardContainer={"cardContainerMessage"}
                        title={this.props.translations.command}
                        containerFooter={this.containerFooterEmpty}
                        cardTitle={"cardTitle"}
                    >
                        <div style={{ paddingBlock: '25%' }}>
                            {this.props.translations.thereAreNoText1 + " " + this.props.translations.command.toLowerCase() + " " + this.props.translations.thereAreNoText2}
                        </div>
                    </MenuCard>
                )
            case "alarm":
                return (
                    <MenuCard
                        menuCard={"menuCardMessage"}
                        cardHeaderBackColor={"cardHeader menuCardMessageHeader"}
                        cardContainer={"cardContainerMessage"}
                        title={this.actionTitle()}
                        containerFooter={this.containerFooterEmpty}
                        cardTitle={"cardTitle"}
                    >
                        <div style={{ paddingBlock: '25%' }}>
                            {this.props.translations.thereAreNoText1 + " " + this.props.translations.alarmsStr.toLowerCase() + " " + this.props.translations.thereAreNoText2}
                        </div>
                    </MenuCard>
                )
            case "alert":
                return (
                    <MenuCard
                        menuCard={"menuCardMessage"}
                        cardHeaderBackColor={"cardHeader menuCardMessageHeader"}
                        cardContainer={"cardContainerMessage"}
                        title={this.props.translations.reminders}
                        containerFooter={this.containerFooterEmpty}
                        cardTitle={"cardTitle"}
                    >
                        <div style={{ paddingBlock: '25%' }}>
                            {this.props.translations.thereAreNoText1 + " " + this.props.translations.reminders.toLowerCase() + " " + this.props.translations.thereAreNoText2}
                        </div>
                    </MenuCard>
                )
            default:
                break;
        }
    }


    confirmation = () => {
        if (confirm(this.props.translations.deleteQuestion)) {
            this.deleteAllActs()
        } else {
            logger.log("cancelled")
        }
    }

    actionTitle = () => {
        let actionTitle = '';
        logger.log("TYPE:", this.props.type);
        switch (this.props.type) {
            case "command":
                actionTitle = this.props.translations.multiplePlan;
                logger.log("actionTitle:", actionTitle);
                break;
            case "alarm":
                actionTitle = this.props.translations.alarmsStr;
                logger.log("actionTitle:", actionTitle);
                break;
            case "alert":
                actionTitle = this.props.translations.reminder;
                logger.log("actionTitle:", actionTitle);
                break;
            default:
                logger.log("actionTitle: No matching type");
                break;
        }
        return actionTitle;
    }





    render() {

        // const containerHeader = (
        //     <></>
        // )


        // const buttonDeleteAllActionsFunction = () => {
        //     this.deleteAllActs();
        // }


        const containerFooter = (
            this.props.permission == "admin" && <>
                <NewButtons
                    buttonArray={this.buttonArray}
                >
                </NewButtons>
            </>
        )


        if (this.props.permission == "guest") {
            return (
                <MenuCard
                    menuCard={"menuCardMessage"}
                    cardHeaderBackColor={"cardHeader menuCardMessageHeader"}
                    cardContainer={"cardContainerMessage"}
                    cardTitle={"cardTitle"}
                    title={this.actionTitle()}
                >
                    <div style={{ paddingBlock: '13%', paddingInline: '15%', fontSize: 'large' }}>
                        {this.props.translations.noPermissionToService}
                    </div>
                </MenuCard>
            )
        }

        if (this.props.actions.length <= 0) {
            return this.typeOfActionEmptyArray()
        }

        return (
            <MenuCard
                url={require("./images/senaria_orange_02.png")}
                title={this.actionTitle()}
                cardHeaderBackColor={"cardHeader"}
                containerFooter={containerFooter}
                menuCard={"menuCard scenarioCard"}
                cardTitle={"cardTitle cardTitleColor"}
            >
                <div className=" column">
                    {this.renderActions(this.props.actions)}
                </div>
            </MenuCard>)
    }
}

// const options = {
//     position: 'bottom-left',
//     style: {
//       marginBottom: '30px'
//     }
//   }
export default NewAction;