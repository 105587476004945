import React, { Component } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styled from 'styled-components';
//import {TileLayer, Marker, Popup} from 'react-leaflet';
import edit from '../images/edit.png';
import all_engineers from '../Engineers.json';
import axios from 'axios'
import md5 from 'md5'

const Wrapper = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
`

class LeafletMapShower extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            posts: this.props.results,
            firstname: this.props.firstname,
            lastname: this.props.lastname,
            phone: this.props.phone,
            city: this.props.city,
            country: this.props.country,
            addrName: this.props.addrName,
            addrNum: this.props.addrNum,
            AFM: this.props.afm,
            DOY: this.props.doy,
            PROFESSION: this.props.profession,
            loginEmail: this.props.email,
            loginPassword: this.props.password
        };
    }

    componentDidMount(){
        this.map = L.map('map', {
            center: [40.634781, 22.943090],
            zoom: 8,
            zoomControl: true
        });
        
        var engineer = L.icon({
            iconUrl: edit,
            iconSize: [35, 35],
            iconAnchor: [0,0],
            popupAnchor: [15, -15]
        });

        var new_phone = this.state.phone;
        var new_firstname = this.state.firstname;
        var new_lastname = this.state.lastname;
        var new_city = this.state.city;
        var new_country = this.state.country;
        var new_addrName = this.state.addrName;
        var new_addrNum = this.state.addrNum;
        var new_AFM = this.state.AFM;
        var new_DOY = this.state.DOY;
        var new_PROFESSION = this.state.PROFESSION;
        var new_email = this.state.loginEmail;
        var new_password = this.state.loginPassword;

        var all_markers = [];

        this.state.posts.map((currElement) => {
            var marker = L.marker(currElement.geometry.coordinates.reverse(), {
                icon: engineer,
                draggable: true
            }).addTo(this.map).bindPopup((currElement.properties.display_name.split(',').slice(0,3).map(curr => {
                if (curr.length > 12) {
                    return (curr.match(/.{1,12}/g)).join(' ');
                } 
                else {
                    return curr;
                }
            })).join(',').replace(/,/g, "<br />")).on('mouseover', function (e) {
                this.openPopup();
            })

            all_markers.push(marker);

            // on contextmenu --> on right click
            marker.on('contextmenu', function(e) {
                /*ADD THIS MARKER TO THE JSON FILE*/
                let newEngineerRecord = {
                    lat: this.getLatLng().lat,
                    lng: this.getLatLng().lng,
                    name: new_firstname,
                    lastName: new_lastname,
                    phoneNumber: new_phone,
                    country: new_country,
                    city: new_city,
                    addressName: new_addrName,
                    addressNumber: new_addrNum,
                    afm: new_AFM,
                    doy: new_DOY,
                    PROFESSION: new_PROFESSION,
                    email: new_email,
                    password: new_password
                };

                /*UPDATE FILE TO SERVER*/
                //axios.post('https://hm-api.entranet.us:7656/main/electrician', newEngineerRecord).then(alert('OK'));
                axios.post('https://hm-api.entranet.us:7656/main/electrician', newEngineerRecord).then(function (response) {
                    if (response.data.state === 0) {
                        alert('ok');
                    }
                    else {
                        alert('some problem occured');
                    }
                });
            });
        });
        
        var group = new L.featureGroup(all_markers);
        this.map.fitBounds(group.getBounds().pad(0.5));

        //this.map.panTo(new L.LatLng(this.state.posts[this.state.posts.length - 1].geometry.coordinates[0], this.state.posts[this.state.posts.length - 1].geometry.coordinates[1]));

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            detectRetina: true,
            maxZoom: 20,
            maxNativeZoom: 17
        }).addTo(this.map);
    }

    render() {
        return (
            <div id = "cMain" style = {{width: "auto",height: "100vh"}}>
                <Wrapper id="map">
                   
                </Wrapper>
            </div>
        )
    }
}

export default LeafletMapShower