import storage from "../../Storage.js";
import { confirmEmailUrl, resetPasswordUrl, deleteUserUrl, guestUrl} from  "../../urls"


export function confirmEmail(token) {
    const DATA = {
        method: 'PUT',
        body: JSON.stringify({
            token: token
        }),
    };
    return storage.FetchData(confirmEmailUrl + '/' + token, DATA);
}

// Reset users password
export function resetPassword(token, newPassword) {
    const DATA = {
        method: 'PUT',
        body: JSON.stringify({
            token: token,
            newPassword: newPassword
        }),
    }
    return storage.FetchData(resetPasswordUrl, DATA);
}

export function deleteUser(token) {
    const DATA = {
        method: 'DELETE',
        body: JSON.stringify({
            plainToken: token,
        })
    };
    return storage.FetchData(deleteUserUrl, DATA);
}

export function renameGuest(name, email) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                email: email,
                name: name
            }),
        };
        return storage.FetchData(guestUrl + '/name/' + buildingID, DATA);
    });
}