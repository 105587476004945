export default  {
    translations:{
        userLogin: "LOGIN UTILIZATOR",//1 //toDo
        login: "AUTENTIFICARE", //2 //ok
        logout: "LOGOUT",//3 //ok
        switches: "ÎNTRERUPĂTOR ",//4 //toDo
        scenarios: "SCENARII",//5 //toDo
        timers: "PROGRAMARE DE SCENARII",//6 //toDo
        commands: "COMENZI DE PROGRAMARE",//7 //toDo
        camera: "CAMERĂ",//8 //ok
        alarm: "ALARMA",//9 //ok
        reminder: "REMINDER",//10 //toDo
        notes: "POST IT",//11 //ok
        energy: "ENERGY METERING",
        scenarioProgCreation: "CREAREA DE SCENARII / PROGRAMAREA",//12 //toDo
        scenarioCreation: "SCENARII / CREAȚIE",//13 //toDo
        commandsCreation: "CREAREA DE COMANDĂ / PROGRAMAREA",//14 //toDo
        alarmCreation: "ALARMĂ / CREARE",//15 //toDo
        reminderCreation: "REAMINTIRE / CREARE",//16 //toDo
        titleNameError: "TITLU NUME EROARE",//17 //toDo
        addNewScene: "SCENARIU NOU",//18 //toDo
        multiplePlan: "PROGRAMARE MULTIPLA",//19 //toDo
        newCommand: "COMANDĂ NOUĂ",//20 //toDo
        newAlarm: "ALARMĂ NOUĂ",//21 //toDo
        newReminder: "NOU REMINDER",//22 //toDo
        dateOrTimeOld: "Data sau ora este veche.",//23 //toDo
        successfulSave: "Salvarea a fost reușită",//24 //toDo
        cancel: "REVOCARE",//25 //toDo
        delete: "ȘTERGE",//26 //ok
        hideCaps: "DEZVĂLUIRI",
        show: "PUBLICITATE",
        save2: "SALVARE",//27 //toDo
        scenarioNameExists: "Acest nume de scenariu există deja.",//28 //toDo
        shortName: "Numele trebuie să aibă cel puțin 3 caractere.",//29 //ok
        scenarioName: "Numele scenariului",//30 //toDo
        selectASwitch: "Selectați cel puțin un comutator.",//31 //toDo
        wrongNameType: "Tip de nume greșit.",//32 //ok
        noDeviceChosen: "Nu ați selectat un dispozitiv.",//33 //toDo
        devices: "DISPOZITIVE",//34
        smallName: "Numele trebuie să fie format din cel puțin 4 caractere.",//35 //toDo
        newNameMult: "Introduceți numele",//36 //toDo
        time: "ORA",//37 //ok
        date: "DATA",//38 //ok
        repeat: "REPETĂ",//39 //toDo
        videoCall: "APEL VIDEO",//40 //toDo
        display: "AFIȘARE",//41 //toDo
        selectCamera: "Selectați Camera",//42 //toDo
        noteLengthRestriction: "Nota trebuie să fie formată din cel puțin 4 caractere.",//43 //toDo
        sendNote: "TRIMITE NOTĂ",//44
        days: {
            Monday: "Luni",
            Tuesday: "Marți",
            Wednesday: "Miercuri",
            Thursday: "Joi",
            Friday: "Vineri",
            Saturday: "Sâmbătă",
            Sunday: "Duminică"
        },//45 //ok
        days_locale: {
            Monday: "Luni",
            Tuesday: "Marți",
            Wednesday: "Miercuri",
            Thursday: "Joi",
            Friday: "Vineri",
            Saturday: "Sâmbătă",
            Sunday: "Duminică"
        },
        changeHome: "SCHIMBARE DE DOMICILIU",
        addNewName: "Introduceți un nume nou",
        selectedLang: "Limba selectată",
        selectLang: "Selectarea limbii",
        english: "Engleză",
        german: "germană",
        spanish: "Spanish",
        romanian: "român",
        bulgarian: "Bulgară",
        greek: "greacă",
        allerRetourWith: "Aller - Retour cu",
        allerRetour: "Aller - Retour",
        changeBuidling: "Schimbați clădirea.",//46 //toDo
        error: "Eroare 404! Pagina nu a fost găsită!",//47 //toDo
        buildingNotValid: "Clădire nevalabilă.",//48 //toDo
        newBuilding: "Adăugați o nouă clădire.",//49 //toDo
        checkInfo: "Vă rugăm să verificați informațiile dvs.",//50 //toDo
        passwordMissing: "Parola lipsește.",//51 //toDo
        emailMissing: "E-mail-ul lipsește.",//52 //toDo
        missMatchPass: "Parolele nu se potrivesc.",//53 //toDo
        switch_on: "pe",//54
        switch_off: "off",//55
        thereAreNo: "NU EXISTĂ",
        command: "COMENZI",
        cameras: "CAMERE",
        alarmsStr: "ALARME",
        reminders: "AMINTIRI",
        hmLanguage:"Limba implicită",
        name: "Română",
        deleteConfirmation: "Ștergerea a fost efectuată cu succes.", //56
        deleteFailure: "Ștergerea a eșuat.", //57
        deleteAllQuestion: "Sunteți sigur că doriți să ștergeți toate mesajele?", //58
        deleteMessageQuestion: "Sunteți sigur că doriți să ștergeți acest mesaj?", //59
        incomingMessages: "MESAJE PRIMITE", //60
        messageFrom: "Mesaj de la", //61
        deleteAll: "ȘTERGEȚI TOATE", //62
        messageArchiveText: "ARHIVA DE MESAJES", //63
        deleteInviteSuccess: "Invitația a fost ștearsă",
        deleteInviteFailure: "Ștergerea invitației nu a avut loc.",
        acceptInviteSuccess: "Invitația a fost acceptată",
        acceptInviteFailure: "Invitația nu a fost acceptată",
        deleteAllInvitesQuestion: "Sunteți sigur că doriți să ștergeți toate invitațiile?",
        deleteInviteQuestion : "Sunteți sigur că doriți să ștergeți această invitație?",
        inviteFrom: "Invitație de la ",
        acceptInvite: "ACCEPT",
        invites: "INVITAȚII",
        newAccessScenario: ": Scenarii",
        newAccessScenarioProgramming: ": Programare de scenarii",
        newAccessCommandsProgramming: ": Comenzi de programare",
        newAccessCamera: ": Camera",
        newAccessAlert: ": Alarme",
        newAccessReminder: ": Reminderi",
        newAccessPostIt: ": Post It",
        newAccessDot: ".",
        newAccessNoAccess: "Nu aveți permisiunea",
        deleteQuestion: "Ești sigur că vrei să le ștergi?",
        account: "CONT",
        successfulRegistration: "Înregistrare reușită",
        unsuccessfulRegistration: "Înregistrare nereușită",
        deleteAccountQuestion: "Sunteți sigur că doriți să vă ștergeți contul?\nDacă DA, verificați-vă e-mailul pentru confirmarea ștergerii.",
        sendEmailSuccess: "Email trimis cu succes.",
        sendEmailFailure: "Nu a reușit să trimită un e-mail.",
        successfulCancelation: "Anulare reușită.",
        nicknameExists: "Acest nume există deja.",
        nicknameSavedSuccessfuly: "Porecla a fost salvată cu succes.",
        nicknameDelete: "Numele a fost șters cu succes",
        nicknamesSavedSuccessfuly: "Poreclele au fost salvate cu succes.",
        myAccount: "CONTUL MEU",
        homeName: "Numele casei",
        username: "Nume utilizator",
        addExtraNickname: "Adăugați nume de utilizator suplimentare",
        deleteAccount: "ȘTERGEȚI CONTUL",
        deleteHome: "ȘTERGE CASĂ",
        deleteHomeQuestion: "Sunteți sigur că doriți să ștergeți această casă?",
        unhideSwitch: "Sunteți sigur că doriți să afișați comutatorul ",
        hideSwitch: "Ești sigur că vrei să ascunzi comutatorul ",
        deleteSwitch: "Sunteți sigur că doriți să ștergeți comutatorul ",
        insertUserName: "Introduceți numele de utilizator",
        weather: "VREMEA",
        weatherText_1: "Acesta va fi utilizat în comenzi precum:",
        weatherText_2: "\"Care va fi vremea mâine?\",\"Spune-mi cum e vremea\"",
        timeZone: "Fusul orar",
        timeZoneText_1: "Acesta va fi utilizat în comenzi precum:",
        timeZoneText_2: "\"Cât este ceasul?\",\"Spune-mi cât e ceasul\"",
        news: "ȘTIRI",
        newsText: "Câte titluri de știri vrei să asculți?",
        tabletLanguage: "Limbajul tabletei",
        changeKeyword: "Schimbați cuvântul cheie",
        generalInformation: "INFORMAȚII GENERALE",
        theUser: "Utilizatorul:  ",
        hasPermission: " are deja permisiunea!",
        members: "Membri",
        changeUsername: "Doriți să modificați numele de utilizator al utilizatorului: ",
        noUsersAvailabe: "Nu există niciun utilizator disponibil pentru a fi șters!",
        deleteMember: "Sunteți sigur că doriți să ștergeți utilizatorul: ",
        invalidEmail: "Ați introdus un e-mail invalid! ",
        insertNewUsername: "Introduceți un nou nume de utilizator",
        save: "Salvați",
        cancel_2: "Anulează",
        insertNewEmail: "Introdu e-mail",
        availablePermissions: "Disponibile permisiuni ",
        memberUsername: "Nume de utilizator membru: ",
        currentUserPermission: "Permisiunea membrului actual",
        renameUser: "RENUMIREA UTILIZATORULUI",
        deleteUsers: "ȘTERGERE USER",
        addNewUser: "ADĂUGAȚI UTILIZATOR NOU",
        permissions: "REGULI DE ACCES",
        week: "SĂPTĂMÂNA",
        month: "LUNA",
        year: "ANUL",
        energyConsumption: "Consumul în KW",
        energyMetering: "Contorizarea energiei",
        energyMeteringCapital: "CONTORIZAREA ENERGIEI",
        insertCommand: "Comanda Insert",
        selectCamera: "SELECȚIA CAMEREI",
        edit: "EDITARE",
        showHiddenDevices: "Afișarea dispozitivelor ascunse",
        editScene: "SCENARIUL EDITARE",
        unselectAll: "Deosebiți toate",
        selectAll: "Selectați toate",
        cancelAllerRetour: "Anulare Aller - Retour",
        showHiddenDevices: "Afișarea dispozitivelor ascunse",
        rename: "Redenumiți",
        addName: "Adăugarea unui nume",
        showLow: "Arată",
        hide: "Ascundeți",
        deleteLow: "Ștergeți",
        currentDeviceName: "Numele comutatorului curent",
        newDeviceName: "Numele noului comutator",
        successfullRename: "Redenumirea comutatorului a avut succes.",
        failRename: "Eșecul redenumirii comutatorului.",
        successfullHide: "Ascunderea cu succes a comutatorului.",
        failHide: "Eșecul ascunderii comutatorului.",
        cancelHide: "Ați anulat ascunderea comutatorului.",
        successfullShow: "Afișare reușită a comutatorului.",
        failShow: "Eșecul afișării comutatorului.",
        cancelShow: "Ați anulat afișarea comutatorului.",
        successfullDelete: "Ștergerea cu succes a comutatorului.",
        failDelete: "Eșecul ștergerii comutatorului.",
        cancelDelete: "Ați anulat ștergerea comutatorului.",
        successfullCancelAllerRetour: "Anularea cu succes a Aller-Retour.",
        failCancelAllerRetour: "Eșecul anulării Aller-Retour.",
        successfullAddName: "Introducerea cu succes a unui nume suplimentar.",
        failAddName: "Eșecul introducerii unui nume.",
        renameCaps: "PARTICIPARE",
        noInvites: "Nu aveți nicio invitație",
        noMessages: "Nu aveți niciun mesaj",
        oneTime: "Odată ce",
        postItMessage: 'Introduceți nota dumneavoastră',
        thereAreNoText1: "Nu există",
        thereAreNoText2: "în lista ta.",
        messages:"mesaje",
        ivnites:"invită",
        noPermissionToService:"Ne pare rău, în prezent nu aveți permisiunea de a accesa acest serviciu.",
        location: "LOCALIZARE",
        changeTimeZone: "Schimbarea fusului orar",
        selectedZone: "zona selectată: ",
        selectZone: "selectarea zonei",
        timeZoneCaps: "ZONA ORARĂ",
        tabletLanguageCaps: "TABLETĂ LINGVISTICĂ",
        changeKeyword: "SCHIMBARE DE CUVÂNT CHEIE",
        changeLanguage: "Schimbarea limbii",
        invitesText : "INVITAȚII"
    }
}