import storage from "./Storage";
import { messagesUrl } from "./urls";

export function fetchMessages() {
	const DATA = { method: "GET" };
	return storage.FetchData(messagesUrl, DATA);
}

export function deleteMsg(id) {
	const DATA = {
		method: "DELETE"
	};
	return storage.FetchData(messagesUrl + "/" + id, DATA);
}

