const dev = false;

function log(text) {
    if(dev){
        console.log(text);
    }
}

function warn(text) {
    if(dev){
        console.warn(text);
    }
}

function error(text) {
    if(dev){
        console.error(text);
    }
}

export default {
    log: log,
    warn: warn,
    error: error
};