import React from 'react';
import './InfoWindowsLayout.css';
import { deleteUser } from '../Functions/userFunctions';

const successImg = require('../../images/success_access.png');
const unsuccessImg = require('../../images/unsuccess_access.png');

class DeleteAccount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            confirm: "Not confirmed",
            title: "",
            resultText: "",
            image: null
        }
    }

    componentDidMount(){
        let params = {}
        params = this.props.deleteParams();
        if(params.delete != null){
            //TODO: Call delete user
            this.DeleteAccount(params.delete);
        }else{
            this.setState({title: "ΛΑΘΟΣ ΣΥΝΔΕΣΜΟΣ", resultText:"Ο σύνδεσμος που καταχωρήθηκε είναι λάθος", image: unsuccessImg});
        }

    }

    DeleteAccount = (token) => {
        deleteUser(token).then((responseData) => {
            this.setState({title:"ΔΙΑΓΡΑΦΗ ΧΡΗΣΤΗ", resultText: "O λογαριασμός διαγράφτηκε με επιτυχία.", image: successImg});

        }).catch((error) => {
            this.setState({title:"ΚΑΤΙ ΠΗΓΕ ΣΤΡΑΒΑ", resultText: "Η διαγραφή του λογαριασμού σας απέτυχε.", image: unsuccessImg})
        });
    }

    ChangePage = () => {
        this.props.setSelected(0);
        window.location.assign("/");
    }

    render(){
        return(
            <div className="inviteElementContainer">
                <div className="header">
                    <div className="logoContainer">
                        <img src={require("./images/housemate_logo_01.png")}></img>
                    </div>
                    
                </div>
                <div className='mainBody'>
                    <div className="infoWindow">
                        <div className='statusImage'>
                            <img src={this.state.image}></img>
                        </div>
                        <div className="resultContainer">
                            <h1>{this.state.title}</h1>
                            <div>{this.state.resultText}</div>
                        </div>
                        <div className="returnBtn" onClick={()=> this.ChangePage()}>
                            <span>ΣΥΝΕΧΕΙΑ</span>
                        </div>
                    </div>
                </div>
            
            </div>
        )
    }
}

export default DeleteAccount;