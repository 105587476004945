
import { React } from 'react'
import logger from '../Functions/logger';

function NewButtons(props) {

    logger.log("PROPS:", props);

    return (


        <div style={{borderTop:'3px solid #ccc', display:'flex', justifyContent:'space-around', width:'100%', alignItems:'center'}}>{
            props.buttonArray.map((button) => {
                return (
                    <div className='buttonCss' onClick={button.onClickFunction} >
                        {button.text}
                    </div>
                )
            })
        }
        </div>



    )
}

export default NewButtons;