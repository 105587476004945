import { React, useEffect, useState } from 'react';
import currentTimeZone from "../../locationsArray/locationsArray.json";
import {setRegion} from "../Functions/buildingFunctions.js"
import logger from '../Functions/logger';


function SetLocationForWeather(props) {

    var currentTimeZoneParsed = currentTimeZone?.data;
    logger.log(currentTimeZoneParsed);
    const [timeZoneArray, setTimeZoneArray] = useState(currentTimeZoneParsed);

    useEffect(() => {
        logger.log(timeZoneArray);
    }, [timeZoneArray])

    useEffect(() => {
        logger.log("hi", props.setRenderCurrentTimeZone)
    })

    return (
        <div style={{height: "100%"}}>
            <div style={{height: "25%", paddingLeft: "8%"}} className="bullet-checkbox">
                <div className="changeTimeZoneTitle selectLanguage">{props.translations.changeTimeZone}</div>
                <div className="selectLanguage" style={{marginBlock: "10px", fontSize: "13px"}}>{props.translations.selectedZone}{props.timezone}</div>
                <div style={{fontSize: "14px", marginBlock: "20px"}} className="selectLanguage">
                    <img className="imgAddSwitchName" src={require("./images/language.png")}/>
                    <div style={{ marginLeft: "10px"}}>{props.translations.selectZone}</div>
                </div>
            </div>
            <div className="scrollable" style={{height: "60%", width: "90%", paddingLeft: "8%", marginTop: "7%"}}>
                {timeZoneArray.map((timeZone) => {
                    
                    return <div key={timeZone.key} className="checkbox-lang noselect">
                                <input
                                    type="checkbox"
                                    id={`checkbox-${timeZone.key}`}
                                    checked={props.timezone === timeZone.text || false}
                                    onChange={() => {
                                        setRegion(timeZone.text)
                                        .then(()=>{
                                            props.setRenderCurrentTimeZone(timeZone.text);
                                        })
                                        .catch(error =>{logger.log("error:",error);})
                                        .finally(props.setTimeZoneModal(false))
                                    }}
                                />
                                <label htmlFor={`checkbox-${timeZone.key}`} className="checkbox-label">{timeZone.text}</label>
                            </div>
                })}
            </div>
        </div>
    )
}





export default SetLocationForWeather;