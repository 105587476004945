import React, { useState } from "react";
import { removeUserHome } from "../../buildingFunctions";
import ModalView from "./ModalView";
import ModalDouble from "./ModalDouble";
import logger from '../Functions/logger';

export default function NewBuilding(props) {

    const [isOpen, setIsOpen] = useState(props.isOpen);
    const [isModalOpen, setIsModalOpen] = useState(false);

    function deleteBuilding(id, userEmail) {
        removeUserHome(id, userEmail)
            .then((responseData) => {
                props.removeFunction(id, "building");
                props.openSnackbar(props.translations.deleteConfirmation);
            })
            .catch((error) => {
                logger.log(error);
                logger.log(props.translations.deleteFailure);
            });
    }

    function confirmation(id) {
        setIsModalOpen(id);
    }

    return (
        <ModalView open={isOpen} close={() => {
            setIsOpen(false);
            props.currentButtonSelected(0);
        }} sizeStyle={{ width: "30%", height: "60%" }}  padding={true}>
            <div style={{ width: "100%", height: "100%", display:"flex", flexDirection:"column"}}>
                <div className="titleRemoveBuilding">
                    {props.translations.deleteHome}
                </div>
                <div className="scrollable" style={{ flex:1, overflow: "scroll" }}>
                    {props.buildings.map((building) =>
                        <div key={building.buildingID} className={`${props.selectedBuilding === building ? "selectedBuildingButton" : "buildingButton"} buttonArea space-between`} >
                            {building.name}
                            <img className="newImageArea" style={{ cursor: 'pointer' }} src={require("./images/bin_black@2x.png")} onClick={() => confirmation(building.buildingID)} />
                        </div>
                    )}
                </div>
            </div>
            <ModalDouble translations={props.translations} isOpen={isModalOpen} ok={() => deleteBuilding(isModalOpen, props.userEmail)}
                closeModal={() => setIsModalOpen(false)} title={props.translations.deleteHomeQuestion}
                rightButton={props.translations.delete} />
        </ModalView>

    );
}