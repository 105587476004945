import React from 'react';
import './InfoWindowsLayout.css';
import '../../App.css';
import {acceptGuestInvite, confirmEmail} from '../../SwitchFunctions'
import logger from '../Functions/logger';

const successImg = require('../../images/success_access.png');
const unsuccessImg = require('../../images/unsuccess_access.png');

class AcceptUser extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            menu: 0,
            id: "",
            title: "",
            resultText: "",
            image: null,
            correctUrl: "false"
        }
    }

    componentDidMount() {
        let params = {};
        params = this.props.inviteParams();
        if(params.home != null) {
            this.AcceptInvite(params.home);
        }
        else{
            this.setState({title: "ΛΑΘΟΣ ΣΥΝΔΕΣΜΟΣ", resultText:"Ο σύνδεσμος που καταχωρήθηκε είναι λάθος", image: unsuccessImg});
        }

    }

    clickedLogout = () => {
        logger.log("Click logout");
        this.props.clickedLogout();
    }


    //Call acceptInvite from UtilsFunctions to send it to server
    AcceptInvite = (id) => {
        logger.log(id);
        return acceptGuestInvite(id).then((response) => {
            this.setState({title: "ΕΠΙΤΥΧΗΣ ΠΡΟΣΚΛΗΣΗ!", resultText:"Η πρόσκληση για το πακέτο ήταν επιτυχής.", image: successImg})
        }
        ).catch((error) => { 
            logger.log("AcceptInvite")
            logger.log(error);
            this.setState({title: "ΚΑΤΙ ΠΗΓΕ ΣΤΡΑΒΑ!", resultText:"Η πρόσκληση για το πακέτο απέτυχε.",  image: unsuccessImg});
        });

    }

    ChangePage = () => {
        this.props.setSelected(0);
        window.location.assign("/");
    }

    render() { 
        return(
            <div className="inviteElementContainer scrollable">
                    <div className="header">
                        <div className="logoContainer">
                            <img src={require("./images/housemate_logo_01.png")}></img>
                        </div>
                        
                        
                    </div>
                    <div className='mainBody'>
                    <div className="infoWindow">
                        <div className='statusImage'>
                            <img src={this.state.image}></img>
                        </div>
                        <div className="resultContainer">
                            <h1>{this.state.title}</h1>
                            <div>{this.state.resultText}</div>
                        </div>
                        <div className="returnBtn" onClick={()=> this.ChangePage()}>
                            <span>ΣΥΝΕΧΕΙΑ</span>
                        </div>
                    </div>
                    </div>
                    
            </div>
        );  
    }

}

export default AcceptUser;