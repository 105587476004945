import React,{useState} from 'react';
import ModalView from './ModalView';


function RenameSwitch(props) {
    
    const [currentName, setCurrentName] = useState(props.name);


    return (
        <ModalView open={props.openModal} close={() => props.close()} sizeStyle={{width: "25%", height: "45%"}}  padding={true}>
            <div className="renameSwitchScreen">
                <div>
                    <div className="renameCurrentSwitchText">{props.translations.rename}</div>
                    <div>
                       <span className="renameSwitchInputsText">{props.translations.currentDeviceName}</span>
                       <input className="renameSwitchInputs" value={currentName} readonly/>
                    </div>
                    <div>
                        <span className="renameSwitchInputsText">{props.translations.newDeviceName}</span>
                        <input className="renameSwitchInputs" onChange={(event) => {props.setName(event.target.value)}}/>
                    </div>
                </div>
                <div className="renameSwitchButtons">
                    <div style={{cursor: "pointer"}} onClick={() => props.close()}>{props.translations.cancel}</div>
                    <div style={{cursor: "pointer"}} onClick={() => {
                        props.changeSwitchName(props.name, props.selectedSw)
                        props.close()
                    }}>{props.translations.renameCaps}</div>
                </div>
            </div>
        </ModalView>
    )
}

export default RenameSwitch;