import storage from "./Storage";
import { guestUrl } from "./urls";
import logger from './Components/Functions/logger';

export function fetchGuests() {
	//Use to be like that
	//const buildingID = storage.getBuildingID();
	return storage
		.getBuildingID()
		.then((buildingID) => {
			const DATA = {
				method: "GET",
			};
			return storage.FetchData(guestUrl + "/" + buildingID, DATA);
		})
		.catch((error) => {
			logger.warn(error);
		});
}

export function deleteGuest(guestEmail) {
	return storage
		.getBuildingID()
		.then((buildingID) => {
			const DATA = {
				method: "DELETE",
				body: JSON.stringify({
					buildingID: buildingID
				})
			}
			return storage.FetchData(guestUrl + "/" + guestEmail, DATA);
		})
		.catch((error) => {
			logger.warn(error);
		});
}

export function addGuest(guestEmail, language, permission) {
	return storage
		.getBuildingID()
		.then((buildingID) => {
			const DATA = {
				method: "POST",
				body: JSON.stringify({
					buildingID: buildingID,
					language: language,
					permission: permission
				})
			}
			return storage.FetchData(guestUrl + "/" + guestEmail, DATA);
		})
		.catch((error) => {
			logger.warn(error);
		});
}

export function renameGuest(name, guestEmail) {

	return storage
		.getBuildingID()
		.then((buildingID) => {
			const DATA = {
				method: "PUT",
				body: JSON.stringify({
					buildingID: buildingID,
					name: name,
					email: guestEmail
				})
			}
			return storage.FetchData(guestUrl + "/name/" + buildingID, DATA);
		})
		.catch((error) => {
			logger.warn(error);
		});
}

export function changePermission(permission,guestEmail) {

	return storage
		.getBuildingID()
		.then((buildingID) => {
			const DATA = {
				method: "PUT",
				body: JSON.stringify({
					buildingID: buildingID,
					email: guestEmail,
					permission: permission
				})
			}
			return storage.FetchData(guestUrl + "/permissions/" + guestEmail, DATA);
		})
		.catch((error) => {
			logger.warn(error);
		});
}
