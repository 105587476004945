/**
 * Translations (English US - English - Greek)
 */
import LocalizedStrings from 'react-localization';


export const strings = new LocalizedStrings({
  "en-US": {
    login: "login",
    login2: "LOG IN",
    register: "register",
    register2: "REGISTER",
    mail: "Email",
    pass: "Password",
    confirmPass: "Confirm Password",
    confirmEmail: "Resend confirmation email?",
    forgotPass: "Forgot your password?",
    resetPass: "Reset your password",
    passChange:"Your password change successfully",
    checkEmail: "Check your email",
    resetEmailText: "A password reset email has been sent to ",
    reset: "Reset",
    send: "Send",
    returnLogin: "LOGIN SCREEN",
    emailConfirmedTitle: "Email Confirmed",
    emailConfirmedTrue: "Your email has been confirmed",
    emailConfirmedFalse: "There was an error confirming your email",
    empty: "Should not be empty",
    emailConfirmation: "Confirmation email",
    emailSent: "Please confirm the email that was sent to ",
    emailPending: "Email confirmation pending",
    buildingIdError: "Building ID must be alphanumeric (i.e. 6a2fc2g)",
    alphanumeric: "Must be alphanumeric",
    buildingIdNameValidation: "Whitespace & non alphanumerics not allowed",
    validID: "Valid ID",
    invalidID: "Invalid ID",
    invalidMail: "This is an invalid email address",
    enterValidMail: "Enter valid email",
    buildingIdLabel: "BuildingID",
    buildingNotExist: "Building doesn't exist",
    nameLabel: "Name (i.e home)",
    shortName: "Name needs to be at least 3 characters",
    shortPass: "Password needs to be at least 8 characters",
    passwordNumber: "Password must contain at least 1 number",
    passwordLowercase: "Password must contain at least 1 lowercase character",
    passwordUppercase: "Password must contain at least 1 uppercase character",
    specialCharacter: "Special characters are not allowed",
    lengthError: "Name length should be from 4-12 characters",
    generalNameError: "Every word should be 3-20 characters and only two space are allowed",
    snackMail: "The email or password you entered is incorrect",
    missMatchPass: "Passwords don't match",
    submit: "SUBMIT",
    emailExist: "Email is used for a different account",
    createPinTitle: "Create a 4-digit PIN",
    confirmPinTitle: "Confirm a 4-digit PIN",
    enterPinTitle: "Enter your 4-digit PIN",
    forgotPin: "Forgot your pin?",
    pinSnack: "Please press \"Forgot your pin?\" to reset",
    locked: "Locked",
    pin: "pin",
    pin2: "Pin",
    edit: "Edit",
    error: "Error",
    whitespaceError: "Whitespace not allowed",
    wrongPin: "Wrong pin",
    example: "Example",
    pinCount: "You can enter pin",
    pinCount2: "more times",
    pinCount3: "more time",
    shortPin: "Pin must be 4 digits",
    missMatchPin: "Pin does not match",
    fabAdd: "Add a new package",
    fabSelect: "Select",
    fabSnack: "You now control housemate: ",
    switches: "switches",
    scenes: "Scenes",
    camera: "Camera",
    alarms: "Alarms",
    alerts: "Alerts",
    timers: "Timers",
    accessRules: "Access rules",
    location: "Location",
    generalInfo: "General information",
    news: "News",
    myAccount: "MY ACCOUNT",
    myAccount2: "Αccount",
    houseName: "House name",
    myName: "My name",
    nameChanged: "Name changed",
    nameChangeFailed: "Name change failed", 
    newDevice: "New device",
    logout: "Logout",
    logout2: "Logout",
    commands: "Commands",
    swipeRightLang: "en-US",
    logoutDialog: "Are you sure you want to logout?",
    rename: "Rename",
    rename2: "RENAME",
    newName: "Type a new name",
    addNewScene: "ADD NEW SCENE",
    removeAll: "REMOVE ALL",
    ok: "OK",
    ok2: "ok",
    yes:"YES",
    cancel: "CANCEL",
    typeName: "Type a name",
    thermostat: "Thermostat",
    deleteDialog: "Are you sure you want to delete",
    deleteSceneDialog: "Are you sure you want to delete scene ",
    deleteAlertDialog: "Are you sure you want to delete alert ",
    deleteAlarmDialog: "Are you sure you want to delete alarm at ",
    deleteProgramSceneDialog: "Are you sure you want to delete scheduled scene ",
    deleteCommandDialog: "Are you sure you want to delete command ", 
    deleteNoteDialog: "Are you sure you want to delete note ", 
    deleteSwitchDialog: "Are you sure you want to delete switch ",
    deleteMessageDialog: "Are you sure you want to delete this message?",
    deleteProgramActions: "There are programmable scenarios for delete ",
    delete: "DELETE",
    deleteLower: "Delete",
    deleteAll: "DELETE ALL",
    deleteAllInvites : "Are you sure you want to delete all the invites",
    deleteAllMessages: "Are you sure you want to delete all messages?",
    deleteInvite:"Are you sure you want to delete the invite from",
    stopAlert: "Stop",
    questionMark: "?",
    videoCall: "video call",
    watch: "watch",
    create: "CREATE",
    alarmInput: "Type a name (optional)",
    time: "time",
    date: "date",
    repeat: "repeat",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
    selectFields: "Please select fields",
    devices: "Devices",
    save: "save",
    save2: "SAVE",
    deleteAccount: "DELETE ACCOUNT",
    deleteExplanation: "You will receive an email to delete your account",
    alertInput: "Type alert's message",
    sceneSnack: "Activated scene ",
    add: "ADD",
    accessDevices: "Access devices",
    accessCamera: "Access camera",
    accessActions: "Access actions",
    accessScenes: "Access scenes",
    receiveEmNot: "Receive emergency notification",
    addGuest: "add your guest",
    guestInput: "Type the guest's email address",
    guestNameInput: "Type the guest's name",
    weather: "weather",
    weatherCom: "Will be used in commands like \"What's the weather?\" ",
    timezone: "timezone",
    timezoneCom: "Will be used in commands like \"What's the time?\"",
    newsTitle: "How many headlines do you want to listen to?",
    registrationFail: "Registration failed. Please try again",
    deleteAllScenes: "Are you sure you want to delete all scenes?",
    deleteAllPosts: "Are you sure you want to delete all post it?",
    noDevices: "You don't have any devices",
    guests: "guests",
    typeToSearch: "Type a message to search",
    saved: "Saved",
    wrongNameType: "Wrong name type",
    smallName: "The name must contain more than 4 characters",
    emptyName: "The name field is empty",
    emptyDevices: "No devices selected",
    noScenariosSelected: "There are not any selected scenarios",
    pickTime: "Select time",
    renameSwitchName: "Please enter name without whitespaces",
    updated: "Updated",
    timeError: "You cannot enter past time",
    options: "Options",
    switchAlreadyExists: "Switch already exists",
    addingSwitch: "Adding switch",
    addSwitch: "Add device",
    name: "Name",
    notes: "Post it",
    energy: "Energy Metering",
    commandInput: "Type command",
    postitInput: "Type message",
    changeKeyword: "Change keyword",
    eleeza: "Eleaza",
    hey_eleeza: "Eliza",
    message_archive: "Messages",
    record_voice: "Record Voice",
    record_again: "Record Again",
    recording: "Recording...",
    record_finished: "Finished Recording",
    show_hidden_switches: "Hidden switches",
    hideSwitch: "Hide",
    unhide: "Unhide",
    showSwitch: "Are you sure you want to unhide ",
    sendCommand: "Send command:",
    sendCommandFail: "Command send failed!",
    multiplePlan: "MULTIPLE SCHEDULE",
    invitations: "Invites",
    accept: "ACCEPT",
    confirmBiometrics: "Confirm fingerprint for Housemate",
    confirmFaceID: "Confirm FaceID for Housemate",
    enterEmailAddress: 'Enter your email address',
    accessDenied: "You don't have access.",
    scanPackage: 'Scan your package',
    scanPackageMsg: "You have no package, scan your package's QR code",
    allerRetour2: 'ALLER-RETOUR',
    allerRetourDelete: 'CANCEL ALLER-RETOUR',
    allerRetourText: 'Aller-Retour with:',
    deviceSelectionNone: 'Please select a device to proceed',
    hiddenEmpty: 'No hidden switches',

    //add Device Screen
    addDeviceDefault: 'No devices found',
    InviteError : "Inactive invitation",
    switch: 'Switch - ',
    loggedIn: "You have successfully logged in",
    connecting: 'Trying to connect',
    acceptOnDevice:"Please press accept in your device",
    inviteAccepted: 'Connected to package!',
    success:  'Your new device is ready!',
    wait: 'Please, wait...',
    search: 'Search...',
    open: "OPEN", 
    openSettingsMsg: "Please enable microphone and/or speech recognition permission from settings.",
    openSettingsTitle: "Open Settings",
    openSettingsMsg2: "Please enable microphone and/or camera permission from settings.",
    holdON:"Hold on!",
    exitAppQuestion:"Are you sure you want to go exit the app?",
    noInternet:"There is no internet connection!",
    pleaseSelectDevice:"Please select a device",
  },

  en: {
    login: "login",
    login2: "LOG IN",
    register: "register",
    register2: "REGISTER",
    mail: "Email",
    pass: "Password",
    confirmPass: "Confirm Password",
    confirmEmail: "Resend confirmation email?",
    checkEmail: "Check your email",
    forgotPass: "Forgot your password?",
    resetPass: "Reset your password",
    passChange:"Your password change successfully",
    resetEmailText: "A password reset email has been sent to ",
    reset: "Reset",
    send: "Resend",
    returnLogin: "LOGIN SCREEN",
    emailConfirmedTitle: "Email Confirmed",
    emailConfirmedTrue: "Your email has been confirmed",
    emailConfirmedFalse: "There was an error confirming your email",
    empty: "Should not be empty",
    emailConfirmation: "Confirmation email",
    emailSent: "Please confirm the email that was sent to ",
    emailPending: "Email confirmation pending",
    buildingIdError: "Building ID must be alphanumeric (i.e. 6a2fc2g)",
    alphanumeric: "Must be alphanumeric",
    buildingIdNameValidation: "Whitespace & non alphanumerics not allowed",
    validID: "Valid ID",
    invalidID: "Invalid ID",
    invalidMail: "This is an invalid email address",
    enterValidMail: "Enter valid email",
    buildingIdLabel: "BuildingID",
    buildingNotExist: "Building doesn't exist",
    nameLabel: "Name (i.e home)",
    shortName: "Name needs to be at least 3 characters",
    shortPass: "Password needs to be at least 8 characters",
    passwordNumber: "Password must contain at least 1 number",
    passwordLowercase: "Password must contain at least 1 lowercase character",
    passwordUppercase: "Password must contain at least 1 uppercase character",
    specialCharacter: "Special characters are not allowed",
    lengthError: "Name length should be from 4-12 characters",
    generalNameError: "Every word should be 3-20 characters and only two space are allowed",
    snackMail: "The email or password you entered is incorrect",
    missMatchPass: "Passwords don't match",
    submit: "SUBMIT",
    emailExist: "Email is used for a different account",
    createPinTitle: "Create a 4-digit PIN",
    confirmPinTitle: "Confirm a 4-digit PIN",
    enterPinTitle: "Enter your 4-digit PIN",
    forgotPin: "Forgot your pin?",
    pinSnack: "Please press \"Forgot your pin?\" to reset",
    locked: "Locked",
    pin: "pin",
    pin2: "Pin",
    edit: "Edit",
    error: "Error",
    whitespaceError: "Whitespace not allowed",
    wrongPin: "Wrong pin",
    example: "Example",
    pinCount: "You can enter pin",
    pinCount2: "more times",
    pinCount3: "more time",
    shortPin: "Pin must be 4 digits",
    missMatchPin: "Pin does not match",
    fabAdd: "Add a new package",
    fabSelect: "Select",
    fabSnack: "You now control housemate: ",
    switches: "switches",
    scenes: "Scenes",
    camera: "Camera",
    alarms: "Alarms",
    alerts: "Alerts",
    timers: "Timers",
    accessRules: "Access rules",
    location: "Location",
    generalInfo: "General information",
    news: "News",
    myAccount: "MY ACCOUNT",
    myAccount2: "Αccount",
    houseName: "House name",
    myName: "My name",
    nameChanged: "Name changed",
    nameChangeFailed: "Name change failed", 
    newDevice: "New device",
    logout: "Logout",
    logout2: "Logout",
    commands: "Commands",
    swipeRightLang: "en-GB",
    logoutDialog: "Are you sure you want to logout?",
    rename: "Rename",
    rename2: "RENAME",
    newName: "Type a new name",
    addNewScene: "ADD NEW SCENE",
    removeAll: "REMOVE ALL",
    ok: "OK",
    ok2: "ok",
    yes:"YES",
    cancel: "CANCEL",
    typeName: "Type a name",
    thermostat: "Thermostat",
    deleteDialog: "Are you sure you want to delete",
    deleteSceneDialog: "Are you sure you want to delete scene ",
    deleteAlertDialog: "Are you sure you want to delete alert ",
    deleteAlarmDialog: "Are you sure you want to delete alarm at ",
    deleteProgramSceneDialog: "Are you sure you want to delete scheduled scene ",
    deleteCommandDialog: "Are you sure you want to delete command ", 
    deleteNoteDialog: "Are you sure you want to delete post it ", 
    deleteSwitchDialog: "Are you sure you want to delete switch ",
    deleteMessageDialog: "Are you sure you want to delete this message?",
    deleteProgramActions: "There are programmable scenarios for delete ",
    delete: "DELETE",
    deleteLower: "Delete",
    deleteAll: "DELETE ALL",
    deleteAllInvites : "Are you sure you want to delete all the invites",
    deleteAllMessages: "Are you sure you want to delete all messages?",
    deleteInvite:"Are you sure you want to delete the invite from",
    stopAlert: "Stop",
    questionMark: "?",
    videoCall: "Video call",
    watch: "Watch",
    create: "CREATE",
    alarmInput: "Type a name (optional)",
    time: "Time",
    date: "Date",
    repeat: "Repeat",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
    selectFields: "Please select fields",
    devices: "Devices",
    save: "save",
    save2: "SAVE",
    deleteAccount: "DELETE ACCOUNT",
    deleteExplanation: "You will receive an email to delete your account",
    alertInput: "Type alert's message",
    sceneSnack: "Activated scene ",
    add: "ADD",
    accessDevices: "Access devices",
    accessCamera: "Access camera",
    accessActions: "Access actions",
    accessScenes: "Access scenes",
    receiveEmNot: "Receive emergency notification",
    addGuest: "Add your guest",
    guestInput: "Type the guest's email address",
    guestNameInput: "Type the guest's name",
    weather: "Weather",
    weatherCom: "Will be used in commands like \"What's the weather?\" ",
    timezone: "Timezone",
    timezoneCom: "Will be used in commands like \"What's the time?\"",
    newsTitle: "How many headlines do you want to listen to?",
    registrationFail: "Registration failed. Please try again",
    deleteAllScenes: "Are you sure you want to delete all scenes?",
    deleteAllPosts: "Are you sure you want to delete all post it?",
    noDevices: "You don't have any devices",
    guests: "guests",
    typeToSearch: "Type a message to search",
    saved: "Saved",
    wrongNameType: "Wrong name type",
    smallName: "The name must contain more than 4 characters",
    emptyName: "The name field is empty",
    emptyDevices: "No devices selected",
    noScenariosSelected: "There are not any selected scenarios",
    pickTime: "Select time",
    renameSwitchName: "Please enter name without whitespaces",
    updated: "Updated",
    timeError: "You cannot enter past time",
    options: "Options",
    switchAlreadyExists: "Switch already exists",
    addingSwitch: "Adding switch",
    addSwitch: "Add device",
    name: "Name",
    notes: "Post it",
    energy: "Energy Metering",
    commandInput: "Type command",
    postitInput: "Type message",
    changeKeyword: "Change keyword",
    eleeza: "Eleaza",
    hey_eleeza: "Eliza",
    message_archive: "Messages",
    record_voice: "Record Voice",
    record_again: "Record Again",
    recording: "Recording...",
    record_finished: "Finished Recording",
    show_hidden_switches: "Hidden switches",
    hideSwitch: "Hide",
    unhide: "Unhide",
    showSwitch: "Are you sure you want to unhide ",
    sendCommand: "Send command:",
    sendCommandFail: "Command send failed!",
    multiplePlan: "MULTIPLE SCHEDULE",
    invitations: "Invites",
    accept: "ACCEPT",
    confirmBiometrics: "Confirm fingerprint for Housemate",
    confirmFaceID: "Confirm FaceID for Housemate",
    enterEmailAddress: 'Enter your email address',
    accessDenied: "You don't have access.",
    scanPackage: 'Scan your package',
    scanPackageMsg: "You have no package, scan your package's QR code",
    allerRetour2: 'ALLER-RETOUR',
    allerRetourDelete: 'CANCEL ALLER-RETOUR',
    allerRetourText: 'Aller-Retour with:',
    deviceSelectionNone: 'Please select a device to proceed',
    hiddenEmpty: 'No hidden switches',
    
    //add Device Screen
    addDeviceDefault: 'No devices found',
    InviteError : "Inactive invitation",
    switch: 'Switch - ',
    loggedIn: "You have successfully logged in",
    connecting: 'Trying to connect',
    acceptOnDevice:"Please press accept in your device",
    inviteAccepted: 'Connected to package!',
    success:  'Your new device is ready!',
    wait: 'Please, wait...',
    search: 'Search...',
    open: "OPEN",
    openSettingsMsg: "Please enable microphone and/or speech recognition permission from settings.",
    openSettingsTitle: "Open Settings",
    openSettingsMsg2: "Please enable microphone and/or camera permission from settings.",
    holdON:"Hold on!",
    exitAppQuestion:"Are you sure you want to go exit the app?",
    noInternet:"There is no internet connection!",
    pleaseSelectDevice:"Please select a device",
  },

  el: {
    login: "Σύνδεση",
    login2: "ΣΥΝΔΕΣΗ",
    register: "Εγγραφή",
    register2: "ΕΓΓΡΑΦΗ",
    mail: "Ηλεκτρονική διεύθυνση",
    pass: "Κωδικός",
    confirmPass: "Επιβεβαίωση κωδικού",
    confirmEmail: "Επαναποστολή email?",
    checkEmail: "Ελένξτε τα email σας",
    forgotPass: "Ξέχασες τον κωδικό σου;",
    resetPass: "Eπαναφορά Κωδικού",
    passChange:"Ο κωδικός σας άλλαξε με επυτιχία",
    resetEmailText: "Το email επαναφοράς κωδικού στάλθηκε επιτυχώς στο ",
    reset: "Επαναφορά",
    send: "Αποστολή",
    returnLogin: "ΟΘΟΝΗ ΣΥΝΔΕΣΗΣ",
    emailConfirmedTitle: "Επιβεβαίωση Email",
    emailConfirmedTrue: "Το email σας επιβεβαιώθηκε επιτυχώς",
    emailConfirmedFalse: "Παρουσιάστη σφάλμα κατά την επιβεβαίωση",
    empty: "Το πεδίο δεν πρέπει να είναι άδειο",
    emailConfirmation: "Επιβεβαίωση email",
    emailSent: "Παρακαλώ επιβεβαιώστε το email που σας στάλθηκε στο ",
    emailPending: "Εκκρεμεί η επιβεβαίωση email",
    buildingIdError: "Το Αναγνωστικό Πακέτου πρέπει να περιέχει γράμματα ή νούμερα (π.χ. 6a2fc2g)",
    alphanumeric: "Το όνομα πρέπει να περιέχει μόνο γράμματα και νούμερα",
    buildingIdNameValidation: "Τα κενά και μη οι μη αλφαριθμητικοί χαρακτήρες απαγορεύονται",
    validID: "Έγκυρο Αναγνωστικό",
    invalidID: "Μη έγκυρο Αναγνωστικό",
    invalidMail: "Μη έγκυρη ηλεκτρονική διεύθυνση",
    enterValidMail: "Εισάγετε έγκυρη ηλεκτρονική διεύθυνση",
    buildingIdLabel: "Αναγνωστικό Πακέτου",
    buildingNotExist: "Δεν υπάρχει σπίτι",
    nameLabel: "Όνομα (π.χ. σπίτι)",
    shortName: "Το όνομα πρέπει να περιέχει τουλάχιστον 3 χαρακτήρες.",
    shortPass: "Ο κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες.",
    passwordNumber: "Ο κωδικός πρέπει να περιέχει τουλάχιστον 1 νούμερο.",
    passwordLowercase: "O κωδικός πρέπει να περιέχει τουλάχιστον 1 πεζό χαρακτήρα.",
    passwordUppercase: "O κωδικός πρέπει να περιέχει τουλάχιστον 1 κεφαλαίο χαρακτήρα.",
    specialCharacter: "Ειδικοί χαρακτήρες δεν επιτρέπονται",
    lengthError: "Το μήκος του ονόματος πρέπει να είναι από 4-12 χαρακτήρες",
    generalNameError: "Κάθε λέξη μπορεί να είναι από 3-20 χαρακτήρες και μόνο δύο κενά επιτρέπονται.",
    snackMail: "Μη έγκυρος κωδικός ή ηλεκτορνική διεύθυνση",
    missMatchPass: "Διαφορετικοί κωδικοί.",
    submit: "ΚΑΤΑΧΩΡΗΣΗ",
    emailExist: "Η ηλεκτρονική διεύθυνση χρησιμποποιείτε από κάποιον άλλο λογαριασμό",
    createPinTitle: "Δημιουργία τετραψήφιου PIN",
    confirmPinTitle: "Επιβεβαίωση τετραψήφιου PIN",
    enterPinTitle: "Εισαγωγή τετραψήφιου PIN",
    forgotPin: "Ξέχασες τον κωδικό σου;",
    pinSnack: "Παρακαλώ πάτησε το πεδίο \"Ξέχασες τον κωδικό σου \" για επαναφορά",
    locked: "Κλειδωμένο",
    pin: "PIN",
    pin2: "PIN",
    edit: "Επεξεργασία",
    error: "Σφάλμα",
    whitespaceError: "Τα κενά δεν επιτρέπονται.",
    wrongPin: "Λάθος PIN",
    example: "Παράδειγμα",
    pinCount: "Μπορείτε να εισάγετε το PIN",
    pinCount2: "ακόμα φορές",
    pinCount3: "ακόμα φορά",
    shortPin: "Το PIN πρέπει να είναι τετραψήφιο",
    missMatchPin: "Τα PIN είναι διαφορετικά",
    fabAdd: "Εισαγωγή νέου πακέτου",
    fabSelect: "Επιλογή",
    fabSnack: "Τώρα ελέγχετε το housemate: ",
    switches: "Διακόπτες",
    scenes: "Σενάρια",
    camera: "Κάμερα",
    alarms: "Ξυπνητήρια",
    alerts: "Υπενθυμίσεις",
    timers: "Προγ/μός Σεναρίων",
    accessRules: "Κανόνες πρόσβασης",
    location: "Τοποθεσία",
    generalInfo: "Γενικές πληροφορίες",
    news: "Νέα",
    myAccount: "ΛΟΓΑΡΙΑΣΜΟΣ",
    myAccount2: "Λογαριασμός",
    houseName: "Όνομα σπιτιού",
    nameChanged: "Το όνομα άλλαξε",
    nameChangeFailed: "Η αλλαγή ονόματος απέτυχε", 
    myName: "Το όνομα μου",
    newDevice: "Προσθήκη συσκευής",
    logout: "Εξοδος",
    logout2: "Έξοδος",
    commands: "Προγ/μός Εντολών",
    swipeRightLang: "el-GR",
    logoutDialog: "Είστε σίγουροι ότι θέλετε να αποσυνδεθείτε",
    rename: "Μετονομασία",
    rename2: "ΜΕΤΟΝΟΜΑΣΙΑ",
    newName: "Εισάγετε νέο όνομα",
    addNewScene: "ΕΙΣΑΓΕΤΕ ΝΕΟ ΣΕΝΑΡΙΟ",
    removeAll: "ΔΙΑΓΡΑΦΗ ΟΛΩΝ",
    ok: "ΟΚ",
    ok2: "οκ",
    yes:"ΝΑΙ",
    cancel: "ΑΚΥΡΩΣΗ",
    typeName: "Εισάγετε όνομα",
    thermostat: "θερμοστάτης",
    deleteDialog: "Είστε σίγουροι ότι θέλετε να διαγραφεί;",
    deleteSceneDialog: "Είστε σίγουροι ότι θέλετε να διαγραφεί το σενάριο ",
    deleteAlertDialog: "Είστε σίγουροι ότι θέλετε να διαγραφεί η υπενθύμιση ",
    deleteSwitchDialog: "Είστε σίγουροι ότι θέλετε να διαγραφεί ο διακόπτης ",
    deleteAlarmDialog: "Είστε σίγουροι ότι θέλετε να διαγραφεί το ξυπνητήρι στις ",
    deleteProgramSceneDialog: "Είστε σίγουροι ότι θέλετε να διαγραφεί το προγραμματισμένο σενάριο ",
    deleteCommandDialog: "Είστε σίγουροι ότι θέλετε να διαγραφεί η εντολή ", 
    deleteNoteDialog: "Είστε σίγουροι ότι θέλετε να διαγραφεί το post it ", 
    deleteMessageDialog: "Είστε σίγουροι ότι θέλετε να διαγραφεί το μήνυμα;",
    deleteProgramActions: "Υπάρχουν προγραμματισμένα σενάρια για διαγραφή ",
    delete: "ΔΙΑΓΡΑΦΗ",
    deleteLower: "Διαγραφή",
    deleteAll: "ΔΙΑΓΡΑΦΗ ΟΛΩΝ",
    deleteAllInvites : "Είστε σίγουροι ότι θέλετε να διαγραφούν όλες τις προσκλήσεις ",
    deleteAllMessages: "Είστε σίγουροι ότι θέλετε να διαγραφούν όλα τα μηνύματα;",
    deleteInvite: "Είστε σίγουροι ότι θέλετε να διαγραφεί η πρόσκληση απο τον/την ",
    stopAlert: "Κλείσιμο",
    questionMark: ";",
    videoCall: "βιντεοκλήση",
    watch: "προβολή",
    create: "ΔΗΜΙΟΥΡΓΙΑ",
    alarmInput: "Εισάγετε όνομα (προαιρετικό)",
    time: "ώρα",
    date: "ημερομηνία",
    repeat: "επανάληψη",
    mon: "Δευ",
    tue: "Τρι",
    wed: "Τετ",
    thu: "Πεμ",
    fri: "Παρ",
    sat: "Σαβ",
    sun: "Κυρ",
    selectFields: "Παρακαλώ επιλέξτε πεδία",
    devices: "Συσκευές",
    save: "αποθήκευση",
    save2: "ΑΠΟΘΗΚΕΥΣΗ",
    deleteAccount: "ΔΙΑΓΡΑΦΗ ΛΟΓΑΡΙΑΣΜΟΥ",
    deleteExplanation: "Θα λάβετε email για την διαγραφή το λογαριασμού σας",
    alertInput: "Εισάγετε μήνυμα υπενθύμισης",
    commandInput: "Εισάγετε εντολή",
    postitInput: "Εισάγετε μήνυμα",
    sceneSnack: "Ενεργοποίησα το σενάριο ",
    add: "ΠΡΟΣΘΗΚΗ",
    accessDevices: "πρόσβαση σε διακόπτες",
    accessCamera: "πρόσβαση στην κάμερα",
    accessActions: "πρόσβαση στις ενέργειες",
    accessScenes: "πρόσβαση στις σκηνές",
    receiveEmNot: "ειδοποιήση έκτακτης ανάγκης",
    addGuest: "Εισαγωγή μέλους",
    guestInput: "Εισάγετε την ηλεκτρονική διεύθυνση του μέλους",
    guestNameInput: "Εισάγετε νέο όνομα μέλους",
    weather: "καιρός",
    weatherCom: "Θα χρησιμοποιηθεί σε εντολές όπως \"Τι καιρό θα κάνει αύριο\"",
    timezone: "ζώνη ώρας",
    timezoneCom: "Θα χρησιμοποιηθεί σε εντολές όπως \"Τι ώρα είναι\"",
    newsTitle: "Πόσες επικεφαλίδες νέων θέλετε να ακούτε;",
    registrationFail: "Η εγγραφή απέτυχε. Παρακαλώ προσπαθήστε ξανά",
    deleteAllScenes: "Είσαι σίγουρος ότι θέλεις να διαγράψεις όλα τα σενάρια;",
    deleteAllPosts: "Είσαι σίγουρος ότι θέλεις να διαγράψεις όλα τα post it;",
    noDevices: "Δεν έχεις καμία συσκευή",
    guests: "Μέλη",
    typeToSearch: "Πληκτρολόγησε για αναζήτηση",
    saved: "Αποθηκεύτηκε",
    wrongNameType: "Λάθος τύπος ονόματος",
    smallName: "Το όνομα πρέπει να περιέχει πάνω από 4 χαρακτήρες",
    emptyName: "Το πεδίο του ονόματος είναι κενό",
    emptyDevices: "Δεν υπάρχουν επιλεγμένες συσκευές",
    noScenariosSelected: "Δεν υπάρχουν επιλεγμένα σενάρια",
    pickTime: "Επέλεξε την ώρα",
    renameSwitchName: "Παρακαλώ εισάγετε όνομα χωρίς κενά στην αρχή",
    updated: "Ενημερώθηκε",
    timeError: "Δεν μπορείτε να εισάγετε περασμένη ώρα",
    options: "Ρυθμίσεις",
    switchAlreadyExists: "Ο Διακόπτης υπάρχει ήδη",
    addingSwitch: "Προσθήκη διακόπτη",
    addSwitch: "Προσθήκη συσκευής",
    name: "Όνομα",
    notes: "Post it",
    energy: "Energy Metering",
    changeKeyword: "Αλλαγή keyword",
    eleeza: "Ελάϊζα",
    hey_eleeza: "Hey Ελίζα",
    message_archive: "Αρχείο μηνυμάτων",
    record_voice: "Εγγραφή φωνής",
    record_again: "Επανάληψη εγγραφής",
    recording: "Εγγραφή...",
    record_finished: "Εγγραφή Ολοκληρώθηκε",
    show_hidden_switches: "Κρυφοί Διακόπτες",
    hideSwitch: "ΑΠΟΚΡΥΨΗ",
    unhide: "ΕΜΦΑΝΙΣΗ",
    showSwitch: "Είστε σίγουροι οτι θέλετε να εμφανίσετε το ",
    sendCommand: "Στάλθηκε η εντολή:",
    sendCommandFail: "Αποτυχία αποστολής της εντολής!",
    multiplePlan: "ΠΟΛΛΑΠΛΟΣ ΠΡΟΓ/ΜΟΣ",
    invitations: "Προσκλήσεις",
    accept: "ΑΠΟΔΟΧΗ",
    confirmBiometrics: "Δακτυλικό αποτύπωμα για την εφαρμογή Housemate",
    confirmFaceID: "FaceID για την εφαρμογή Housemate",
    enterEmailAddress: 'Παρακαλώ εισάγετε ηλεκτρονική διεύθυνση.',
    accessDenied: "Δεν έχετε πρόσβαση.",
    scanPackage: 'Σαρώστε το πακέτο σας',
    scanPackageMsg: "Δεν έχετε πακέτο, σαρώστε τον κωδικό QR του πακέτου σας",
    allerRetour2: 'ALLER-RETOUR',
    allerRetourDelete: 'ΑΚΥΡΩΣΗ ALLER-RETOUR',
    allerRetourText: 'Aller-Retour με:',
    deviceSelectionNone: 'Παρακαλώ επιλέξτε συσκευή για να προχωρήσετε',
    hiddenEmpty: 'Δεν υπάρχουν κρυφοί διακόπτες',
    //add Device Screen
    addDeviceDefault: 'Δεν βρέθηκε συσκευή',
    InviteError : "Η πρόσκληση δεν είναι ενεργή",
    switch: 'Διακόπτης - ',
    loggedIn: "Συνδεθήκατε με επιτυχία",
    connecting: 'Προσπάθεια σύνδεσης',
    acceptOnDevice:"Πατήστε αποδοχή στη Κεντρική Μονάδα housemate",
    inviteAccepted: 'Συνδεθήκατε στο πακέτο',
    success:  'Η νέα σας συσκευή είναι έτοιμη!',
    wait: 'Παρακαλώ, περιμένετε...',
    search: 'Αναζήτηση...',

    open: "ΑΝΟΙΓΜΑ",
    openSettingsMsg: "Παρακαλώ ενεργοποιήστε την άδεια για το μικρόφωνο ή/και την αναγνώριση ομιλίας από τις ρυθμίσεις.",
    openSettingsTitle: "Άνοιγμα Ρυθμίσεων",
    openSettingsMsg2: "Παρακαλώ ενεργοποιήστε την άδεια για το μικρόφωνο ή/και την κάμερα από τις ρυθμίσεις.",
    holdON:"Προσοχή!",
    exitAppQuestion:"Είστε βέβαιοι ότι θέλετε να βγείτε από την εφαρμογή;",
    noInternet:"Δεν έχετε πρόσβαση στο ίντερνετ!",
    pleaseSelectDevice:"Επιλέξτε μία συσκευή",
  },

  de: {
    login: "Anmeldung",
    login2: "ANMELDUNG",
    register: "Registrieren",
    register2: "REGISTRIEREN",
    mail: "Email",
    pass: "Passwort",
    confirmPass: "Passwort bestätigen",
    confirmEmail: "Resend confirmation email?", //TODO
    checkEmail: "Check your mail",
    forgotPass: "Passwort vergessen?",
    resetPass: "Setze dein Passwort zurück",
    passChange:"Your password change successfully", //TODO
    resetEmailText: "A password reset email has been sent to ", //TODO
    reset: "Zurücksetzen",
    send: "Send", //TODO
    empty: "Sollte nicht leer sein",
    buildingIdError: "Building ID  muss alphanumerisch sein (i.e. 6a2fc2g)",
    alphanumeric: "Muss alphanumerisch sein",
    buildingIdNameValidation: "Leerzeichen & nicht alphanumerische Zeichen sind nicht zulässig",
    validID: "Gültige ID",
    invalidID: "Ungültige ID",
    invalidMail: "Dies ist eine ungültige E-mail Adresse",
    enterValidMail: "Gültige E-Mail-Adresse eingeben",
    buildingIdLabel: "BuildingID",
    buildingNotExist: "Building doesn't exist", //TODO
    nameLabel: "Name (i.e Heimat)",
    shortName: "Name needs to be at least 3 characters", //TODO
    shortPass: "Passwort muss mindestens 8 Zeichen lang sein",
    passwordNumber: "Password must contain at least 1 number", //TODO
    passwordLowercase: "Password must contain at least 1 lowercase character", //TODO
    snackMail: "Die eingegebene E-Mail Adresse oder das eingegebene Passwort ist falsch",
    missMatchPass: "Passwörter stimmen nicht überein",
    submit: "EINREICHEN",
    emailExist: "E-Mail wird für ein anderes Konto verwendet",
    createPinTitle: "Erstellen Sie eine 4-stellige PIN",
    confirmPinTitle: "Bestätigen Sie eine 4-stellige PIN",
    enterPinTitle: "Geben Sie Ihre 4-stellige PIN ein",
    forgotPin: "PIN vergessen ?",
    pinSnack: "Bitte drücken Sie zum Zurücksetzen \"Pin vergessen?\"",
    locked: "Gesperrt",
    pin: "pin",
    pin2: "Pin",
    edit: "Bearbeiten",
    error: "Error",
    whitespaceError: "Leerzeichen nicht erlaubt",
    wrongPin: "Falsche Pin",
    example: "Beispiel",
    pinCount: "Sie können die PIN eingeben",
    pinCount2: "mehrere Mal",
    pinCount3: "mehr Mal",
    shortPin: "Pin muss 4-stellig sein",
    missMatchPin: "Pin stimmt nicht überein",
    fabAdd: "Neues Paket hinzufügen",
    fabSelect: "Auswählen",
    fabSnack: "Du kontrollierst jetzt housemate: ",
    switches: "Schalter",
    scenes: "Szenen",
    camera: "Kamera",
    alarms: "Alarm",
    alerts: "Warnungen",
    timers: "Timer",
    accessRules: "Zugriffsregeln",
    location: "Lage",
    news: "Nachrichten",
    newDevice: "neues Gerät",
    logout: "Ausloggen",
    logout2: "Ausloggen",
    commands: "Befehle",
    swipeRightLang: "de-DE",
    logoutDialog: "Sind Sie sicher, dass Sie sich abmelden möchten?",
    rename: "umbenennen",
    rename2: "UMBENENNEN",
    newName: "Geben Sie einen neuen Namen ein",
    addNewScene: "NEUE SZENE HINZUFÜGEN",
    removeAll: "ALLES ENTFERNEN",
    ok: "OK",
    ok2: "ok",
    yes:"JA",
    cancel: "ABBRECHEN",
    typeName: "Geben Sie einen Namen ein",
    thermostat: "Thermostat",
    deleteDialog: "Sind Sie sicher, dass Sie löschen möchten",
    deleteSceneDialog: "Sind Sie sicher, dass Sie den Szene löschen möchten ",
    deleteAlertDialog: "Sind Sie sicher, dass Sie den Alert löschen möchten ",
    deleteSwitchDialog: "Sind Sie sicher, dass Sie den Schalten löschen möchten ",
    deleteAlarmDialog: "Sind Sie sicher, dass Sie den Alarm löschen möchten bei ",
    deleteProgramSceneDialog: "Sind Sie sicher, dass Sie geplante Szene löschen möchten ",
    deleteCommandDialog: "Sind Sie sicher, dass Sie Befehl löschen möchten ", 
    deleteNoteDialog: "Sind Sie sicher, dass Sie Post it löschen möchten ", 
    deleteMessageDialog: "Möchten Sie diese Nachricht wirklich löschen?",
    deleteProgramActions: "Es gibt programmierbare Szenarien zum Löschen ",
    delete: "LÖSCHEN",
    deleteLower: "Löschen",
    deleteAll: "ALLES LÖSCHEN",
    deleteAllInvites : "Sind Sie sicher, dass Sie alle Einladungen löschen möchten?",
    deleteAllMessages: "Sind Sie sicher, dass Sie alle Nachrichten löschen wollen?",
    deleteInvite:"Are you sure you want to delete the invite from ", //TODO
    stopAlert: "Halt",
    questionMark: "?",
    videoCall: "Videoanruf",
    watch: "sehen",
    create: "SCHAFFEN",
    alarmInput: "Geben Sie einen Namen ein (optional)",
    time: "Zeit",
    date: "Datum",
    repeat: "wiederholen",
    mon: "Mon",
    tue: "Di",
    wed: "Wed",
    thu: "Do",
    fri: "Fr",
    sat: "Sa",
    sun: "Son",
    selectFields: "Bitte Felder auswählen",
    devices: "Geräte",
    save: "speichern",
    save2: "SPEICHERN",
    deleteAccount: "Konto löschen", //TODO
    deleteExplanation: "You will receive an email to delete your account", //TODO
    alertInput: "Geben Sie die Nachricht der Warnung ein",
    sceneSnack: "Aktivierte Szene ",
    add: "HINZUFÜGEN",
    accessDevices: "Zugriffsgeräte",
    accessCamera: "Auf Kamera zugreifen",
    accessActions: "Auf Aktionen zugreifen",
    accessScenes: "Zugriffsszenen",
    receiveEmNot: "Notfallbenachrichtigung erhalten",
    addGuest: "fügen Sie Ihren Gast hinzu",
    guestInput: "Geben Sie die E-Mail-Adresse des Gastes ein",
    guestNameInput: "Geben Sie den Namen des Gastes ein",
    weather: "Wetter",
    weatherCom: "Wird in Befehlen verwendet wie \"Wie ist das Wetter?\" ",
    timezone: "Zeitzone",
    timezoneCom: "Wird in Befehlen wie \"Wie spät ist es?\" verwendet werden",
    newsTitle: "Wie viele Schlagzeilen möchtest du hören?",
    registrationFail: "Registrierung fehlgeschlagen. Bitte versuchen Sie es erneut",
    deleteAllScenes: "Möchten Sie wirklich alle Szenen löschen?",
    deleteAllPosts: "Sind Sie sicher, dass Sie alle Beiträge löschen möchten?",
    noDevices: "Sie haben keine Geräte",
    guests: "Gäste",
    typeToSearch: "Geben Sie eine Nachricht ein, um zu suchen",
    saved: "Gerettet",
    wrongNameType: "Falscher Namenstyp",
    emptyName: "Das Namensfeld ist leer",
    smallName: "Der Name muss mehr als 4 Zeichen enthalten",
    emptyDevices: "Keine Geräte ausgewählt",
    noScenariosSelected: "Es gibt keine ausgewählten Szenarien",
    pickTime: "Zeit auswählen",
    renameSwitchName: "Bitte Namen ohne Leerzeichen eingeben",
    updated: "Aktualisiert",
    timeError: "Sie können die vergangene Zeit nicht eingeben",
    options: "Optionen",
    switchAlreadyExists: "Schalter existiert bereits",
    addingSwitch: "Schalter hinzufügen",
    addSwitch: "Gerät hinzufügen",
    name: "Name",
    notes: "Post it",
    energy: "Energy Metering",
    commandInput: "Befehl eingeben",
    postitInput: "Nachricht eingeben",
    changeKeyword: "Schlüsselwort ändern",
    eleeza: "Eleaza",
    hey_eleeza: "Eliza",
    message_archive: "Mitteilungen",
    record_voice: "Aufnahmestimme",
    record_again: "Erneut aufnehmen",
    recording: "Aufzeichnung...",
    record_finished: "Beendete Aufnahme",
    show_hidden_switches: "Ausgeblendete Schalter anzeigen",
    hideSwitch: "Verstecken",
    unhide: "Aussehen",
    showSwitch: "Are you sure you want to unhide ", //TODO
    sendCommand: "Befehl senden:",
    sendCommandFail: "Befehl senden gescheitert !",
    multiplePlan: "MULTIPLE SCHEDULE",
    invitations: "Invites",
    accept: "ACCEPT",
    confirmBiometrics: "Confirm fingerprint for Housemate",
    confirmFaceID: "Confirm FaceID for Housemate",
    enterEmailAddress: 'Geben Sie Ihre E-Mail Adresse ein.',
    accessDenied: "You don't have access.", //TODO
    scanPackage: 'Scan your package', //TODO
    scanPackageMsg: "You have no package, scan your package's QR code", //TODO
    allerRetour2: 'ALLER-RETOUR',
    allerRetourDelete: 'CANCEL ALLER-RETOUR',
    allerRetourText: 'Aller-Retour mit:',
    deviceSelectionNone: 'Please select a device to proceed',
    hiddenEmpty: "No hidden switches",

    //add Device Screen   TODO!!!!
    addDeviceDefault: 'No devices found',
    InviteError : "Inactive invitation",
    switch: 'Switch - ',
    loggedIn: "You have successfully logged in",
    connecting: 'Trying to connect',
    acceptOnDevice:"Please press accept in your device",
    inviteAccepted: 'Connected to package!',
    success:  'Your new device is ready!',
    wait: 'Please, wait...',
    search: 'Search...',
    open: "Öffnung",
    openSettingsMsg: "Please enable microphone and/or speech recognition permission from settings.", //TODO
    openSettingsTitle: "Open Settings", //TODO
    openSettingsMsg2: "Please enable microphone and/or camera permission from settings.", //TODO
    holdON:"Festhalten!",
    exitAppQuestion:"Möchten Sie die App wirklich beenden?",
    noInternet:"There is no internet connection!", //TODO
    pleaseSelectDevice:"Please select a device",//TODO
  },
});
