import React, {useState} from "react";
import ModalView from "./ModalView";
import './NewMain.css';

function ModalDouble(props) {

    
    return (
        <ModalView open={props.isOpen} close={() => props.closeModal()} sizeStyle={{width: "35%", height: "20%"}} >
            <div className="modalDouble">        
                <div className="modalTitle">
                    <p className="modalTextRemoveBuildings">{props.title}</p>
                </div>
                <div className="modalButtons">
                    <div className="cancel-button" onClick={props.closeModal}>{props.translations.cancel}</div>
                    <div className="confirm-button" onClick={() => {
                        props.ok();
                        props.closeModal();
                    }}>{props.rightButton}</div>
                </div>
            </div>
        </ModalView>
    )

}

export default ModalDouble;