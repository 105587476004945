function RadioButtonPermissions(props){
    return(
    <>
        <div onClick={() => { props.onClick("admin") }}>
                <input key={"admin"} type="radio" id="admin" onChange={e => {}} checked={props.permission == "admin"} />  {/* newUser.permission == "admin" */}
                <label>Admin</label>
        </div>

        <div onClick={() => { props.onClick("family") }}>
                <input key={"family"} type="radio" id="family" onChange={e => {}} checked={props.permission == "family"} /> {/*newUser.permission == "family" */}
                <label>Family</label>
        </div>

        <div onClick={() => { props.onClick("guest") }}>
                <input key={"guest"} type="radio" id="guest" onChange={e => {}} checked={props.permission =="guest"} /> {/*newUser.permission == "guest" */}
                <label>Guest</label>
        </div>
    </>
    )
}

export default RadioButtonPermissions