import React, { useState, useEffect } from 'react';
import './NewMain.css';
import { renameCurrentSwitch, updateSwitchNames, hideSwitch, deleteSwitches, deleteAllerRetourSwitches, unhideSwitch } from '../../buildingFunctions'
import AddAllerRetour from './AddAllerRetour'
import AddSwitchNames from './AddSwitchNames'
import MenuCard from './MenuCard';
import RenameSwitch from './renameSwitch';
import logger from '../Functions/logger';


function SwitchesEditMenu(props) {
    const [name, setName] = useState(props.selectedSw.name)
    const [openAllerRetourModal, setOpenAllerRetourModal] = useState(false);
    const [openNamesModal, setOpenNamesModal] = useState(false);
    const [openRenameSwitchModal, setOpenRenameSwitchModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [menuModalSelection, setMenuModalSelection] = useState("");
    const switches = props.switches;
    let allerRetourStatus = false;


    logger.log("switchess:", props.switches);

    function goBack() {
        props.changeMenu(0);
    }

    function changeSwitchName(name, mSwitch) {
        renameCurrentSwitch(name, mSwitch).then(() => {
            props.openSnackbar(props.translations.successfullRename)
        }).catch(() => {
            props.openSnackbar(props.translations.failRename)
        }).finally(() => {
            goBack();
        })
    }

    function switchNamesView() {
        return (
            <AddSwitchNames openModal={openNamesModal} close={() => setOpenNamesModal(false)}
                closePrevious={props.handleClose} selectedSw={props.selectedSw} openSnackbar={props.openSnackbar} translations={props.translations} >
            </AddSwitchNames>)
    }

    function renameSwitchView() {
        return (
            <RenameSwitch openModal={openRenameSwitchModal} close={() => setOpenRenameSwitchModal(false)}
                openSnackbar={props.openSnackbar} selectedSw={props.selectedSw} closePrevious={props.handleClose}
                name={name} changeSwitchName={changeSwitchName} setName={setName} translations={props.translations}>
            </RenameSwitch>)
    }

    function allerRetourView() {
        return (
            <AddAllerRetour openModal={openAllerRetourModal} close={() => setOpenAllerRetourModal(false)} closePrevious={props.handleClose}
                switches={switches} selectedSw={props.selectedSw} openSnackbar={props.openSnackbar} translations={props.translations}>
            </AddAllerRetour>)
        //changeSwitchName(name, props.selectedSw)
    }


    function hideUnhideSwitches(mSwitch) {
        if (!mSwitch.hidden) {
            hideSwitch(mSwitch).then(() => {
                props.openSnackbar(props.translations.successfullHide)
            }).catch(() => {
                props.openSnackbar(props.translations.failHide)
            }).finally(() => {
                goBack();
                logger.log("Error on hide");
            })
        } else {
            unhideSwitch(mSwitch).then(() => {
                props.openSnackbar(props.translations.successfullShow)
            }).catch(() => {
                props.openSnackbar(props.translations.failShow)
            }).finally(() => {
                goBack();
                logger.log("Error on unhide");
            })
        }
    }


    function hideSwitches(mSwitch) {
        if (showConfirmModal) {
            hideSwitch(mSwitch).then(() => {
                props.openSnackbar(props.translations.successfullHide)
            }).catch(() => {
                props.openSnackbar(props.translations.failHide)
            }).finally(() => {
                goBack();
            })
        } else {
            props.openSnackbar(props.translations.cancelHide);
        }
    }

    function unhideSwitches(mSwitch) {
        if (showConfirmModal) {
            unhideSwitch(mSwitch).then(() => {
                props.openSnackbar(props.translations.successfullShow)
            }).catch(() => {
                props.openSnackbar(props.translations.failShow)
            }).finally(() => {
                goBack();
            })
        } else {
            props.openSnackbar(props.translations.cancelShow);
        }
    }

    function deleteSwitch(mSwitch) {
        if (showConfirmModal) {
            deleteSwitches(mSwitch).then(() => {
                props.openSnackbar(props.translations.successfullDelete);
            }).catch(() => {
                props.openSnackbar(props.translations.failDelete);
            }).finally(() => {
                goBack();
            })
        } else {
            props.openSnackbar(props.translations.cancelDelete);
        }
    }

    function checkAllerRetourStatus() {
        allerRetourStatus = props.ARgroups.some(array => {
            return array.some(obj => obj === props.selectedSw.id)
        });
    }

    function AllerRetourButton(status) {
        if (!allerRetourStatus) {
            setOpenAllerRetourModal(true)
        } else {
            deleteAllerRetourSwitches(props.selectedSw.id).then(() => {
                props.openSnackbar(props.translations.successfullCancelAllerRetour)
            }).catch(() => {
                props.openSnackbar(props.translations.failCancelAllerRetour)
            }).finally(() => {
                allerRetourStatus = false;
                goBack();
            })
        }
    }

    function showModal() {
        return (
            <>
                {showConfirmModal && (
                    <div className="modal">
                        <div className="modal-content">
                            {menuModalSelection === "hide" ? (
                                <>
                                    <div>
                                        <p className="modalTextRemoveBuilding">{!props.selectedSw.hidden ? props.translations.hideSwitch : props.translations.unhideSwitch + props.selectedSw.name + " ?"}</p>
                                    </div>
                                    <div className="modalButtons">
                                        <div className="cancel-button" onClick={() => setShowConfirmModal(false)}>{props.translations.cancel}</div>
                                        <div className="confirm-button" onClick={() => {
                                            setShowConfirmModal(false);
                                            hideSwitches(props.selectedSw);
                                        }}>{!props.selectedSw.hidden ? props.translations.hideCaps : props.translations.show}</div>
                                    </div>
                                </>
                            ) : menuModalSelection === "unhide" ? (
                                <>
                                    <div>
                                        <p className="modalTextRemoveBuilding">{props.translations.unhideSwitch + props.selectedSw.name + " ?"}</p>
                                    </div>
                                    <div className="modalButtons">
                                        <div className="cancel-button" onClick={() => setShowConfirmModal(false)}>{props.translations.cancel}</div>
                                        <div className="confirm-button" onClick={() => {
                                            setShowConfirmModal(false);
                                            unhideSwitches(props.selectedSw);
                                        }}>{props.translations.show}</div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <p className="modalTextRemoveBuilding">{props.translations.deleteSwitch + props.selectedSw.name + " ?"}</p>
                                    </div>
                                    <div className="modalButtons">
                                        <div className="cancel-button" onClick={() => setShowConfirmModal(false)}>{props.translations.cancel}</div>
                                        <div className="confirm-button" onClick={() => {
                                            setShowConfirmModal(false);
                                            deleteSwitch(props.selectedSw.id);
                                        }}>{props.translations.delete}</div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }

    function hideUnhide_devices() {
        if (props.selectedSw.hidden) {
            setMenuModalSelection("unhide");
            setShowConfirmModal(true);
        } else {
            setShowConfirmModal(true);
            setMenuModalSelection("hide");
        }
    }

    const containerHeader = (
        <div className="titleArea sceneTitleLabel">
            <div className="buttonNameSwitch"> <div className='editSwitchesLabel' style={{ fontWeight: "bold", fontSize: "18px" }}>{props.selectedSw.name}</div></div>
        </div>
    )

    return (
        <MenuCard
            url={require("./images/senaria_orange_02.png")}
            title={props.translations.edit}
            menuCard={"menuCard scenarioCard editSwitchCard"}
            containerHeader={containerHeader}
            cardHeaderBackColor={"switchEditCardHeader"}
            cardTitle={"cardTitle cardTitleColor"}
        >
            <div className="menuSwitches">
                {checkAllerRetourStatus()}
                <div className="buttonsSwitches" onClick={() => setOpenRenameSwitchModal(true)}> <div className='editSwitchesLabel'>{props.translations.rename}</div></div>
                <div className="buttonsSwitches" onClick={() => setOpenNamesModal(true)}><div className='editSwitchesLabel'>{props.translations.addName}</div></div>
                <div className="buttonsSwitches" onClick={() => AllerRetourButton(allerRetourStatus)}><div className='editSwitchesLabel'>{allerRetourStatus ? props.translations.cancelAllerRetour : props.translations.allerRetour}</div></div>
                <div className="buttonsSwitches">
                    <div className="hideSwitchesLabel" onClick={() => hideUnhideSwitches(props.selectedSw)}>
                        <div>
                            {props.translations.hide}
                        </div>
                        {props.selectedSw.hidden ? <div className='hiddenIndicatorOn'></div> : <div className='hiddenIndicatorOff'></div>}
                    </div>
                </div>

                <div className="buttonsSwitches" onClick={() => {
                    setShowConfirmModal(true);
                    setMenuModalSelection("delete");
                }}>
                    <div className='editSwitchesLabel'>{props.translations.deleteLow}</div>
                </div>
                {renameSwitchView()}
                {switchNamesView()}
                {allerRetourView()}
                {showModal()}
            </div>
        </MenuCard>
    );
}

export default SwitchesEditMenu;