module.exports = {
    specialChars: {
        sceneName: /^(?=.{1,20}$)(?!(?:.*\s){4})(?!^\d+$)[\p{L}\d_]{2,20}(?:\s[\p{L}\d_]{2,20})*$/u,
        // Check that scene name contains up to 3
        sceneNameSpaces: /(?:.*\s){4}/,
        // Check that name is not only numbers
        nameIsNaN: /^\d+$/,
        deviceName: /^(?=.{1,20}$)(?!(?:.*\s){4})(?!^\d+$)[\p{L}\d_]{2,20}(?:\s[\p{L}\d_]{2,20})*$/u,
        buildingName: /^(?=.{1,20}$)(?!(?:.*\s){3})(?!^\d+$)[\p{L}\d_]{2,20}(?:\s[\p{L}\d_]{2,20})*$/u,
        userName: /^(?=[\p{L}\d_ ]{2,22}$)(?!.*\s.*\s.*)(?!^\d+$|^[~`!@#$%.\^&*()+=_\-\[\]\\';,/{}|\\":<>\?]+$)/u,
        renameBuilding: function (name) {
            return !this.buildingName.test(name)
        },
        checkSceneFormat: function (name) {
            return !this.sceneName.test(name);
        },
        checkSwitchFormat: function (name) {
            return !this.deviceName.test(name)
        },
        checkDimmerFormat: function (name) {
            return !this.deviceName.test(name);
        },
        checkUserName: function(name) {
            return !this.userName.test(name);
        },
    },

    action: {
        actionTime: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]?$/,

        actionName: /^[ά-ώΆ-Ώ-α-ωΑ-Ω-a-zA-Z0-9_ ]*/,

        actionDate: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,

        time: function (time) {
            return this.actionTime.test(time)
        },

        date: function (date) {
            return this.actionDate.test(date)
        }
    }
}