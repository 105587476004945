import { React, useState, useEffect, useRef } from 'react';
import NewAccessMessage from './NewAccessMessage';
import { updateActions } from '../../ActionFunctions';
import MenuCard from './MenuCard'
import { checkArrayLength } from '../Functions/helperFunctions';
import NewOnOff from './NewOnOff';
import { act } from 'react';
import NewButtons from './NewButtons';
import logger from '../Functions/logger';

function NewProScene(props) {

    const [scenes, setScenes] = useState([]);
    const [actions, setActions] = useState(props.actions);
    const nextProps = useRef(props);

    useEffect(() => {
        logger.log("MOUNT");
        logger.log("");
        logger.log("State scenes:", scenes);
        logger.log("Actions:", props.actions);
        // logger.log("scenes:", props.scenes);
        onSaveRefresh();
    }, []) // Empty dependency array ensures this runs only once on mount

    useEffect(() => {
        if (nextProps !== props) {
            logger.log("================================================");
            logger.log("test");
            logger.log("==================");

            onSaveRefresh();
        }
        nextProps.current = props;
    }, [props])

    const enableScene = (name) => {
        props.proSceneName(name)
    }

    const onSaveRefresh = () => {
        logger.log("refresh");
        let scenesInActions = [];
        let actionsToBeDeleted = [];
        let proScenes = [];
        scenesInActions = props.actions.filter(action => action.type === "scene")
        logger.log("Initial actions:", scenesInActions);
        logger.log("props.scenes:", props.scenes);

        scenesInActions.forEach(action => {
            let found = props.scenes.find(scene =>
                scene.name === action.text
            )
            if (found === undefined) {
                actionsToBeDeleted.push(action)
            }
            else {
                proScenes.push(action);
            }
        })

        props.scenes.forEach(scene => {
            let found = scenesInActions.find(action =>
                action.text === scene.name
            )
            if (found === undefined) {
                proScenes.push(scene);
            }
        })



        // logger.log("scenes in actions:", scenesInActions);
        logger.log("deleted:", actionsToBeDeleted);
        setScenes(proScenes);
    }
    const DeactivateAction = (enabled, id) => {
        logger.log("deactivate");
        logger.log(enabled);
        logger.log(id);
        let index = props.actions.findIndex(action => action.id === id);
        logger.log("index: " + index);
        if (enabled) {
            props.actions[index].enabled = false;
            setActions(props.actions);
        }
        else {
            props.actions[index].enabled = true;
            setActions(props.actions);
        }
        updateActions(props.actions[index]).then((responseData) => {
            changeMenu();
        }).catch((error) => { });
    }
    const renderScene = (scene, actions) => {
        //change scene to action
        if (props.id === "name") {
            if (typeof props.scenes !== "undefined") {
                let index = props.scenes.findIndex(scene => scene.text === scene.name);
                if (index > -1)
                    scene.enabled = props.scenes[index].enabled;
            }

        }
        //  let found = actions.findIndex(action => action.id === scene.id);
        // logger.log("enabled: ", scene.enabled);
        // if (scene.text !== undefined) {
        return (

            <div
                className="commandProgrammingTable"
                key={scene.id}
            >
                <div className='scenarioTableImageResize' >
                    {props.permission === "admin" ? <img onClick={() => { props.sceneClicked(scene.text ? scene.id : scene.name) }} src={require("./images/edit_dark_blue_01.png")} ></img> : <></>}
                </div>
                <div onClick={() => { if (scene.name) { props.sceneClicked(scene.name) } }} className="commandProgrammingRow" >
                    <span className="sceneName">{scene.text ?? scene.name}</span>
                </div>
                {scene.text !== undefined && <div className="toggleContainerExtra">
                    <NewOnOff
                        repeat={scenes}
                        OnOff={() => { props.sceneActivate(scene) }}
                        isEnabled={scene.enabled}
                    ></NewOnOff>
                </div>}
            </div>
        );
    }

    const renderScenes = (scenes, actions) => {

        return scenes.map((aScene) => {
            return (<div className="proSceneDivColor">{renderScene(aScene, actions)}</div>)
        })

    }



    const containerHeader = (
        <></>
    )

    const buttonFunction = () => {
        props.sceneClicked("")
    }

    const buttonArray = [{ text: props.new, onClickFunction: buttonFunction }]


    const containerFooter = (
        props.permission == "admin" &&

        <NewButtons
            buttonArray={buttonArray}
        >
        </NewButtons>


    )

    if (props.permission == "guest") {
        return (
            <MenuCard
                menuCard={"menuCardMessage"}
                cardHeaderBackColor={"cardHeader menuCardMessageHeader"}
                cardContainer={"cardContainerMessage"}
                cardTitle={"cardTitle"}

                title={props.translations.scenarioProgCreation}
            >
                <div style={{ paddingBlock: '13%', paddingInline: '15%', fontSize: 'large' }}>
                    {props.translations.noPermissionToService}
                </div>
            </MenuCard>
        )
    }


    if (props.scenes.length <= 0) {
        <MenuCard
            menuCard={"menuCardMessage menuCardMessageWidth"}
            cardHeaderBackColor={"cardHeader menuCardMessageHeaderScenarioSchedule"}
            cardContainer={"cardContainerMessage"}
            title={props.translations.scenarioProgCreation}
            cardTitle={"cardTitle"}
            containerFooter={containerFooter}
        >
            <div style={{ paddingBlock: '18%' }}>
                {props.translations.thereAreNoText1 + " " + props.translations.scenarioProgCreation.toLowerCase() + " " + props.translations.thereAreNoText2}
            </div>
        </MenuCard>
    }

    return (
        <MenuCard
            url={require("./images/senaria_orange_02.png")}
            title={props.translations.scenarioProgCreation}
            containerFooter={containerFooter}
            menuCard={"menuCard scenarioCard"}
            cardTitle={"cardTitle cardTitleColor"}

        >
            <div className="column">
                {renderScenes(scenes, props.actions)}
            </div>
        </MenuCard>
    );
}

export default NewProScene;