import storage from './Storage';
import { guestInvitesUrl } from './urls'

export function fetchInvites() {
    const DATA = {
        method: 'GET',
    };
    return storage.FetchData(guestInvitesUrl, DATA);
}

export function deleteInvites(id) {
    const DATA = {
        method: 'DELETE',
        body: JSON.stringify({
            invite: id
        })
    };
    return storage.FetchData(guestInvitesUrl, DATA);
}

export function deleteAllInvites(id) {
    const DATA = {
        method: 'DELETE',
    };
    return storage.FetchData(guestInvitesUrl + '/' + id, DATA);
}

export function acceptInvites(id) {
    const DATA = {
        method: 'put',
        body: JSON.stringify({
            invite: id
        })
    };
    return storage.FetchData(guestInvitesUrl, DATA);
}