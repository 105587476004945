import React from 'react';


function MenuCard(props) {

    return (
        <div id="menuCardMainContainer" className={`${props.menuCard}`}>
            <div className={`${props.cardHeaderBackColor}`}>
                <div className={`${props.cardTitle}`}>
                    {props.url && <div className='cardHeaderImg'>
                        <img src={props.url} alt="" />
                    </div>}
                    <span style={{ alignItems: 'center' }}>{props.title}</span>
                </div>
                <div>
                    {props.buttons}
                </div>
            </div>
            <div className={`cardContainer ${props.cardContainer} ${props.tableCss} ${props.sceneCreateButton} ${props.cardContainerExtraPadding}`}>
                {props.containerHeader && props.containerHeader}
                <div className={'containerColumn'}>
                    {props.children}
                </div>
                <div>{props.containerFooter && props.containerFooter}</div>
            </div>
        </div>
    )
}
export default MenuCard;