import React from 'react';
//import '../css/Clock.css';

class NewActionDays extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let days = this.props.translations.days
    return (
      <div className="daysContainer ">
        {Object.keys(days).map((day) =>
          <div className={`daysSelector ${this.props.day[day] ? "dayOn" : "dayOff"}`}
            onClick={() => { this.props.dayClicked(day) }} key={day}>
            {days[day].substring(0, 3).toUpperCase()}
          </div>)
        }
      </div>
    );
  }
}

export default NewActionDays;