import React from 'react';
//import '../css/Clock.css';
import logger from '../Functions/logger';

class CustomTable extends React.Component {
    constructor(props) {
        super(props);
        
    }



    renderItems = (items) => {
        if(items == undefined){
            return <div className="column scrollable">{
                }
            </div>
        }
        let tempItems = [];
        let tempItemsRows = [];
        let column = this.props.column;
        let numberOfColumns = this.props.row;
        let len = items.length;
        let y = 0;
        let x = 0;
        for (y = 0; y < len; y += column) {
            tempItems = [];
            for (x = 0; x < column; x++) {
                if (y + x >= len) tempItems.push(null);
                else tempItems.push(items[y + x]);
            }
            tempItemsRows.push(tempItems);
        }
        if (tempItemsRows.length < numberOfColumns) {
            for(y=tempItemsRows.length;y<numberOfColumns-1;y++){
                tempItems = [];
                for (x = 0; x < column; x++) {
                    tempItems.push(null);
                }
                tempItemsRows.push(tempItems);
            }
        }
        return <div className="column scrollable">{
            tempItemsRows.map(this.renderItemRow)}
        </div>
    }

    renderItemRow = (itemRow, index) => {
        return <div className="container square" key={this.props.keys + "r" + index}>
            {itemRow.map(this.renderItem)}
        </div>
    }

    renderItem = (item, index) => {
        //logger.log("This is our key");
        //logger.log(this.props.keys + index);
        if (item == null) {
            return <div className="container switchButton" key={this.props.keys + index}></div>
        }
        return this.props.renderItem(item);
    }


    render() {
        return (
            this.renderItems(this.props.items)
        );
    }
}

export default CustomTable;