import React, { useState, useEffect } from "react";
import VanillaCalendar from "./Calendar";
import { Bar } from "react-chartjs-2";
import MenuCard from './MenuCard';

function EnergySwitchesModal(props) {
  const [chartData, setChartData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    props.consumption(getPreviousDate(7), getPreviousDate(1));
  }, []);

  useEffect(() => {
    if (props.switchData.length <= 0) return;
    const dataa = {
      labels: props.switchData.map((d) => d.date),
      datasets: [
        {
          label: "KW",
          data: props.switchData.map((d) => d.kW / 1000),
          backgroundColor: "rgb(246, 146, 32)",
        },
      ],
    };
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Consumption to KW",
        },
      },
    };
    setChartData({
      options: options,
      data: dataa,
    });
  }, [props.switchData]);

  function getPreviousDate(num) {
    const d = new Date();
    d.setDate(d.getDate() - num);
    return d;
  }

  function week() {
    props.consumption(getPreviousDate(7), getPreviousDate(1));
  }
  function month() {
    props.consumption(getPreviousDate(30), getPreviousDate(1));
  }
  function year() {
    props.deviceYearConsumption();
  }

  function handleConsumptionChange(start, end) {
    props.consumption(start, end);
    setIsOpen(false);
  }

  const containerHeader = (
      <div className="selectedSwName">
        <div style={{ marginBottom: "10px" }}>{props.currentSwitch.name}</div>
        <div className="buttonsEnergy">
          <div
            className="insideButtonEnergy"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img
              className="imageEnergyCalendar"
              src={require("./images/calendarEnergy.png")}
            ></img>
          </div>
          <div className="insideButtonEnergy" onClick={week}>
            WEEK
          </div>
          <div className="insideButtonEnergy" onClick={month}>
            MONTH
          </div>
          <div className="insideButtonEnergy" onClick={year}>
            YEAR
          </div>
        </div>
      </div>
    );

  return (
    <MenuCard
      url={require("./images/energy_metering.png")}
      title={props.translations.energy}
      containerHeader={containerHeader}
      menuCard={"menuCard energyMeteringDisplayModal"}
      cardHeaderBackColor={"cardHeader energyModalBold"}
      cardTitle={"cardTitle"}
    >
      <div className="energyView">
        <div style={{ marginRight: "89%" }}>
          <VanillaCalendar
            isOpen={isOpen}
            consumption={(start, end) => handleConsumptionChange(start, end)}
          />
        </div>
        <div className="chartEnergy">
          {chartData.data && (
            <Bar options={chartData.options} data={chartData.data} />
          )}
        </div>
      </div>
    </MenuCard>
  );
}

export default EnergySwitchesModal;
