import React from 'react';
import CustomTable from './CustomTable';
import { FetchData } from './../../Storage';
import NewAccessMessage from './NewAccessMessage';
import { checkArrayLength } from '../Functions/helperFunctions';
import MenuCard from './MenuCard';
import RadioButtons from './RadioButtons';
import { ReactComponent as ViewCall } from './images/view_blue.svg'
import { ReactComponent as VideoCall } from './images/videocall blue.svg'
import CameraWindow from './CameraWindow';
import logger from '../Functions/logger';

class NewCamera extends React.Component {
  constructor(props) {
    super(props);
    this.getVideoFeedLink = "https://hm-api-v2.entranet.us:7656/housemate/video/requestVideoFeed";
    this.getVideoLink = "https://hm-api-v2.entranet.us:7656/housemate/video/requestVideoCall";
    this.closeVideoLink = "https://hm-api-v2.entranet.us:7656/main/misc/revokeVideoFeed";
    this.state = {
      selectedDevice: "",
      modalIsOpen: false,
      menu: null,
      data: [],
      selectedDeviceName: '',
      cameraModal: false,
      menuCardModal: true,

    };
  }

  componentDidMount() {
    logger.log("Those are the devices");
    logger.log("CAMERA PROPS:", this.props.permission);
    logger.log(this.props.devices);
    if (this.props.devices.length > 0) {
      this.setState({
        selectedDevice: this.props.devices[0].id,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.devices !== this.props.devices) {
      if (this.props.devices.length > 0) {
        this.setState({ selectedDevice: this.props.devices[0].id });
      } else {
        this.setState({ selectedDevice: "" });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.permission !== this.props.permission) {
      this.setState({ permission: this.props.permission });
    }
  }

  componentWillUnmount() { }

  handleOptionChange = (option) => {
    this.setState({ selectedOption: option });
  };

  renderDevices = () => {
    return this.props.devices.map(device => <div key={device.id}></div>);
  }

  selectedDevice = (deviceID) => {
    this.setState({ selectedDevice: deviceID });
  }

  renderItem = (device) => {
    return (
      <div className={device.id === this.state.selectedDevice ? "menuLeftRight switchButton menuOn " : "menuLeftRight switchButton "}
        key={device.id}
        onClick={() => this.selectedDevice(device.id)}>
        <span>{device.name}</span>
      </div>
    );
  }

  openModal = async (deviceId, link, title) => {
    logger.log(deviceId);
    const DATA = {
      method: 'POST',
      body: JSON.stringify({
        buildingID: localStorage.getItem('buildingID'),
        deviceID: deviceId,
        origin: 4,
      }),
    };

    return FetchData(link, DATA).then((response) => {
      logger.log("RESPONSE");
      logger.log(response);
      // this.setState({
      //   url: response.data.ip,
      //   modalIsOpen: true
      // })
      this.props.cameraWindow(response.data.ip, title)
    }).catch((error) => {
      logger.log(error);
    });
  }

  closeModal = () => {
    let url = this.state.url;
    let regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match
    while ((match = regex.exec(url))) {
      params[match[1]] = match[2]
    }
    const { room } = params;
    let closeRoom = room;

    const DATA = {
      method: 'POST',
      body: JSON.stringify({
        roomID: closeRoom,
      }),
    };

    return FetchData(this.closeVideoLink, DATA).then((response) => {
      logger.log("close room: " + closeRoom);
      this.setState({ modalIsOpen: false });
    }).catch((error) => {
      logger.log("I think closing from tablet doesn't work well");
      logger.log(error);
    });
  }

  openVideoCall = () => {
    if (this.props.permission === "admin" || this.props.permission === "family") {
      this.openModal(this.state.selectedDevice, this.getVideoLink, this.props.translations.videoCall);
    }
  }

  openFeedCall = () => {
    if (this.props.permission === "admin" || this.props.permission === "family") {
      this.openModal(this.state.selectedDevice, this.getVideoFeedLink, this.props.translations.display);
    }
  }

  pickAnotherDevice = () => {
    this.setState({ menu: null });
    if (this.state.modalIsOpen) {
      this.closeModal();
    } else {
      this.setState({ modalIsOpen: false });
    }
  }

  handleVideoCallClick = () => {
    this.openVideoCall();
  }
  handleFeedCallClick = () => {
    this.openFeedCall();
  }

  render() {
    logger.log("MENU:", this.state.menu);

    const containerHeader = (
      (this.props.permission === "admin" || this.props.permission === "family") ? (
        <div className="cameraContainer">
          <div
            className={`menuLeftRight cameraInsideContainer column deviceButton ${this.state.menu === "videocall" ? 'deviceOn' : ''}`}
            onClick={this.handleVideoCallClick}
          >
            {logger.log("this.state.menu:", this.state.menu)}
            <VideoCall
              className={`icon ${this.state.menu === "videocall" ? 'active' : ''}`}
              style={{ width: "70px", height: "70px" }}
            />
            {this.props.translations.videoCall}
          </div>
          <div
            className={`menuLeftRight cameraInsideContainer column deviceButton ${this.state.menu === "feedcall" ? 'deviceOn' : ''}`}
            onClick={this.handleFeedCallClick}
          >
            <ViewCall
              className={`icon ${this.state.menu === "feedcall" ? 'active' : ''}`}
              style={{ width: "50px", height: "50px" }}
            />
            {this.props.translations.display}
          </div>
        </div>
      ) : (
        <NewAccessMessage type={this.props.type} translations={this.props.translations} />
      )
    );

    // Define the footer container only if the user has admin permissions
    const containerFooter = (
      this.props.permission === 'admin' && (
        <div className="sceneCreateButton" style={{ cursor: 'pointer' }} onClick={() => { this.props.sceneClicked("") }}>
          <hr className='scenariosSeperator centerHr' />
          <img className="newImageArea" src={require("./images/new_dark_blue_01.png")} alt="New Scene" />
          <div className="buttonArea">{this.props.new}</div>
        </div>
      )
    );

    if (this.props.permission !== "admin") {
      return (
        <MenuCard
          menuCard={"menuCardMessage"}
          cardHeaderBackColor={"cardHeader menuCardMessageHeader"}
          cardContainer={"cardContainerMessage"}
          cardTitle={"cardTitle"}
          title={this.props.translations.camera}
        >
          <div style={{ paddingBlock: '13%', paddingInline: '15%', fontSize: 'large' }}>
            {this.props.translations.noPermissionToService}
          </div>
        </MenuCard>
      )
    }

    if (this.props.devices.length <= 0) {
      return (
        <MenuCard
          menuCard="menuCardMessage"
          cardHeaderBackColor="cardHeader menuCardMessageHeader"
          cardContainer="cardContainerMessage"
          title={this.props.translations.camera}
        >
          <div style={{ paddingBlock: '25%' }}>
            {`${this.props.translations.thereAreNoText1} ${this.props.translations.camera.toLowerCase()} ${this.props.translations.thereAreNoText2}`}
          </div>
        </MenuCard>
      )
    }

    return (
      <>
        {this.state.menuCardModal && (
          <MenuCard
            url={require("./images/camera_orange_02.png")}
            title={this.props.translations.camera}
            containerHeader={containerHeader}
            menuCard="menuCard scenarioCard"
            cardTitle={"cardTitle cardTitleColor"}
          >
            <div className="cameraSelection">
              <h2>{this.props.translations.selectCamera}</h2>
              {/* Map through devices and render radio buttons for each */}
              {this.props.devices.map((option, index) => (
                <RadioButtons
                  name={option.name}
                  key={index}
                  id={`option${index}`}
                  value={option}
                  checked={this.state.selectedDevice === option.id}
                  handleRadioChange={() => { this.selectedDevice(option.id) }}
                  containerCSS="containerCSS"
                />
              ))}
            </div>
          </MenuCard>
        )}

        {this.state.cameraModal && (
          <CameraWindow
            src={this.state.url}
            menu={this.state.menu}
            closeModal={() => {
              this.setState({
                menu: 2,
                cameraModal: false,
                menuCardModal: true
              });
            }}
          />
        )}
      </>
    );
  }
}

export default NewCamera;
