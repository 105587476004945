import React, {useState} from "react";
import ModalView from "./ModalView";


function ChangeBuildingModal(props) {

    const [isOpen, setIsOpen] = useState(props.isOpen);

    const [selectedBuildings, setSelectedBuildings] = useState([]);

    function handleCheckboxChange(name) {
        // Check if the building is already selected
        const isSelected = selectedBuildings.includes(name);

        // If it's selected, remove it from the array, otherwise add it
        if (isSelected) {
            setSelectedBuildings(selectedBuildings.filter(buildingName => buildingName !== name));
        } else {
            setSelectedBuildings([...selectedBuildings, name]);
        }
    }
    
    function BuildingsList() {
        return (
            <div className="buildingsModal scrollable">
                <div className="textChangeBuildingModal">{props.translations.changeHome}</div>
                {props.buildings
                    .map((building) => (
                        <div className={props.selectedBuilding.includes(building.name)?"checkboxChangeHome2":"buildingNames2"} key={building.buildingID} onClick={() => props.selectBuilding(building.buildingID)}>
                            <label htmlFor={`checkbox-${building.name}`} className="checkbox-label-home">{building.name}</label>
                            <input 
                                className="checkboxChangeHome"
                                type="checkbox"
                                id={`checkbox-${building.name}`}
                                onChange={() => handleCheckboxChange(building.name)}
                                checked={props.selectedBuilding.includes(building.name)}
                            />
                        </div>
                    ))}
            </div>
        )
    }

    return (
        <ModalView open={isOpen} close={() => {
            setIsOpen(false);
            props.currentButtonSelected(0);
        }} sizeStyle={{width: "28%", height: "55%"}} padding={true}> 
            <BuildingsList/>
        </ModalView>
    )

}

export default ChangeBuildingModal;