import React, { Component } from 'react'
import FetchLogin from './FetchLogin'


class UploadEngineerMap extends Component {
    componentDidMount() {
        document.getElementById("rootApp").remove();
    }

    render() {
        return (
            <FetchLogin />
        )
    }
}

export default UploadEngineerMap

