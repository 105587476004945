import logger from './Components/Functions/logger';

function getBuildingID() {
	return new Promise((resolve, reject) => {
		return resolve(localStorage.getItem("buildingID"));
	});
}

function getToken() {
	return new Promise((resolve, reject) => {
		return resolve(localStorage.getItem("hmToken"));
	});
}

const responseDataCheck = (response, status) => {
	logger.log("in responseDataCheck");
	return new Promise((resolve, reject) => {
		response.then((responseData) => {
			if (
				responseData.hasOwnProperty("state") &&
				responseData.state === 0
			) {
				logger.log("Response is resolved");
				logger.log(responseData);
				return resolve(responseData);
			} else {
				if (
					responseData.hasOwnProperty("data") &&
					responseData.data !== null &&
					responseData.data.hasOwnProperty("text")
				) {
					logger.log(responseData.data.text);
					return reject(responseData.data.text);
				}
				logger.log("Response gets rejected");
				return reject(responseData);
			}
		});
	});
};

const handleFetch = (url, data) => {
	return new Promise((resolve, reject) => {
		fetch(url, data)
			.then((response) => {
				if (response.status === 401) {
					// logger.log("token expired");
					// remove the token from the local storage
					localStorage.removeItem("hmToken");
					localStorage.removeItem("Token");
					// send rejection to the caller
					return reject("token expired");
				} else {
					responseDataCheck(response.json(), response.status).then(
						(res) => resolve(res)
					).catch((error) => {
						logger.log(error.message);
						return reject(error);
					});
				}
			})
			.catch((error) => {
				logger.log(error.message);
				if (error.message === "Network request failed") {
					logger.log("test");
					Snackbar.show({
						text: error.message.toString(),
						duration: 5000,
					});
				} else {
					return reject(error);
				}
			});
	});
};

export function FetchData(url, data) {
	logger.log("fetchData: ", url, data);
	return getToken().then((signedToken) => {
		data.headers = {
			"Content-Type": "application/json",
			Authorization: signedToken,
		};

		return handleFetch(url, data);

		// return fetch(url, data).then((response) =>
		// 	responseDataCheck(response.json())
		// );
	});
}

export const getBuildingsForUserData = (signedToken) => {
	return {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: signedToken,
		},
	};
};

export default { getBuildingID, getToken, FetchData };
