import React, { Component } from "react";
import "./App.css";
import axios from "axios";
import md5 from "md5";
import { RingLoader } from "react-spinners";
import QRcode from "./Components/QRcode";
import io from "socket.io-client";
import NewMain from "./Components/NewInterface/NewMain";
import AcceptUser from "./Components/NewInterface/AcceptUser";
import ConfirmEmail from "./Components/NewInterface/ConfirmEmail";
import DeleteAccount from "./Components/NewInterface/DeleteAccount";
import ResetPass from "./Components/NewInterface/ResetPass";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import UploadEngineerMap from "./Stateless/UploadEngineerMap";
import { fetchActions } from "./ActionFunctions";
import { fetchInvites } from "./InviteFunctions";
import { fetchScenes } from "./ScenariosFunctions";
import { login, getBuildingsForUser } from "./urls";
import { getBuildingsForUserData, handleFetch } from "./Storage";
import { fetchThermostats } from "./ThermostatFunctions";
import { fetchSwitches } from "./SwitchFunctions";
import { getBuildingDevices, getAllerRetourDevices } from "./buildingFunctions";
import SnackbarProvider from "react-simple-snackbar";
import { server_address } from "./urls";
import GuideAfterConfirmEmail from "./Components/NewInterface/GuideAfterConfirmEmail";
import { default as translationGR } from "./translations/gr/strings";
import { default as translationEN } from "./translations/en/strings";
import { default as translationDE } from "./translations/de/strings";
import { default as translationES } from "./translations/es/strings";
import { default as translationRO } from "./translations/ro/strings";
import { default as translationBG } from "./translations/bg/strings";
import { fetchMessages } from "./MessagesFunctions";
import { getEnergyConsumption } from "./buildingFunctions";
import logger from './Components/Functions/logger';

let socket;
class App extends Component {
	constructor(props) {
		super(props);
		this.changeLanguage = this.changeLanguage.bind(this);

		this.state = {
			socket: {},
			qrcode: null,
			loading: false,
			selected: 0,
			onBuildingName: false,
			loginEmail: "",
			loginPassword: "",
			authed: false,
			email: localStorage.getItem("email"),
			failedLogin: false,
			wrongEmail: false,
			wrongPassword: false,
			redirect: false,
			//token: localStorage.getItem('hmToken'),
			token: localStorage.getItem("token"),
			buildingID: localStorage.getItem("buildingID"),
			buildings: [{ name: "" }],
			selectedBuilding: {
				actions: [],
				buildingID: "",
				devices: [],
				isMaster: false,
				name: "",
				settings: {
					numberOfNews: 0,
				},
				permissions: {
					canAccessCamera: false,
					canAccessSwitches: false,
					canModifyActions: false,
					canModifyScenes: false,
					canReceiveEmergencyNotification: false,
				},
				scenes: [],
				switches: [],
				thermostats: [],
			},
			currentLanguage: { name: "gr", translation: translationGR.translations },
			availableLanguages: [
				{ name: "gr", translation: translationGR.translations },
				{ name: "en", translation: translationEN.translations },
				{ name: "de", translation: translationDE.translations },
				{ name: "es", translation: translationES.translations },
				{ name: "ro", translation: translationRO.translations },
				{ name: "bg", translation: translationBG.translations }
			],
			dataisLoaded: false,
			switches: [],
			thermostats: [],
			actions: [],
			scenes: [],
			devices: [],
			messages: [],
			invites: [],
			permission: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.clickedLogin = this.clickedLogin.bind(this);
		this.clickedLogout = this.clickedLogout.bind(this);
		this.selectedLogin = this.selectedLogin.bind(this);
		this.clickedRegister = this.clickedRegister.bind(this);
		this.selectedRegister = this.selectedRegister.bind(this);
		this.clickedAddBuilding = this.clickedAddBuilding.bind(this);
		this.selectBuilding = this.selectBuilding.bind(this);
		this.initiate = this.initiate.bind(this);
		this.updateState = this.updateState.bind(this);
		this.setUpUser = this.setUpUser.bind(this);
		this.setUpBuilding = this.setUpBuilding.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	UNSAFE_componentWillMount() {
		let systemLang = navigator.language;
		logger.log("systemLang", systemLang);
		const currLang = this.state.availableLanguages.find(lang => systemLang.includes(lang.name))
		if(currLang) this.setState({ currentLanguage: currLang })
		this.state.availableLanguages.forEach(lang => {
			if (systemLang.includes(lang.name)) {
				this.setState({ currentLanguage: lang })
			}
		})
		// let code = "";
		// for (let i = 0; i < 8; i++) code += Math.floor(Math.random() * 10);

		// this.setState({ qrcode: code });
		logger.log("connect");
		this.connectSocket();
		this.initiate();
		// this.getEnergy();
	}

	joinRooms = () => {
		this.state.buildings.forEach((building) => {
			// logger.log("deixe2:",localStorage.getItem("hmToken"))
			this.state.socket.emit("join", JSON.stringify({ roomID: building.buildingID, token: this.state.token }));
		});
	};

	leaveRooms = () => {
		logger.log(this.state.buildings);
		this.state.buildings.forEach((building) => {
			this.state.socket.emit("leave", building.buildingID);
		});
	};

	getEnergy = () => {
		return getEnergyConsumption().then((response) => {
			this.setState({ energySwitches: response.data.energySwitches, energyConsumption: response.data.consumption })
		}).catch((error) => {
			this.setState({ energySwitches: null, energyConsumption: null })
			logger.log("Error fetching energy consumption:", error);
		})
	};


	connectSocket = () => {
		socket = io(server_address);
		socket.on("connect", (socket) => {
			logger.log("qr connect");
			this.state.socket.emit("joinSession");
		});
		socket.on("connect_error", (socket) => {
			logger.log(socket);
			logger.log("connect error");
		});
		socket.on("connection", (socket) => {
			logger.log("qr connection");
			this.state.socket.emit("joinSession");
		});

		socket.on("qrcode", (qrcode) => {
			// JSON.parse(qrcode)
			this.setState({
				qrcode: qrcode
			})
		});

		socket.on("disconnect", () => {
			logger.log(socket.connected); // false
		});
		socket.on("error", (socket) => {
			logger.log("error"); // prints { x: "42", EIO: "4", transport: "polling" }
		});
		socket.on("update", (socket) => {
			logger.log("update");
			let buildingID = localStorage.getItem('buildingID')
			let token = localStorage.getItem('token')
			let hmToken = localStorage.getItem('hmToken')
			if (buildingID !== null && token !== null && hmToken !== null) {
				axios.get(
					getBuildingsForUser,
					getBuildingsForUserData(this.state.token)
				).then(
					function (response) {
						if (response.data.state === 0) {
							let buildings = response.data.data.buildings;
							if (buildings.length > 0) {
								if (buildings !== this.state.buildings) {
									this.state.buildings = buildings;
									this.setState({ buildings: buildings });
								}
								let selectBuilding = buildings.find(
									(building) =>
										building.buildingID ===
										this.state.buildingID
								);
								logger.log(
									"This is selected building on socket update"
								);
								logger.log(selectBuilding);
								if (typeof selectBuilding === "undefined") {
									if (buildings.length > 0) {
										logger.log("Grt building on start");
										this.selectBuilding(buildings[0].buildingID);
									} else {
										//There isn't any building for our app to find
										logger.log("No building found");
									}
								} else {
									this.selectBuilding(selectBuilding.buildingID);
								}
							}
							this.state.socket.emit("leave", this.state.qrcode);
						}
					}.bind(this)
				)
					.catch((error) => {
						logger.warn(this.state.token);
					});
			}
		});

		socket.on("setup", (signedToken, buildingID, deviceID, email) => {
			logger.log("Token", signedToken);
			logger.log("BuildingID ", buildingID);
			logger.log("deviceID ", deviceID);
			if (
				typeof signedToken !== "undefined" &&
				typeof buildingID !== "undefined" &&
				typeof deviceID != "undefined" &&
				typeof email != "undefined"
			) {
				if (
					signedToken !== null &&
					buildingID !== null &&
					deviceID !== null &&
					email !== null
				) {
					//this.state.socket.disconnect();
					//logger.log('this is token in socket on: ');
					//logger.log(token);
					//this.setState({ selected: 1, buildingID: buildingID, token: token });
					this.state.token = signedToken;
					this.state.buildingID = buildingID;
					this.state.email = email;
					this.setSelected(1);
					logger.log("signedToken");
					logger.log(signedToken);
					localStorage.setItem("token", signedToken);
					localStorage.setItem("hmToken", signedToken);
					localStorage.setItem("email", email);
					logger.log("prev location: ", window.location.pathname);
					localStorage.setItem(
						"prevLocation",
						window.location.pathname
					);

					//I have to check this at some time cause initiate is being called in UNSAFE_componentWillMount too.
					this.initiate();
					this.state.socket.emit("leave", this.state.qrcode);
				}
			}
		});
		this.state.socket = socket;
	};

	getScenes = () => {
		logger.log("get scenes");
		return fetchScenes()
			.then((responseData) => {
				//logger.log("get scenes");
				//logger.log(responseData.data.scenes);
				let index = this.state.buildings.findIndex(
					(building) =>
						building.buildingID ===
						this.state.selectedBuilding.buildingID
				);
				if (index < 0) {
					logger.log("Building was not found for scenarios");
				} else {
					this.state.buildings[index].scenes =
						responseData.data.scenes;
				}
				this.state.selectedBuilding.scenes = responseData.data.scenes;
				logger.log(this.state.selectedBuilding.scenes);
				this.setState({
					scenes: this.state.selectedBuilding.scenes,
				});
			})
			.catch((error) => {
				logger.log(error);
			});
	};

	getSwitches = () => {
		return fetchSwitches()
			.then((responseData) => {
				let index = this.state.buildings.findIndex(
					(building) =>
						building.buildingID ===
						this.state.selectedBuilding.buildingID
				);
				if (index < 0) {
					logger.log("Building didn't found");
				} else {
					this.state.buildings[index].switches =
						responseData.data.switches;
				}
				// logger.log("Gamw to spiti mou");
				logger.log("Response data");
				logger.log(responseData);
				this.state.selectedBuilding.switches =
					responseData.data.switches;
				this.setState({
					switches: this.state.selectedBuilding.switches,
				});
			})
			.catch((error) => {
				logger.log("Trouble with switches");
				logger.log(error);
				if (error === "token expired") {
					this.clickedLogout();
				}
			});
	};

	getThermostats = () => {
		return fetchThermostats().then((responseData) => {
			let index = this.state.buildings.findIndex(
				(building) =>
					building.buildingID ===
					this.state.selectedBuilding.buildingID
			);
			if (index < 0) {
				logger.log("Building does not exist");
			} else {
				this.state.buildings[index].thermostats =
					responseData.data.thermostats;
				logger.log("Thermostat stored on buildings");
			}
			this.state.selectedBuilding.thermostats =
				responseData.data.thermostats;
			this.setState({
				thermostats: this.state.selectedBuilding.thermostats,
			});
		});
	};

	getDevices = () => {
		return getBuildingDevices().then((responseData) => {
			logger.log("get devices");
			logger.log(responseData.data.devices);
			let index = this.state.buildings.findIndex(
				(building) =>
					building.buildingID ===
					this.state.selectedBuilding.buildingID
			);
			if (index < 0) {
				logger.log("Building was not found for devices");
			} else {
				this.state.buildings[index].devices = responseData.data.devices;
				this.setState({
					devices: this.state.selectedBuilding.devices,
				});
			}
		}).catch(err=>{
			logger.log(err);
		})
	};

	//Fetch all type of actions
	getActions = () => {
		return fetchActions("all")
			.then((responseData) => {
				logger.log("get actions");
				logger.log(responseData.data.actions);
				let index = this.state.buildings.findIndex(
					(building) =>
						building.buildingID ===
						this.state.selectedBuilding.buildingID
				);
				if (index < 0) {
					logger.log("Building was not found for actions");
				} else {
					this.state.buildings[index].actions =
						responseData.data.actions;
					this.setState({
						actions: this.state.selectedBuilding.actions,
					});

					// this.state.selectedBuilding.actions = responseData.data.actions
				}
				//  logger.log("after: ", this.state.selectedBuilding.actions);
			})
			.catch((error) => {
				logger.log(error);
			});
	};
	getInvites = () => {
		return fetchInvites()
			.then((responseData) => {
				logger.log("get invites");
				logger.log(responseData);
				this.setState({
					invites: responseData.data
				});

			})
			.catch((error) => {
				logger.log(error);
			});
	};

	getMessages = () => {
		fetchMessages().then(response => {
			this.setState({
				messages: response.data
			});
		});
	}

	setSelected = (num) => {
		if (this.state.selected !== num) this.setState({ selected: num });
	};

	addFunction = (newValue, identifier) => {
		logger.log("Trying to add elements");
		logger.log(newValue);
		if (identifier === "scenario") {
			this.state.selectedBuilding.scenes.push(newValue);
		} else if (identifier === "action") {
			this.state.selectedBuilding.actions.push(newValue);

			logger.log("Actions from APP");
			logger.log(this.state.selectedBuilding.actions);
		}

		this.setState({
			selectedBuilding: this.state.selectedBuilding,
		});
	};

	removeFunction = (id, identifier) => {
		logger.log("Trying to remove elements");
		logger.log(identifier);
		logger.log(id);
		let index = -1;
		if (identifier === "scenario") {
			index = this.state.selectedBuilding.scenes.findIndex(
				(scene) => scene.id === id
			);
			if (index > -1) {
				this.state.selectedBuilding.scenes.splice(index, 1);
			}
		} else if (identifier === "action") {
			index = this.state.selectedBuilding.actions.findIndex(
				(action) => action.id === id
			);
			if (index > -1) {
				this.state.selectedBuilding.actions.splice(index, 1);
				logger.log("Actions from APP");
				logger.log(this.state.selectedBuilding.actions);
			}
		} else if (identifier === "messageArchive") {
			if (id == 'all') {
				this.setState({
					messages: []
				})
			}
			else {
				let filterMessages = this.state.messages.filter((msg) => { return msg.id !== id })
				this.setState({
					messages: filterMessages
				})
			}

		} else if (identifier === "building") {
			index = this.state.buildings.findIndex(
				(building) => building.buildingID === id
			);
			if (index > -1) {
				this.state.buildings.splice(index, 1);
				this.selectBuilding(this.state.buildings[0].buildingID);
			}

		} else if (identifier === "invite") {
			if (id == "all") {
				this.setState({ invites: [] });
			} else {
				logger.log('the deletion is complete')
				index = this.state.invites.findIndex(
					(invite) => invite.id === id
				);
				if (index > -1) {
					this.state.invites.splice(index, 1);
					//
				}
			}
		}

		this.setState({
			selectedBuilding: this.state.selectedBuilding,
		});
	};


	updateDevices = (newDevices) => {
		logger.log("Trying to update newDevices");
		logger.log(newDevices);
		this.state.selectedBuilding.devices = newDevices;
		logger.log("Actions from APP");
		logger.log(this.state.selectedBuilding.actions);
		this.setState({
			selectedBuilding: this.state.selectedBuilding,
		});
	}



	identifyLink() {
		const currentURL = window.location.href;
		if (currentURL.indexOf("test") > -1) {
			alert("There is test in URL");
		}
	};

	handleRedirect() {
		if (
			localStorage.getItem("prevProps") !== undefined ||
			localStorage.getItem("prevProps") !== ""
		) {
			this.setState({ redirect: true });
			logger.log("What is inside localStorage");
			logger.log(localStorage.getItem("prevLocation"));
		}
	};

	initiate() {
		if (
			(this.state.selected === 0 ||
				this.state.selected === 3 ||
				this.state.selected === 1 ||
				this.state.selected === 4) &&
			this.state.token &&
			this.state.buildingID
		) {
			this.setSelected(1);
			this.setState({ authed: true, redirect: true });
			fetch(
				getBuildingsForUser,
				getBuildingsForUserData(this.state.token)
			)
				.then((response) => response.json())
				.then(
					function (response) {
						logger.log("======================");
						logger.log(response);
						if (response.state === 0) {
							let buildings = response.data.buildings;
							if (buildings.length > 0) {
								//this.setState({ buildings: buildings });
								this.state.buildings = buildings;
								let selectBuilding = buildings.find(
									(building) =>
										building.buildingID ===
										this.state.buildingID
								);
								if (typeof selectBuilding === "undefined") {
									logger.log(
										"selected building is indefined"
									);
									if (buildings.length > 0) {
										selectBuilding = buildings[0];
										this.selectBuilding(selectBuilding.buildingID);
									}
								} else {
									logger.log("building is not undefined");
									this.selectBuilding(selectBuilding.buildingID);
								}
								const currLang = this.state.availableLanguages.find(lang => selectBuilding.settings?.language?.includes?.(lang.name))
								if (currLang) {
									this.setState({ currentLanguage: currLang });
								}
								this.joinRooms();
							}
						}
					}.bind(this)
				);
		}
	}

	setUpBuilding(name) {
		logger.log("setUpBuildingName", name);
		let newBuildings = this.state.buildings.map(building => {
			if (building.buildingID == this.state.selectedBuilding.buildingID) {
				return { ...building, name: name }
			}
			return building
		})
		logger.log(newBuildings)

		this.setState({
			selectedBuilding: {
				...this.state.selectedBuilding,
				name: name
			},
			buildings: newBuildings
		})


	}

	getARSwitches() {
		getAllerRetourDevices().then((response) => {
			logger.log(response)
			this.setState({
				ARgroups: response.data.groups
			})
		}).catch(() => {

		})
	}

	selectBuilding(buildingID) {
		let selectedBuilding = this.state.buildings.find(
			(building) => building.buildingID === buildingID
		);
		if (selectedBuilding !== undefined) {
			localStorage.setItem("building", JSON.stringify(selectedBuilding));
			logger.log("SelectBuilding: ");
			logger.log(this.state.selectedBuilding);
			localStorage.setItem("buildingID", selectedBuilding.buildingID);
			logger.log(this.state.selectedBuilding);
			this.state.selectedBuilding = selectedBuilding;
			this.state.buildingID = selectedBuilding.buildingID;
			this.setUpUser(selectedBuilding);
			this.setSelected(1);
			//this.setState({ selectedBuilding: selectedBuilding, selected: 1,buildingID:selectedBuilding.buildingID });

			this.setState({
				permission: selectedBuilding.permission
			})
			this.getEnergy();
			this.getSwitches();
			this.getThermostats();
			this.getScenes();
			this.getActions();
			this.getDevices();
			this.getInvites();
			this.getARSwitches();

			this.getMessages();

			//Get actions and scenes should be in here
		}
	}

	setUpUser(building) {
		let user = building.users?.find?.(u => u.email === this.state.email);
		if (user) {
			localStorage.setItem("user", JSON.stringify(user));
			localStorage.setItem("building", JSON.stringify(building));
		}
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
			failedLogin: false,
		});
	}

	clickedLogout() {
		localStorage.clear();
		// this.leaveRooms()
		this.setState({ loginEmail: "", loginPassword: "", authed: false, token: "", buildingID: "" });
		localStorage.setItem("prevLocation", "");
		
		this.state.socket.emit("joinSession");
		this.setSelected(0);
	}

	selectedLogin() {
		this.setSelected(0);
	}

	selectedRegister() {
		this.setSelected(2);
	}

	selectedSpecificLink() {
		this.setSelected(4);
	}

	clickedAddBuilding() {
		this.setState({ failedLogin: false });
		if (!this.state.addBuildingID || !this.state.addBuildingName) {
			this.setState({ failedLogin: true });
		} else {
			axios
				.post(this.addBuildingLink, {
					buildingID: this.state.addBuildingID,
					token: this.state.token,
					buildingName: this.state.addBuildingName,
					email: this.state.email,
				})
				.then(
					function (response) {
						if (response.data.state === 0) {
							if (!this.state.buildingID) {
								localStorage.setItem(
									"buildingID",
									this.state.addBuildingID
								);
								this.setState({
									buildingID: this.state.addBuildingID,
								});
							}
							this.setState({ loading: true });
							setTimeout(() => {
								this.initiate();
								this.setState({ loading: false });
							}, 1000);
						} else {
							this.setState({ failedLogin: true });
						}
					}.bind(this)
				);
		}
	}

	clickedLogin() {
		this.setState({ failedLogin: false });
		if (!this.state.loginEmail || !this.state.loginPassword) {
			this.setState({ failedLogin: true });
		} else {
			axios
				.post(login, {
					email: this.state.loginEmail,
					password: md5(this.state.loginPassword),
					origin: 2,
				})
				.then(
					function (response) {
						if (response.data.state === 0) {
							localStorage.setItem(
								"token",
								response.data.data.signedToken
							);
							localStorage.setItem(
								"hmToken",
								response.data.data.signedToken
							);
							localStorage.setItem(
								"email",
								this.state.loginEmail
							);
							if (response.data.data.buildings.length > 0) {
								logger.log(
									"prev location: ",
									window.location.pathname
								);
								localStorage.setItem(
									"prevLocation",
									window.location.pathname
								);
								//logger.log("=====");
								//logger.log(window.location.pathname);
								/*if(localStorage.getItem('prevLocation') !== "" || localStorage.getItem('prevLocation') !== undefined){
									this.setState({redirect:true, selected:4});

									logger.log("Selected is 4");
								}
								else{
								}*/
								localStorage.setItem(
									"buildingID",
									response.data.data.buildings[0]
								);
								this.setState(
									{
										token: response.data.data.signedToken,
										email: this.state.loginEmail,
										buildingID:
											response.data.data.buildings[0],
										selected: 1,
									},
									() => this.initiate()
								);
							} else {
								logger.log(window.location.pathname);
								this.setState({
									token: response.data.data.signedToken,
									email: this.state.loginEmail,
									selected: 3,
								});
							}
						} else {
							this.setState({
								failedLogin: true,
								loginPassword: "",
							});
						}
					}.bind(this)
				);
		}
	}

	clickedRegister_2 = () => {
		let error = {};
		let name = this.state.name;
		let email = this.state.email;
		let password = this.state.password;
		let confirmPassword = this.state.confirmPassword;
	};

	currentButtonSelected = (params) => {
		logger.log(params);
	}

	clickedRegister() {
		this.setState({ failedLogin: false });
		if (
			!this.state.registerEmail ||
			!this.state.registerPassword ||
			!this.state.registerRepeatPassword
		) {
			this.setState({ failedLogin: true });
		} else {
			axios
				.post(this.registerLink, {
					email: this.state.registerEmail,
					password: md5(this.state.registerPassword),
					origin: 2,
				})
				.then(
					function (response) {
						if (response.data.state === 0) {
							axios
								.post(this.loginLink, {
									email: this.state.registerEmail,
									password: md5(this.state.registerPassword),
									origin: 2,
								})
								.then(
									function (response) {
										if (response.data.state === 0) {
											localStorage.setItem(
												"hmToken",
												response.data.data.token
											);
											localStorage.setItem(
												"email",
												this.state.registerEmail
											);
											if (
												response.data.data.buildings
													.length > 0
											) {
												localStorage.setItem(
													"buildingID",
													response.data.data
														.buildings[0]
												);

												this.setState({
													token: response.data.data
														.Signedtoken,
													email: this.state
														.registerEmail,
													buildingID:
														response.data.data
															.buildings[0],
													selected: 1,
												});
											} else {
												this.setState({
													token: response.data.data
														.Signedtoken,
													email: this.state
														.registerEmail,
													selected: 3,
												});
											}
										} else {
											this.setState({
												failedLogin: true,
											});
										}
									}.bind(this)
								);
						} else {
							this.setState({ failedLogin: true });
						}
					}.bind(this)
				);
		}
	}

	updateState(obj) {
		this.setState(obj);
	}

	handleKeyPress(event) {
		if (event.key === "Enter") {
			this.clickedLogin();
		}
	}
	changeLanguage(lang) {
		this.setState({ currentLanguage: lang })
	}

	render() {
		const register = (
			<div
				className={this.state.failedLogin ? "shake" : ""}
				id="loginNew"
			>
				<div id="selBar">
					<h1 id="selLogin" onClick={this.selectedLogin}>
						Login
					</h1>
					<span id="selMid">/</span>
					<h1 id="selRegister" style={{ color: "orange" }}>
						Register
					</h1>
				</div>
				<div>
					<input
						name="registerEmail"
						className="lgInputNew regemail"
						type="text"
						placeholder="email"
						onChange={this.handleChange}
						value={this.state.registerEmail}
					></input>
				</div>
				<div>
					<input
						name="registerPassword"
						className="lgInputNew regpass"
						type="password"
						placeholder="password"
						onChange={this.handleChange}
						value={this.state.registerPassword}
					></input>
				</div>
				<div>
					<input
						name="registerRepeatPassword"
						className="lgInputNew regpass"
						type="password"
						placeholder="repeat password"
						onChange={this.handleChange}
						value={this.state.registerRepeatPassword}
					></input>
				</div>
				<span onClick={this.clickedRegister} id="lgButtonNew">
					Register
				</span>
				{this.state.failedLogin &&
					this.state.registerPassword !=
					this.state.registerRepeatPassword && (
						<span id="lgError">{this.state.currentLanguage.translation.missMatchPass}</span>
					)}
				{this.state.failedLogin && !this.state.registerEmail && (
					<span id="lgError">{this.state.currentLanguage.translation.emailMissing}</span>
				)}
				{this.state.failedLogin && !this.state.registerPassword && (
					<span id="lgError">{this.state.currentLanguage.translation.passwordMissing}</span>
				)}
				<QRcode code={this.state.qrcode}></QRcode>
				{/*The next line should be added again when creating a desktop app.*/}
				{/*<div><a className="desktopAppLink" href="" target="_blank">Download the desktop application</a></div>*/}
			</div>
		);

		const login = (
			<div className="loginContainer">
				<div className="logoContainerTop">
					<div className="logoImg">
						<img
							style={{ marginLeft: "10%" }}
							src="https://housemate.online/wp-content/uploads/2021/06/housemate-logo.png"
						></img>
					</div>
				</div>
				<div
					className={this.state.failedLogin ? "shake" : ""}
					id="loginNew"
				>
					<div className="inviteElementContainer scrollable">
						<div className="mainBody">
							<div className="resetInfoWindows">
								<div className="statusImage">
									<img
										src={require("./images/login.png")}
									></img>
								</div>
								<div className="resetResultContainer">
									<img
										src={"https://housemate.online/wp-content/uploads/2021/06/housemate-logo.png"}
										className="myHousemate"
									></img>
									<h2 style={{ marginTop: "0px", color: "rgb(55, 66, 78)", fontSize: "21px", paddingLeft: "1px" }}>
										USER LOGIN
									</h2>
									<div className="passContainer">
										<div><img className="loginImgs" src={require("./images/username.png")} /></div>
										<input
											name="loginEmail"
											className="lgInputNew regmail"
											type="text"
											placeholder="Username"
											onChange={this.handleChange}
											value={this.state.loginEmail}
										></input>
									</div>
									<div className="passContainer">
										<div><img className="loginImgs" src={require("./images/password.png")} /></div>
										<input
											name="loginPassword"
											className="lgInputNew regpass"
											type="password"
											placeholder="Password"
											onChange={this.handleChange}
											value={this.state.loginPassword}
											onKeyPress={this.handleKeyPress}
										></input>
									</div>
									{/*We need better errors layout in order to upload it*/}
								</div>

								<div
									className="lgButtonNew"
									onClick={this.clickedLogin}
								>
									<span>{this.state.currentLanguage.translation.login}</span>
								</div>
								{this.state.failedLogin && (
									<span id="lgError">
										{this.state.currentLanguage.translation.checkInfo}
									</span>
								)}
								{this.state.qrcode && <QRcode code={this.state.qrcode}></QRcode>}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
		{
			/* <div id="selBar">
		  <h1 id="selLogin" style={{ color: "orange" }}><img src="https://housemate.online/wp-content/uploads/2021/06/housemate-logo.png"></img></h1> */
		}
		{
			/*<span id="selMid">/</span>*/
		}
		{
			/*Button to move on register page. Remove it temporarely*/
		}
		{
			/*<h1 id="selRegister" onClick={this.selectedRegister}>Register</h1>*/
		}
		{
			/* </div>
		<div>
		  <input
			name="loginEmail"
			className="lgInputNew regemail"
			type="text"
			placeholder="email"
			onChange={this.handleChange}
			value={this.state.loginEmail}></input>
		</div>
		<div>
		  <input
			name="loginPassword"
			className="lgInputNew regpass"
			type="password"
			placeholder="password"
			onChange={this.handleChange}
			value={this.state.loginPassword}></input>
		</div>
		<span onClick={this.clickedLogin} id="lgButtonNew">LOGIN</span>
		{this.state.failedLogin && <span id="lgError">Please check your info</span>}
		<QRcode code={this.state.qrcode}></QRcode> */
		}
		{
			/*The next line should be added again when creating a desktop app.*/
		}
		{
			/*<div><a className="desktopAppLink" href="" target="_blank">Download the desktop application</a></div>*/
		}
		{
			/* </div>
	</div> */
		}
		const addBuilding = (
			<div className={this.state.failedLogin ? "shake" : ""} id="login">
				<div id="selBar">
					<h1>{this.state.currentLanguage.translation.newBuilding}</h1>
				</div>
				<div>

					<input
						name="addBuildingName"
						className="lgInput"
						type="text"
						placeholder="name"
						onChange={this.handleChange}
						value={this.state.addBuildingName}
					></input>
				</div>
				<div>
					<img src={require("./images/password.png")} />
					<input
						name="addBuildingID"
						className="lgInput"
						type="text"
						placeholder="buildingID"
						onChange={this.handleChange}
						value={this.state.addBuildingID}
					></input>
				</div>
				{!this.state.loading && (
					<span onClick={this.clickedAddBuilding} id="lgButton">
						→
					</span>
				)}
				{this.state.loading && (
					<span id="lgButton">
						<RingLoader
							size={35}
							color={"#F5A623"}
							loading={this.state.loading}
						/>
					</span>
				)}
				{this.state.failedLogin && (
					<span id="lgError">{this.state.currentLanguage.translation.buildingNotValid}</span>
				)}
			</div>
		);
		/*const my = <span>
	  <Clock/>
	  <br/>
	  <Weather/>
	  <br/>
	  <Camera
		token={this.state.token}
		buildingID={this.state.buildingID}
		devices={this
		.state
		.selectedBuilding
		.devices
		.map(device => {
		  return {name: device.name, id: device.deviceID}
		})}/>
	  <br/>
	  <Switches building={this.state.selectedBuilding} token={this.state.token} buildingID={this.state.buildingID} updateState={this.updateState}/>
	  <br/>
	  <Settings token={this.state.token} buildingID={this.state.buildingID} building={this.state.selectedBuilding} updateState={this.updateState}/>
	  <br/>
	  <Scenarios token={this.state.token} buildingID={this.state.buildingID} building={this.state.selectedBuilding} updateState={this.updateState}/>
	  <br/>
	  <Thermostat building={this.state.selectedBuilding} token={this.state.token} buildingID={this.state.buildingID} updateState={this.updateState}/>
	  <br/>
	  <Actions token={this.state.token} buildingID={this.state.buildingID} building={this.state.selectedBuilding} updateState={this.updateState}/>
	</span>;*/
		/* DEH smart home and logout top Bar*/

		// const topBar = (
		// 	<span id="topBar">
		// 		<img
		// 			id="buildingSelect"
		// 			src={require("./images/location.png")}
		// 		/>
		// 		<div
		// 			id="buildingName"
		// 			onMouseEnter={() => {
		// 				this.setState({ onBuildingName: true });
		// 			}}
		// 			onMouseLeave={() => {
		// 				this.setState({ onBuildingName: false });
		// 			}}
		// 		>
		// 			<span
		// 				className="buildingNames"
		// 				onClick={() => {
		// 					this.selectBuilding(
		// 						this.state.selectedBuilding.buildingID
		// 					);
		// 				}}
		// 			>
		// 				{this.state.selectedBuilding.name}
		// 			</span>
		// 			{/* {this.state.onBuildingName && this.state.buildings.filter(building => building.name != this.state.selectedBuilding.name).map(building => <span key={building.buildingID} className="buildingNames" onClick={() => { this.selectBuilding(building.name); }}>{building.name}</span>)}
		// {this.state.onBuildingName && <span onClick={() => { this.setState({ selected: 3 }) }} className="buildingNames"><u>add new</u></span>} */}
		// 		</div>
		// 		|
		// 		<div className="dropdown">
		// 			<div className="dropbtn">{this.state.currentLanguage.translation.changeBuidling}</div>
		// 			<div className="dropdown-content">
		// 				{this.state.buildings
		// 					.filter(
		// 						(building) =>
		// 							building.name !=
		// 							this.state.selectedBuilding.name
		// 					)
		// 					.map((building) => (
		// 						<span
		// 							key={building.buildingID}
		// 							className="buildingNames"
		// 							onClick={() => {
		// 								this.selectBuilding(building.buildingID);
		// 							}}
		// 						>
		// 							{building.name}
		// 						</span>
		// 					))}
		// 			</div>
		// 		</div>
		// 		|
		// 		<div className="dropdown">
		// 			<div className="dropbtn">{this.state.currentLanguage.name}</div>
		// 			<div className="dropdown-content dropwdown-language" >
		// 				{this.state.availableLanguages
		// 					.filter(
		// 						(language) =>
		// 							language.name !=
		// 							this.state.currentLanguage.name
		// 					)
		// 					.map((language) => (
		// 						<span
		// 							key={language.name}
		// 							className="buildingNames"
		// 							onClick={() => {
		// 								this.setState({ currentLanguage: language })
		// 								logger.log(language.translation)
		// 							}}
		// 						>
		// 							{language.name}
		// 						</span>
		// 					))}
		// 			</div>
		// 		</div>
		// 		{/*<div id="logout" onClick={this.clickedLogout}>logout</div>*/}
		// 	</span>
		// );
		const guide = (
			<GuideAfterConfirmEmail />
		)
		//Test page that only logged in user can access
		const invite = (
			<AcceptUser
				inviteParams={cleanUrl}
				clickedLogout={this.clickedLogout}
				setSelected={this.setSelected}
			></AcceptUser>
		);
		const confirm = (
			<ConfirmEmail
				confirmParams={cleanUrl}
				setSelected={this.setSelected}
				token={this.state.token}
			></ConfirmEmail>
		);
		const reset = (
			<ResetPass
				resetParams={cleanUrl}
				setSelected={this.setSelected}
			></ResetPass>
		);
		const deleteUser = (
			<DeleteAccount
				deleteParams={cleanUrl}
				setSelected={this.setSelected}
			></DeleteAccount>
		);

		const error404 = (
			<div className="notFoundContainer">
				<h1 className="notFound">{this.state.currentLanguage.translation.error}</h1>
			</div>
		);

		const rootApp = (
			<div id="rootApp">
				{this.state.selected === 0 && login}
				{/*this.state.selected === 2 && register*/}
				{this.state.selected === 3 && addBuilding}
				{this.state.selected === 1 && (
					<SnackbarProvider>
						<NewMain
							switches={this.state.switches}
							thermostats={this.state.thermostats}
							scenes={this.state.scenes}
							actions={this.state.actions}
							devices={this.state.devices}
							token={this.state.token}
							buildingID={this.state.buildingID}
							clickedLogout={this.clickedLogout}
							removeFunction={this.removeFunction}
							addFunction={this.addFunction}
							getScenes={this.getScenes}
							getActions={this.getActions}
							getSwitches={this.getSwitches}
							getEnergy={() => this.getEnergy()}
							energySwitches={this.state.energySwitches}
							energyConsumption={this.state.energyConsumption}
							translations={this.state.currentLanguage.translation}
							currentLanguage={this.state.currentLanguage}
							buildingLanguage={this.state.selectedBuilding.settings.language}
							availableLanguages={this.state.availableLanguages}
							selectedBuilding={this.state.selectedBuilding}
							newDevices={this.state.selectedBuilding.devices}
							updateDevices={this.updateDevices}
							messages={this.state.messages}
							userEmail={this.state.email}
							permission={this.state.permission}
							setUpBuilding={this.setUpBuilding}
							ARgroups={this.state.ARgroups}
							menu={this.menu}
							buildings={this.state.buildings}
							invites={this.state.invites}
							selectBuilding={this.selectBuilding}
							changeLanguage={this.changeLanguage}
						/>

					</SnackbarProvider>
				)}
			</div>
		);

		const upload = () => {
			return <div>{uploadMap}</div>;
		};

		function cleanUrl() {
			const url = window.location.href;
			logger.log(url);
			let regex = /[?&]([^=#]+)=([^&#]*)/g,
				params = {},
				match;
			while ((match = regex.exec(url))) {
				params[match[1]] = match[2];
			}

			return params;
		}

		/*In case we add Route we can use PrivateRoute,
	 for those that we want the user to access only when he/she is logged in.*/
		function PrivateRoute({ authed, path, component: Component, ...rest }) {
			if (!authed) {
				logger.log("No authentication");
				logger.log(window.location.pathname);
				localStorage.setItem("prevLocation", window.location.pathname);
			}
			return (
				<Route
					{...rest}
					render={(props) =>
						authed === true ? (
							<Component {...props} />
						) : (
							<Redirect
								to={{
									pathname: "/",
									state: { from: props.location },
								}}
							/>
						)
					}
				/>
			);
		}

		// the following is an option after you click "my housemate"
		const uploadMap = (
			<div>
				<UploadEngineerMap />
			</div>
		);

		return (
			<Router>
				<Switch>
					{/*Use this if authentications is needed*/
					/*<Route exact path="/invite" render={() => this.state.authed ? invite:
          <div className='afterRoot'>{(this.state.selected === 1 || this.state.selected === 3) && topBar} {rootApp}
          </div>} />*/}
					<Route exact path="/guide" render={() => guide} />
					<Route exact path="/invite" render={() => invite} />
					<Route exact path="/email_confirmation" render={() => confirm} />
					<Route exact path="/resetpassword" render={() => reset} />
					<Route exact path="/delete_confirmation" render={() => deleteUser} />
					<Route exact path="/" render={() => (
						rootApp
					)} />
					{/*<Route path="/electrician" component={upload} />*/}
					{/*<PrivateRoute authed={this.state.authed} path="/invite" component={invite} />*/}
					{<Route render={() => error404} />}
				</Switch>
			</Router>
		);
	}
}

export default App;
