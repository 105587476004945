export default {
    translations: {
        userLogin: "INICIO DE SESIÓN DE USUARIO",//1 //toDo
        login: "INICIAR SESIÓN", //2 //ok
        logout: "CERRAR SESIÓN",//3 //ok
        switches: "INTERRUPTORES",//4 //ok
        scenarios: "ESCENARIOS",//5 //ok
        timers: "PLANIFICACIÓN DE LOS ESCENARIOS",//6 //ok
        commands: "PROGRAMACIÓN DE COMANDOS",//7 //toDo
        camera: "CÁMARA",//8 //ok
        alarm: "DESPERTADOR",//9 //ok
        reminder: "RECORDATORIO",//10 //toDo
        notes: "POST IT",//11 //ok
        energy: "ENERGY METERING",
        scenarioProgCreation: "CREACIÓN DE ESCENARIOS / PROGRAMACIÓN",//12 //toDo
        scenarioCreation: "ESCENARIOS / CREACIÓN",//13 //toDo
        commandsCreation: "CREACIÓN DE COMANDOS / PROGRAMACIÓN",//14 //toDo
        alarmCreation: "ALARMA / CREACIÓN",//15 //toDo
        reminderCreation: "RECORDATORIO / CREACIÓN",//16 //toDo
        titleNameError: "TÍTULO NOMBRE ERROR",//17 //toDo
        addNewScene: "NUEVO ESCENARIO",//18 //toDO
        multiplePlan: "PLANIFICACIÓN MÚLTIPLE",//19 //ok
        newCommand: "NUEVO COMANDO",//20 //toDo
        newAlarm: "NUEVA ALARMA",//21 //toDo
        newReminder: "NUEVO RECORDATORIO",//22 //toDo
        dateOrTimeOld: "La fecha o la hora son antiguas.",//23 //toDo
        successfulSave: "The save was succesful.",//24 //toDo
        cancel: "CANCELAR",//25 //ok
        delete: "DELETE",//26 //toDo
        hideCaps: "DIVULGACIÓN",
        show: "ANUNCIO",
        save2: "AHORRAR",//27 //toDo
        scenarioNameExists: "Este nombre de escenario ya existe.",//28 //toDo
        shortName: "El nombre debe tener al menos 3 caracteres.",//29 //ok
        scenarioName: "Nombre del escenario",//30 //toDo
        selectASwitch: "Selecciona al menos un interruptor.",//31 //toDo
        wrongNameType: "Tipo de nombre incorrecto.",//32 //ok
        noDeviceChosen: "No ha seleccionado ningún dispositivo",//33 //toDo
        devices: "DISPOSITIVOS",//34 //ok
        smallName: "El nombre debe contener más de 4 caracteres",//35 //ok
        newNameMult: "Introduce un nuevo nombre",//36 //ok
        time: "HORA",//37 //ok
        date: "FECHA",//38 //ok
        repeat: "REPETICIÓN",//39 //ok
        videoCall: "VIDEOLLAMADA",//40 //ok
        display: "PANTALLA",//41 //toDo
        selectCamera: "Seleccionar cámara",//42 //toDo
        noteLengthRestriction: "La nota debe constar de al menos 4 caracteres",//43 //toDo
        sendNote: "ENVIAR NOTA",//44 //toDo
        days: {
            Monday: "Lunes",
            Tuesday: "Martes",
            Wednesday: "Miércoles",
            Thursday: "Jueves",
            Friday: "Viernes",
            Saturday: "Sábado",
            Sunday: "Domingo"
        },//45
        days_locale: {
            Monday: "Lun",
            Tuesday: "Mar",
            Wednesday: "Mié",
            Thursday: "Jue",
            Friday: "Vie",
            Saturday: "Sáb",
            Sunday: "Dom"
        },
        changeHome: "CAMBIO DE DOMICILIO",
        addNewName: "Introduzca un nuevo nombre",
        selectedLang: "Idioma seleccionado",
        selectLang: "Selección de idioma",
        english: "Ιnglés",
        german: "Alemán",
        spanish: "Εspañol",
        romanian: "Ρumano",
        bulgarian: "Βúlgaro",
        greek: "griego",
        allerRetourWith: "Aller - Retour con",
        allerRetour: "Aller - Retour",
        changeBuidling: "Cambiar edificio",//46 //toDo
        error: "¡Error 404! Página no encontrada",//47 //toDo
        buildingNotValid: "Edificio no válido",//48 //toDo
        newBuilding: "Añadir un nuevo edificio",//49 //toDo
        checkInfo: "Compruebe sus datos",//50 //toDo
        passwordMissing: "Falta la contraseña",//51 //toDo
        emailMissing: "Falta el correo electrónico",//52 //toDo
        missMatchPass: "Las contraseñas no coinciden",//53 //toDo
        switch_on: "en",//54
        switch_off: "off",//55
        thereAreNo: "NO EXISTEN",
        command: "COMANDOS",
        cameras: "CÁMARAS",
        alarmsStr: "ALARMAS",
        reminders: "RECORDATORIOS",
        hmLanguage: "Idioma predeterminado",
        name: "Español",
        deleteConfirmation: "La eliminación fue exitosa.", //56
        deleteFailure: "La eliminación falló.", //57
        deleteAllQuestion: "¿Estás seguro de que quieres borrar todos los mensajes?", //58
        deleteMessageQuestion: "¿Seguro que quieres borrar este mensaje?", //59
        incomingMessages: "MENSAJES ENTRANTES", //60
        messageFrom: "Mensaje de", //61
        deleteAll: "ELIMINAR TODO", //62
        messageArchiveText: "ARCHIVO DE MENSAJES", //63
        deleteInviteSuccess: "La invitación ha sido eliminada",
        deleteInviteFailure: "La supresión de la invitación no tuvo lugar",
        acceptInviteSuccess: "La invitación ha sido aceptada",
        acceptInviteFailure: "La invitación no ha sido aceptada",
        deleteAllInvitesQuestion: "¿Estás seguro/a de que quieres eliminar todas las invitaciones?",
        deleteInviteQuestion: "¿Estás seguro de que quieres borrar esta invitación?",
        inviteFrom: "Invitación de ",
        acceptInvite: "ACEPTAR",
        invites: "INVITACIONES",
        newAccessScenario: ": Escenarios",
        newAccessScenarioProgramming: ": Programación de escenarios",
        newAccessCommandsProgramming: ": Comandos Programación",
        newAccessCamera: ": Cámara",
        newAccessAlert: ": Alarms",
        newAccessReminder: ": Alarmas",
        newAccessPostIt: ": Post It",
        newAccessDot: ".",
        newAccessNoAccess: "No tiene permiso",
        deleteQuestion: "¿Estás seguro de que quieres borrarlos?",
        account: "CUENTA",
        successfulRegistration: "Registro con éxito",
        unsuccessfulRegistration: "Registro sin éxito.",
        deleteAccountQuestion: "¿Seguro que quieres eliminar tu cuenta?\nSi la respuesta es SÍ, compruebe su correo electrónico para ver la confirmación de eliminación.",
        sendEmailSuccess: "Correo electrónico enviado correctamente.",
        sendEmailFailure: "No se pudo enviar el correo electrónico.",
        successfulCancelation: "Cancelación con éxito.",
        nicknameExists: "Este nombre ya existe.",
        nicknameSavedSuccessfuly: "El apodo se ha salvado con éxito.",
        nicknameDelete: "El nombre se ha eliminado correctamente.",
        nicknamesSavedSuccessfuly: "Los apodos se han guardado con éxito.",
        myAccount: "MI CUENTA",
        homeName: "Nombre de la casa",
        username: "Nombre de usuario",
        addExtraNickname: "Añadir nombres de usuario adicionales",
        deleteAccount: "ELIMINAR CUENTA",
        deleteHome: "BORRAR CASA",
        deleteHomeQuestion: "¿Seguro que quieres borrar esta casa?",
        unhideSwitch: "¿Está seguro de que desea mostrar el interruptor ",
        hideSwitch: "¿Estás seguro de que quieres ocultar el interruptor ",
        deleteSwitch: "¿Está seguro de que desea eliminar el interruptor ",
        insertUserName: "Insertar nombre de usuario",
        weather: "CLIMA",
        weatherText_1: "Se utilizará en comandos como:",
        weatherText_2: "\"¿Qué tiempo hará mañana?\",\"Dime el tiempo\"",
        timeZone: "Huso horario",
        timeZoneText_1: "Se utilizará en comandos como:",
        timeZoneText_2: "\"¿Qué hora es?\",\"Dime la hora\"",
        news: "NOTICIAS",
        newsText: "¿Cuántos titulares quieres escuchar?",
        tabletLanguage: "Idioma de la tableta",
        changeKeyword: "Cambiar palabra clave",
        generalInformation: "INFORMACIÓN GENERAL",
        theUser: "El usuario:  ",
        hasPermission: " ¡Ya tiene el permiso!",
        members: "Miembros",
        changeUsername: "¿Desea cambiar el nombre de usuario del usuario: ",
        noUsersAvailabe: "No hay usuarios disponibles para eliminar!",
        deleteMember: "¿Estás seguro de que quieres borrar el usuario? ",
        invalidEmail: "Ha introducido una dirección de correo electrónico no válida!",
        insertNewUsername: "Insertar nuevo nombre de usuario",
        save: "Ahorrar",
        cancel_2: "Cancelar",
        insertNewEmail: "Insertar correo electrónico",
        availablePermissions: "Permisos disponibles",
        memberUsername: "Nombre de usuario del miembro: ",
        currentUserPermission: "Permiso del miembro actual",
        renameUser: "RENOMBRAR USUARIO",
        deleteUsers: "ELIMINAR USUARIO",
        addNewUser: "AÑADIR NUEVO USUARIO",
        permissions: "NORMAS DE ACCESO",
        week: "SEMANA",
        month: "MES",
        year: "AÑO",
        energyConsumption: "Consumo en KW",
        energyMetering: "Medición de la energía",
        energyMeteringCapital: "MEDICIÓN DE LA ENERGÍA",
        insertCommand: "Comando de inserción",
        selectCamera: "SELECCIÓN DE CÁMARA",
        edit: "EDITAR",
        showHiddenDevices: "Mostrar dispositivos ocultos",
        editScene: "EDITAR ESCENARIO",
        unselectAll: "Deseleccionar todo",
        selectAll: "Seleccionar todo",
        cancelAllerRetour: "Anulación Aller - Retour",
        showHiddenDevices: "Mostrar dispositivos ocultos",
        rename: "Cambie el nombre de",
        addName: "Añadir un nombre",
        showLow: "Mostrar",
        hide: "Ocultar",
        deleteLow: "Borrar",
        currentDeviceName: "Nombre actual del interruptor",
        newDeviceName: "Nuevo nombre del interruptor",
        successfullRename: "Cambio de nombre de interruptor correcto",
        failRename: 'Fallo al renombrar el interruptor',
        successfullHide: 'Ocultación de interruptor exitosa.',
        failHide: 'Fallo al ocultar interruptor.',
        cancelHide: "Se ha cancelado la ocultación del interruptor.",
        successfullShow: "Mostrado interruptor con éxito",
        failShow: "Fallo al mostrar interruptor",
        cancelShow: "Ha cancelado la muestra del interruptor.",
        successfullDelete: "Borrado exitoso del interruptor",
        failDelete: 'Fallo al borrar interruptor.',
        cancelDelete: 'Ha cancelado el borrado del interruptor.',
        successfullCancelAllerRetour: 'Cancelación exitosa del Aller-Retour',
        failCancelAllerRetour: 'Fallo al cancelar Aller-Retour.',
        successfullAddName: 'Entrada exitosa de un nombre adicional',
        failAddName: 'Fallo al introducir un nombre.',
        renameCaps: "PARTICIPACIÓN",
        noInvites: "No tienes ninguna invitación",
        noMessages: "No tienes ningún mensaje",
        oneTime: "Una vez",
        postItMessage: 'Inserte su nota',
        thereAreNoText1: "No hay",
        thereAreNoText2: "a su lista",
        messages:"mensajes",
        ivnites:"invita",
        noPermissionToService:"Lo sentimos, actualmente no tienes permiso para acceder a este servicio.",
        location: "LOCALIZACIÓN",
        changeTimeZone: "Cambio de huso horario",
        selectedZone: "zona seleccionada: ",
        selectZone: "selección de zonas",
        timeZoneCaps: "ZONA HORARIA",
        tabletLanguageCaps: "TABLETA DE IDIOMAS",
        changeKeyword: "CAMBIO DE PALABRA CLAVE",
        changeLanguage: "Cambio lingüístico",
        invitesText : "INVITACIONES"
    }
}