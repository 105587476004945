import React from 'react';


function RadioButtons(props) {


    return (
        <div key={props.name} className={`generalRadioContainer ${props.containerCSS}`}>
            
                <div className='radioButtonName'>{props.name}</div>
                
                    <label className={"radioButtonContainer"}>
                        <input
                            type="radio"
                            name="radiobuttons"
                            value={props.deviceID}
                            checked={props.checked}
                            onClick={props.handleRadioChange}
                        />
                        <span className="checkmark"></span>
                    </label>
                
            
        </div>
    )
}

export default RadioButtons;