import React from 'react';
//import '../css/Clock.css';
import logger from '../Functions/logger';

class NewOnOff extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  onChange(e){
    // if(!this.props.OnOff){
    //   return
    // }
    let flag = 0;
    if(e.target.checked){
      this.props.OnOff(true);
      logger.log("ON:",this.props.OnOff);
    }
    else{
      this.props.OnOff(false);
      logger.log("OFF:",this.props.OnOff);
    }
  }


  render() {
    // logger.log("repeat inside render: ", this.props.repeat);
    return (
      <div className="container space-between">
        {/* <div className={this.props.repeat ? "dayOn" : "dayOff"} onClick={() => this.props.OnOff(true)}>ON</div>
        <div className="OnOff-line">|</div>
        <div className={!this.props.repeat ? "dayOffOn" : "dayOff"} onClick={() => this.props.OnOff(false)}>OFF</div> */}
        <label class="switch">
          <input type="checkbox" checked={this.props.isEnabled}  onChange={(event)=>{this.onChange(event)}} />
          <span class="slider round" onClick={this.props.onClickScene}></span>
        </label>
      </div>
    );
  }
}

export default NewOnOff;