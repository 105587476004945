import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

export default function ModalView(props) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: "51%",
      height: "85%",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "30px",
      boxShadow: theme.shadows[5],
      padding: props.padding ? theme.spacing(2, 4, 3) : {},
      outline: 'none',
    },
    overwrite: props.sizeStyle ? { ...props.sizeStyle } : {},
  }));
  const classes = useStyles();


  return (
    <Modal
      open={props.open}
      onClose={props.close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{display:'flex', justifyContent:'center', alignItems:'center'}}
    >
      <div
        className={`${classes.paper} ${classes.overwrite}`}
      >
        {props.children}
      </div>
    </Modal>
  );
}