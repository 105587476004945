import React from 'react';
import './NewMain.css';
import NewSwitches from './NewSwitches';
import NewScenes from './NewScenes';
import NewPorgramScene from './NewPorgramScene';
import NewProScene from './NewProScene';
import NewCamera from './NewCamera';
import Energy from './Energy'
import NewNote from './NewNote';
import moment, { lang } from 'moment';
import EditScene from './EditScene';
import NewAction from './NewAction';
import EditAction from './EditAction';
import { sceneActivate } from '../../ScenariosFunctions';
import { updateActions } from '../../ActionFunctions';
import PropTypes from 'prop-types'
import SnackbarProvider from 'react-simple-snackbar'
import { withSnackbar } from 'react-simple-snackbar'
import GeneralInformation from './GeneralInformation'
import MessageArchive from './MessageArchive'
import NewBuilding from './NewBuilding';
import AccountModal from './AccountModal';
import Permissions from './Permissions';
import NewInvites from './NewInvites';
import SwitchesEditMenu from './SwitchesEditMenu';
// import { ReactComponent as AccountSvg } from './images/account.svg'
import ChangeBuildingModal from './ChangeBuildingModal'
import UserMenu from './UserMenu';
import CameraWindow from './CameraWindow';
import logger from '../Functions/logger';

class NewMain extends React.Component {
    constructor(props) {
        super(props);
        this.updateLink = "https://hm-api.entranet.us:7656/housemate/action/updateAction";
        this.activateSceneLink = "https://hm-api.entranet.us:7656/housemate/home/sceneActivate";
        this.state = {
            menu: 0,
            menu2: 0,
            action: {},
            scene: {},
            title: "",
            new: false,
            newScenes: [],
            newActions: [],

        }



    }

    static get propTypes() {
        return {
            actions: PropTypes.any,
        }
    }

    timeFormat = (date) => {
        return moment(date).format("HH:mm");
    }

    dateFormat = (date) => {
        return moment(date).format("DD/MM/YYYY");
    }

    proActionEnable = (action) => {
        logger.log("here");
        logger.log("scene name :", action);
        // logger.log("this.state.actions:",this.state.actions);

        this.state.action = action;
        this.state.action.enabled = !this.state.action.enabled;
        // let index = this.props.actions.findIndex(action => action.id === this.state.action.id);
        //     if (index > -1) {
        //         this.props.actions[index] = this.state.action;

        //     }
        if (this.state.action.enabled) {
            this.state.action.confirmed = false;
        }

        this.setState({ action: this.state.action });
        logger.log(this.state.action);
        updateActions(this.state.action).then((responseData) => {
            logger.log(responseData);
            let index = this.props.actions.findIndex(action => action.id === this.state.action.id);
            if (index > -1) {
                this.props.actions[index] = this.state.action;

            }
        }).catch((error) => {
            this.state.action.enabled = !this.state.action.enabled;
            this.setState({ action: this.state.action });
        });
    }

    actionEnable = (ActionName, id) => {
        const { openSnackbar, closeSnackbar } = this.props
        this.actionClicked(ActionName, "", id);
        // if action is old and we want to disable activate/deactivate
        let newDate = new Date();
        const actionDate = (this.state.action.day.date).split('/'); // DD/MM/YYYY
        const time = (this.state.action.time).split(':');
        let flag = false;
        if ((this.state.action.day.date !== '--/--/--')) {
            if (parseInt(actionDate[2]) < newDate.getFullYear()) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
            if (parseInt(actionDate[2]) == newDate.getFullYear()) {
                logger.log("year");
                if (parseInt(actionDate[1]) < (newDate.getMonth() + 1)) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
                if (parseInt(actionDate[1]) == (newDate.getMonth() + 1)) {
                    logger.log("month");
                    if (parseInt(actionDate[0]) < newDate.getDate()) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
                    if (parseInt(actionDate[0]) == newDate.getDate()) {
                        logger.log("day");
                        if (parseInt(time[0]) < newDate.getHours()) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) };
                        if (parseInt(time[0]) == newDate.getHours()) {
                            logger.log("hours");
                            if (parseInt(time[1]) <= (newDate.getMinutes() - 3)) { flag = true; this.props.openSnackbar(this.props.translations.dateOrTimeOld) }
                        }
                    }
                }
            }
        }
        if (flag == false) {
            logger.log("ELSE");
            this.state.action.enabled = !this.state.action.enabled;
            if (this.state.action.enabled) {
                this.state.action.confirmed = false;
            }
            updateActions(this.state.action).then((responseData) => {
                let index = this.props.actions.findIndex(action => action.id === this.state.action.id);
                if (index > -1) {
                    this.props.actions[index] = this.state.action;

                }
            }).catch((error) => { });
        }

    }

    proSceneName = (name) => {
        logger.log("PRO SCENE NAME");
        this.state.action.text = name;
        // this.setState({action: this.state.action})
    }

    actionClicked = (sceneName, type, id) => {

        let action = this.props.actions.find(action => action[id] == sceneName);
        logger.log("action clicked");
        logger.log(this.props.actions, action);
        if (typeof action === "undefined") {

            let date = new Date();
            action = {
                buildingID: localStorage.buildingID,
                confirmed: false,
                day: {
                    Monday: false,
                    Tuesday: false,
                    Wednesday: false,
                    Thursday: false,
                    Friday: false,
                    Saturday: false,
                    Sunday: false,
                    date: this.dateFormat(date)
                },
                devices: [],
                enabled: true,
                id: "",
                repeat: false,
                text: sceneName,
                time: this.timeFormat(date),
                type: type
            }
        }

        this.state.action = action;
        this.setState({ action: this.state.action });
    }

    changeMenu = (menu, item) => {
        logger.log("changeMenu:", menu, item);
        this.setState({
            menu: menu,
            item: item
        });
    }

    sceneActivate = (sceneName, id) => {
        //let scene = this.props.building.scene.find(scene => scene[id] == sceneName);
        let scene = this.props.scenes.find(scene => scene.name === sceneName);
        logger.log("activate", scene);
        if (typeof scene !== "undefined") {
            this.state.scene = scene;

            this.state.scene.enabled = true;
            this.setState({ scene: this.state.scene });

            //this.state.scene.enabled = true;
            //this.setState({ scene: this.state.scene });


            sceneActivate(id);
        }
    }

    open_Snackbar() {
        this.openSnackbar(this.props.translations.successfulSave)
    }

    sceneClicked = (id, menuID) => {
        let scene = this.props.scenes.find(scene => scene.id === id);
        if (typeof scene === "undefined") {
            scene = {
                name: "",
                switches: [],
                thermostats: []
            }
        }
        this.state.scene = scene;
        if (menuID == 20) {
            this.setState({ scene: this.state.scene, menu: 20 });
        }
        else if (menuID == 20) {
            this.setState({ scene: this.state.scene, menu: 20 });
        }

    }


    actionTest = (id) => {
        //let action = this.props.building.actions.find(action => action.id === id)
        let action = this.props.actions.find(action => action.id === id);
        /* if(typeof action === "undefined"){
            action = {
                 text: "",
             }
         } */
        this.state.action = action;
        this.setState({ action: this.state.action });
    }

    currentButtonSelected = (screen) => {
        this.setState({ menu2: screen })
    }

    titleVariable = (sceneId) => {
        if (sceneId == 0) {
            return this.props.translations.switches;
        }
        else if (sceneId == 1) {
            return this.props.translations.scenarios;
        }
        else if (sceneId == 2) {
            return this.props.translations.timers;
        }
        else if (sceneId == 3) {
            return this.props.translations.commands;
        }
        else if (sceneId == 4) {
            return this.props.translations.camera;
        }
        else if (sceneId == 5) {
            return this.props.translations.alarm;
        }
        else if (sceneId == 6) {
            return this.props.translations.reminder;
        }
        else if (sceneId == 7) {
            return this.props.translations.notes;
        }
        else if (sceneId == 8) {
            return this.props.translations.scenarioProgCreation;
        }
        else if (sceneId == 9) {
            return this.props.translations.scenarioCreation;
        }
        else if (sceneId == 11) {
            if (this.state.action.type === "command")
                return this.props.translations.commandsCreation;
            else if (this.state.action.type === "alarm")
                return this.props.translations.alarmCreation;
            else if (this.state.action.type === "alert")
                return this.props.translations.reminderCreation;
        }
        else if (sceneId == 12) {
            return this.props.translations.permissions;
        }
        else if (sceneId == 13) {
            return this.props.translations.generalInformation;
        }
        else if (sceneId == 14) {
            return this.props.translations.myAccount
        }
        else if (sceneId == 15) {
            return this.props.translations.deleteHome;
        }
        else if (sceneId == 17) {
            return this.props.translations.invites;
        }
        else if (sceneId == 18) {
            return this.props.translations.energyMeteringCapital;
        }
        else {
            return this.props.translations.titleNameError;
        }
    }

    renderMenu = () => {
        switch (this.state.menu2) {
            case 'general_info':
                return (
                    <SnackbarProvider>
                        <GeneralInformation token={this.props.token} translations={this.props.translations} currentLanguage={this.props.currentLanguage} buildingLanguage={this.props.buildingLanguage} availableLanguages={this.props.availableLanguages} selectedBuilding={this.props.selectedBuilding} newDevices={this.props.updateDevices} currentButtonSelected={this.currentButtonSelected} isOpen={true}></GeneralInformation>
                    </SnackbarProvider>
                )
            case 'account':
                return (
                    <SnackbarProvider>
                        <AccountModal openSnackbar={this.props.openSnackbar} setUpBuilding={this.props.setUpBuilding} translations={this.props.translations} isOpen={true} currentButtonSelected={this.currentButtonSelected}> </AccountModal>
                    </SnackbarProvider>
                )
            case 'remove_building':
                return (
                    <SnackbarProvider>
                        <NewBuilding selectedBuilding={this.props.selectedBuilding} openSnackbar={this.props.openSnackbar} userEmail={this.props.userEmail} currentButtonSelected={this.currentButtonSelected} buildings={this.props.buildings} removeFunction={this.props.removeFunction} translations={this.props.translations} isOpen={true} ></NewBuilding>
                    </SnackbarProvider >
                )
            case 'messages':
                return (
                    <MessageArchive openSnackbar={this.props.openSnackbar} messages={this.props.messages} removeFunction={this.props.removeFunction} currentButtonSelected={this.currentButtonSelected} translations={this.props.translations} isOpen={true}></MessageArchive>
                )
            case 'invites':
                return (
                    <SnackbarProvider>
                        <NewInvites invites={this.props.invites} removeFunction={this.props.removeFunction} openSnackbar={this.props.openSnackbar} currentButtonSelected={this.currentButtonSelected} translations={this.props.translations} isOpen={true}></NewInvites>
                    </SnackbarProvider>
                )
            case 'change_building':
                return (
                    <ChangeBuildingModal buildings={this.props.buildings} selectedBuilding={this.props.selectedBuilding.name}
                        selectBuilding={(id) => this.props.selectBuilding(id)} isOpen={true} translations={this.props.currentLanguage.translation} currentButtonSelected={this.currentButtonSelected} />
                )
            case 'disconnect':
                this.props.clickedLogout();
        }
    }

    renderMain = () => {
        switch (this.state.menu) {
            case 0: //diakoptes
                return <NewSwitches changeMenu={this.changeMenu} switches={this.props.switches} thermostats={this.props.thermostats} buildingID={this.props.buildingID} getSwitches={this.props.getSwitches} currentLanguage={this.props.currentLanguage} translations={this.props.translations} ARgroups={this.props.ARgroups} openSnackbar={this.props.openSnackbar} permission={this.props.permission} ></NewSwitches>
            case 1: //senaria
                return (
                    <SnackbarProvider>
                        <NewScenes sceneActivate={this.sceneActivate} scenes={this.props.scenes} /*newScenes={this.props.building.scenes}*/ id={"name"} label={"name"}
                            new={this.props.translations.addNewScene} sceneClicked={this.sceneClicked} translations={this.props.translations} permission={this.props.permission} ></NewScenes>
                    </SnackbarProvider>)
            case 2: //prosenarion
                return (
                    <SnackbarProvider>
                        <NewProScene proSceneName={this.proSceneName} sceneActivate={this.proActionEnable} id={"id"}
                            permission={this.props.permission}
                            new={this.props.translations.multiplePlan} label={"name"} /*building={this.props.building}*/ devices={this.props.devices} scenes={this.props.scenes} scene={this.state.scene}
                            actions={this.props.actions} action={this.state.action} translations={this.props.translations}
                            sceneClicked={(name) => {
                                this.actionClicked(name, "scene", "id");
                                this.setState({ menu: 8 });
                                this.state.action.enabled = true;
                            }
                            } ></NewProScene>
                    </SnackbarProvider>)
            case 3: //proentolon
                return (
                    <SnackbarProvider>
                        <NewAction sceneActivate={this.actionEnable} permission={this.props.permission} id={"id"} new={this.props.translations.newCommand} label={"text"}
                            translations={this.props.translations}
                            removeFunction={this.props.removeFunction}
                            openSnackbar={this.props.openSnackbar}
                            type={"command"} actions={this.props.actions.filter(action => action.type == "command")}
                            actionTest={(name) => {
                                this.actionClicked(name, "command", "id");
                                this.setState({ menu: 11 });
                            }} >

                        </NewAction>
                    </SnackbarProvider>
                )
            case 4: //camera
                return <NewCamera token={this.props.token} devices={this.props.devices} translations={this.props.translations} permission={this.props.permission} cameraWindow={this.cameraWindow}></NewCamera>;
            case 5: //alarm
                return (
                    <SnackbarProvider>
                        <NewAction enableScene={this.proSceneName} sceneActivate={this.actionEnable} id={"id"} new={this.props.translations.newAlarm} label={"time"}
                            permission={this.props.permission}
                            removeFunction={this.props.removeFunction}
                            openSnackbar={this.props.openSnackbar}
                            type={"alarm"} actions={this.props.actions.filter(action => action.type == "alarm")} translations={this.props.translations}
                            actionTest={(name) => {
                                this.actionClicked(name, "alarm", "id");
                                this.setState({ menu: 11 });
                            }} ></NewAction>
                    </SnackbarProvider>)
            case 6: //alert
                return (
                    <SnackbarProvider>
                        <NewAction sceneActivate={this.actionEnable} id={"id"} new={this.props.translations.newReminder} label={"text"}
                            permission={this.props.permission}
                            removeFunction={this.props.removeFunction}
                            openSnackbar={this.props.openSnackbar}
                            type={"alert"} actions={this.props.actions.filter(action => action.type == "alert")} translations={this.props.translations}
                            actionTest={(name) => {
                                this.actionClicked(name, "alert", "id");
                                this.setState({ menu: 11 });
                            }} ></NewAction>
                    </SnackbarProvider>)
            case 7: //postit
                return (
                    <SnackbarProvider>
                        <NewNote buildingID={this.props.buildingID} devices={this.props.devices} token={this.props.token} translations={this.props.translations} permission={this.props.permission}></NewNote>
                    </SnackbarProvider>
                )
            case 8:
                return (
                    <SnackbarProvider>
                        <NewPorgramScene open_Snackbar={this.open_Snackbar} thermostats={this.props.thermostats} enableScene={this.proSceneName} sceneActivate={this.proActionEnable} changeMenu={this.changeMenu} token={this.props.token} buildingID={this.props.buildingID} devices={this.props.devices} actions={this.props.actions}
                            action={this.state.action} scenes={this.props.scenes} scene={this.state.scene} getScenes={this.props.getScenes} getActions={this.props.getActions}
                            addFunction={this.props.addFunction} removeFunction={this.props.removeFunction} translations={this.props.translations}></NewPorgramScene>
                    </SnackbarProvider>
                )
            case 20:
                return (
                    <SnackbarProvider>
                        <EditScene open_Snackbar={this.open_Snackbar} actions={this.props.actions} token={this.props.token} removeFunction={this.props.removeFunction} addFunction={this.props.addFunction}
                            changeMenu={this.changeMenu} scene={this.state.scene} thermostats={this.props.thermostats}
                            switches={this.props.switches} scenes={this.props.scenes} getScenes={this.props.getScenes}
                            translations={this.props.translations} /*building={this.props.building}*/ /*getScenes={this.getScenes}*/></EditScene>
                    </SnackbarProvider>
                )
            case 11:
                return (
                    <SnackbarProvider>
                        <EditAction open_Snackbar={this.open_Snackbar} enableScene={this.proSceneName} changeMenu={this.changeMenu} token={this.props.token} devices={this.props.devices} actions={this.props.actions}
                            action={this.state.action} removeFunction={this.props.removeFunction} addFunction={this.props.addFunction} getActions={this.props.getActions} translations={this.props.translations}></EditAction>
                    </SnackbarProvider>
                )

            case 12:
                return (
                    <SnackbarProvider>
                        <Permissions translations={this.props.translations}></Permissions>
                    </SnackbarProvider>
                )

            case 18:
                return (
                    <Energy building={this.props.building} buildingID={this.props.buildingID} translations={this.props.translations}
                        currentLanguage={this.props.currentLanguage} availableLanguages={this.props.availableLanguages} menu={this.state.menu}
                        getEnergy={() => this.props.getEnergy()} energySwitches={this.props.energySwitches} energyConsumption={this.props.energyConsumption}>
                    </Energy>
                )

            case 19:
                return (
                    <SwitchesEditMenu switches={this.props.switches} selectedSw={this.state.item} changeMenu={this.changeMenu} translations={this.props.translations} openSnackbar={this.props.openSnackbar} ARgroups={this.props.ARgroups} item ></SwitchesEditMenu>
                )

        }
    }

    cameraWindow = (url, title) => {
        logger.log("url:", url);
        logger.log("title:", title);
        this.setState({
            url: url,
            title: title
        })
    }

    render() {
        const topBar = (
            <span id="topBar">
                <div className="dropdown">
                    <div className="dropbtn">{this.props.currentLanguage.translation.changeBuidling.toUpperCase()}</div>
                    <div className="dropdown-content">
                        {this.props.buildings
                            .filter(
                                (building) =>
                                    building.name !=
                                    this.props.selectedBuilding.name
                            )
                            .map((building) => (
                                <span
                                    key={building.buildingID}
                                    className="buildingNames"
                                    onClick={() => {
                                        this.props.selectBuilding(building.buildingID);
                                    }}
                                >
                                    {building.name.toUpperCase()}
                                </span>
                            ))}
                    </div>
                </div>
                <div className="dropdown">
                    <div className="dropbtn">{this.props.currentLanguage.name.toUpperCase()}</div>
                    <div className="dropdown-content dropwdown-language" >
                        {this.props.availableLanguages
                            .filter(
                                (language) =>
                                    language.name !=
                                    this.props.currentLanguage.name

                            )
                            .map((language) => (
                                <span
                                    key={language.name}
                                    className="buildingNames"
                                    onClick={() => { this.props.changeLanguage(language) }}
                                >
                                    {language.name.toUpperCase()}
                                </span>
                            ))}
                    </div>
                </div>
            </span>
        );




        return (
            <div className="main container">
                <div className="container column">
                    <div className="header">
                        <div className="logoContainer">
                            <img src={require("./images/housemate_logo_01.png")}></img>
                        </div>
                        <div className="titleContainer">
                            {topBar}
                        </div>
                        <div>

                        </div>
                        <div className='profileContainer'>
                            {/* <div className="topBarImgAccount"><AccountSvg /></div>
                            <div id="buildingName">
                                <span className="buildingNames">
                                    {this.props.selectedBuilding.name.toUpperCase()}
                                </span>
                            </div> */}
                            <UserMenu buildings={this.props.buldings}
                                selectedBuilding={this.props.selectedBuilding.name}
                                currentButtonSelected={this.currentButtonSelected}
                                translations={this.props.translations}>
                            </UserMenu>
                        </div>
                    </div>
                    <div className="mainSection">
                        <div className="leftColumn">
                            <div className={this.state.menu == 0 ? "menuLeftRight menuOn" : "menuLeftRight"} onClick={() => { this.setState({ menu: 0 }) }}>
                                <img src={this.state.menu == 0 ? require("./images/switches_orange.png") : require("./images/switches_dark_blue.png")} ></img>
                                <span>{this.props.translations.switches}</span>
                            </div>
                            <div className={this.state.menu == 1 ? "menuLeftRight menuOn" : "menuLeftRight"} onClick={() => { this.setState({ menu: 1 }) }}>
                                <img src={this.state.menu == 1 ? require("./images/senaria_orange_02.png") : require("./images/senaria_dark_blue_01.png")} ></img>
                                <span>{this.props.translations.scenarios}</span>
                            </div>
                            <div className={this.state.menu == 2 ? "menuLeftRight menuOn" : "menuLeftRight"} onClick={() => { this.setState({ menu: 2 }) }}>
                                <img src={this.state.menu == 2 ? require("./images/senaria_orange_02.png") : require("./images/senaria_dark_blue_01.png")} ></img>
                                <span>{this.props.translations.timers}</span>
                            </div>
                            <div className={this.state.menu == 3 ? "menuLeftRight menuOn" : "menuLeftRight"} onClick={() => { this.setState({ menu: 3 }) }}>
                                <img src={this.state.menu == 3 ? require("./images/senaria_orange_02.png") : require("./images/senaria_dark_blue_01.png")} ></img>
                                <span>{this.props.translations.commands}</span>
                            </div>
                            <div className={this.state.menu == 4 ? "menuLeftRight menuOn" : "menuLeftRight"} onClick={() => { this.setState({ menu: 4 }) }}>
                                <img src={this.state.menu == 4 ? require("./images/camera_orange_02.png") : require("./images/camera_dark_blue_01.png")} ></img>
                                <span>{this.props.translations.camera}</span>
                            </div>
                            <div className={this.state.menu == 5 ? "menuLeftRight menuOn" : "menuLeftRight"} onClick={() => { this.setState({ menu: 5 }) }}>
                                <img src={this.state.menu == 5 ? require("./images/alarm_orange_02.png") : require("./images/alarm_dark_blue_01.png")} ></img>
                                <span>{this.props.translations.alarm}</span>
                            </div>
                            <div className={this.state.menu == 6 ? "menuLeftRight menuOn" : "menuLeftRight"} onClick={() => { this.setState({ menu: 6 }) }}>
                                <img src={this.state.menu == 6 ? require("./images/reminder_orange_02.png") : require("./images/reminder_blue_01.png")} ></img>
                                <span>{this.props.translations.reminder}</span>
                                {logger.log("Reminder:", this.props.translations.reminder)}
                            </div>
                            <div className={this.state.menu == 7 ? "menuLeftRight menuOn" : "menuLeftRight"} onClick={() => { this.setState({ menu: 7 }) }}>
                                <img src={this.state.menu == 7 ? require("./images/post_it_orange_02.png") : require("./images/post_it_dark_blue_01.png")}></img>
                                <span>{this.props.translations.notes}</span>
                            </div>
                            {this.props.energySwitches ? <div className={this.state.menu == 18 ? "menuLeftRight menuOn" : "menuLeftRight"} onClick={() => { this.setState({ menu: 18 }) }}>
                                <img src={this.state.menu == 18 ? require("./images/energy_metering.png") : require("./images/energy_metering_darkBlue.png")}></img>
                                <span>{this.props.translations.energy}</span>
                            </div> : null}
                        </div>
                        <div className="mainMenu">
                            <div className='mainContainer'>
                                {this.renderMain()}
                                {this.renderMenu()}
                            </div>
                        </div>
                    </div >
                </div >
                {this.state.url && <div id="cameraWindowContainer" className='cameraCardMax cameraCard'  >
                    <CameraWindow
                        src={this.state.url}
                        title={this.state.title}
                        translations={this.props.translations}
                        closeModal={() => {
                            this.setState({
                                url: null,
                            })
                        }}
                    />
                </div>}
            </div >
        );
    }
}

const options = {
    position: 'bottom-left',
    style: {
        marginBottom: '30px'
    }
}

export default withSnackbar(NewMain, options);
