import logger from './Components/Functions/logger';
import storage from './Storage';
import {actionsUrl} from './urls';

export function fetchActions(type) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'GET',
        };
        return storage.FetchData(actionsUrl + "/" + type + "/" + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function createAction(action) {
    logger.log("Inside createActions");
    logger.log(action);
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'POST',
            body: JSON.stringify({
                buildingID,
                action
            }),
        };
        return storage.FetchData(actionsUrl + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function updateActions(action) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                 buildingID,
                 action
            }),
        };
        logger.log("building", buildingID);
        return storage.FetchData(actionsUrl + '/' + action.id + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function toggleAction(action) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                ...action
            }),
        };
        return storage.FetchData(actionsUrl + '/' + action.id + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function deleteAction(id)
{
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'DELETE',
        };
        return storage.FetchData(actionsUrl + '/' + id + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}

export function deleteAllActions(type) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'DELETE',
        };
        return storage.FetchData(actionsUrl + '/all/' + type + '/' + buildingID, DATA);
    }).catch((error) => {
        logger.warn(error);
    });
}