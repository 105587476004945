import {specialChars} from './regexValidation';

export function homenameRegexCheck(homeName) {
    if (specialChars.renameBuilding(homeName)) {
        return false;
    }else{
        return true;
    }
}

export function usernameRegexCheck(name) {
    if (specialChars.checkUserName(name)) {
        return false;
    } else {
        return true;
    }
}

