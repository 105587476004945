import React, { useState, useEffect, useRef } from 'react';
import {setCity} from '../Functions/buildingFunctions'
import logger from '../Functions/logger';

function GooglePlacesAutocomplete(props) {
    const [userLocation, setUserLocation] = useState(null);

    useEffect(() => {
        if (userLocation) {
            logger.log("name:", userLocation.name);
            props.setCurrentCityModal(false);
            // Do something with the userLocation, for example, pass it to a parent component
        }
    }, [userLocation]);

    function handlePlaceSelect() {
        const input = inputRef.current;
        const options = {
            types: ['locality', 'country'],
            fields: ["address_components", "geometry", "icon", "name"],
        };
        const autocomplete = new window.google.maps.places.Autocomplete(input, options);

        autocomplete.addListener('place_changed', () => {
            const selectedPlace = autocomplete.getPlace();
            let cityName = selectedPlace.name;
            let cityCountry = selectedPlace.address_components.find(component => component.types.includes("country")).long_name
            logger.log("city country:",cityCountry);
            setCity(cityName, cityCountry)
                .then(() => {
                    logger.log("country:", selectedPlace.address_components.find(component => component.types.includes("country")).long_name);
                    setUserLocation(selectedPlace);
                    props.insertedLocation(cityName+","+cityCountry)
                })
                .catch((e) => { logger.log("error:", e); })
                .finally(() => {
                    logger.log("finally");
                })
        });
        
    }

    const inputRef = useRef(null);

    return (
        <div>
            <div>
                <input className="inputLocation" id='pac-input' type='text' ref={inputRef} onChange={handlePlaceSelect}></input>
            </div>
            <div>
                {userLocation && (
                    <span id='location'>
                        {userLocation.name}, {userLocation.address_components.find(component => component.types.includes("country")).long_name}
                    </span>
                )}
            </div>
        </div>
    );
}

export default GooglePlacesAutocomplete;


