import React from "react";
import NewOnOff from "./NewOnOff";
import "./DatePicker.css";
import NewActionDays from "./NewActionDays";
import Datetime from "react-datetime";
import NewDevices from "./NewDevices";
import axios from "axios";
import moment from "moment";
import {
	createAction,
	updateActions,
	toogleAction,
	deleteAction,
} from "../../ActionFunctions";
import { activateBuilding } from "../../urls";
import { withSnackbar } from "react-simple-snackbar";
import { ActionNameRules } from "../../validation";
import MenuCard from "./MenuCard";
import NewButtons from './NewButtons';
import logger from '../Functions/logger';

class EditAction extends React.Component {
	constructor(props) {
		super(props);
		this.insertLink =
			"https://hm-api.entranet.us:7656/housemate/action/createAction";
		this.updateLink =
			"https://hm-api.entranet.us:7656/housemate/action/updateAction";
		this.deleteLink =
			"https://hm-api.entranet.us:7656/housemate/action/deleteAction";
		this.state = {
			allDevices: [],
			action: null,
			date: null,
			noDate: "--/--/--",
			noDeviceSelected: false,
			nameExists: false,
			DaysSelected: false,
			buttonArray: []
		};
		logger.log("PROPS:", props.action);
	}

	componentDidMount() {


		logger.log("action:", this.props.action);

		const isNew = this.props.action.id === "";
		logger.log("ISNEW:", isNew);

		const newButtonArray = isNew ? [
			{ text: this.props.translations.cancel, onClickFunction: () => { this.changeMenu() } },
			{ text: this.props.translations.save2, onClickFunction: () => { this.save() } }
		] :
			[
				{ text: this.props.translations.cancel, onClickFunction: () => { this.changeMenu() } },
				{ text: this.props.translations.delete, onClickFunction: () => { this.deleteAction() } },
				{ text: this.props.translations.save2, onClickFunction: () => { this.save() } }
			]

		this.setState({
			action: this.props.action,
			buttonArray: newButtonArray
		});
	}

	dayClicked = (day) => {
		// if day is clicked -> repeat will be on
		this.state.action.repeat = true;
		this.state.action.day[day] = !this.state.action.day[day];

		//check if any day is clicked and set DaysSelected -> true so that the switch is on
		Object.keys(this.state.action.day).forEach((x) => {
			if (this.state.action.day[x] == true) {
				this.state.action.DaysSelected = true;
			}
		});

		//if no days are selected -> the switch is off and the action has the date that is selected
		if (!this.state.action.DaysSelected) {
			this.repeatOnOff(false);
			this.state.action.repeat = false;
			let date = new Date();
			this.state.action.day.date = this.dateFormat(date);
		} else {
			this.state.action.day.date = this.state.noDate;
		}

		this.state.action.DaysSelected = false;
		this.setState({ action: this.state.action });
	};

	repeatOnOff = (on) => {
		if (on === false) {
			//when the switch is off -> all days must be unselected
			this.unselectDays();
		}
		this.state.action.repeat = on;
		this.setState({ action: this.state.action });
	};

	unselectDays = () => {
		//every day that was selected (true) gets unselected (false)
		Object.keys(this.state.action.day).forEach((x) => {
			if (this.state.action.day[x] == true) {
				this.state.action.day[x] = false;
			}
		});
		let date = new Date();
		this.state.action.day.date = this.dateFormat(date);
		this.setState({ action: this.state.action });
	};

	timeFormat = (date) => {
		return moment(date).format("HH:mm");
	};

	dateFormat = (date) => {
		return moment(date).format("DD/MM/YYYY");
	};

	saveTime = (date) => {
		this.state.action.time = this.timeFormat(date);
		this.setState({ action: this.state.action });
	};

	saveDate = (date) => {
		this.state.action.day.date = this.dateFormat(date);
		this.setState({ action: this.state.action });
	};

	deviceOnOff = (on, deviceID) => {
		logger.log("deviceOnOff");

		let deviceIndex = this.props.devices.findIndex(
			(device) => deviceID === device.id
		);
		if (deviceIndex > -1) {
			logger.log("found the device");
			if (!on) {
				//if a device is not selected then remove it from the list of devices
				this.state.action.devices.splice(deviceIndex, 1);
				this.setState({ action: this.state.action });
			} else {
				//if a device is selected then add it to the list of devices
				this.state.action.devices.push(deviceID);
				this.state.action.noDeviceSelected = false;
				this.setState({ action: this.state.action });
			}
		}
	};

	deleteAction = () => {
		logger.log("delete");

		deleteAction(this.state.action.id)
			.then((responseData) => {
				this.props.removeFunction(this.state.action.id, "action");
				this.changeMenu();
			})
			.catch((error) => { });
	};

	createAction = () => {
		const { openSnackbar } = this.props;
		logger.log("create actions");
		let newDate = new Date();
		const actionDate = this.state.action.day.date.split("/"); // DD/MM/YYYY
		const time = this.state.action.time.split(":");
		let flag = false;

		//if the action is an alarm then set its name to the time that was selected
		if (this.state.action.type === "alarm") {
			this.props.enableScene(this.state.action.time);
		}

		//check if no devices are selected and show message for 2 seconds
		if (this.state.action.devices.length < 1) {
			flag = true;
			openSnackbar(this.props.translations.noDeviceChosen, 2000);
		}
		//check if name is no more than 4 characters and show message for 2 seconds
		else if (this.state.action.text.length < 5) {
			flag = true;
			openSnackbar(
				this.props.translations.smallName, 2000);
		} else if (this.state.action.day.date !== "--/--/--") {
			if (this.state.action.day.date !== "--/--/--") {
				if (parseInt(actionDate[2]) < newDate.getFullYear()) {
					flag = true;
					this.props.openSnackbar(this.props.translations.dateOrTimeOld);
				}
				if (parseInt(actionDate[2]) == newDate.getFullYear()) {
					logger.log("year");
					if (parseInt(actionDate[1]) < newDate.getMonth() + 1) {
						flag = true;
						this.props.openSnackbar(
							this.props.translations.dateOrTimeOld
						);
					}
					if (parseInt(actionDate[1]) == newDate.getMonth() + 1) {
						logger.log("month");
						if (parseInt(actionDate[0]) < newDate.getDate()) {
							flag = true;
							this.props.openSnackbar(
								this.props.translations.dateOrTimeOld
							);
						}
						if (parseInt(actionDate[0]) == newDate.getDate()) {
							logger.log("day");
							if (parseInt(time[0]) < newDate.getHours()) {
								flag = true;
								this.props.openSnackbar(
									this.props.translations.dateOrTimeOld
								);
							}
							if (parseInt(time[0]) == newDate.getHours()) {
								logger.log("hours");
								if (
									parseInt(time[1]) <=
									newDate.getMinutes() - 3
								) {
									flag = true;
									this.props.openSnackbar(
										this.props.translations.dateOrTimeOld
									);
								}
							}
						}
					}
				}
			}
		}

		//if none of the above errors are true then the action can be created
		if (flag == false) {
			createAction(this.state.action)
				.then((responseData) => {
					logger.log("--------");
					logger.log(this.state.action);
					this.props.addFunction(this.state.action, "action");
					this.changeMenu();
					this.props.getActions();
					if (responseData.state == 0) {
						this.props.open_Snackbar();
					}
				})
				.catch((error) => { });
		}
	};

	updateAction = () => {
		logger.log("update", this.state.action);
		if (this.state.action.type === "alarm") {
			this.props.enableScene(this.state.action.time);
		}

		let newDate = new Date();
		const actionDate = this.state.action.day.date.split("/"); // DD/MM/YYYY
		const time = this.state.action.time.split(":");
		let flag = false;

		if (this.state.action.day.date !== "--/--/--") {
			if (parseInt(actionDate[2]) < newDate.getFullYear()) {
				flag = true;
				this.props.openSnackbar(this.props.translations.dateOrTimeOld);
			}
			if (parseInt(actionDate[2]) == newDate.getFullYear()) {
				logger.log("year");
				if (parseInt(actionDate[1]) < newDate.getMonth() + 1) {
					flag = true;
					this.props.openSnackbar(this.props.translations.dateOrTimeOld);
				}
				if (parseInt(actionDate[1]) == newDate.getMonth() + 1) {
					logger.log("month");
					if (parseInt(actionDate[0]) < newDate.getDate()) {
						flag = true;
						this.props.openSnackbar(
							this.props.translations.dateOrTimeOld
						);
					}
					if (parseInt(actionDate[0]) == newDate.getDate()) {
						logger.log("day");
						if (parseInt(time[0]) < newDate.getHours()) {
							flag = true;
							this.props.openSnackbar(
								this.props.translations.dateOrTimeOld
							);
						}
						if (parseInt(time[0]) == newDate.getHours()) {
							logger.log("hours");
							logger.log(flag);
							if (parseInt(time[1]) <= newDate.getMinutes() - 3) {
								logger.log("minutes");
								logger.log(time[1]);
								logger.log(newDate.getMinutes() - 3);
								flag = true;
								this.props.openSnackbar(
									this.props.translations.dateOrTimeOld
								);
							}
						}
					}
				}
			}
		}
		if (flag == false) {
			if (this.state.action.enabled) {
				this.state.action.confirmed = false;
			}

			this.setState({ action: this.state.action });
			updateActions(this.state.action)
				.then((responseData) => {
					let index = this.props.actions.findIndex(
						(action) => action.id === this.state.action.id
					);
					if (index > -1) {
						this.props.actions[index] = this.state.action;
					}
					this.changeMenu();
				})
				.catch((error) => { });
		}
	};
	save = () => {
		//Validate the action
		if (this.state.action.type === "alarm") {
			this.state.action.text = this.state.action.time;
		}
		if (ActionNameRules(this.state.action.text, this.state.action.type)) {
			logger.log("HERE");
			this.state.action.enabled = true
			if (this.state.action.id === "") {
				this.createAction();
			} else {
				this.updateAction();
			}
		} else {
			this.props.openSnackbar(this.props.translations.wrongNameType);
		}
	};

	changeMenu = () => {
		let menu = 0;
		switch (this.state.action.type) {
			case "scene":
				menu = 2;
				break;
			case "command":
				menu = 3;
				break;
			case "alarm":
				menu = 5;
				break;
			case "alert":
				menu = 6;
				break;
			case "postit":
				menu = 7;
				break;
		}

		this.props.changeMenu(menu);
	};

	render() {

		const containerHeader = (
			this.state.action != null && this.state.action.type !== "alarm" ? (
				<div className="commandTitleArea">
					<span className="commandTitleLabel">
						<input
							type="text"
							name="name"
							placeholder={this.props.translations.insertCommand}
							value={this.state.action.text}
							onChange={(event) => {
								this.state.action.text = event.target.value;
								this.setState({
									action: this.state.action,
								});
							}}
						/>
					</span>
				</div>
			) : (
				<div></div>
			)
		)

		const buttonCancelFunction = () => {
			this.changeMenu()
		}

		const buttonSaveFunction = () => {
			this.save();
		}

		const buttonDeleteFunction = () => {
			this.deleteAction();
		}
		const actionTitle = () => {
			let actionTitle = '';
			logger.log("TYPE:", this.props.action.type);
			switch (this.props.action.type) {
				case "command":
					actionTitle = this.props.translations.multiplePlan;
					logger.log("actionTitle:", actionTitle);
					break;
				case "alarm":
					actionTitle = this.props.translations.alarmsStr;
					logger.log("actionTitle:", actionTitle);
					break;
				case "alert":
					actionTitle = this.props.translations.reminder;
					logger.log("actionTitle:", actionTitle);
					break;
				default:
					logger.log("actionTitle: No matching type");
					break;
			}
			return actionTitle;
		}


		const containerFooter = (
			this.state.action !== null &&
			<NewButtons
				buttonArray={this.state.buttonArray}
			>
			</NewButtons>
		)
		return (
			<MenuCard
				url={require("./images/senaria_orange_02.png")}
				title={actionTitle()}
				containerFooter={containerFooter}
				containerHeader={containerHeader}
				menuCard={"menuCard scenarioCard"}
				cardTitle={"cardTitle cardTitleColor"}
			>
				{this.state.action !== null && (
					<div className="container column onCreate">
						<div className="container actionLabelContainer">
							<div className="actionLabel">{this.props.translations.time}</div>
							<div className="container end time">
								<Datetime
									id="time"
									timeFormat="HH:mm"
									dateFormat={false}
									onChange={(date) => {
										this.saveTime(date._d);
									}}
									value={this.state.action.time}
								/>
							</div>
						</div>
						<div className="container actionLabelContainer">
							<div className="actionLabel">{this.props.translations.date}</div>
							<div className="container end date">
								<Datetime
									id="date"
									dateFormat="DD/MM/YYYY"
									timeFormat={false}
									closeOnSelect={true}
									onChange={(date) => {
										this.saveDate(date._d);
									}}
									value={
										!this.state.action.repeat
											? this.state.action.day.date
											: this.state.noDate
									}
								/>
							</div>
						</div>
						<div className="container space-between repeatLabelContainer">
							<div style={{ border: 'none' }}>{this.props.translations.repeat}</div>
						</div>
						<div className="daysArea actionLabelContainer">
							<NewActionDays
								day={this.state.action.day}
								dayClicked={this.dayClicked}
								translations={this.props.translations}
							></NewActionDays>
						</div>

						<div className="container repeatDeviceLabelContainer">
							<div className="actionLabel">{this.props.translations.devices}</div>
						</div>
						<div className="devicesArea">
							<NewDevices
								buildingDevices={this.props.devices}
								devices={this.state.action.devices}
								deviceOnOff={this.deviceOnOff}
							></NewDevices>
						</div>
					</div>
				)}
			</MenuCard>

		)

	}
}
const options = {
	position: "bottom-left",
	style: {
		marginBottom: "30px",
	},
};

export default withSnackbar(EditAction, options);
